// chakra imports
import {
  Box,
  Flex,
  Icon,
  useColorModeValue,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
//   Custom components
import Brand from "./Brand";
import React from "react";
import SidebarLink from "./Link";
import {
  FaChartBar,
  FaShareAltSquare,
  FaHistory,
  FaRegQuestionCircle,
  FaUserPlus,
  FaWpforms,
  FaDropbox,
} from "react-icons/fa";
import { RiAddCircleLine, RiUserReceivedFill } from "react-icons/ri";
import { isLoggedIn } from "@defense-station/auth";
import { IoLogIn, IoSettingsOutline } from "react-icons/io5";
import CollapsableLink from "./CollapsableLink";
import { FiList, FiSend } from "react-icons/fi";
import { TiCloudStorage } from "react-icons/ti";

// FUNCTIONS

function SidebarContent(props) {
  const textColor = useColorModeValue("navy.700", "white");
  const authenticated = isLoggedIn();
  const iconSize = useBreakpointValue({
    base: "20px",
    xl: "10px",
    "2xl": "20px",
  });
  // SIDEBAR
  return (
    <Flex
      direction="column"
      minH="100%"
      height="max-content"
      pt="25px"
      borderRadius="30px"
    >
      <Brand />
      <Stack direction="column" mb="auto" mt="8px">
        <Box ps="20px" pe={{ md: "16px", "2xl": "1px" }}>
          {authenticated ? (
            <>
              <SidebarLink
                to="/dashboard"
                name="Dashboard"
                icon={
                  <Icon
                    as={FaChartBar}
                    width={iconSize}
                    height={iconSize}
                    color="inherit"
                  />
                }
              />
              <CollapsableLink
                to="send"
                name="Send"
                icon={
                  <Icon
                    as={FiSend}
                    width={iconSize}
                    height={iconSize}
                    color="inherit"
                  />
                }
              >
                <SidebarLink
                  to="/send/new"
                  name="New"
                  icon={
                    <Icon
                      as={RiAddCircleLine}
                      width={iconSize}
                      height={iconSize}
                      color="inherit"
                    />
                  }
                />
                <SidebarLink
                  to="/send/history"
                  name="History"
                  icon={
                    <Icon
                      as={FaHistory}
                      width={iconSize}
                      height={iconSize}
                      color="inherit"
                    />
                  }
                />
              </CollapsableLink>
              <CollapsableLink
                to="request"
                name="Request"
                icon={
                  <Icon
                    as={FaUserPlus}
                    width={iconSize}
                    height={iconSize}
                    color="inherit"
                  />
                }
              >
                <SidebarLink
                  to="/request/new"
                  name="New"
                  icon={
                    <Icon
                      as={RiAddCircleLine}
                      width={iconSize}
                      height={iconSize}
                      color="inherit"
                    />
                  }
                />
                <SidebarLink
                  to="/request/history"
                  name="History"
                  icon={
                    <Icon
                      as={FaHistory}
                      width={iconSize}
                      height={iconSize}
                      color="inherit"
                    />
                  }
                />
                <SidebarLink
                  to="/request/responses"
                  name="Resposes"
                  icon={
                    <Icon
                      as={FaHistory}
                      width={iconSize}
                      height={iconSize}
                      color="inherit"
                    />
                  }
                />
              </CollapsableLink>
              <CollapsableLink
                to="dropzones"
                name="Dropzone"
                icon={
                  <Icon
                    as={FaUserPlus}
                    width={iconSize}
                    height={iconSize}
                    color="inherit"
                  />
                }
              >
                <SidebarLink
                  to="/dropzones/new"
                  name="New"
                  icon={
                    <Icon
                      as={RiAddCircleLine}
                      width={iconSize}
                      height={iconSize}
                      color="inherit"
                    />
                  }
                />
                <SidebarLink
                  to="/dropzones/history"
                  name="History"
                  icon={
                    <Icon
                      as={FaHistory}
                      width={iconSize}
                      height={iconSize}
                      color="inherit"
                    />
                  }
                />
                <SidebarLink
                  to="/dropzones/responses"
                  name="Responses"
                  icon={
                    <Icon
                      as={FaHistory}
                      width={iconSize}
                      height={iconSize}
                      color="inherit"
                    />
                  }
                />
              </CollapsableLink>
              <SidebarLink
                to="/templates/list"
                name="Templates"
                icon={
                  <Icon
                    as={FaWpforms}
                    width={iconSize}
                    height={iconSize}
                    color="inherit"
                  />
                }
              />
              {/* <SidebarLink
                as="button"
                to="/sypher/storage"
                name="Storage"
                icon={
                  <Icon
                    as={TiCloudStorage}
                    width={iconSize}
                    height={iconSize}
                    color="inherit"
                  />
                }
              />
              <SidebarLink
                as="button"
                to="/sypher/settings"
                name="Settings"
                icon={
                  <Icon
                    as={IoSettingsOutline}
                    width={iconSize}
                    height={iconSize}
                    color="inherit"
                  />
                }
              /> */}
            </>
          ) : (
            <>
              <SidebarLink
                as="button"
                to="/login"
                name="Login"
                icon={
                  <Icon
                    as={IoLogIn}
                    width={iconSize}
                    height={iconSize}
                    color="inherit"
                  />
                }
              />
            </>
          )}
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
