import { FormControl, FormLabel, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { FaFileInvoice } from "react-icons/fa";
import { useReadResponse } from "../../context/dropzone/read-dropzone-context";
import { readAndDecryptFileBlobFromURL } from "../../utils/custom-function";
import { Dropzone } from "../Dropzone";
import FileView from "../fileview";
import ViewFile from "./ViewFile";

function CustomArrayTemplate(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const readRequest = useReadResponse();
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState(null);
  const decKey = readRequest.decKey;
  const readFiles = readRequest.files;

  const onDownload = async () => {
    try {
      setIsDownloading(true);
      const decryptBlob = await readAndDecryptFileBlobFromURL(
        readFiles[props.name]?.value,
        decKey,
        props.formData
      );
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(decryptBlob);

      a.download = props.formData;
      a.click();
    } catch (e) {
      throw e;
    }
    setIsDownloading(false);
  };

  const onView = async () => {
    try {
      setIsLoading(true);
      const decryptBlob = await readAndDecryptFileBlobFromURL(
        readFiles[props.name]?.value,
        decKey,
        props.formData
      );
      const url = window.URL.createObjectURL(decryptBlob);
    //   setFileUrl(url);
      onOpen();
    } catch (e) {
      throw e;
    }
    setIsLoading(false);
  };

  const onMultiFileChange = (files) => {
    if (files?.length) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
        //   setFileName(`${file.path} (${file.size} bytes)`);
          props.onChange(file?.name);
        };
        // addFile(file, props.name + [i]);
      }
    }
  };

  return !props.readonly ? (
    <>
      <FormControl isRequired={props.required} width={"100%"}>
        <FormLabel
          display="flex"
          ms="10px"
          color={textColorPrimary}
          fontSize={"md"}
          fontWeight="bold"
          _hover={{ cursor: "pointer" }}
        >
          {props.schema.title}
        </FormLabel>
      </FormControl>
      <Dropzone
        multifiles={true}
        onChange={onMultiFileChange}
        name={props.name}
        values={fileName ? { [props.name]: { name: fileName } } : null}
        width="full"
      />
    </>
  ) : (
    <>
      {isOpen && (
        <FileView
          isOpen={isOpen}
          onClose={onClose}
          file_name={props.formData}
          fileLocalUrl={fileUrl}
        />
      )}
      <FormControl isRequired={props.required} width={"100%"}>
        <FormLabel
          display="flex"
          ms="10px"
          color={textColorPrimary}
          fontSize={"md"}
          fontWeight="bold"
          _hover={{ cursor: "pointer" }}
        >
          {props.schema.title}
        </FormLabel>
      </FormControl>
      <ViewFile
        name={props.formData}
        url="https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI"
        onView={onView}
        onDownload={onDownload}
        isDownloading={isDownloading}
        isLoading={isLoading}
        downloadAllowed
        icon={<FaFileInvoice />}
      />
    </>
  );
}
export default CustomArrayTemplate;
