import { SimpleGrid } from "@chakra-ui/react";
import { FieldItemType } from "../../types/common-types";
import DraggableItem from "../dnd-components/DraggableItem";
import { v4 as uuidv4 } from "uuid";
import PalleteElement from "../PalleteElement";
import { FIELDS } from "../../constants/fields";
import { useJSONBuilderContext } from "../../context/dnd-context";

type FieldPalleteProps = {
  fields?: FieldItemType[];
  renderFieldPalleteItem?: (data: FieldItemType) => JSX.Element;
  numOfColumns?: number;
};

export default function FieldPallete({ fields = FIELDS, renderFieldPalleteItem, numOfColumns = 1 }: FieldPalleteProps) {
  const { sidebarKey } = useJSONBuilderContext();
  return (
    <div key={sidebarKey?.toString()}>
      <SimpleGrid w="full" gap={3} columns={numOfColumns}>
      {fields.map((field) => {
        const id = uuidv4();
        let child = <PalleteElement {...field} />
        if(renderFieldPalleteItem){
          child = renderFieldPalleteItem(field);
        }
        return (
          <DraggableItem
            key={id}
            id={id}
            data={{ ...field, id: id, fromPallete: true }}
            w={"full"}
          >
            {child}
          </DraggableItem>
        );
      })}
      </SimpleGrid>
    </div>
  );
}
