import { Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import React from "react";

export default function CustomDocHeading({ children, ...rest }) {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textSize = useBreakpointValue({
    base: "2xl",
    xl: "md",
    "2xl": "2xl",
  });
  return (
    <Text
      fontSize={textSize}
      color={textColorPrimary}
      fontWeight="bold"
      {...rest}
    >
      {children}
    </Text>
  );
}
