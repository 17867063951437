import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { Player } from "@lottiefiles/react-lottie-player";
import jsonPlayerData from "../assets/error.json";
import CustomButton from "../components/custom-styled-components/CustomButton";
import { showServerError } from "../components/toast";

export default function ServerError({ error, reset = () => null }) {
  const onReset = () => {
    try {
      window.location.reload();
    } catch (e) {
      showServerError(e);
    }
  };
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection={"column"}
      flex="1"
      gap={"3"}
    >
      <Player
        autoplay
        loop
        keepLastFrame
        src={jsonPlayerData}
        style={{ height: "500px", width: "500px" }}
      ></Player>
      <Text px="4" fontSize={"xl"} fontWeight="500">
        {error
          ? `${error}`
          : "Something went wrong. Please try again after sometime."}
      </Text>
      <CustomButton colorScheme="brand" onClick={onReset}>
        Reload app
      </CustomButton>
    </Flex>
  );
}
