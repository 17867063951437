import React from "react";

// Chakra imports
import {
  Flex,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import IconBox from "../icons/IconBox";

// Custom components

export default function Transaction(props) {
  const { date, sum, icon, name, ...rest } = props;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconBoxBg = useColorModeValue("secondaryGray.300", "navy.700");
  const showDescription = useBreakpointValue({
    base: true,
    xl: false,
    "2xl": true,
  });
  const iconSize = useBreakpointValue({
    base: "42px",
    xl: "30px",
    "2xl": "42px",
  });
  return (
    <Flex justifyContent="center" alignItems="center" w="100%" {...rest}>
      <IconBox h={iconSize} w={iconSize} bg={iconBoxBg} me="20px" icon={icon} />
      <Flex direction="column" align="start" me="auto">
        <Text
          color={textColor}
          fontSize={{ base: "md", md: "xs", "2xl": "md" }}
          me="6px"
          fontWeight="700"
        >
          {name}
        </Text>
        {showDescription && (
          <Text
            color="secondaryGray.600"
            fontSize={{ base: "md", md: "xs", "2xl": "md" }}
            fontWeight="500"
          >
            {date}
          </Text>
        )}
      </Flex>
      <Text
        ms="auto"
        color={textColor}
        fontSize={{ base: "sm", md: "xs", "2xl": "md" }}
        me="6px"
        fontWeight="700"
      >
        {sum}
      </Text>
    </Flex>
  );
}
