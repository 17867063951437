import { UniqueIdentifier } from "@dnd-kit/core";
import { UseSortableArguments } from "@dnd-kit/sortable";
import { IconType } from "react-icons";

export type DroppableItemProps = {
  children: React.ReactElement;
} & UseSortableArguments;

export type DraggableItemProps = {
  children: React.ReactElement;
} & UseSortableArguments;

export enum LayoutType {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export type EnumOptionType = {
  title: string;
  const: string;
};

export type Item = {
  keyName: string;
  title: string;
  type: string;
  format?: string;
  id: UniqueIdentifier;
  required?: boolean;
  description?: string;
  icon: IconType;
  minLength?: number | undefined;
  maxLength?: number | undefined;
  minimum?: number | undefined;
  maximum?: number| undefined;
  minDate?: string | undefined;
  maxDate?: string | undefined;
  readonly?: boolean;
  secret?: boolean,
  multiline?: boolean;
  multiple?: boolean;
  placeholder?: string;
  direction?: LayoutType;
  parentId?: UniqueIdentifier;
  isLayoutElement?: boolean;
  options?: EnumOptionType[];
};

export interface FieldProps extends Omit<Item, 'isLayoutElement' | 'parentId' | 'direction' | 'title' | 'type' | 'id' | 'icon'>  {
  title?: string;
  type?: string;
  format?: string;
  id?: UniqueIdentifier;
  required?: boolean;
  description?: string;
  icon?: IconType;
  onDelete?: (event: React.MouseEvent<SVGElement, MouseEvent>) => void;
  data?: any;
};

export interface BuilderFieldArguments extends FieldProps {

};
