import React, { useState, useCallback } from "react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { FileText, Upload, X } from "lucide-react";

interface FileUploaderProps {
  value?: File;
  onChange: (file: File) => void;
  accept?: string;
  maxSize?: number;
}

export function FileUploader({ 
  value, 
  onChange, 
  accept = ".txt", 
  maxSize = 5 * 1024 * 1024 // 5MB default
}: FileUploaderProps) {
  const [dragOver, setDragOver] = useState(false);

  const handleFileChange = useCallback((selectedFile: File | null) => {
    if (!selectedFile) return;

    // Validate file type
    if (accept && !selectedFile.name.match(new RegExp(`(${accept.replace(/\./g, '')})$`, 'i'))) {
      alert(`Please upload a file with extension: ${accept}`);
      return;
    }

    // Validate file size
    if (selectedFile.size > maxSize) {
      alert(`File is too large. Maximum size is ${maxSize / 1024 / 1024}MB`);
      return;
    }

    onChange(selectedFile);
  }, [accept, maxSize, onChange]);

  const handleDragEvents = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragOver(true);
    } else if (e.type === 'dragleave' || e.type === 'drop') {
      setDragOver(false);
    }
  }, []);

  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    handleDragEvents(e);
    const files = e.dataTransfer?.files;
    if (files && files.length > 0) {
      handleFileChange(files[0]);
    }
  }, [handleDragEvents, handleFileChange]);

  return (
    <div 
      className={cn(
        "border-2 border-dashed rounded-lg p-6 text-center transition-colors duration-200",
        dragOver 
          ? "border-primary bg-primary/10" 
          : "border-gray-300 hover:border-primary"
      )}
      onDragEnter={handleDragEvents}
      onDragLeave={handleDragEvents}
      onDragOver={handleDragEvents}
      onDrop={handleDrop}
    >
      <input 
        type="file"
        className="hidden"
        id="file-upload"
        accept={accept}
        onChange={(e) => handleFileChange(e.target.files?.[0] || null)}
      />
      <label 
        htmlFor="file-upload" 
        className="cursor-pointer flex flex-col items-center justify-center"
      >
        {value ? (
          <div className="flex items-center space-x-2">
            <FileText className="w-6 h-6 text-primary" />
            <span className="text-sm">{value.name}</span>
            <Button 
              variant="ghost" 
              size="sm" 
              onClick={(e) => {
                e.preventDefault();
                onChange(undefined as any);
              }}
            >
              <X className="w-4 h-4" />
            </Button>
          </div>
        ) : (
          <>
            <Upload className="w-10 h-10 text-gray-400 mb-4" />
            <p className="text-sm text-gray-600">
              Drag and drop or click to upload
            </p>
            <p className="text-xs text-gray-500 mt-2">
              Accepted file type: {accept}
            </p>
          </>
        )}
      </label>
    </div>
  );
}

export default FileUploader;