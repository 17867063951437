import {
  JsonSchema,
  Layout,
} from "@reactjsonforms/core";
import { EnumOptionType, Item, LayoutType } from "../types/dnd-types";
import { v4 as uuidv4 } from "uuid";
import { MdLabel } from "react-icons/md";
import { GoColumns, GoRows } from "react-icons/go";
import { RxGroup } from "react-icons/rx";

// Function to convert schemas
export function convertSchemas(jsonSchema: JsonSchema, uiSchema: Layout): Item[] {
  const outputArray: Item[] = [];

  function processUiElements(
    uiElements: any,
    path = "",
    parentId = "mainContainer"
  ) {
    for (const element of uiElements) {
      if (element.type === "Control") {
        const keyName = element.scope.split("/").pop();
        // const propertyPath = path ? `${path}/${keyName}` : keyName;
        const id = uuidv4();

        const item: Item = {
          keyName,
          title: jsonSchema?.properties?.[keyName]?.title?jsonSchema?.properties?.[keyName]?.title:keyName,
          type: jsonSchema?.properties?.[keyName]?.type
            ? (jsonSchema?.properties[keyName]?.type as string)
            : "",
          id,
          icon: MdLabel, // You can set an appropriate default icon
          parentId,
          isLayoutElement: false,
          description: undefined,
        };

        const property = jsonSchema?.properties?.[keyName];
        if (property?.description) item.description = property.description;
        if (property?.enum)
          item.options = property.enum.map(
            (value) =>
              ({
                label: value,
                value,
              } as unknown as EnumOptionType)
          );
        if (property?.format) item.format = property.format;
        if (property?.minLength) item.minLength = property.minLength;
        if (property?.maxLength) item.maxLength = property.maxLength;
        if (property?.minimum) item.minimum = property.minimum;
        if (property?.maximum) item.maximum = property.maximum;
        if (property?.default) item.placeholder = property.default;
        if (property?.required) item.required = true;

        outputArray.push(item);
      } else if (
        element.type === "HorizontalLayout" ||
        element.type === "VerticalLayout"
      ) {
        const layoutId = uuidv4();
        const layoutItem: Item = {
          keyName: "",
          title: element.type,
          type: element.type,
          direction: element.type === "HorizontalLayout" ? LayoutType.HORIZONTAL : LayoutType.VERTICAL,
          id: layoutId,
          icon: element.type === "HorizontalLayout" ? GoColumns : GoRows, // You can set an appropriate layout icon
          parentId,
          isLayoutElement: true,
        };

        outputArray.push(layoutItem);

        if (element.elements) {
          processUiElements(element.elements, path, layoutId);
        }
      } else if (element.type === "Group") {
        const layoutId = uuidv4();
        const layoutItem: Item = {
          keyName: "",
          title: element.type,
          type: element.type,
          direction: LayoutType.VERTICAL,
          id: layoutId,
          icon: RxGroup, // You can set an appropriate layout icon
          parentId,
          isLayoutElement: true,
        };

        outputArray.push(layoutItem);

        if (element.elements) {
          processUiElements(element.elements, path, layoutId);
        }
      }
    }
  }

  processUiElements(uiSchema.elements);

  return outputArray;
}
