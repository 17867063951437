import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import jsonPlayerData from "../assets/response-not-found.json";
import { Text, useBreakpointValue } from "@chakra-ui/react";
import TextContent from "./custom-styled-components/TextContent";

export default function ResponseNotReceivedComponent() {
  const size = useBreakpointValue({
    base: "300px",
    xl: "200px",
    "2xl": "300px",
  });
  return (
    <>
      <Player
        autoplay
        keepLastFrame
        src={jsonPlayerData}
        style={{ height: size, width: size }}
      ></Player>
      <TextContent fontWeight="500">
        Response not recieved yet.
      </TextContent>
    </>
  );
}
