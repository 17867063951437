// Chakra imports
import { Flex, Spinner } from "@chakra-ui/react";
// Custom components
// Assets
import Card from "../../../card/Card";
import ResponseContent from "./ResponseContent";
import "../../../../assets/css/json-form.css";

export default function ResponseDetails(props) {
  const {
    response,
    bgButton,
    bgFocus,
    bgHover,
    schema,
    formData,
    isLoading,
    ...rest
  } = props;
  return (
    <Card
      className={"viewJSONData"}
      {...rest}
      justifySelf="center"
      alignSelf="center"
      my="0px"
      p="30px"
    >
      {!isLoading ? (
        <ResponseContent
          schema={schema}
          formData={formData}
          response={response}
        />
      ) : (
        <Flex justifyContent={"center"} alignItems="center">
          <Spinner />
        </Flex>
      )}
    </Card>
  );
}
