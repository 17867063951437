import React, { useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { nanoid } from "nanoid"
import { Download } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"

import { SymEncrptMessage } from "@/utils/encrypt"
import { useAuth } from "@/context/auth"
import { useRootKey } from "@/context/request/root-key"
import { setupClient } from "@/grpc/client"
import { showServerError } from "@/services/toast-service"
import { Spinner } from "@/components/ui/spinner"

// Zod validation schema
const rootKeySchema = z.object({
  phrase: z.string()
    .min(8, { message: "Phrase is too short - should be 8 chars minimum." }),
  confirmPhrase: z.string()
}).refine((data) => data.phrase === data.confirmPhrase, {
  message: "Phrases must match",
  path: ["confirmPhrase"],
})

type RootKeyFormProps = {
  onSuccess?: (data: {
    phrase: string;
    root_key: string;
    backup?: boolean;
  }) => void;
}

export default function RootKeyForm({ onSuccess }: RootKeyFormProps) {
  const { user } = useAuth()
  const { root_key, setRootKey, setRestoreRootKey, restoreRootKey } = useRootKey()

  const [isReady, setIsReady] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [downloadedBackupPhrase, setDownloadedBackupPhrase] = useState(false)

  const [phrase, setPhrase] = useState("")
  const [local_root_key, setLocalRootKey] = useState<string | null>(null)
  const [enRootKey, setEnRootKey] = useState("")
  const [backupPhrase, setBackupPhrase] = useState("")
  const [backupRootKey, setBackupRootKey] = useState("")

  const form = useForm<z.infer<typeof rootKeySchema>>({
    resolver: zodResolver(rootKeySchema),
    defaultValues: {
      phrase: "",
      confirmPhrase: "",
    },
  })

  const createAndDownloadBackupKey = () => {
    if (!user?.username) return

    const csvContent = `data:text/plain;charset=utf-8,${backupPhrase}`
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement("a")

    link.setAttribute("href", encodedUri)
    link.setAttribute("download", `${user.username}.txt`)
    document.body.appendChild(link)

    link.click()
    document.body.removeChild(link)

    setDownloadedBackupPhrase(true)
  }

  const onSubmit = async (values: z.infer<typeof rootKeySchema>) => {
    try {
      const date = new Date()
      setPhrase(values.phrase)

      const rootKey = root_key
        ? root_key
        : await SymEncrptMessage(date, `${user?.accountId}${user?.username}`)

      setLocalRootKey(rootKey)

      const enRootKey = await SymEncrptMessage(values.phrase, rootKey)
      setEnRootKey(enRootKey)

      const generatedBackupPhrase = nanoid(256)
      setBackupPhrase(generatedBackupPhrase)

      const backupRootKey = await SymEncrptMessage(generatedBackupPhrase, rootKey)
      setBackupRootKey(backupRootKey)

      setIsReady(true)
    } catch (e: any) {
      showServerError(e)
    }
  }

  const onContinue = async () => {
    if (!user?.accountId || !user?.username) return

    setLoading(true)
    try {
      const res = restoreRootKey
        ? await setupClient.updateRootKey({
          backRootKey: backupRootKey,
          rootKey: enRootKey,
        })
        : await setupClient.createRootKey({
          backRootKey: backupRootKey,
          rootKey: enRootKey,
        })

      if (onSuccess && local_root_key) {
        const successData = {
          phrase: phrase,
          root_key: local_root_key,
          ...(restoreRootKey ? { backup: true } : {})
        }

        setRestoreRootKey(false)
        onSuccess(successData)
        setRootKey(local_root_key)
      }
    } catch (e: any) {
      showServerError(e)
    } finally {
      setLoading(false)
    }
  }

  if (isReady) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Card className="w-full max-w-md">
          <CardHeader>
            <CardTitle>Download Backup Key</CardTitle>
            <CardDescription>
              If you forgot your security phrase, this backup key will be used to reset it.
              Without this key, all previous data will be lost.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <Button
                onClick={createAndDownloadBackupKey}
                className="w-full"
                variant="outline"
              >
                <Download className="mr-2 h-4 w-4" /> Download Backup Phrase
              </Button>

              <Button
                onClick={onContinue}
                className="w-full"
                disabled={!downloadedBackupPhrase || isLoading}
              >
                {isLoading ? <Spinner /> : "Continue"}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    )
  }

  return (
  <div className="w-full h-full flex justify-center items-center">
    <Card className="w-full max-w-md">
      <CardHeader>
        <CardTitle>Set Security Phrase</CardTitle>
        <CardDescription>
          Security phrase is used as an encryption key that stays in your browser.
          All data in the request feature is encrypted using this key.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="phrase"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Security Phrase</FormLabel>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder="Enter security phrase"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="confirmPhrase"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Confirm Security Phrase</FormLabel>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder="Confirm security phrase"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="text-destructive text-sm">
              Note: All data will be lost if you forget this phrase.
            </div>

            <Button
              type="submit"
              className="w-full"
              disabled={form.formState.isSubmitting}
            >
              {form.formState.isSubmitting ? <Spinner /> : "Submit"}
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
      </div >
    )
}