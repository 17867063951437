// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/sypher/v1/service/request/request.proto (package sypher.v1.request, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AccessRequestRequest, AccessRequestResponse, CreateRequestRequest, CreateRequestResponse, GetRequestRequest, GetRequestResponse, ListRequestsRequest, ListRequestsResponse, UpdateRequestRequest } from "./request_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service sypher.v1.request.RequestService
 */
export const RequestService = {
  typeName: "sypher.v1.request.RequestService",
  methods: {
    /**
     * @generated from rpc sypher.v1.request.RequestService.CreateRequest
     */
    createRequest: {
      name: "CreateRequest",
      I: CreateRequestRequest,
      O: CreateRequestResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.request.RequestService.GetRequest
     */
    getRequest: {
      name: "GetRequest",
      I: GetRequestRequest,
      O: GetRequestResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.request.RequestService.ListRequests
     */
    listRequests: {
      name: "ListRequests",
      I: ListRequestsRequest,
      O: ListRequestsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.request.RequestService.UpdateRequest
     */
    updateRequest: {
      name: "UpdateRequest",
      I: UpdateRequestRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.request.RequestService.AccessRequest
     */
    accessRequest: {
      name: "AccessRequest",
      I: AccessRequestRequest,
      O: AccessRequestResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

