import React, { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import {
  Box,
  Flex,
  HStack,
  Heading,
  Spinner,
  VStack,
  Button,
  FormControl,
  FormLabel,
  Text,
  Grid,
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbItem,
  Link,
  Tooltip,
  useColorModeValue,
  Icon,
  Stack,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";
import validator from "@rjsf/validator-ajv8";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  accessDropzone,
  uploadFile,
  submitRequestData,
} from "@defense-station/api-service";
import { useAuth } from "../context/auth";
import { navigateToUrl } from "single-spa";
import { Formik, Form } from "formik";
import { Dropzone } from "../components/Dropzone";
import * as yup from "yup";
import {
  PostQAsymEncryptFile,
  PostQAsymEncryptMessage,
} from "../utils/encrypt";
import {  signOut, setRedirectionBackActionForLogin } from "@defense-station/auth";
import MessageContent from "../components/secure-share/secure-view/MessageContent";
import MiniStatistics from "../components/dashboard/MiniStatistics";
import { BiMailSend, BiStopwatch } from "react-icons/bi";
import IconBox from "../components/icons/IconBox";
import Card from "../components/card/Card";
import InputField from "../components/fields/InputField";
import TextField from "../components/fields/TextField";
import * as timeago from "timeago.js";
import LinkBroken from "../components/secure-share/request/LinkBroken";
import LoginScreen from "../components/secure-share/request/LoginScreen";
import DocSideBar from "../components/dashboard/DocSideBar";
import toast, { showServerError } from "../components/toast";
import CustomStringField from "../components/rjsf/CustomStringField";
import { useSubmitData } from "../context/request/submit-data";
import { buildFileFromString, futch } from "../utils/custom-function";
import DatePicker from "../components/DatePicker";
import { addDays, endOfDay, startOfDay } from "date-fns";
import NumberField from "../components/fields/NumberField";
import CustomCheckboxField from "../components/rjsf/CustomCheckboxField";
import CustomArrayTemplate from "../components/rjsf/CustomArrayTemplate";
import { RiLockPasswordFill } from "react-icons/ri";
import EnterPassword from "../components/secure-share/dropzone/EnterPassword";
import CardHeaderText from "../components/custom-styled-components/CardHeaderText";
import CardDescriptionText from "../components/custom-styled-components/CardDescriptionText";
import CustomFormLabel from "../components/custom-styled-components/CustomFormLabel";
import CustomButton from "../components/custom-styled-components/CustomButton";
import DataSubmittedComponent from "../components/DataSubmittedComponent";
import { SOMETHING_WENT_WRONG } from "../constants/constants";
import CustomProgressBar from "../components/custom-styled-components/CustomProgressBar";
import JSONForm from "../components/JSONForm";

const buildValidationRules = (requestedData = null) => {
  let schema = {};
  if (requestedData) {
    requestedData?.requested_fields?.map((field) => {
      if (field?.field_required) {
        schema = {
          ...schema,
          [field?.field_name]: yup
            .string()
            .required(`${field?.field_name} can't blank.`),
        };
      }
    });
    requestedData?.requested_files?.map((file) => {
      if (file?.file_required) {
        schema = {
          ...schema,
          [file?.file_name]: yup
            .mixed()
            .required(`${file?.file_name} can't blank.`),
        };
      }
    });
  }
  return yup.object().shape(schema);
};

export default function DropzoneSubmit() {
  const params = useParams();
  const { user } = useAuth();
  const searchParams = new URLSearchParams(window.location.search);
  const [login_required, setLoginRequired] = useState(false);
  const [linkBroken, setLinkBroke] = useState(false);
  const [validations, setValidations] = useState(buildValidationRules());
  const [data, setData] = useState(null);
  const [session, setSession] = useState(null);
  const [schema, setSchema] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [progress, setProgress] = useState(0);
  const [isPassword, setIsPassword] = useState(
    params.is_password == "no" ? false : true
  );
  const [isLoading, setLoading] = useState(false);
  const [password, setPassword] = useState();
  const baseDate = new Date();
  const retryCount = useRef(0);

  const [range, setRange] = useState([
    {
      start_date: startOfDay(baseDate),
      end_date: endOfDay(addDays(baseDate, 3)),
      startDate: startOfDay(baseDate),
      endDate: endOfDay(addDays(baseDate, 3)),
      key: "selection",
    },
  ]);
  const [accessLimit, setAccessLimit] = useState(10);
  const onAccessLimitChange = (value) => {
    if (value >= 100) {
      setAccessLimit(100);
    } else if (value <= 0) {
      setAccessLimit(0);
    } else {
      setAccessLimit(value);
    }
  };
  const [isSubmitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState({});
  const { files, addFile } = useSubmitData();
  const fetchData = async (password = null) => {
    if (!params?.uuid || !searchParams.get("id")) {
      setLinkBroke(true);
      return true;
    }
    if (isPassword && !password) {
      return;
    }
    setLoading(true);
    // const token = await window.grecaptcha.execute(captchaKey, {
    //   action: "access_dropzone",
    // });
    await accessDropzone(
      params?.uuid,
      password,
      searchParams.get("id"),
      searchParams.get("respondent"),
      null,
      {
        disableErrorHandling: true,
      }
    )
      .then((result) => {
        const res = result.data?.sypher_v1_AccessDropzone;
        setSession(res?.session);
        // setValidations(buildValidationRules(res));
        let template = String.fromCharCode(
          ...res?.dropzone?.template?.data?.data
        );
        template = JSON.parse(template);
        setSchema(template);
        if (res.errors) {
          const error = res.errors[0]?.message;
          const code = error.split(" ")[0];
          if (code == 7) {
          }
        } else {
          setData(res?.dropzone);
        }
      })
      .catch((e) => {
        if (
          e.message.includes("permission denied") ||
          e.message.includes("access denied")
        ) {
          if (e.message.includes("login required")) {
            setLoginRequired(true);
          } else {
            setLinkBroke(true);
          }
        }
        if (e.message.includes("3 INVALID_ARGUMENT: invalid password")) {
          setPassword(null);
          toast({
            title: "Invalid Password",
            description: "Password you have entered is invalid.",
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "top",
          });
        } 
        else if(e.message.includes("2 UNKNOWN: redis: nil")) {
          console.log("retrying")
          if(retryCount.current == 0){
            retryCount.current = 1;
            signOut().then(fetchData);
          }
          else {
            setLinkBroke(true);
          }
        } 
        else {
          showServerError(e);
        }
      });
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
    // const unlink = loadCaptchaScript(fetchData);
    // return () => {
    //   unlink();
    // };
  }, []);

  const onSignIn = () => {
    setRedirectionBackActionForLogin();
    navigateToUrl("/login");
  };

  const onChange = (data) => {
    if (data?.data){
      setFormData(data?.data);
    }
  };
  const onSubmit = async (submittedFormData) => {
    setProgress(0);
    setLoadingText("Encrypting...");
    setSubmitting(true);
    try {
      const enc_key = data.enc_key;
      // const formData = submittedFormData.formData;
      // const schema = submittedFormData.schema?.properties;
      const { createResponse, createResponseWithLimit } = await import(
        "@defense-station/api-service"
      );
      // Added bytes to match the size after encryption so that we can send it to create the response.
      const FIXED_ENCRYPTION_SIZE = 5645;
      let dataSize = 0;
      const fileData = Object.keys(files)?.map((key) => {
        dataSize += files[key]?.size + FIXED_ENCRYPTION_SIZE;
        return {
          id: key,
          size: files[key]?.size + FIXED_ENCRYPTION_SIZE,
        };
      });
      let contentLength = 0;
      let uploadedData = 0;
      setLoadingText("Uploading...");
      const endDate = endOfDay(range[0]?.end_date)?.toISOString();
      console.log({formData})
      const submittableData = JSON.stringify(formData);
      const dataFile = buildFileFromString(submittableData);
      let response = null;
      const sendableFiles = fileData
        ? [
            ...fileData,
            { id: "data", size: dataFile.size + FIXED_ENCRYPTION_SIZE },
          ]
        : [{ id: "data", size: dataFile.size + FIXED_ENCRYPTION_SIZE }];
      dataSize += dataFile.size + FIXED_ENCRYPTION_SIZE;
      const totalSize = contentLength + dataSize;
      if (data?.responder_controls) {
        contentLength = JSON.stringify({
          session: session,
          sendableFiles: sendableFiles,
          accessLimit: accessLimit,
          start_date: range[0].start_date?.toISOString(),
          end_date: endDate,
        }).length;
        response = await createResponseWithLimit(
          session,
          sendableFiles,
          accessLimit,
          range[0].start_date?.toISOString(),
          endDate
        );
      } else {
        contentLength = JSON.stringify({
          session: session,
          sendableFiles: sendableFiles,
        }).length;
        response = await createResponse(session, sendableFiles);
      }
      uploadedData = contentLength;
      setProgress(((uploadedData / totalSize) * 100).toFixed(0));
      const trackProgress = (e) => {
        const progressNow = (
          ((uploadedData + e.loaded) / totalSize) *
          100
        ).toFixed(0);
        if (e.loaded == e.total) {
          setProgress((prevProgress) => {
            return progressNow > prevProgress ? progressNow : prevProgress;
          });
          uploadedData += e.total;
        } else {
          setProgress((prevProgress) => {
            return progressNow > prevProgress ? progressNow : prevProgress;
          });
        }
      };
      const urls = response.data?.sypher_v1_CreateResponse?.signed_urls;
      let headers = {};
      if (data?.responder_controls) {
        headers = {
          "x-amz-meta-expired-at": endDate,
        };
      }
      await Promise.all(
        Object.keys(urls)?.map(async (key) => {
          if (key != "data") {
            const enBlob = await PostQAsymEncryptFile(enc_key, files[key]);
            const file = new File([enBlob], files[key].name, {
              type: files[key]?.type,
            });
            const opt = {
              headers: {
                ...headers,
                "Content-Type": file?.type,
              },
              body: file,
              method: "PUT",
            };
            await futch(urls[key], opt, trackProgress);
          } else {
            const enBlob = await PostQAsymEncryptFile(enc_key, dataFile);
            const file = new File([enBlob], dataFile.name, {
              type: "plain/text",
            });
            const opt = {
              headers: {
                ...headers,
                "Content-Type": file?.type,
              },
              body: file,
              method: "PUT",
            };
            await futch(urls[key], opt, trackProgress);
          }
        })
      ).catch((e) => {
        throw e;
      });
      setSubmitted(true);
    } catch (e) {
      if (e.response) {
        toast({
          title: "Response Error",
          description: e?.response?.errors[0]?.message,
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });

        return;
      }

      toast({
        title: "Application Error",
        description: e?.message ? e?.message : SOMETHING_WENT_WRONG,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    }
    setSubmitting(false);
  };

  const onSetPassword = (password) => {
    setPassword(password);
    fetchData(password);
  };

  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorLink = useColorModeValue("blue.500", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const statisticsIconSize = useBreakpointValue({
    base: "32px",
    xl: "25px",
    "2xl": "32px",
  });
  const statisticsIconBoxSize = useBreakpointValue({
    base: "56px",
    xl: "40px",
    "2xl": "56px",
  });


  console.log({schema})
  return (
    <>
      {submitted ? (
        <Flex height={"95vh"} justifyContent={"center"} alignItems="center">
          <VStack>
            <DataSubmittedComponent />
          </VStack>
        </Flex>
      ) : linkBroken ? (
        <LinkBroken />
      ) : login_required ? (
        <LoginScreen />
      ) : isPassword && !password ? (
        <EnterPassword isLoading={isLoading} onSubmit={onSetPassword} />
      ) : data ? (
        <Grid
          h="calc(100vh - 48px)"
          w="100%"
          maxH={"calc(100vh - 48px)"}
          maxW="100%"
          overflow={"auto"}
          gap="4"
          p={{ base: "6", xl: "6", "2xl": "6" }}
          display={{ base: "block", "2xl": "grid" }}
          gridTemplateColumns="2.9fr 1fr"
        >
          <Box gridArea="1 / 1 / 2 / 2" mb={{ base: "20px", "2xl": "0px" }}>
            <Box flex={1}>
              <Box mb={{ sm: "8px", md: "0px" }}>
                <Breadcrumb>
                  <BreadcrumbItem color={secondaryText} fontSize="sm">
                    <BreadcrumbLink href="/" color={secondaryText}>
                      Sypher
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem color={secondaryText} fontSize="sm">
                    <BreadcrumbLink href="share" color={secondaryText}>
                      Requested
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
                {/* <Link
                  color={mainText}
                  href="#"
                  bg="inherit"
                  borderRadius="inherit"
                  fontWeight="bold"
                  fontSize="34px"
                  _hover={{ color: { mainText } }}
                  _active={{
                    bg: "inherit",
                    transform: "none",
                    borderColor: "transparent",
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                >
                  Requested
                </Link> */}
              </Box>
              <Stack spacing={{ base: "4", xl: "2", "2xl": "4" }}>
                <Grid
                  gridTemplateColumns="2.9fr 1fr"
                  display={{ base: "block", md: "grid" }}
                  gap={{ base: "20px", xl: "10px", "2xl": "20px" }}
                >
                  <Box gridArea="1 / 1 / 2 / 2">
                    <MessageContent
                      flex={{ base: "1", lg: "3.5" }}
                      maxH={{ base: "100%", lg: "200px" }}
                      username={data?.username}
                      hide={data?.hide_identity}
                      message={data?.message}
                    />
                  </Box>
                  <Box gridArea="1 / 2 / 2 / 3">
                    <SimpleGrid
                      h="100%"
                      gap={{ base: "20px", xl: "10px", "2xl": "20px" }}
                      columns={1}
                    >
                      <MiniStatistics
                        tagEnable={data?.hide_stats}
                        tagTooltipLabel={"Stats are hidden by the sender."}
                        startContent={
                          <IconBox
                            w={statisticsIconBoxSize}
                            h={statisticsIconBoxSize}
                            bg={boxBg}
                            icon={
                              <Icon
                                w={statisticsIconSize}
                                h={statisticsIconSize}
                                as={RiLockPasswordFill}
                                color={brandColor}
                              />
                            }
                          />
                        }
                        name="Password"
                        value={data?.password ? "YES" : "NO"}
                      />
                      <MiniStatistics
                        tagEnable={data?.hide_stats}
                        tagTooltipLabel={"Stats are hidden by the sender."}
                        startContent={
                          <IconBox
                            w={statisticsIconBoxSize}
                            h={statisticsIconBoxSize}
                            bg={boxBg}
                            icon={
                              <Icon
                                w={statisticsIconSize}
                                h={statisticsIconSize}
                                as={BiStopwatch}
                                color={brandColor}
                              />
                            }
                          />
                        }
                        name="Expiring"
                        value={
                          data?.hide_stats
                            ? "Hidden"
                            : timeago.format(data?.end_date)
                        }
                      />
                    </SimpleGrid>
                  </Box>
                </Grid>
                {data?.responder_controls && (
                  <Card
                    flex={{ base: "1", lg: "1" }}
                    mt={{ base: "4", md: "0" }}
                  >
                    <Flex
                      direction="column"
                      mb={{
                        base: "30px",
                        xl: "15px",
                        "2xl": "30px",
                      }}
                      ms="10px"
                    >
                      <CardHeaderText
                        color={textColorPrimary}
                        fontWeight="bold"
                      >
                        Settings
                      </CardHeaderText>
                      <CardDescriptionText color={textColorSecondary}>
                        Required settings for limited access
                      </CardDescriptionText>
                    </Flex>
                    <Flex flexDirection={{ base: "column", md: "row" }}>
                      <Flex flex="1">
                        <FormControl isRequired direction="column" mb="0px">
                          <CustomFormLabel
                            display="flex"
                            ms="10px"
                            color={textColorPrimary}
                            fontWeight="bold"
                            _hover={{ cursor: "pointer" }}
                          >
                            Select Access Date Range
                            <Flex
                              ml="2"
                              alignItems={"center"}
                              justifyContent="center"
                            >
                              <Tooltip label="Choose when you want message to be accessed">
                                <InfoOutlineIcon color={"brand.600"} />
                              </Tooltip>
                            </Flex>
                          </CustomFormLabel>
                          <DatePicker
                            baseDate={baseDate}
                            range={range}
                            onDateChange={(range) => setRange(range)}
                          />
                        </FormControl>
                      </Flex>
                      <Flex flex="1">
                        <NumberField
                          mb="25px"
                          pr="4.5rem"
                          max={100}
                          label="Access Limit"
                          required
                          name="retry_limit"
                          min={1}
                          onChange={onAccessLimitChange}
                          value={accessLimit}
                          p="3"
                          width="50px"
                        />
                      </Flex>
                    </Flex>
                  </Card>
                )}
                <>
                  {schema ? (
                    <Card flex="2" mt="4">
                      <Flex
                        direction="column"
                        mb={{
                          base: "30px",
                          xl: "15px",
                          "2xl": "30px",
                        }}
                        ms="10px"
                      >
                        <CardHeaderText
                          color={textColorPrimary}
                          fontWeight="bold"
                        >
                          Submit Data
                        </CardHeaderText>
                        <CardDescriptionText color={textColorSecondary}>
                          Enter information to be shared
                        </CardDescriptionText>
                      </Flex>
                      <Formik
                        enableReinitialize
                        validateOnChange={false}
                        initialValues={formData}
                        onSubmit={onSubmit}
                      >
                        {(formProps) => {
                          const {
                            values,
                            errors,
                            isSubmitting,
                            setFieldValue,
                            handleChange,
                          } = formProps;
                          return (
                            <Form>
                              <JSONForm
                                schema={schema.schema}
                                uischema={schema.uischema}
                                onChange={onChange}
                              />
                              <Flex mt="4" mb="4">
                                <CustomButton
                                  type="submit"
                                  colorScheme="brand"
                                  disabled={isSubmitting}
                                  variant={isSubmitting ? "ghost" : "solid"}
                                  width={"full"}
                                >
                                  {isSubmitting
                                    ? !progress
                                      ? loadingText
                                      : ""
                                    : "Submit"}
                                  {isSubmitting && (
                                    <CustomProgressBar
                                      label={`Uploading...(${progress}%)`}
                                      value={progress}
                                    />
                                  )}
                                </CustomButton>
                              </Flex>
                            </Form>
                          );
                        }}
                      </Formik>
                    </Card>
                  ) : null}
                </>
              </Stack>
            </Box>
          </Box>
          <Box
            gridArea="1 / 2 / 2 / 3"
            mt={{ base: "0", lg: "2" }}
            p={{ base: "6", "2xl": "0px" }}
            pr={{ base: "6", "2xl": "6" }}
            display={{ base: "none", "2xl": "block" }}
          >
            <DocSideBar />
          </Box>
        </Grid>
      ) : (
        <Flex height={"95vh"} justifyContent={"center"} alignItems="center">
          <HStack>
            <Heading>Getting Data...</Heading>
            <Spinner size={"lg"} />
          </HStack>
        </Flex>
      )}
    </>
  );
}
