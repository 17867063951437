import { Item, LayoutType } from "../types/dnd-types";

import {
    MdCalendarToday,
    MdCheckBox,
    MdEmail,
    MdFileUpload,
    MdHttp,
    MdInput,
    MdLocationOn,
    MdOutlineArrowDropDownCircle,
    MdRadioButtonChecked,
    MdTextFields,
  } from "react-icons/md";
  import { GoColumns, GoRows } from "react-icons/go";
  import { RxGroup } from "react-icons/rx";
  import { TbDecimal } from "react-icons/tb";
  import { RiNumber1 } from "react-icons/ri";

export const FIELDS: Omit<Item, 'id'>[] = [
    {
      keyName: "Email",
      title: "Email",
      type: "string",
      format: "email",
      icon: MdEmail,
    },
    {
      keyName: "File",
      title: "File",
      type: "string",
      format: "data-url",
      multiple: true,
      icon: MdFileUpload,
    },
    {
      keyName: "URI",
      title: "URI",
      type: "string",
      format: "uri",
      icon: MdHttp,
    },
    {
      keyName: "Input",
      title: "Input",
      type: "string",
      minLength: 3,
      icon: MdInput,
    },
    {
      keyName: "Number Input",
      title: "Number Input",
      type: "number",
      minLength: 3,
      icon: TbDecimal,
    },
    {
      keyName: "Integer Input",
      title: "Integer Input",
      type: "integer",
      minLength: 3,
      icon: RiNumber1,
    },
    {
      keyName: "Date",
      title: "Date",
      type: "string",
      format: "date",
      icon: MdCalendarToday,
    },
    {
      keyName: "TextArea",
      title: "TextArea",
      type: "string",
      minLength: 3,
      multiline: true,
      icon: MdTextFields,
    },
    {
      keyName: "Checkbox",
      title: "Checkbox",
      type: "boolean",
      icon: MdCheckBox,
    },
    {
      keyName: "Dropdown",
      title: "Dropdown",
      type: "string",
      format: "enum",
      icon: MdOutlineArrowDropDownCircle,
    },
    {
      keyName: "Radio",
      title: "Radio",
      type: "string",
      format: "radio",
      icon: MdRadioButtonChecked,
    },
    {
      keyName: "IPv4",
      title: "IPv4",
      type: "string",
      format: "ipv4",
      icon: MdLocationOn,
    },
    {
      keyName: "Horizontal",
      title: "Horizontal",
      type: "HorizontalLayout",
      direction: LayoutType.HORIZONTAL,
      icon: GoColumns,
      isLayoutElement: true,
    },
    {
      keyName: "Vertical",
      title: "Vertical",
      type: "VerticalLayout",
      direction: LayoutType.VERTICAL,
      icon: GoRows,
      isLayoutElement: true,
    },
    {
      keyName: "Group",
      title: "Group",
      type: "Group",
      direction: LayoutType.VERTICAL,
      icon: RxGroup,
      isLayoutElement: true,
    },
  ];