// Chakra imports
import {
  Avatar,
  Badge,
  Box,
  Flex,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";
import Card from "../../../card/Card";
import responseReceivedJson from "../../../../assets/response-recieved.json";
import CardHeaderText from "../../../custom-styled-components/CardHeaderText";
import CardDescriptionText from "../../../custom-styled-components/CardDescriptionText";
import CustomBadge from "../../../custom-styled-components/CustomBadge";

export default function Banner(props) {
  const {
    banner,
    avatar,
    name,
    job,
    startDate,
    endDate,
    status,
    statusColor,
    responderControls,
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  const completeBg = useColorModeValue(
    "white",
    "linear-gradient(180deg, #1F2A4F 0%, #18224D 50.63%, #111C44 100%)"
  );
  const completeShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "inset 0px 4px 4px rgba(255, 255, 255, 0.2)"
  );
  const iconSize = useBreakpointValue({
    base: "87px",
    xl: "60px",
    "2xl": "87px",
  });
  const iconMarginTop = useBreakpointValue({
    base: "-48px",
    xl: "-32px",
    "2xl": "-48px",
  });
  return (
    <Card align="center">
      <Box
        bg={`url(${banner})`}
        bgSize="cover"
        borderRadius="16px"
        h="131px"
        w="100%"
      />
      <Box
        mx="auto"
        h={iconSize}
        w={iconSize}
        borderRadius="100px"
        bg={completeBg}
        boxShadow={completeShadow}
        mt={iconMarginTop}
        border="4px solid"
        overflow={"hidden"}
        borderColor={borderColor}
      >
        <Player
          autoplay
          loop
          resizeMode="cover"
          src={responseReceivedJson}
          style={{
            height: "100%",
            width: "100%",
          }}
        ></Player>
      </Box>
      <CardHeaderText color={textColorPrimary} fontWeight="bold" mt="10px">
        {name}
      </CardHeaderText>
      <CardDescriptionText color={textColorSecondary}>
        {job}
      </CardDescriptionText>
      <Flex
        w="max-content"
        mx="auto"
        mt={{ base: "26px", xl: "5px", "2xl": "26px" }}
      >
        {responderControls && (
          <>
            <Flex mx="auto" me="60px" align="center" direction="column">
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                Start Date
              </Text>
              <CardHeaderText color={textColorPrimary} fontWeight="700">
                {startDate}
              </CardHeaderText>
            </Flex>
            <Flex mx="auto" me="60px" align="center" direction="column">
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                End Date
              </Text>
              <CardHeaderText color={textColorPrimary} fontWeight="700">
                {endDate}
              </CardHeaderText>
            </Flex>
          </>
        )}
        <Flex mx="auto" align="center" direction="column">
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Status
          </Text>
          <CustomBadge colorScheme={statusColor} fontWeight="700">
            {status}
          </CustomBadge>
        </Flex>
      </Flex>
    </Card>
  );
}
