import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  StackDivider,
  Switch,
  Tooltip,
} from "@chakra-ui/react";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { MdAdd } from "react-icons/md";
import * as yup from "yup";
import { useJSONBuilderContext } from "../../context/dnd-context";
import { Item } from "../../types/dnd-types";
import { CloseIcon } from "@chakra-ui/icons";

const selectOptionTypeSchema = yup.object({
  label: yup.string(),
  value: yup.string().required("Value is required"),
});

const valuesSchema = yup.object({
  key: yup.string().max(255).required("Key is required"),
  title: yup.string().max(255).required("Title is required"),
  placeholder: yup.string().max(255),
  description: yup.string().max(255),
  required: yup.boolean(),
  minLength: yup
    .number()
    .typeError("Minimum Length must be a number")
    .positive("Minimum Length must be a positive number")
    .integer("Minimum Length must be an integer")
    .min(0, "Minimum Length must be greater than or equal to 0"),

  maxLength: yup
    .number()
    .typeError("Maximum Length must be a number")
    .positive("Maximum Length must be a positive number")
    .integer("Maximum Length must be an integer")
    .when("minLength", (minLength, schema) => {
      return minLength
        ? schema.min(
            parseInt(minLength.toString()),
            "Maximum Length must be greater than or equal to Minimum Length"
          )
        : schema;
    }),
  minimum: yup.number().typeError("Minimum Value must be a number"),
  maximum: yup
    .number()
    .typeError("Maximum Value must be a number")
    .when("minimum", (minimum, schema) => {
      return minimum !== undefined
        ? schema.moreThan(
            parseInt(minimum.toString()),
            "Maximum Value must be greater than Minimum Value"
          )
        : schema;
    }),
  minDate: yup.date(), // Validation for minDate
  maxDate: yup
    .date()
    .min(yup.ref("minDate"), "Date must be after the minimum date"), // Minimum date validation
  readonly: yup.boolean(),
  options: yup.array().of(selectOptionTypeSchema),
});

// Define the LayoutValues schema
const layoutValuesSchema = yup.object({
  type: yup
    .string()
    .oneOf(["Group", "VerticalLayout", "HorizontalLayout"])
    .required(),
  title: yup.string().required("Title is required"),
});

// Define the FieldValues schema
const fieldValuesSchema = yup.object({
  type: yup
    .string()
    .notOneOf(["Group", "VerticalLayout", "HorizontalLayout"])
    .required("Type is required"),
  ...valuesSchema.fields, // Include the validation for Values type
});

type SelectOptionType = {
  label: string;
  value: string;
};

type Values = {
  key: string;
  title: string;
  type: string;
  placeholder: string;
  description: string;
  required: boolean;
  secret: boolean;
  minLength: number | undefined;
  maxLength: number | undefined;
  minimum: number | undefined;
  maximum: number | undefined;
  minDate: string | undefined;
  maxDate: string | undefined;
  readonly: boolean | undefined;
  options?: SelectOptionType[];
};

type LayoutValues = {
  type: "layout";
  title: string;
} & Partial<Omit<Values, "type" | "title">>;

type FieldValues = {
  type: Exclude<string, "layout">;
} & Values;

const INITIAL_FORM_DATA: LayoutValues | FieldValues = {
  key: "",
  title: "",
  type: "",
  placeholder: "",
  description: "",
  secret: false,
  required: false,
  minLength: undefined,
  maxLength: undefined,
  readonly: false,
  minimum: undefined,
  minDate: undefined,
  maxDate: undefined,
  maximum: undefined,
  options: [],
};

const SelectOptions = ({
  handleChange,
  values,
  errors,
}: FormikProps<Values | LayoutValues>) => {
  return (
    <FieldArray
      name="options"
      render={({ push, remove }) => (
        <FormControl>
          <HStack mb="10px" justify={"space-between"} align={"center"}>
            <FormLabel m="0">Options</FormLabel>
            <Tooltip label="Add option">
              <IconButton
                size={"sm"}
                icon={<MdAdd />}
                aria-label={""}
                onClick={() => push({ label: "", value: "" })}
              />
            </Tooltip>
          </HStack>
          <Stack divider={<StackDivider />}>
            {values?.options?.length
              ? values.options?.map((option, index) => (
                  <FormControl
                    isInvalid={errors.options?.[index] ? true : false}
                  >
                    <InputGroup>
                      <Input
                        name={`options.${index}.value`}
                        type="text"
                        value={option ? option?.value : ""}
                        onChange={handleChange}
                      />
                      <InputRightElement
                        cursor={"pointer"}
                        onClick={() => remove(index)}
                      >
                        <CloseIcon boxSize={3} />
                      </InputRightElement>
                    </InputGroup>
                    {errors.options?.[index] && (
                      <FormErrorMessage>Value is required.</FormErrorMessage>
                    )}
                  </FormControl>
                ))
              : null}
          </Stack>
        </FormControl>
      )}
    />
  );
};

let autoSaveTimer: any = undefined;

export default function FieldProperties(): React.ReactElement | null {
  const { selectedItem, updateItem } = useJSONBuilderContext();
  const [formData, setFormData] = useState<LayoutValues | FieldValues>(
    INITIAL_FORM_DATA
  );
  const formikRef = useRef<FormikProps<LayoutValues | FieldValues>>();
  const onSubmit = (values: LayoutValues | FieldValues) => {
    if (selectedItem) {
      let updatedItem: Item = {
        ...selectedItem,
        title: values.title,
        keyName: values.key ? values.key : "",
      };
      if (selectedItem.type !== "layout") {
        updatedItem = {
          ...updatedItem,
          required: values.required,
          description: values.description,
          placeholder: values.placeholder,
        };
        if (values.minLength !== undefined)
          updatedItem.minLength = values.minLength;
        if (values.maxLength !== undefined)
          updatedItem.maxLength = values.maxLength;
        if (values.minimum !== undefined) updatedItem.minimum = values.minimum;
        if (values.maximum !== undefined) updatedItem.maximum = values.maximum;
        if (
          selectedItem.type == "string" &&
          (selectedItem.format == undefined ||
            selectedItem.format == "password")
        ) {
          updatedItem.format = values.secret ? "password" : undefined;
        }
        if (selectedItem.format === "date") {
          if (values.minDate !== undefined)
            updatedItem.minDate = values.minDate;
          if (values.maxDate !== undefined)
            updatedItem.maxDate = values.maxDate;
        }
        if (values.readonly) updatedItem.readonly = values.readonly;
        if (values.options?.length)
          updatedItem.options = values.options.map((option) => ({
            const: option.value,
            title: option.value,
          }));
      }
      if (selectedItem.type === "layout") {
        updatedItem = {
          ...updatedItem,
          type: values?.type,
        };
      }
      console.log({ selectedItem });
      console.log({ updatedItem });
      updateItem(updatedItem);
    }
  };

  const clearAutoSaveTimeout = () => {
    if (autoSaveTimer) {
      clearTimeout(autoSaveTimer);
    }
    autoSaveTimer = undefined;
  };

  useEffect(() => {
    console.log({selectedItem}, "Changed")
    formikRef.current?.resetForm();
    clearAutoSaveTimeout();
    setFormData({
      description: selectedItem?.description ? selectedItem.description : "",
      maximum: selectedItem?.maximum ? selectedItem.maximum : undefined,
      maxLength: selectedItem?.maxLength ? selectedItem?.maxLength : undefined,
      minimum: selectedItem?.minimum ? selectedItem?.minimum : undefined,
      minLength: selectedItem?.minLength ? selectedItem?.minLength : undefined,
      minDate: selectedItem?.minDate ? selectedItem?.minDate : undefined,
      maxDate: selectedItem?.maxDate ? selectedItem?.maxDate : undefined,
      placeholder: selectedItem?.placeholder ? selectedItem?.placeholder : "",
      required: selectedItem?.required ? true : false,
      title: selectedItem?.title ? selectedItem?.title : "",
      readonly: selectedItem?.readonly ? true : false,
      secret: selectedItem?.format == "password" ? true : false,
      type: selectedItem?.type ? selectedItem?.type : "",
      key: selectedItem?.keyName ? selectedItem?.keyName : "",
      options: selectedItem?.options?.length
        ? selectedItem.options.map((option) => ({
            label: option.title,
            value: option.const,
          }))
        : [],
    });
  }, [selectedItem?.id]);

  const handleAutoSave = (callback: any) => {
    clearAutoSaveTimeout();
    autoSaveTimer = setTimeout(callback, 50);
  };

  return (
    <Box w="full">
      {selectedItem &&
      formData &&
      ((selectedItem.isLayoutElement && selectedItem.type === "Group") ||
        !selectedItem.isLayoutElement) ? (
        <Formik
          initialValues={formData}
          innerRef={formikRef}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={
            selectedItem.isLayoutElement
              ? layoutValuesSchema
              : fieldValuesSchema
          }
        >
          {(formikProps) => {
            const { values, errors, handleChange, setFieldValue, submitForm } =
              formikProps;
            const onChange = (e: React.ChangeEvent<any>) => {
              handleChange(e);
              handleAutoSave(() => submitForm());
            };

            const onChangeBoolean = (key: string, value: any) => {
              setFieldValue(key, value);
              handleAutoSave(() => submitForm());
            };

            return (
              <Form>
                <Stack spacing={"3"}>
                  <FormControl isRequired isInvalid={!!errors.title}>
                    <FormLabel>Label</FormLabel>
                    <Input
                      value={values.title}
                      type="text"
                      name="title"
                      onChange={onChange}
                    />

                    {errors.title ? (
                      <FormErrorMessage>{errors.title}</FormErrorMessage>
                    ) : (
                      <FormHelperText>
                        It will be displayed above the field.
                      </FormHelperText>
                    )}
                  </FormControl>
                  {!selectedItem.isLayoutElement && (
                    <>
                      <FormControl isRequired isInvalid={!!errors.key}>
                        <FormLabel>Field Key</FormLabel>
                        <Input
                          value={values.key}
                          type="text"
                          name="key"
                          onChange={onChange}
                        />

                        {errors.key ? (
                          <FormErrorMessage>{errors.key}</FormErrorMessage>
                        ) : (
                          <FormHelperText>
                            The key name used in the data object of the form
                            output.
                          </FormHelperText>
                        )}
                      </FormControl>
                      {selectedItem.type !== "number" &&
                        selectedItem.type !== "integer" &&
                        selectedItem.format !== "radio" &&
                        selectedItem.format !== "enum" && 
                        selectedItem.format !== "file" && (
                          <FormControl isInvalid={!!errors.placeholder}>
                            <FormLabel>Placeholder</FormLabel>
                            <Input
                              value={values.placeholder}
                              type="text"
                              name="placeholder"
                              onChange={onChange}
                            />

                            {errors.placeholder ? (
                              <FormErrorMessage>
                                {errors.placeholder}
                              </FormErrorMessage>
                            ) : (
                              <FormHelperText>
                                The placeholder of the field.
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      <FormControl isInvalid={!!errors.description}>
                        <FormLabel>Helper Text</FormLabel>
                        <Input
                          value={values.description}
                          type="text"
                          name="description"
                          onChange={onChange}
                        />

                        {errors.description ? (
                          <FormErrorMessage>
                            {errors.description}
                          </FormErrorMessage>
                        ) : (
                          <FormHelperText>
                            It will be displayed below the field.
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl isInvalid={!!errors.required}>
                        <HStack
                          justifyContent={"space-between"}
                          align={"center"}
                          borderRadius={"5px"}
                          borderWidth={"1px"}
                          p={2}
                        >
                          <Flex flexDir={"column"}>
                            <FormLabel m="0">Required</FormLabel>
                            <FormHelperText>
                              When enabled user will have to enter the value.
                            </FormHelperText>
                          </Flex>
                          <Switch
                            name="required"
                            isChecked={values.required}
                            onChange={(e) =>
                              onChangeBoolean(
                                "required",
                                e.target.checked ? true : false
                              )
                            }
                          />
                        </HStack>
                      </FormControl>
                      {selectedItem.type === "string" &&
                        !selectedItem.multiline &&
                        (selectedItem.format == undefined ||
                          selectedItem.format == "password") && (
                          <FormControl isInvalid={!!errors.secret}>
                            <HStack
                              justifyContent={"space-between"}
                              align={"center"}
                              borderRadius={"5px"}
                              borderWidth={"1px"}
                              p={2}
                            >
                              <Flex flexDir={"column"}>
                                <FormLabel m="0">Secret</FormLabel>
                                <FormHelperText>
                                  Make the field confidential.
                                </FormHelperText>
                              </Flex>
                              <Switch
                                name="secret"
                                isChecked={values.secret}
                                onChange={(e) =>
                                  onChangeBoolean(
                                    "secret",
                                    e.target.checked ? true : false
                                  )
                                }
                              />
                            </HStack>
                          </FormControl>
                        )}
                      <Box
                        w="full"
                        borderWidth={"1px"}
                        borderColor={"gray.100"}
                      ></Box>
                      <FormControl isInvalid={!!errors.readonly}>
                        <HStack
                          justifyContent={"space-between"}
                          align={"center"}
                          borderRadius={"5px"}
                          borderWidth={"1px"}
                          p={2}
                        >
                          <Flex flexDir={"column"}>
                            <FormLabel m="0">Read Only</FormLabel>
                            <FormHelperText>
                              It will disable the field.
                            </FormHelperText>
                          </Flex>
                          <Switch
                            name="readonly"
                            isChecked={values.readonly}
                            onChange={(e) =>
                              onChangeBoolean(
                                "readonly",
                                e.target.checked ? true : false
                              )
                            }
                          />
                        </HStack>
                      </FormControl>
                      {selectedItem.type === "string" &&
                        selectedItem.format !== "enum" &&
                        selectedItem.format !== "radio" &&
                        selectedItem.format !== "date" && 
                        selectedItem.format !== "file" && (
                          <FormControl isInvalid={!!errors.minLength}>
                            <FormLabel>Minimum</FormLabel>
                            <Input
                              value={
                                values.minLength !== undefined
                                  ? values.minLength
                                  : undefined
                              }
                              type="number"
                              name="minLength"
                              onChange={onChange}
                            />
                            {errors.minLength ? (
                              <FormErrorMessage>
                                {errors.minLength}
                              </FormErrorMessage>
                            ) : (
                              <FormHelperText>
                                Minimum character length for field.
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      {(selectedItem.type === "number" ||
                        selectedItem.type === "integer") && (
                        <FormControl isInvalid={!!errors.minimum}>
                          <FormLabel>Minimum</FormLabel>
                          <Input
                            value={
                              values.minimum !== undefined
                                ? values.minimum
                                : undefined
                            }
                            type="number"
                            name="minimum"
                            onChange={onChange}
                          />
                          {errors.minimum ? (
                            <FormErrorMessage>
                              {errors.minimum}
                            </FormErrorMessage>
                          ) : (
                            <FormHelperText>
                              Minimum value for field.
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                      {selectedItem.type === "string" &&
                        selectedItem.format === "date" && (
                          <FormControl isInvalid={!!errors.minDate}>
                            <FormLabel>Minimum</FormLabel>
                            <Input
                              value={
                                values.minDate !== undefined
                                  ? values.minDate
                                  : undefined
                              }
                              type="date"
                              name="minDate"
                              onChange={onChange}
                            />
                            {errors.minDate ? (
                              <FormErrorMessage>
                                {errors.minDate}
                              </FormErrorMessage>
                            ) : (
                              <FormHelperText>
                                Start date of the field.
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      {selectedItem.type === "string" &&
                        selectedItem.format !== "enum" &&
                        selectedItem.format !== "radio" &&
                        selectedItem.format !== "date" && 
                        selectedItem.format !== "file" && (
                          <FormControl isInvalid={!!errors.maxLength}>
                            <FormLabel>Maximum</FormLabel>
                            <Input
                              value={
                                values.maxLength !== undefined
                                  ? values.maxLength
                                  : undefined
                              }
                              type="number"
                              name="maxLength"
                              onChange={onChange}
                            />
                            {errors.maxLength ? (
                              <FormErrorMessage>
                                {errors.maxLength}
                              </FormErrorMessage>
                            ) : (
                              <FormHelperText>
                                Maximum character length for field.
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      {(selectedItem.type === "number" ||
                        selectedItem.type === "integer") && (
                        <FormControl isInvalid={!!errors.maximum}>
                          <FormLabel>Maximum</FormLabel>
                          <Input
                            value={
                              values.maximum !== undefined
                                ? values.maximum
                                : undefined
                            }
                            type="number"
                            name="maximum"
                            onChange={onChange}
                          />
                          {errors.maximum ? (
                            <FormErrorMessage>
                              {errors.maximum}
                            </FormErrorMessage>
                          ) : (
                            <FormHelperText>
                              Maximum value for field.
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                      {selectedItem.type === "string" &&
                        selectedItem.format === "date" && (
                          <FormControl isInvalid={!!errors.maxDate}>
                            <FormLabel>Maximum</FormLabel>
                            <Input
                              value={
                                values.maxDate !== undefined
                                  ? values.maxDate
                                  : undefined
                              }
                              type="date"
                              name="maxDate"
                              onChange={onChange}
                            />
                            {errors.maxDate ? (
                              <FormErrorMessage>
                                {errors.maxDate}
                              </FormErrorMessage>
                            ) : (
                              <FormHelperText>
                                End date for the field.
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      {(selectedItem.format === "enum" ||
                        selectedItem.format === "radio") && (
                        <SelectOptions
                          {...formikProps}
                          handleChange={onChange}
                        />
                      )}
                    </>
                  )}
                  {/* <Button
                    isDisabled={!isChanged}
                    size={"sm"}
                    type="submit"
                    colorScheme="green"
                  >
                    Update
                  </Button> */}
                </Stack>
              </Form>
            );
          }}
        </Formik>
      ) : null}
    </Box>
  );
}
