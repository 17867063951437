// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/asset/v1/service/asset/asset.proto (package asset.v1.asset, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateAssetRequest, CreateAssetResponse, CreateRelationRequest, CreateRelationResponse, DeleteAssetsRequest, DeleteRelationRequest, GetAssetRequest, GetAssetResponse, ListAssetsRequest, ListAssetsResponse, ListAssetStagesRequest, ListAssetStagesResponse, UpdateAssetRequest, UpdateAssetResponse, UpdateRelationRequest } from "./asset_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service asset.v1.asset.AssetService
 */
export const AssetService = {
  typeName: "asset.v1.asset.AssetService",
  methods: {
    /**
     * @generated from rpc asset.v1.asset.AssetService.CreateAsset
     */
    createAsset: {
      name: "CreateAsset",
      I: CreateAssetRequest,
      O: CreateAssetResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.asset.AssetService.GetAsset
     */
    getAsset: {
      name: "GetAsset",
      I: GetAssetRequest,
      O: GetAssetResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.asset.AssetService.ListAssets
     */
    listAssets: {
      name: "ListAssets",
      I: ListAssetsRequest,
      O: ListAssetsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.asset.AssetService.UpdateAsset
     */
    updateAsset: {
      name: "UpdateAsset",
      I: UpdateAssetRequest,
      O: UpdateAssetResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.asset.AssetService.DeleteAssets
     */
    deleteAssets: {
      name: "DeleteAssets",
      I: DeleteAssetsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.asset.AssetService.ListAssetStages
     */
    listAssetStages: {
      name: "ListAssetStages",
      I: ListAssetStagesRequest,
      O: ListAssetStagesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.asset.AssetService.CreateRelation
     */
    createRelation: {
      name: "CreateRelation",
      I: CreateRelationRequest,
      O: CreateRelationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.asset.AssetService.UpdateRelation
     */
    updateRelation: {
      name: "UpdateRelation",
      I: UpdateRelationRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.asset.AssetService.DeleteRelation
     */
    deleteRelation: {
      name: "DeleteRelation",
      I: DeleteRelationRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

