import {
  useCheckbox,
  FormLabel,
  Flex,
  Box,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../custom-styled-components/CustomText";
import CheckboxRead from "./CheckboxRead";
export default function CustomCheckboxField(props) {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
    useCheckbox(props);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const onClick = () => {
    if (!props.readonly) {
      props.onChange(props.value ? false : true);
    }
  };
  return !props?.readonly ? (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gridColumnGap={2}
      px={2}
      py={1}
      cursor="pointer"
      _hover={{ cursor: "pointer" }}
    >
      <CustomText color={textColorPrimary} marginRight="1" fontWeight="bold">
        {props.label}
        {props?.required && (
          <span
            role="presentation"
            aria-hidden="true"
            class="chakra-form__required-indicator css-1ssjhh"
          >
            *
          </span>
        )}
      </CustomText>
      <input type={"checkbox"} value={props.value} hidden />
      <Flex
        alignItems="center"
        justifyContent="center"
        border="2px solid"
        borderColor="green.500"
        w={4}
        h={4}
        {...getCheckboxProps()}
        onClick={onClick}
      >
        {props.value && <Box w={2} h={2} bg="brand.500" />}
      </Flex>
    </Box>
  ) : (
    <CheckboxRead name={props.label} isChecked={props.value ? true : false} />
  );
}
