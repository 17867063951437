/* eslint-disable react/react-in-jsx-scope */
import { PropsWithChildren, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { navigateToUrl } from "single-spa";
import {isLoggedIn, redirectToLogin} from '@defense-station/auth'
import { showServerError } from "../services/toast-service";
import { userClient } from "@/grpc/client";
import { User } from "@/ds-apis";

type AuthContextType = {
  user: User | undefined;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  loggedIn: boolean;
  hasPermission: boolean;
  setHasPermission: React.Dispatch<React.SetStateAction<boolean>>
}

const AuthContext = createContext<AuthContextType>({
  user: undefined,
  setUser: function (value: SetStateAction<User | undefined>): void {
    throw new Error("Function not implemented.");
  },
  loggedIn: false,
  hasPermission: false,
  setHasPermission: function (value: SetStateAction<boolean>): void {
    throw new Error("Function not implemented.");
  }
});

export const AuthProvider = ({ children }: PropsWithChildren) => {
  // We can have our existing Auth logic here, just making sure that we have a single QueryClient.
  const [user, setUser] = useState<User>();
const [hasPermission, setHasPermission] = useState<boolean>(true);
const [loggedIn, setLoggedIn] = useState<boolean>(() =>
    isLoggedIn()
  );

  useEffect(() => {
    setLoggedIn(isLoggedIn())
    userClient.getAuthInfo({}).then((resp) => {
        setUser(resp.user)
        console.log("Getting user", resp)
        // getSettings({
        //   username: user?.data?.iam_v1_GetAuthInfo?.user?.username,
        // account_id: user?.data?.iam_v1_GetAuthInfo?.user?.account_id
        // }).catch((e: any) => {
        //   console.log(e)
        //   if(!window.location.href.includes("/policy/configure")) document.location.href  = `/policy/configure`
        // })
    }).catch((e: unknown) => showServerError(e))
  }, []);

  // Any user details we want can go here
  const value = {
    loggedIn,
    user,
    setUser,
    setLoggedIn,
    hasPermission, 
    setHasPermission
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export const useAuth = () => useContext(AuthContext);


const redirectToHome = () => {
  navigateToUrl("/secureshare");
};

const InnerLogin = ({ children }: PropsWithChildren) => {
  const loggedIn = isLoggedIn();
  if (loggedIn) {
    return children;
  } else {
    redirectToLogin(true);
    return null;
  }
};

export const LoggedInRoute = ({ children }: PropsWithChildren) => (
  <AuthProvider>
    <InnerLogin>{children}</InnerLogin>
  </AuthProvider>
);

const InnerLogout = ({ children }: PropsWithChildren) => {
  const loggedIn = isLoggedIn();
  if (loggedIn) {
    redirectToHome();
    return null;
  } else {
    return children;
  }
};

export const LoggedOutRoute = ({ children }: PropsWithChildren) => (
  <AuthProvider>
    <InnerLogout>{children}</InnerLogout>
  </AuthProvider>
);


export const LoggedOutRouteWithUser = ({children}: PropsWithChildren) => {
  <AuthProvider>
    {children}
  </AuthProvider>
}