import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
  Text,
  Tag,
  TagLabel,
  IconButton,
  Button,
  Stack,
  StackItem,
  HStack,
  Box,
  useColorModeValue,
  Popover,
  Flex,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  useDisclosure,
  useBreakpointValue,
  Spinner,
  TagLeftIcon,
} from "@chakra-ui/react";
import * as timeago from "timeago.js";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { ViewIcon } from "@chakra-ui/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import Card from "../../card/Card";
import {
  addDays,
  addYears,
  endOfDay,
  format,
  isAfter,
  parseISO,
  startOfDay,
} from "date-fns";
import { DateRange, DateRangePicker } from "react-date-range";
import {
  defaultInputOldRanges,
  defaultStaticOldRanges,
} from "../../../utils/defaultDateRange";
import { MdOutlineCancel, MdRefresh } from "react-icons/md";
import "../../../assets/css/table.css";
import { GoDotFill } from "react-icons/go";
import { CgTimer } from "react-icons/cg";
import TextContent from "../../custom-styled-components/TextContent";
import CustomText from "../../custom-styled-components/CustomText";
import CustomActionIconButton from "../../custom-styled-components/CustomActionIconButton";
import CustomTh from "../../custom-styled-components/CustomTh";
import CustomTd from "../../custom-styled-components/CustomTd";
import CustomTag from "../../custom-styled-components/CustomTag";
import CustomButton from "../../custom-styled-components/CustomButton";
import LoadMoreComponent from "../../custom-styled-components/LoadMoreComponent";

const TableRow = ({ rowData, onClick }) => {
  const [expanded, setExpanded] = useState(false);
  const open = () => setExpanded(true);
  const close = () => setExpanded(false);
  const navigate = useNavigate();
  const onActionClick = () => {
    navigate(
      "/dropzones/responses/" +
        rowData?.requester_id +
        "/" +
        rowData?.respondent_id
    );
  };
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const parsedEndDate = parseISO(rowData?.end_date);
  const parsedStartDate = parseISO(rowData?.start_date);
  const expired = isAfter(new Date(), parsedEndDate);
  const live = rowData?.start_date
    ? isAfter(new Date(), parsedStartDate) && isAfter(parsedEndDate, new Date())
    : true;
  const idle =
    isAfter(parsedStartDate, new Date()) && isAfter(parsedEndDate, new Date());
  let selectedColor = null;
  let icon = null;
  let colorScheme = null;
  let status = null;
  let rowClass = "";
  if (expired) {
    selectedColor = "red.400";
    icon = MdOutlineCancel;
    colorScheme = "red";
    status = "Expired";
    rowClass = "clickableRowExpired";
  } else if (live) {
    selectedColor = "brand.600";
    icon = GoDotFill;
    colorScheme = "brand";
    status = "Live";
    rowClass = "clickableRowLive";
  } else if (idle) {
    selectedColor = "gray.200";
    icon = CgTimer;
    colorScheme = "gray";
    status = "Idle";
    rowClass = "clickableRowIdle";
  }
  const RowComponent = (
    <Tr
      className={rowClass}
      onClick={onActionClick}
      borderColor={selectedColor}
      borderLeftWidth="1px"
      key={rowData?.response_id}
    >
      <CustomTd>{timeago.format(rowData?.created_at)}</CustomTd>
      <CustomTd raw>
        {/* <Tooltip label={"Status description"} placement="bottom"> */}
        <CustomTag colorScheme={colorScheme} variant="outline">
          <TagLeftIcon as={icon} />
          <TagLabel>{status}</TagLabel>
        </CustomTag>
        {/* </Tooltip> */}
      </CustomTd>
      <CustomTd raw>
        <CustomActionIconButton
          icon={<ViewIcon />}
          color={"green.600"}
          variant={"outline"}
          onClick={onActionClick}
        >
          View
        </CustomActionIconButton>
      </CustomTd>
    </Tr>
  );
  return expanded
    ? [
        RowComponent,
        <Tr>
          <Td colSpan={6}>
            <Stack spacing={2}>
              <StackItem>
                <HStack>
                  <Text fontWeight={"bold"}>Identity Hidden: </Text>
                  <Text>{rowData?.hide_identity ? "Yes" : "No"}</Text>
                </HStack>
              </StackItem>
              <StackItem>
                <HStack>
                  <Text fontWeight={"bold"}>Stats Hidden: </Text>
                  <Text>{rowData?.hideState ? "Yes" : "No"}</Text>
                </HStack>
              </StackItem>
              <StackItem>
                <HStack>
                  <Text fontWeight={"bold"}>Login Required: </Text>
                  <Text>{rowData?.login_required ? "Yes" : "No"}</Text>
                </HStack>
              </StackItem>
              <StackItem>
                <HStack>
                  <Text fontWeight={"bold"}>Start Date: </Text>
                  <Text>{timeago.format(rowData?.start_date)}</Text>
                </HStack>
              </StackItem>
              <StackItem>
                <HStack>
                  <Text fontWeight={"bold"}>Expiration Date: </Text>
                  <Text>{timeago.format(rowData?.end_date)}</Text>
                </HStack>
              </StackItem>
            </Stack>
          </Td>
        </Tr>,
      ]
    : RowComponent;
};

export const DropzonesResponsesTable = ({
  dropzones,
  hasNext,
  fetchNext,
  loading,
  onDateRangeApply,
  onRefresh,
  refreshing,
  header,
  ...rest
}) => {
  const observer = useRef();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const popM = useDisclosure();
  const baseDate = new Date();
  const navigate = useNavigate();
  const minData = addYears(baseDate, -50);
  const [range, setRange] = useState([
    {
      start_date: startOfDay(addDays(baseDate, -30)),
      end_date: endOfDay(baseDate),
      startDate: startOfDay(addDays(baseDate, -30)),
      endDate: endOfDay(baseDate),
      key: "selection",
    },
  ]);
  const popsize = useBreakpointValue({ base: null, lg: "auto" });
  const displayMobile = useBreakpointValue({ base: true, lg: false });
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const displayDesktop = useBreakpointValue({ base: false, lg: true });
  const onApply = () => {
    if (onDateRangeApply) onDateRangeApply(range);
    popM.onClose();
  };
  const onReload = () => {
    if (onRefresh) onRefresh();
  };
  const [currentRow, setCurrentRow] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onClick = (data) => {
    setCurrentRow(data);
    onOpen();
  };
  const onCreate = () => navigate("/dropzone");
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {header ? header() : null}
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        px="25px"
        justify="space-between"
        mb="20px"
        align={{ base: "flex-end", md: "center" }}
      >
        <HStack style={{ alignItems: "center" }} mb={{ base: "2", md: "0" }}>
          <TextContent color={textColor} fontWeight="700">
            Date Selected
          </TextContent>
          <Stack spacing={2}>
            <Popover
              matchWidth
              placement="bottom-end"
              isOpen={popM.isOpen}
              onClose={popM.onClose}
            >
              <PopoverTrigger>
                <Flex
                  flexDirection={{ base: "row", lg: "row" }}
                  tabIndex="0"
                  role="button"
                  aria-label="Date Range"
                  paddingRight={1}
                  paddingLeft={1}
                  borderWidth={1}
                  borderRadius="lg"
                  borderColor={"brand.600"}
                  onClick={popM.onOpen}
                  variant="outline"
                >
                  <CustomText mr={2} color="brand.600" fontWeight="bold">
                    {format(range[0].start_date, "MM/dd/yyyy")}
                  </CustomText>
                  <CustomText mr={2}>to</CustomText>
                  <CustomText color="brand.600" fontWeight="bold">
                    {format(range[0].end_date, "MM/dd/yyyy")}
                  </CustomText>
                </Flex>
              </PopoverTrigger>
              <PopoverContent width={popsize}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Date Range</PopoverHeader>
                <PopoverBody>
                  {displayMobile && (
                    <DateRange
                      minDate={minData}
                      maxDate={baseDate}
                      rangeColors={["#48BB78", "#68D391", "#9AE6B4"]}
                      color="#48BB78"
                      editableDateInputs
                      onChange={(item) => {
                        item.selection.start_date = startOfDay(
                          item.selection.startDate
                        );

                        item.selection.end_date = endOfDay(
                          item.selection.endDate
                        );

                        setRange([item.selection]);
                      }}
                      moveRangeOnFirstSelection={false}
                      ranges={range}
                    />
                  )}
                  {displayDesktop && (
                    <DateRangePicker
                      minDate={minData}
                      maxDate={baseDate}
                      rangeColors={["#48BB78", "#68D391", "#9AE6B4"]}
                      color="#48BB78"
                      editableDateInputs
                      onChange={(item) => {
                        item.selection.start_date = startOfDay(
                          item.selection.startDate
                        );

                        item.selection.end_date = endOfDay(
                          item.selection.endDate
                        );

                        setRange([item.selection]);
                      }}
                      showSelectionPreview
                      moveRangeOnFirstSelection={false}
                      months={1}
                      ranges={range}
                      staticRanges={defaultStaticOldRanges}
                      inputRanges={defaultInputOldRanges}
                      direction="horizontal"
                    />
                  )}
                </PopoverBody>
                <PopoverFooter d="flex" justifyContent="flex-end">
                  <Button onClick={onApply} colorScheme="brand">
                    Apply
                  </Button>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          </Stack>
        </HStack>
        <HStack>
          <CustomActionIconButton
            isLoading={refreshing}
            onClick={onReload}
            variant={"outline"}
            colorScheme="brand"
            icon={<MdRefresh />}
          />
        </HStack>
      </Flex>
      <Box h={"70vh"} id="table-div" style={{ overflowY: "auto" }} {...rest}>
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            <Tr>
              <CustomTh>Received At</CustomTh>
              <CustomTh>Status</CustomTh>
              <CustomTh>Action</CustomTh>
              <CustomTh />
            </Tr>
          </Thead>
          <Tbody>
            {dropzones?.map((v, index) => (
              <TableRow onClick={onClick} key={index} rowData={v} />
            ))}
          </Tbody>
        </Table>
        <LoadMoreComponent
          hasNext={hasNext}
          isLoading={loading}
          fetchNext={fetchNext}
        />
      </Box>
    </Card>
  );
};

export default DropzonesResponsesTable;
