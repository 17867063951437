// Chakra imports
// Custom components
import React, { useState } from "react";
import {
  Text,
  Box,
  Heading,
  Flex,
  VStack,
  useColorModeValue,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Link,
  Grid,
  SimpleGrid,
  Icon,
  FormLabel,
  useDisclosure,
  Badge,
  FormControl,
  Button,
  Spinner,
  InputGroup,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import SwitchField from "../../../fields/SwitchField";
import InputField from "../../../fields/InputField";
import NumberField from "../../../fields/NumberField";
import { Formik, Form } from "formik";
import { updateRequestedDataSchema } from "../../../../validations/request-data-schema";
import toast from "../../../toast";
import Card from "../../../card/Card";
import { useAuth } from "../../../../context/auth";
import CustomFormLabel from "../../../custom-styled-components/CustomFormLabel";
import ReactMultiURL from "../../../multi-url/ReactMultiURL";
import CustomTag from "../../../custom-styled-components/CustomTag";
import isURL from "../../../multi-url/isURL";
import CustomText from "../../../custom-styled-components/CustomText";
import "react-multi-email/style.css";
import CustomButton from "../../../custom-styled-components/CustomButton";
import { updateDropzoneSchema } from "../../../../validations/create-dropzone-schema";
import { isAfter, parseISO } from "date-fns";

const UPDATE_MESSAGE_ON_EXPIRED = "Settings will not be updated because dropzone has been expired.";

const MAX_EMAIL_LENGTH = 20;

export default function UpdateForm(props) {
  const { dropzone, onSuccess, onError, ...rest } = props;
  const initFormData = {
    allowed_origins: dropzone?.allowed_origins,
    retry_limit: dropzone?.access_limit,
    hide_stats: dropzone?.hide_stats,
    login_required: dropzone?.login_required,
    responder_controls: dropzone?.responder_controls,
    hide_identity: dropzone?.hide_identity,
    label: dropzone?.label,
  };
  const [formData, setFormData] = useState(initFormData);
  const [isExpiring, setIsExpiring] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [makingActive, setMakingActive] = useState(false);
  const { user } = useAuth();
  const parsedEndDate = parseISO(dropzone?.end_date);
  const isInPast = isAfter(new Date(), parsedEndDate);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  // Chakra Color Mode

  const _onSubmit = async (values) => {
    try {
      if (isInPast) {
        toast({
          description: UPDATE_MESSAGE_ON_EXPIRED,
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        return;
      }
      const { updateDropzone } = await import("@defense-station/api-service");
      const data = await updateDropzone({
        allowed_origins: values?.allowed_origins,
        login_required: values?.login_required,
        responder_controls: values?.responder_controls,
        account_id: user?.account_id,
        username: user?.username,
        dropzone_id: dropzone?.dropzone_id,
        dropzone_name: dropzone?.dropzone_name,
        respondent_id: dropzone?.respondent_id,
      });
      if (onSuccess) onSuccess({ ...dropzone, ...values });
      setIsUpdated(false);
      toast({
        description: "Dropzone has been updated.",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    } catch (e) {
      if (e.response) {
        toast({
          title: "Response Error",
          description: e?.response?.errors[0]?.message,
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        return;
      }

      toast({
        title: "Application Error",
        description: e.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const resetKey = async () => {
    if (isInPast) {
      toast({
        description: UPDATE_MESSAGE_ON_EXPIRED,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    setIsResetting(true);
    try {
      const { updateDropzone } = await import("@defense-station/api-service");
      const data = await updateDropzone({
        allowed_origins: dropzone?.allowed_origins,
        login_required: dropzone?.login_required,
        responder_controls: dropzone?.responder_controls,
        account_id: user?.account_id,
        username: user?.username,
        dropzone_id: dropzone?.dropzone_id,
        dropzone_name: dropzone?.dropzone_name,
        respondent_id: dropzone?.respondent_id,
        reset_dropzone_key: true,
      });
      if (onSuccess)
        onSuccess({
          ...dropzone,
          dropzone_key: data?.data?.sypher_v1_UpdateDropzone?.dropzone_key,
        });
      toast({
        description: "Dropzone key has been reset.",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    } catch (e) {
      if (e.response) {
        toast({
          title: "Response Error",
          description: e?.response?.errors[0]?.message,
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        return;
      }

      toast({
        title: "Application Error",
        description: e.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    }
    setIsResetting(false)
  };
  let bg = useColorModeValue("brand.500", "brand.400");

  return (
    <Card p="30px" {...rest}>
      <Flex mb="40px" justify="space-between" align="center">
        <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
          Update Settings
        </Text>
      </Flex>
      <Formik
        enableReinitialize
        validateOnChange={false}
        validationSchema={updateDropzoneSchema}
        initialValues={formData}
        onSubmit={_onSubmit}
      >
        {({ values, errors, setFieldValue, submitForm, isSubmitting }) => (
          <Form
            onChange={() => {
              setIsUpdated(true);
            }}
          >
            <SimpleGrid
              mb="30px"
              ms="10px"
              columns={{ sm: 1, md: 1, xl: 1 }}
              spacing={{ base: "20px", xl: "0px" }}
            >
              <Box>
                <CustomFormLabel
                  display="flex"
                  ms="10px"
                  color={textColorPrimary}
                  fontWeight="bold"
                  _hover={{ cursor: "pointer" }}
                >
                  Allowed Origins
                </CustomFormLabel>
                <InputGroup size="md">
                  <ReactMultiURL
                    style={{
                      minHeight: "80px",
                      height: "50px",
                      borderColor: "#E2E8F0",
                      marginBottom: "10px",
                      borderRadius: "16px",
                    }}
                    urls={values?.allowed_origins}
                    variant="main"
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <CustomTag
                          mb="6px"
                          me="6px"
                          borderRadius="12px"
                          variant="solid"
                          bg={bg}
                          key={index}
                        >
                          <TagLabel w="100%">{email}</TagLabel>
                          <TagCloseButton
                            justifySelf="flex-end"
                            color="white"
                            onClick={() => removeEmail(index)}
                          />
                        </CustomTag>
                      );
                    }}
                    onChange={(_allowed_origins) => {
                      setFieldValue("allowed_origins", _allowed_origins);
                    }}
                    validateURL={(url) => {
                      if (isURL(url)) {
                        if (values.allowed_origins.length == MAX_EMAIL_LENGTH) {
                          toast({
                            title: "Error",
                            description: "Maximum 20 origins are allowed.",
                            status: "error",
                            duration: 4000,
                            isClosable: true,
                            position: "top",
                          });
                          return false;
                        }
                        return true;
                      }
                      return false;
                    }}
                  />
                </InputGroup>
                {errors.allowed_origins && (
                  <CustomText color={"red.400"}>
                    {errors.allowed_origins}
                  </CustomText>
                )}
              </Box>
              <SwitchField
                mb="25px"
                me="30px"
                id="responder_controls"
                isChecked={values?.responder_controls}
                onChange={(v) => {
                  setFieldValue("responder_controls", v?.currentTarget.checked);
                }}
                label="Responder Controls"
              />
              <SwitchField
                mb="25px"
                me="30px"
                id="login_required"
                isChecked={values?.login_required}
                onChange={(v) => {
                  setFieldValue("login_required", v?.currentTarget.checked);
                }}
                label="Login Required"
              />
              <CustomButton
                isLoading={isResetting}
                onClick={resetKey}
                variant={"solid"}
                colorScheme="brand"
                mb={{ base: "20px", xl: "10px" }}
                width={"full"}
              >
                Reset Key
              </CustomButton>
              {isUpdated && (
                <CustomButton
                  type="submit"
                  isLoading={isSubmitting}
                  variant={"solid"}
                  colorScheme="brand"
                  width={"full"}
                >
                  Submit
                </CustomButton>
              )}
            </SimpleGrid>
          </Form>
        )}
      </Formik>
    </Card>
  );
}
