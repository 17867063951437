// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/policy/v1/service/onboarding/onboarding.proto (package policy.v1.onboarding, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Document, OnboardingAnswerTree, OnboardingQuestionTree } from "../../types/types_pb";

/**
 * @generated from message policy.v1.onboarding.OptInRequest
 */
export class OptInRequest extends Message<OptInRequest> {
  constructor(data?: PartialMessage<OptInRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.onboarding.OptInRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OptInRequest {
    return new OptInRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OptInRequest {
    return new OptInRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OptInRequest {
    return new OptInRequest().fromJsonString(jsonString, options);
  }

  static equals(a: OptInRequest | PlainMessage<OptInRequest> | undefined, b: OptInRequest | PlainMessage<OptInRequest> | undefined): boolean {
    return proto3.util.equals(OptInRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.onboarding.OptInResponse
 */
export class OptInResponse extends Message<OptInResponse> {
  /**
   * @generated from field: string onboarding_status = 1;
   */
  onboardingStatus = "";

  constructor(data?: PartialMessage<OptInResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.onboarding.OptInResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "onboarding_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OptInResponse {
    return new OptInResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OptInResponse {
    return new OptInResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OptInResponse {
    return new OptInResponse().fromJsonString(jsonString, options);
  }

  static equals(a: OptInResponse | PlainMessage<OptInResponse> | undefined, b: OptInResponse | PlainMessage<OptInResponse> | undefined): boolean {
    return proto3.util.equals(OptInResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.onboarding.GetOnboardingQuestionsRequest
 */
export class GetOnboardingQuestionsRequest extends Message<GetOnboardingQuestionsRequest> {
  constructor(data?: PartialMessage<GetOnboardingQuestionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.onboarding.GetOnboardingQuestionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOnboardingQuestionsRequest {
    return new GetOnboardingQuestionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOnboardingQuestionsRequest {
    return new GetOnboardingQuestionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOnboardingQuestionsRequest {
    return new GetOnboardingQuestionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOnboardingQuestionsRequest | PlainMessage<GetOnboardingQuestionsRequest> | undefined, b: GetOnboardingQuestionsRequest | PlainMessage<GetOnboardingQuestionsRequest> | undefined): boolean {
    return proto3.util.equals(GetOnboardingQuestionsRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.onboarding.GetOnboardingQuestionsResponse
 */
export class GetOnboardingQuestionsResponse extends Message<GetOnboardingQuestionsResponse> {
  /**
   * @generated from field: policy.v1.types.OnboardingQuestionTree questions = 1;
   */
  questions?: OnboardingQuestionTree;

  /**
   * @generated from field: string onboarding_status = 2;
   */
  onboardingStatus = "";

  constructor(data?: PartialMessage<GetOnboardingQuestionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.onboarding.GetOnboardingQuestionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "questions", kind: "message", T: OnboardingQuestionTree },
    { no: 2, name: "onboarding_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOnboardingQuestionsResponse {
    return new GetOnboardingQuestionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOnboardingQuestionsResponse {
    return new GetOnboardingQuestionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOnboardingQuestionsResponse {
    return new GetOnboardingQuestionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOnboardingQuestionsResponse | PlainMessage<GetOnboardingQuestionsResponse> | undefined, b: GetOnboardingQuestionsResponse | PlainMessage<GetOnboardingQuestionsResponse> | undefined): boolean {
    return proto3.util.equals(GetOnboardingQuestionsResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.onboarding.SubmitOnboardingAnswersRequest
 */
export class SubmitOnboardingAnswersRequest extends Message<SubmitOnboardingAnswersRequest> {
  /**
   * @generated from field: policy.v1.types.OnboardingAnswerTree answers = 1;
   */
  answers?: OnboardingAnswerTree;

  constructor(data?: PartialMessage<SubmitOnboardingAnswersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.onboarding.SubmitOnboardingAnswersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "answers", kind: "message", T: OnboardingAnswerTree },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmitOnboardingAnswersRequest {
    return new SubmitOnboardingAnswersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmitOnboardingAnswersRequest {
    return new SubmitOnboardingAnswersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubmitOnboardingAnswersRequest {
    return new SubmitOnboardingAnswersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SubmitOnboardingAnswersRequest | PlainMessage<SubmitOnboardingAnswersRequest> | undefined, b: SubmitOnboardingAnswersRequest | PlainMessage<SubmitOnboardingAnswersRequest> | undefined): boolean {
    return proto3.util.equals(SubmitOnboardingAnswersRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.onboarding.SubmitOnboardingAnswersResponse
 */
export class SubmitOnboardingAnswersResponse extends Message<SubmitOnboardingAnswersResponse> {
  /**
   * @generated from field: repeated policy.v1.types.Document documents = 1;
   */
  documents: Document[] = [];

  constructor(data?: PartialMessage<SubmitOnboardingAnswersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.onboarding.SubmitOnboardingAnswersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "documents", kind: "message", T: Document, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmitOnboardingAnswersResponse {
    return new SubmitOnboardingAnswersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmitOnboardingAnswersResponse {
    return new SubmitOnboardingAnswersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubmitOnboardingAnswersResponse {
    return new SubmitOnboardingAnswersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SubmitOnboardingAnswersResponse | PlainMessage<SubmitOnboardingAnswersResponse> | undefined, b: SubmitOnboardingAnswersResponse | PlainMessage<SubmitOnboardingAnswersResponse> | undefined): boolean {
    return proto3.util.equals(SubmitOnboardingAnswersResponse, a, b);
  }
}

