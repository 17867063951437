import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import jsonPlayerData from '../assets/not-found.json';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
  const playerSizes = {
    base: '100%',
    md: '700px',
    lg: '700px',
    xl: '700px',
    '2xl': '800px'
  };

  const getPlayerSize = () => {
    // Simple responsive size selection
    if (window.innerWidth >= 1536) return playerSizes['2xl'];
    if (window.innerWidth >= 1280) return playerSizes['xl'];
    if (window.innerWidth >= 1024) return playerSizes['lg'];
    if (window.innerWidth >= 768) return playerSizes['md'];
    return playerSizes['base'];
  };

  return (
    <div 
      className="flex h-[90vh] justify-center flex-col items-center"
    >
      <Player
        autoplay
        loop
        keepLastFrame
        src={jsonPlayerData}
        style={{ 
          height: getPlayerSize(), 
          width: getPlayerSize() 
        }}
      />
      <div className="flex items-center mt-5 flex-col md:flex-row">
        <p 
          className="text-gray-900 dark:text-white font-medium 
                     text-md md:text-lg mr-1 text-center md:text-left"
        >
          Maybe it's best you start back at our home page...
        </p>
        <Link 
          to="/" 
          className="text-brand-500 dark:text-brand-400 
                      font-medium text-md md:text-lg 
                      hover:underline"
        >
          Return at home here.
        </Link>
      </div>
    </div>
  );
};

export default NotFound;