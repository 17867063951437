import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import { DraggableItemProps } from "../../../types/dnd-types";
import { Box, ChakraProps } from "@chakra-ui/react";

interface Props extends DraggableItemProps {}

export default function DraggableItem({
  children,
  id,
  data,
  disabled,
  animateLayoutChanges,
  attributes,
  getNewIndex,
  resizeObserverConfig,
  strategy,
  transition,
  ...rest
}: Props & ChakraProps): React.ReactElement {
  const {
    attributes: draggableAttributes,
    listeners,
    setNodeRef,
    transform,
  } = useDraggable({
    id,
    data,
    disabled: !!disabled,
  });
  return (
    <Box
      ref={setNodeRef}
      transform={CSS.Translate.toString(transform)}
      {...listeners}
      {...draggableAttributes}
      {...rest}
    >
      {children}
    </Box>
  );
}
