import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Input,
  InputGroup,
  Stack,
  StackItem,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import Card from "../secure-view/Card";
import * as yup from "yup";
import { useAuth } from "../../../context/auth";
import { createRootKey, readRootKey } from "@defense-station/api-service";
import { SymDecryptMessage } from "../../../utils/encrypt";
import Centered from "../../../layouts/Centered";
import { Dropzone } from "../../Dropzone";
import {
  RootKeyProvider,
  useRootKey,
  withRootKeyProvider,
} from "../../../context/request/root-key";
import toast from "../../toast";
import CustomDescription from "../../custom-styled-components/CustomDescription";
import CustomHeading from "../../custom-styled-components/CustomHeading";

const validation = yup.object().shape({
  phrase: yup.string().required("Phrase can't be blank."),
});

const forgotValidation = yup.object().shape({
  file: yup.string().required("Phrase can't be blank."),
});

function EnterPhrase({ onSuccess, root_key, onSubmit }) {
  const { rootKey, setRootKey, setRestoreRootKey } = useRootKey();
  const [forgotMode, setForgotMode] = useState(false);
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { user } = useAuth();
  const size = useBreakpointValue({
    base: "md",
    xl: "sm",
    "2xl": "md",
  });
  return (
    <Centered
      image={"linear-gradient(135deg, #9AE6B4 0%, #48BB78 100%)"}
      cardTop={{ base: "140px", md: "24vh" }}
      cardBottom={{ base: "50px", lg: "auto" }}
    >
      <Flex
        w="100%"
        maxW="max-content"
        me="auto"
        h="100%"
        alignItems="start"
        position={"relative"}
        justifyContent="center"
        px={{ base: "25px", md: "0px" }}
        flexDirection="column"
      >
        {forgotMode ? (
          <>
            <Box me="auto" mb="34px">
              <CustomHeading color={textColor} mb="16px">
                Reset Phrase
              </CustomHeading>
              <CustomDescription
                color={textColorSecondary}
                w={{ base: "100%", lg: "456px" }}
                maxW="100%"
              >
                Forgetting your security phrase? don’t worry just provide us
                with the backup key and we will be able to reset your pharse.
              </CustomDescription>
            </Box>
            <Flex
              zIndex="2"
              direction="column"
              w={{ base: "100%", lg: "456px" }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
              align="start"
            >
              <Formik
                enableReinitialize
                validateOnChange={false}
                validationSchema={forgotValidation}
                initialValues={{
                  file: "",
                }}
                onSubmit={async (values, { resetForm }) => {
                  try {
                    const res = await readRootKey(
                      user?.account_id,
                      user?.username,
                      true
                    );
                    const rootKey = res?.data?.sypher_v1_GetRootKey?.root_key;
                    let backupPhrase = "";
                    await Promise.resolve(
                      new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsText(values.file, "UTF-8");
                        reader.onload = (e) => {
                          resolve();
                          backupPhrase = e.target.result;
                          // setFiles(e.target.result);
                        };
                      })
                    );
                    const decryptedRootKey = await SymDecryptMessage(
                      backupPhrase,
                      rootKey
                    );
                    if (decryptedRootKey) {
                      if (onSubmit) {
                        setRestoreRootKey(true);
                        setRootKey("null hai");
                        onSubmit({
                          phrase: null,
                          root_key: decryptedRootKey,
                          backup: true,
                        });
                      }
                    } else {
                      toast({
                        title: "Invalid Backup Phrase!",
                        description:
                          "The file you have uploaded, is not correct.",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                        position: "top",
                      });
                    }
                  } catch (e) {
                    if (e.name == "OperationError") {
                      toast({
                        title: "Invalid Backup Phrase!",
                        description:
                          "The file you have uploaded, is not correct.",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                        position: "top",
                      });
                    } else {
                      toast({
                        title: "Error!",
                        description: e.message,
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                        position: "top",
                      });
                    }
                  }
                }}
              >
                {({
                  values,
                  errors,
                  isSubmitting,
                  setFieldValue,
                  handleChange,
                }) => (
                  <Form style={{ width: "100%" }}>
                    <Stack spacing={"2"} pb={"4"}>
                      <StackItem>
                        <Dropzone
                          disableDescription
                          isRequired
                          errors={errors}
                          setFieldValue={setFieldValue}
                          name={"file"}
                          values={values}
                          width="full"
                        />
                      </StackItem>
                      <Box mt={4}>
                        <Flex justifyContent="center">
                          <ButtonGroup width={"full"}>
                            <Button
                              size={size}
                              width={"full"}
                              colorScheme="brand"
                              variant={"outline"}
                              mr={3}
                              onClick={() => setForgotMode(false)}
                            >
                              Back
                            </Button>
                            <Button
                              size={size}
                              width={"full"}
                              colorScheme="brand"
                              mr={3}
                              type="submit"
                              isLoading={isSubmitting}
                            >
                              Submit
                            </Button>
                          </ButtonGroup>
                        </Flex>
                      </Box>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Flex>
          </>
        ) : (
          <>
            <Box me="auto" mb="34px">
              <CustomHeading color={textColor} mb="16px">
                Enter Security Phrase
              </CustomHeading>
              <CustomDescription
                color={textColorSecondary}
                w={{ base: "100%", lg: "456px" }}
                maxW="100%"
              >
                Security phrase is used as encryption key that stays in your
                browser. All the data in request feature is encrypted from this
                key. If you have forget your key please use forgot button to
                reset it.
              </CustomDescription>
            </Box>
            <Flex
              zIndex="2"
              direction="column"
              w={{ base: "100%", lg: "456px" }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
              align="start"
            >
              <Formik
                enableReinitialize
                validateOnChange={false}
                validationSchema={validation}
                initialValues={{
                  phrase: "",
                }}
                onSubmit={async (values, { resetForm }) => {
                  try {
                    const decryptedRootKey = await SymDecryptMessage(
                      values.phrase,
                      root_key
                    );
                    if (decryptedRootKey) {
                      if (onSubmit) {
                        onSubmit({
                          phrase: values.phrase,
                          root_key: decryptedRootKey,
                        });
                        setRootKey(decryptedRootKey);
                      }
                    } else {
                      toast({
                        title: "Invalid Phrase!",
                        description:
                          "The phrase you have entered, is not correct.",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                        position: "top",
                      });
                    }
                  } catch (e) {
                    console.log(e);
                    if (e.name == "OperationError") {
                      toast({
                        title: "Invalid Phrase!",
                        description:
                          "The phrase you have entered, is not correct.",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                        position: "top",
                      });
                    } else {
                      toast({
                        title: "Error!",
                        description: e.message,
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                        position: "top",
                      });
                    }
                  }
                }}
              >
                {({
                  values,
                  errors,
                  isSubmitting,
                  setFieldValue,
                  handleChange,
                }) => (
                  <Form style={{ width: "100%" }}>
                    <Stack spacing={"2"} pb={"4"}>
                      <StackItem>
                        <FormControl isInvalid={errors.label}>
                          <InputGroup size={size}>
                            <Input
                              autoFocus
                              pr="4.5rem"
                              max={5}
                              type="password"
                              placeholder="Enter A Phrase"
                              required
                              name="phrase"
                              value={values.phrase}
                              onChange={handleChange}
                            />
                          </InputGroup>
                          {errors.phrase && (
                            <FormErrorMessage>{errors.phrase}</FormErrorMessage>
                          )}
                        </FormControl>
                      </StackItem>
                      <StackItem></StackItem>
                      <Box mt={4}>
                        <Flex justifyContent="center">
                          <ButtonGroup width={"full"}>
                            <Button
                              size={size}
                              width={"full"}
                              colorScheme="brand"
                              variant={"outline"}
                              mr={3}
                              onClick={() => setForgotMode(true)}
                            >
                              Forgot Phrase
                            </Button>
                            <Button
                              size={size}
                              width={"full"}
                              colorScheme="brand"
                              mr={3}
                              type="submit"
                              isLoading={isSubmitting}
                            >
                              Submit
                            </Button>
                          </ButtonGroup>
                        </Flex>
                      </Box>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Flex>
          </>
        )}
      </Flex>
    </Centered>
  );
}

export default EnterPhrase;
