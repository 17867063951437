// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/sso/sso.proto (package iam.v1.sso, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DeleteSSORequest, GetSSORequest, GetSSOResponse, SetupSSORequest, SetupSSOResponse, SSOCallBackRequest, SSOCallBackResponse, SSOLoginRequest, SSOLoginResponse, UpdateSSORequest, UpdateSSOResponse } from "./sso_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service iam.v1.sso.SSOService
 */
export const SSOService = {
  typeName: "iam.v1.sso.SSOService",
  methods: {
    /**
     * @generated from rpc iam.v1.sso.SSOService.SetupSSO
     */
    setupSSO: {
      name: "SetupSSO",
      I: SetupSSORequest,
      O: SetupSSOResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.sso.SSOService.UpdateSSO
     */
    updateSSO: {
      name: "UpdateSSO",
      I: UpdateSSORequest,
      O: UpdateSSOResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.sso.SSOService.DeleteSSO
     */
    deleteSSO: {
      name: "DeleteSSO",
      I: DeleteSSORequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.sso.SSOService.GetSSO
     */
    getSSO: {
      name: "GetSSO",
      I: GetSSORequest,
      O: GetSSOResponse,
      kind: MethodKind.Unary,
    },
    /**
     * login
     *
     * @generated from rpc iam.v1.sso.SSOService.SSOLogin
     */
    sSOLogin: {
      name: "SSOLogin",
      I: SSOLoginRequest,
      O: SSOLoginResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.sso.SSOService.SSOCallBack
     */
    sSOCallBack: {
      name: "SSOCallBack",
      I: SSOCallBackRequest,
      O: SSOCallBackResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

