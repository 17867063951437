import { Box, Flex } from "@chakra-ui/react";
import React from "react";

export default function CustomProgressBar({ value, label }) {
  return (
    <Box
      borderRadius={"16px"}
      position="absolute"
      borderColor={"green.500"}
      borderWidth="1px"
      left={0}
      overflow="hidden"
      h="100%"
      w="100%"
    >
      <Box
        position="absolute"
        background={"green.500"}
        left={0}
        h="100%"
        transition={"width 0.2s"}
        overflow="hidden"
        w={value + "%"}
      >
        <Flex
          height={"100%"}
          width="100%"
          justifyContent={"center"}
          alignItems={"center"}
          color="white"
        >
          {label}
        </Flex>
      </Box>
    </Box>
  );
}
