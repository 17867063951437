import { DeleteIcon, PlusSquareIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  StackDivider,
  StackItem,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FieldArray, Formik } from "formik";
import React from "react";
import InputField from "../../fields/InputField";
import toast from "../../toast";
import NumberField from "../../fields/NumberField";
import CustomButton from "../../custom-styled-components/CustomButton";
import CustomText from "../../custom-styled-components/CustomText";
import CustomActionIconButton from "../../custom-styled-components/CustomActionIconButton";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { BiGridVertical } from "react-icons/bi";
import { arrayMoveImmutable } from "array-move";

const SortableItem = SortableElement(
  ({ i, values, arrayHelpers, errors, file }) => {
    const index = i;
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const onDelete = (arrayHelpers, index) => arrayHelpers.remove(index);

    const onFirstNameChange = (arrayHelpers, index, value) => {
      let file = { ...values?.requested_fields[index], field_name: value };
      arrayHelpers.replace(index, file);
    };

    const onIsRequiredChange = (arrayHelpers, index, value) => {
      let file = { ...values?.requested_fields[index], field_required: value };
      arrayHelpers.replace(index, file);
    };

    const onTypeChange = (arrayHelpers, index, value) => {
      let file = { ...values?.requested_fields[index], type: value };
      arrayHelpers.replace(index, file);
    };

    const onNumberOfFilesChange = (arrayHelpers, index, value) => {
      let file = { ...values?.requested_fields[index], max_files: value };
      arrayHelpers.replace(index, file);
    };
    return (
      <StackItem>
        <Grid
          templateColumns={{ base: "1fr", lg: "3fr 1fr 1fr 1fr" }}
          mt={{ base: "20px", xl: "10px", "2xl": "20px" }}
          gap={{ base: "20px", xl: "10px", "2xl": "20px" }}
          mb={{ base: "20px", xl: "10px", "2xl": "20px" }}
        >
          <Flex flexDir={{ base: "column", md: "row" }} alignItems="center">
            <Icon
              _hover={{ cursor: "grabbing" }}
              h="25px"
              w="25px"
              as={BiGridVertical}
            />
            <Flex
              flexDirection={"column"}
              flex="1"
              paddingRight={{ base: "0", md: "2" }}
            >
              <Input
                autoFocus
                variant={"main"}
                type={"text"}
                pr="4.5rem"
                max={5}
                required
                error={errors?.requested_fields?.[index]?.field_name}
                placeholder="Enter name"
                name={`requiredFiles.${index}`}
                value={file.field_name}
                onChange={(e) =>
                  onFirstNameChange(arrayHelpers, index, e.currentTarget.value)
                }
                h={{ base: "50px", xl: "30px", "2xl": "50px" }}
                padding={{
                  base: "20px",
                  xl: "10px",
                  "2xl": "20px",
                }}
                borderRadius="65px"
                _placeholder={{ color: "secondaryGray.500" }}
              />
              <CustomText ml="3" color={"red.400"}>
                {errors?.requested_fields?.[index]?.field_name}
              </CustomText>
            </Flex>
          </Flex>
          <Flex flex="1">
            <FormControl>
              <Select
                name="allowed_device"
                size={"sm"}
                variant="brand"
                fontWeight="400"
                h={{ base: "50px", xl: "30px", "2xl": "50px" }}
                borderRadius="65px"
                me="20px"
                value={file.type}
                onChange={(e) =>
                  onTypeChange(arrayHelpers, index, e.currentTarget.value)
                }
              >
                <option value="string">Input</option>
                <option value="file">File</option>
                <option value="boolean">Boolean</option>
              </Select>
              <CustomText ml="3" color={"red.400"}>
                {errors?.requested_fields?.[index]?.type}
              </CustomText>
            </FormControl>
          </Flex>
          {file.type == "multifiles" && (
            <Flex
              flex="1"
              flexDir="row"
              ml="10px"
              alignItems={"center"}
              justifyContent={{
                base: "space-between",
                md: "center",
              }}
            >
              <CustomText color={textColorPrimary} fontWeight="bold" mr="1">
                Count
              </CustomText>
              <NumberInput
                variant={"main"}
                h={{ base: "50px", xl: "30px", "2xl": "50px" }}
                borderRadius="65px"
                defaultValue={15}
                onChange={onNumberOfFilesChange}
                value={file?.max_files}
                min={0}
                max={20}
              >
                <NumberInputField
                  variant={"main"}
                  h={{ base: "50px", xl: "30px", "2xl": "50px" }}
                  fontSize={{ base: "md", xl: "xs", "2xl": "sm" }}
                  borderRadius="65px"
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Flex>
          )}
          <Flex
            justifyContent={{
              base: "space-between",
              md: "center",
            }}
            alignItems="center"
            flex="1"
          >
            <CustomButton
              variant={file.field_required ? "brand" : "outline"}
              mr="2"
              size={"sm"}
              flex="1"
              colorScheme="brand"
              onClick={() => {
                onIsRequiredChange(
                  arrayHelpers,
                  index,
                  file.field_required ? false : true
                );
              }}
            >
              Required
            </CustomButton>
            <CustomActionIconButton
              colorScheme={"red"}
              variant="outline"
              onClick={() => onDelete(arrayHelpers, index)}
              icon={<DeleteIcon />}
            />
          </Flex>
        </Grid>
      </StackItem>
    );
  }
);

const SortableList = SortableContainer(({ values, arrayHelpers, errors }) => {
  return (
    <Stack
      spacing={"2"}
      divider={<StackDivider />}
      maxH={{ base: "100%", md: "190px" }}
      overflow={"auto"}
    >
      {values.requested_fields.map((file, index) => (
        <SortableItem
          key={`${
            values.requested_fields?.length
              ? values.requested_fields?.length
              : 0
          }${index}`}
          index={index}
          i={index}
          values={values}
          arrayHelpers={arrayHelpers}
          errors={errors}
          file={file}
        />
      ))}
    </Stack>
  );
});

export default function InputRequestedFields({
  handleChange,
  errors,
  setFieldValue,
  values,
  maxFieldCount,
}) {
  const onAddClick = (arrayHelpers) => {
    try {
      if (values.requested_fields.length == maxFieldCount) {
        toast({
          title: "Error!",
          description: `You can\'t add more than ${maxFieldCount} fields.`,
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        return;
      }
      arrayHelpers.push({
        field_name: "",
        field_required: false,
        type: "string",
      });
    } catch (e) {
      throw e;
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setFieldValue(
      "requested_fields",
      arrayMoveImmutable(values.requested_fields, oldIndex, newIndex)
    );
  };

  return (
    <Box width={"full"}>
      <FieldArray
        name="requested_fields"
        render={(arrayHelpers) => {
          return (
            <>
              <Stack spacing={"2"}>
                <StackItem>
                  <CustomButton
                    onClick={() => onAddClick(arrayHelpers)}
                    width={"full"}
                    rightIcon={<PlusSquareIcon />}
                    colorScheme="teal"
                    variant="outline"
                  >
                    {"Add a new field" +
                      (values.requested_fields?.length
                        ? "(" + values.requested_fields?.length + ")"
                        : "")}
                  </CustomButton>
                </StackItem>
                <SortableList
                  onSortEnd={onSortEnd}
                  values={values}
                  arrayHelpers={arrayHelpers}
                  errors={errors}
                />
              </Stack>
            </>
          );
        }}
      />
    </Box>
  );
}
