// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/sypher/v1/service/dashboard/dashboard.proto (package sypher.v1.dashboard, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetUsageRequest, GetUsageResponse } from "./dashboard_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service sypher.v1.dashboard.DashboardService
 */
export const DashboardService = {
  typeName: "sypher.v1.dashboard.DashboardService",
  methods: {
    /**
     * @generated from rpc sypher.v1.dashboard.DashboardService.GetUsage
     */
    getUsage: {
      name: "GetUsage",
      I: GetUsageRequest,
      O: GetUsageResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

