import { SOMETHING_WENT_WRONG } from "@/constants/constants";
import { toast } from "sonner";



export const showErrorToast = (description: string) => {
    toast.error(description);
}

export const showWarningToast = (description: string) => {
  toast.warning(description);
}

export const showSuccessToast = (description: string) => {
  toast.success(description);
}


export const showServerError = (e: any) => {
  console.log(e)
  toast.error(e?.message ? e?.message: SOMETHING_WENT_WRONG);
}