// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/asset/v1/types/types.proto (package asset.v1.types, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Struct } from "@bufbuild/protobuf";

/**
 * @generated from enum asset.v1.types.ResourceCategory
 */
export enum ResourceCategory {
  /**
   * @generated from enum value: RESOURCE_CATEGORY_UNSPECIFIED = 0;
   */
  RESOURCE_CATEGORY_UNSPECIFIED = 0,

  /**
   * @generated from enum value: PERSONNEL = 1;
   */
  PERSONNEL = 1,

  /**
   * @generated from enum value: BOT = 2;
   */
  BOT = 2,

  /**
   * @generated from enum value: ORG = 3;
   */
  ORG = 3,

  /**
   * @generated from enum value: KEY = 4;
   */
  KEY = 4,

  /**
   * @generated from enum value: DEVICE = 5;
   */
  DEVICE = 5,

  /**
   * @generated from enum value: MOBILE_DEVICE = 6;
   */
  MOBILE_DEVICE = 6,

  /**
   * @generated from enum value: APPLICATION = 7;
   */
  APPLICATION = 7,

  /**
   * @generated from enum value: ROLE = 8;
   */
  ROLE = 8,

  /**
   * @generated from enum value: POLICY = 9;
   */
  POLICY = 9,

  /**
   * @generated from enum value: GROUP = 10;
   */
  GROUP = 10,

  /**
   * @generated from enum value: STORAGE = 11;
   */
  STORAGE = 11,

  /**
   * @generated from enum value: COMPUTE = 12;
   */
  COMPUTE = 12,

  /**
   * @generated from enum value: NETWORK = 13;
   */
  NETWORK = 13,

  /**
   * @generated from enum value: SOFTWARE = 14;
   */
  SOFTWARE = 14,

  /**
   * @generated from enum value: ALIAS = 15;
   */
  ALIAS = 15,

  /**
   * @generated from enum value: DOMAIN = 16;
   */
  DOMAIN = 16,

  /**
   * @generated from enum value: CLOUD = 17;
   */
  CLOUD = 17,

  /**
   * @generated from enum value: VIRTUAL = 18;
   */
  VIRTUAL = 18,

  /**
   * @generated from enum value: HARDWARE = 19;
   */
  HARDWARE = 19,

  /**
   * @generated from enum value: PHYSICAL = 20;
   */
  PHYSICAL = 20,

  /**
   * @generated from enum value: API = 21;
   */
  API = 21,

  /**
   * @generated from enum value: CONFIGURATION = 22;
   */
  CONFIGURATION = 22,

  /**
   * @generated from enum value: VENDOR = 23;
   */
  VENDOR = 23,

  /**
   * @generated from enum value: ACCOUNT = 24;
   */
  ACCOUNT = 24,
}
// Retrieve enum metadata with: proto3.getEnumType(ResourceCategory)
proto3.util.setEnumType(ResourceCategory, "asset.v1.types.ResourceCategory", [
  { no: 0, name: "RESOURCE_CATEGORY_UNSPECIFIED" },
  { no: 1, name: "PERSONNEL" },
  { no: 2, name: "BOT" },
  { no: 3, name: "ORG" },
  { no: 4, name: "KEY" },
  { no: 5, name: "DEVICE" },
  { no: 6, name: "MOBILE_DEVICE" },
  { no: 7, name: "APPLICATION" },
  { no: 8, name: "ROLE" },
  { no: 9, name: "POLICY" },
  { no: 10, name: "GROUP" },
  { no: 11, name: "STORAGE" },
  { no: 12, name: "COMPUTE" },
  { no: 13, name: "NETWORK" },
  { no: 14, name: "SOFTWARE" },
  { no: 15, name: "ALIAS" },
  { no: 16, name: "DOMAIN" },
  { no: 17, name: "CLOUD" },
  { no: 18, name: "VIRTUAL" },
  { no: 19, name: "HARDWARE" },
  { no: 20, name: "PHYSICAL" },
  { no: 21, name: "API" },
  { no: 22, name: "CONFIGURATION" },
  { no: 23, name: "VENDOR" },
  { no: 24, name: "ACCOUNT" },
]);

/**
 * @generated from enum asset.v1.types.StageTrigger
 */
export enum StageTrigger {
  /**
   * @generated from enum value: STAGE_TRIGGER_ON_UPDATE = 0;
   */
  ON_UPDATE = 0,

  /**
   * @generated from enum value: STAGE_TRIGGER_ON_TIME = 1;
   */
  ON_TIME = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(StageTrigger)
proto3.util.setEnumType(StageTrigger, "asset.v1.types.StageTrigger", [
  { no: 0, name: "STAGE_TRIGGER_ON_UPDATE" },
  { no: 1, name: "STAGE_TRIGGER_ON_TIME" },
]);

/**
 * @generated from enum asset.v1.types.StageEffect
 */
export enum StageEffect {
  /**
   * @generated from enum value: STAGE_EFFECT_PUSH_EVENT = 0;
   */
  PUSH_EVENT = 0,
}
// Retrieve enum metadata with: proto3.getEnumType(StageEffect)
proto3.util.setEnumType(StageEffect, "asset.v1.types.StageEffect", [
  { no: 0, name: "STAGE_EFFECT_PUSH_EVENT" },
]);

/**
 * @generated from message asset.v1.types.Asset
 */
export class Asset extends Message<Asset> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: repeated string labels = 2;
   */
  labels: string[] = [];

  /**
   * @generated from field: google.protobuf.Struct props = 3;
   */
  props?: Struct;

  /**
   * @generated from field: google.protobuf.Struct metadata = 4;
   */
  metadata?: Struct;

  constructor(data?: PartialMessage<Asset>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.types.Asset";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "labels", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "props", kind: "message", T: Struct },
    { no: 4, name: "metadata", kind: "message", T: Struct },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Asset {
    return new Asset().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Asset {
    return new Asset().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Asset {
    return new Asset().fromJsonString(jsonString, options);
  }

  static equals(a: Asset | PlainMessage<Asset> | undefined, b: Asset | PlainMessage<Asset> | undefined): boolean {
    return proto3.util.equals(Asset, a, b);
  }
}

/**
 * @generated from message asset.v1.types.Relation
 */
export class Relation extends Message<Relation> {
  /**
   * @generated from field: string start_dri = 1;
   */
  startDri = "";

  /**
   * @generated from field: string end_dri = 2;
   */
  endDri = "";

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  /**
   * @generated from field: google.protobuf.Struct props = 4;
   */
  props?: Struct;

  constructor(data?: PartialMessage<Relation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.types.Relation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "end_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "props", kind: "message", T: Struct },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Relation {
    return new Relation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Relation {
    return new Relation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Relation {
    return new Relation().fromJsonString(jsonString, options);
  }

  static equals(a: Relation | PlainMessage<Relation> | undefined, b: Relation | PlainMessage<Relation> | undefined): boolean {
    return proto3.util.equals(Relation, a, b);
  }
}

/**
 * @generated from message asset.v1.types.MatchAsset
 */
export class MatchAsset extends Message<MatchAsset> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: repeated string labels = 2;
   */
  labels: string[] = [];

  constructor(data?: PartialMessage<MatchAsset>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.types.MatchAsset";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "labels", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MatchAsset {
    return new MatchAsset().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MatchAsset {
    return new MatchAsset().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MatchAsset {
    return new MatchAsset().fromJsonString(jsonString, options);
  }

  static equals(a: MatchAsset | PlainMessage<MatchAsset> | undefined, b: MatchAsset | PlainMessage<MatchAsset> | undefined): boolean {
    return proto3.util.equals(MatchAsset, a, b);
  }
}

/**
 * @generated from message asset.v1.types.GlobalRelations
 */
export class GlobalRelations extends Message<GlobalRelations> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: asset.v1.types.MatchAsset start_asset = 3;
   */
  startAsset?: MatchAsset;

  /**
   * @generated from field: asset.v1.types.MatchAsset end_asset = 4;
   */
  endAsset?: MatchAsset;

  /**
   * @generated from field: string type = 5;
   */
  type = "";

  /**
   * @generated from field: google.protobuf.Struct props = 6;
   */
  props?: Struct;

  /**
   * @generated from field: bool reverse_relation = 7;
   */
  reverseRelation = false;

  /**
   * @generated from field: repeated string depended_plugin_ids = 8;
   */
  dependedPluginIds: string[] = [];

  /**
   * @generated from field: string created_at = 9;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 10;
   */
  updatedAt = "";

  constructor(data?: PartialMessage<GlobalRelations>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.types.GlobalRelations";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "start_asset", kind: "message", T: MatchAsset },
    { no: 4, name: "end_asset", kind: "message", T: MatchAsset },
    { no: 5, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "props", kind: "message", T: Struct },
    { no: 7, name: "reverse_relation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "depended_plugin_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GlobalRelations {
    return new GlobalRelations().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GlobalRelations {
    return new GlobalRelations().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GlobalRelations {
    return new GlobalRelations().fromJsonString(jsonString, options);
  }

  static equals(a: GlobalRelations | PlainMessage<GlobalRelations> | undefined, b: GlobalRelations | PlainMessage<GlobalRelations> | undefined): boolean {
    return proto3.util.equals(GlobalRelations, a, b);
  }
}

/**
 * @generated from message asset.v1.types.StageTransition
 */
export class StageTransition extends Message<StageTransition> {
  /**
   * @generated from field: string guard_condition = 1;
   */
  guardCondition = "";

  /**
   * @generated from field: asset.v1.types.StageTrigger trigger = 2;
   */
  trigger = StageTrigger.ON_UPDATE;

  /**
   * @generated from field: asset.v1.types.StageEffect effect = 3;
   */
  effect = StageEffect.PUSH_EVENT;

  /**
   * @generated from field: int32 priority = 4;
   */
  priority = 0;

  constructor(data?: PartialMessage<StageTransition>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.types.StageTransition";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "guard_condition", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "trigger", kind: "enum", T: proto3.getEnumType(StageTrigger) },
    { no: 3, name: "effect", kind: "enum", T: proto3.getEnumType(StageEffect) },
    { no: 4, name: "priority", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StageTransition {
    return new StageTransition().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StageTransition {
    return new StageTransition().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StageTransition {
    return new StageTransition().fromJsonString(jsonString, options);
  }

  static equals(a: StageTransition | PlainMessage<StageTransition> | undefined, b: StageTransition | PlainMessage<StageTransition> | undefined): boolean {
    return proto3.util.equals(StageTransition, a, b);
  }
}

/**
 * @generated from message asset.v1.types.StageRelation
 */
export class StageRelation extends Message<StageRelation> {
  /**
   * @generated from field: string start_dri = 1;
   */
  startDri = "";

  /**
   * @generated from field: string end_dri = 2;
   */
  endDri = "";

  /**
   * @generated from field: asset.v1.types.StageTransition transition = 3;
   */
  transition?: StageTransition;

  constructor(data?: PartialMessage<StageRelation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.types.StageRelation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "end_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "transition", kind: "message", T: StageTransition },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StageRelation {
    return new StageRelation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StageRelation {
    return new StageRelation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StageRelation {
    return new StageRelation().fromJsonString(jsonString, options);
  }

  static equals(a: StageRelation | PlainMessage<StageRelation> | undefined, b: StageRelation | PlainMessage<StageRelation> | undefined): boolean {
    return proto3.util.equals(StageRelation, a, b);
  }
}

/**
 * @generated from message asset.v1.types.Stage
 */
export class Stage extends Message<Stage> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string lifecycle_dri = 2;
   */
  lifecycleDri = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: string created_at = 5;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 6;
   */
  updatedAt = "";

  /**
   * @generated from field: map<string, string> metadata = 7;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<Stage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.types.Stage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "lifecycle_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Stage {
    return new Stage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Stage {
    return new Stage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Stage {
    return new Stage().fromJsonString(jsonString, options);
  }

  static equals(a: Stage | PlainMessage<Stage> | undefined, b: Stage | PlainMessage<Stage> | undefined): boolean {
    return proto3.util.equals(Stage, a, b);
  }
}

/**
 * @generated from message asset.v1.types.Lifecycle
 */
export class Lifecycle extends Message<Lifecycle> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: int32 total_stages = 4;
   */
  totalStages = 0;

  /**
   * @generated from field: string created_at = 5;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 6;
   */
  updatedAt = "";

  /**
   * @generated from field: map<string, string> metadata = 7;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<Lifecycle>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.types.Lifecycle";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_stages", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Lifecycle {
    return new Lifecycle().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Lifecycle {
    return new Lifecycle().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Lifecycle {
    return new Lifecycle().fromJsonString(jsonString, options);
  }

  static equals(a: Lifecycle | PlainMessage<Lifecycle> | undefined, b: Lifecycle | PlainMessage<Lifecycle> | undefined): boolean {
    return proto3.util.equals(Lifecycle, a, b);
  }
}

/**
 * @generated from message asset.v1.types.JsonForm
 */
export class JsonForm extends Message<JsonForm> {
  /**
   * @generated from field: bytes schema = 1;
   */
  schema = new Uint8Array(0);

  /**
   * @generated from field: bytes ui_schema = 2;
   */
  uiSchema = new Uint8Array(0);

  constructor(data?: PartialMessage<JsonForm>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.types.JsonForm";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "schema", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "ui_schema", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JsonForm {
    return new JsonForm().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JsonForm {
    return new JsonForm().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JsonForm {
    return new JsonForm().fromJsonString(jsonString, options);
  }

  static equals(a: JsonForm | PlainMessage<JsonForm> | undefined, b: JsonForm | PlainMessage<JsonForm> | undefined): boolean {
    return proto3.util.equals(JsonForm, a, b);
  }
}

/**
 * @generated from message asset.v1.types.CategoryRelation
 */
export class CategoryRelation extends Message<CategoryRelation> {
  /**
   * @generated from field: asset.v1.types.JsonForm json_form = 1;
   */
  jsonForm?: JsonForm;

  /**
   * @generated from field: repeated string match_labels = 2;
   */
  matchLabels: string[] = [];

  constructor(data?: PartialMessage<CategoryRelation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.types.CategoryRelation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "json_form", kind: "message", T: JsonForm },
    { no: 2, name: "match_labels", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CategoryRelation {
    return new CategoryRelation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CategoryRelation {
    return new CategoryRelation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CategoryRelation {
    return new CategoryRelation().fromJsonString(jsonString, options);
  }

  static equals(a: CategoryRelation | PlainMessage<CategoryRelation> | undefined, b: CategoryRelation | PlainMessage<CategoryRelation> | undefined): boolean {
    return proto3.util.equals(CategoryRelation, a, b);
  }
}

/**
 * @generated from message asset.v1.types.Category
 */
export class Category extends Message<Category> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: string icon = 4;
   */
  icon = "";

  /**
   * @generated from field: asset.v1.types.ResourceCategory type = 5;
   */
  type = ResourceCategory.RESOURCE_CATEGORY_UNSPECIFIED;

  /**
   * @generated from field: string sub_category = 6;
   */
  subCategory = "";

  /**
   * @generated from field: repeated string labels = 7;
   */
  labels: string[] = [];

  /**
   * @generated from field: asset.v1.types.JsonForm json_form = 8;
   */
  jsonForm?: JsonForm;

  /**
   * @generated from field: map<string, asset.v1.types.CategoryRelation> relations = 9;
   */
  relations: { [key: string]: CategoryRelation } = {};

  /**
   * @generated from field: string created_at = 10;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 11;
   */
  updatedAt = "";

  /**
   * @generated from field: map<string, string> metadata = 12;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<Category>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.types.Category";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "icon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(ResourceCategory) },
    { no: 6, name: "sub_category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "labels", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "json_form", kind: "message", T: JsonForm },
    { no: 9, name: "relations", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: CategoryRelation} },
    { no: 10, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Category {
    return new Category().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Category {
    return new Category().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Category {
    return new Category().fromJsonString(jsonString, options);
  }

  static equals(a: Category | PlainMessage<Category> | undefined, b: Category | PlainMessage<Category> | undefined): boolean {
    return proto3.util.equals(Category, a, b);
  }
}

