import React from "react";

// Chakra imports
import {
  Flex,
  Image,
  Icon,
  Text,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Badge,
  useBreakpointValue,
  Button,
} from "@chakra-ui/react";

// Custom components

// Assets
import balanceImg from "../assets/img/dashboards/balanceImg.png";
import fakeGraph from "..//assets/img/dashboards/fakeGraph.png";
import { MdOutlineMoreHoriz, MdDomain, MdElectricCar } from "react-icons/md";
import Transaction from "./dataDisplay/Transaction";
import Card from "./card/Card";
import { BiTransfer } from "react-icons/bi";
import { TbDatabaseExport } from "react-icons/tb";

export default function Balance(props) {
  const {
    estimated,
    percentage,
    cost,
    billing_status,
    statusColorScheme,
    onUpgrade,
    isUpgrading,
    ...rest
  } = props;
  // Ellipsis modals
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  // Chakra Color Mode
  const blueIcon = useColorModeValue("blue.500", "white");
  const greenIcon = useColorModeValue("green.500", "white");
  const balanceBg = useColorModeValue("brand.500", "#1B254B");
  const fontSize = useBreakpointValue({
    base: "sm",
    xl: "xs",
    "2xl": "sm",
  });
  const buttonSize = useBreakpointValue({ base: "sm", xl: "xs", "2xl": "sm" });
  return (
    <Card direction="column" w="100%" {...rest}>
      <Flex
        justify="space-between"
        p={{ base: "16px", xl: "10px", "2xl": "16px" }}
        py={{ base: "16px", xl: "5px", "2xl": "16px" }}
        mb={{ base: "20px", xl: "10px", "2xl": "20px" }}
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgSize="cover"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex flexDirection="column" me="20px">
            <Text color="white" fontSize={fontSize} fontWeight="500">
              Cost Estimation
            </Text>
            <Text
              color="white"
              fontSize={{ base: "3xl", xl: "md", "2xl": "3xl" }}
              fontWeight="700"
              lineHeight="100%"
            >
              {"$" + (estimated + cost).toFixed(2)}
            </Text>
          </Flex>
          <Flex flexDirection="column" ms="auto" align="flex-end">
            <Menu isOpen={isOpen1} onClose={onClose1}>
              <MenuButton disabled onClick={onOpen1}>
                <Icon
                  cursor="pointer"
                  as={MdOutlineMoreHoriz}
                  color="white"
                  mt="-2px"
                  w="30px"
                  h="30px"
                />
              </MenuButton>
              <MenuList
                border="transparent"
                backdropFilter="blur(63px)"
                bg="linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.69) 76.65%)"
                borderRadius="20px"
              >
                <MenuItem
                  borderRadius="8px"
                  _hover={{
                    bg: "transparent",
                  }}
                  _active={{
                    bg: "transparent",
                  }}
                  _focus={{
                    bg: "transparent",
                  }}
                  mb="10px"
                  color="white"
                >
                  Action
                </MenuItem>
                <MenuItem
                  borderRadius="8px"
                  _hover={{
                    bg: "transparent",
                  }}
                  _active={{
                    bg: "transparent",
                  }}
                  _focus={{
                    bg: "transparent",
                  }}
                  mb="10px"
                  color="white"
                >
                  Another action
                </MenuItem>
                <MenuItem
                  borderRadius="8px"
                  _hover={{
                    bg: "transparent",
                  }}
                  _active={{
                    bg: "transparent",
                  }}
                  _focus={{
                    bg: "transparent",
                  }}
                  mb="10px"
                  color="white"
                >
                  Something else here
                </MenuItem>
              </MenuList>
            </Menu>
            {billing_status == "FREE TIER" ? (
              <Button
                colorScheme={statusColorScheme}
                size={buttonSize}
                isLoading={isUpgrading}
                onClick={onUpgrade}
              >
                Upgrade
              </Button>
            ) : (
              <Badge
                py={{ base: "7px", xl: "3px", "2xl": "7px" }}
                colorScheme={statusColorScheme}
                fontSize={fontSize}
                fontWeight="600"
              >
                {billing_status}
              </Badge>
            )}
          </Flex>
        </Flex>
      </Flex>
      {/* <Text color="secondaryGray.600" fontWeight="500" fontSize={fontSize} mb="10px">
        Details
      </Text> */}
      <Flex direction="column">
        <Transaction
          mb={{ base: "20px", xl: "10px", "2xl": "20px" }}
          name="Query Cost"
          date="Each created item charges are $0.25"
          sum={"$" + cost.toFixed(2)}
          icon={<Icon as={BiTransfer} color={blueIcon} w="20px" h="18px" />}
        />
        <Transaction
          name="Data Cost"
          date="Data cost is $0.003/1GB per day"
          sum={"$" + estimated.toFixed(2)}
          icon={
            <Icon as={TbDatabaseExport} color={greenIcon} w="20px" h="18px" />
          }
        />
      </Flex>
    </Card>
  );
}
