import {
  Heading,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

export default function CustomHeading({ children, ...rest }) {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textSize = useBreakpointValue({
    base: "3xl",
    xl: "2xl",
    "2xl": "3xl",
  });
  return (
    <Heading
      fontSize={textSize}
      color={textColorPrimary}
      fontWeight="bold"
      {...rest}
    >
      {children}
    </Heading>
  );
}
