import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Input,
  InputGroup,
  Stack,
  StackItem,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import Card from "../secure-view/Card";
import * as yup from "yup";
import { useAuth } from "../../../context/auth";
import { createRootKey, updateRootKey } from "@defense-station/api-service";
import { SymEncrptMessage } from "../../../utils/encrypt";
import Centered from "../../../layouts/Centered";
import { nanoid } from "nanoid";
import { useState } from "react";
import { FaDownload } from "react-icons/fa";
import { useRootKey } from "../../../context/request/root-key";
import toast from "../../toast";
import CustomHeading from "../../custom-styled-components/CustomHeading";
import CustomDescription from "../../custom-styled-components/CustomDescription";
import CustomButton from "../../custom-styled-components/CustomButton";
import CustomText from "../../custom-styled-components/CustomText";

const validation = yup.object().shape({
  phrase: yup
    .string()
    .required("Phrase is required.")
    .min(8, "Phrase is too short - should be 8 chars minimum."),
  confirmPhrase: yup
    .string()
    .oneOf([yup.ref("phrase"), null], "Phrase must match")
    .required("Phrase confirm is required"),
});

export default function RootKeyForm({ onSuccess }) {
  const { user, setUser } = useAuth();
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [downloadedBackupPhrase, setDownloadedBackupPhrase] = useState(false);
  const [phrase, setPhrase] = useState("");
  const { root_key, setRootKey, setRestoreRootKey, restoreRootKey } =
    useRootKey();
  const [local_root_key, setLocalRootKey] = useState(null);
  const [enRootKey, setEnRootKey] = useState("");
  const [backupPhrase, setBackupPhrase] = useState("");
  const [backupRootKey, setBackupRootKey] = useState("");
  const onSubmit = async (values, { resetForm }) => {
    try {
      const date = new Date();
      setPhrase(values.phrase);
      const rootKey = root_key
        ? root_key
        : await SymEncrptMessage(date, user?.account_id + user?.username);
      setLocalRootKey(rootKey);
      const enRootKey = await SymEncrptMessage(values.phrase, rootKey);
      setEnRootKey(enRootKey);
      const backupPhrase = nanoid(256);
      setBackupPhrase(backupPhrase);
      const backupRootKey = await SymEncrptMessage(backupPhrase, rootKey);
      setBackupRootKey(backupRootKey);
      setIsReady(true);
    } catch (e) {
      toast({
        title: "Error!",
        description: e.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    }
  };
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const createAndDownloadCSV = () => {
    let csvContent = "data:text/plain;charset=utf-8," + backupPhrase;
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", user?.username + ".txt");
    document.body.appendChild(link); // Required for FF

    link.click();
    setDownloadedBackupPhrase(true);
  };
  const onContinue = async () => {
    setLoading(true);
    const res = restoreRootKey
      ? await updateRootKey(
          user?.account_id,
          user?.username,
          enRootKey,
          backupRootKey
        )
      : await createRootKey(
          user?.account_id,
          user?.username,
          enRootKey,
          backupRootKey
        );
    if (onSuccess) {
      let successData = { phrase: phrase, root_key: local_root_key };
      if (restoreRootKey) {
        successData.backup = true;
      }
      setRestoreRootKey(false);
      onSuccess(successData);
      setRootKey(local_root_key);
    }
    setLoading(false);
  };
  const size = useBreakpointValue({
    base: "md",
    xl: "sm",
    "2xl": "md",
  });
  return (
    <Centered
      image={"linear-gradient(135deg, #9AE6B4 0%, #48BB78 100%)"}
      cardTop={{ base: "140px", md: "24vh" }}
      cardBottom={{ base: "50px", lg: "auto" }}
    >
      <Flex
        w="100%"
        maxW="max-content"
        me="auto"
        h="100%"
        alignItems="start"
        position={"relative"}
        justifyContent="center"
        px={{ base: "25px", md: "0px" }}
        flexDirection="column"
      >
        {isReady ? (
          <>
            <Box me="auto" mb="34px">
              <CustomHeading color={textColor} mb="16px">
                Download Backup Key
              </CustomHeading>
              <CustomDescription
                color={textColorSecondary}
                w={{ base: "100%", lg: "456px" }}
                maxW="100%"
              >
                If you forgot your security phrase this backup key will be used
                to reset it. If you don’t have access to backup key in that
                case. All the pervious data will be lost
              </CustomDescription>
              <Stack spacing={"2"} pb={"4"}>
                <StackItem>
                  <Box mt={4}>
                    <Flex justifyContent="center">
                      <CustomButton
                        size={size}
                        colorScheme={"gray"}
                        width={"full"}
                        onClick={createAndDownloadCSV}
                        leftIcon={<FaDownload />}
                      >
                        Download backup phrase
                      </CustomButton>
                    </Flex>
                  </Box>
                </StackItem>
                <StackItem>
                  <Box mt={4}>
                    <Flex justifyContent="center">
                      <CustomButton
                        size={size}
                        width={"full"}
                        colorScheme="brand"
                        isLoading={isLoading}
                        mr={3}
                        onClick={onContinue}
                        disabled={!downloadedBackupPhrase}
                      >
                        Continue
                      </CustomButton>
                    </Flex>
                  </Box>
                </StackItem>
              </Stack>
            </Box>
          </>
        ) : (
          <>
            <Box me="auto" mb="34px">
              <CustomHeading color={textColor} mb="16px">
                Set Security Phrase
              </CustomHeading>
              <CustomDescription
                color={textColorSecondary}
                w={{ base: "100%", lg: "456px" }}
                maxW="100%"
              >
                Security phrase is used as encryption key that stays in your
                browser. All the data in request feature is encrypted from this
                key.
              </CustomDescription>
            </Box>
            <Flex
              zIndex="2"
              direction="column"
              w={{ base: "100%", lg: "456px" }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
              align="start"
            >
              <Formik
                enableReinitialize
                validateOnChange={false}
                validationSchema={validation}
                initialValues={{
                  phrase: "",
                  confirmPhrase: "",
                }}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  errors,
                  isSubmitting,
                  setFieldValue,
                  handleChange,
                }) => (
                  <Form style={{ width: "100%" }}>
                    <Stack spacing={"2"} pb={"4"}>
                      <StackItem>
                        <FormControl
                          isRequired={true}
                          isInvalid={errors.phrase}
                        >
                          <InputGroup size={size}>
                            <Input
                              autoFocus
                              pr="4.5rem"
                              max={5}
                              variant="main"
                              type="password"
                              placeholder="Set phrase"
                              required
                              name="phrase"
                              value={values.phrase}
                              onChange={handleChange}
                            />
                          </InputGroup>
                          {errors.phrase && (
                            <FormErrorMessage>{errors.phrase}</FormErrorMessage>
                          )}
                        </FormControl>
                      </StackItem>
                      <StackItem>
                        <FormControl
                          isRequired={true}
                          isInvalid={errors.confirmPhrase}
                        >
                          <InputGroup size={size}>
                            <Input
                              pr="4.5rem"
                              max={5}
                              type="password"
                              variant="main"
                              placeholder="Confirm phrase"
                              required
                              name="confirmPhrase"
                              value={values.confirmPhrase}
                              onChange={handleChange}
                            />
                          </InputGroup>
                          {errors.confirmPhrase && (
                            <FormErrorMessage>
                              {errors.confirmPhrase}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </StackItem>
                      <StackItem>
                        <HStack>
                          <CustomText color="red" fontWeight={"bold"}>
                            Note:{" "}
                          </CustomText>
                          <CustomText color={"red"}>
                            All data will be lost, if you forget this phrase.
                          </CustomText>
                        </HStack>
                      </StackItem>
                      <Box mt={4}>
                        <Flex justifyContent="center">
                          <Button
                            size={size}
                            width={"full"}
                            colorScheme="brand"
                            mr={3}
                            type="submit"
                            isLoading={isSubmitting || isLoading}
                          >
                            Submit
                          </Button>
                        </Flex>
                      </Box>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Flex>
          </>
        )}
      </Flex>
    </Centered>
  );
}
