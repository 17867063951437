// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/asset/v1/service/category/category.proto (package asset.v1.category, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Category, CategoryRelation, JsonForm, ResourceCategory } from "../../types/types_pb";

/**
 * @generated from message asset.v1.category.CreateCategoryRequest
 */
export class CreateCategoryRequest extends Message<CreateCategoryRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: asset.v1.types.ResourceCategory type = 3;
   */
  type = ResourceCategory.RESOURCE_CATEGORY_UNSPECIFIED;

  /**
   * @generated from field: string sub_category = 4;
   */
  subCategory = "";

  /**
   * @generated from field: repeated string labels = 5;
   */
  labels: string[] = [];

  /**
   * @generated from field: asset.v1.types.JsonForm json_form = 6;
   */
  jsonForm?: JsonForm;

  /**
   * @generated from field: map<string, asset.v1.types.CategoryRelation> relations = 7;
   */
  relations: { [key: string]: CategoryRelation } = {};

  /**
   * @generated from field: int64 icon_size = 8;
   */
  iconSize = protoInt64.zero;

  constructor(data?: PartialMessage<CreateCategoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.CreateCategoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(ResourceCategory) },
    { no: 4, name: "sub_category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "labels", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "json_form", kind: "message", T: JsonForm },
    { no: 7, name: "relations", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: CategoryRelation} },
    { no: 8, name: "icon_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCategoryRequest {
    return new CreateCategoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCategoryRequest {
    return new CreateCategoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCategoryRequest {
    return new CreateCategoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCategoryRequest | PlainMessage<CreateCategoryRequest> | undefined, b: CreateCategoryRequest | PlainMessage<CreateCategoryRequest> | undefined): boolean {
    return proto3.util.equals(CreateCategoryRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.CreateCategoryResponse
 */
export class CreateCategoryResponse extends Message<CreateCategoryResponse> {
  /**
   * @generated from field: asset.v1.types.Category category = 1;
   */
  category?: Category;

  constructor(data?: PartialMessage<CreateCategoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.CreateCategoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category", kind: "message", T: Category },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCategoryResponse {
    return new CreateCategoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCategoryResponse {
    return new CreateCategoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCategoryResponse {
    return new CreateCategoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCategoryResponse | PlainMessage<CreateCategoryResponse> | undefined, b: CreateCategoryResponse | PlainMessage<CreateCategoryResponse> | undefined): boolean {
    return proto3.util.equals(CreateCategoryResponse, a, b);
  }
}

/**
 * @generated from message asset.v1.category.GetCategoryRequest
 */
export class GetCategoryRequest extends Message<GetCategoryRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  constructor(data?: PartialMessage<GetCategoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.GetCategoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCategoryRequest {
    return new GetCategoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCategoryRequest {
    return new GetCategoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCategoryRequest {
    return new GetCategoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCategoryRequest | PlainMessage<GetCategoryRequest> | undefined, b: GetCategoryRequest | PlainMessage<GetCategoryRequest> | undefined): boolean {
    return proto3.util.equals(GetCategoryRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.GetCategoryResponse
 */
export class GetCategoryResponse extends Message<GetCategoryResponse> {
  /**
   * @generated from field: asset.v1.types.Category category = 1;
   */
  category?: Category;

  constructor(data?: PartialMessage<GetCategoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.GetCategoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category", kind: "message", T: Category },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCategoryResponse {
    return new GetCategoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCategoryResponse {
    return new GetCategoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCategoryResponse {
    return new GetCategoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCategoryResponse | PlainMessage<GetCategoryResponse> | undefined, b: GetCategoryResponse | PlainMessage<GetCategoryResponse> | undefined): boolean {
    return proto3.util.equals(GetCategoryResponse, a, b);
  }
}

/**
 * @generated from message asset.v1.category.ListCategoriesRequest
 */
export class ListCategoriesRequest extends Message<ListCategoriesRequest> {
  /**
   * @generated from field: string search_name = 1;
   */
  searchName = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 3;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListCategoriesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.ListCategoriesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "search_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCategoriesRequest {
    return new ListCategoriesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCategoriesRequest {
    return new ListCategoriesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCategoriesRequest {
    return new ListCategoriesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListCategoriesRequest | PlainMessage<ListCategoriesRequest> | undefined, b: ListCategoriesRequest | PlainMessage<ListCategoriesRequest> | undefined): boolean {
    return proto3.util.equals(ListCategoriesRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.ListCategoriesResponse
 */
export class ListCategoriesResponse extends Message<ListCategoriesResponse> {
  /**
   * @generated from field: repeated asset.v1.types.Category categories = 1;
   */
  categories: Category[] = [];

  constructor(data?: PartialMessage<ListCategoriesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.ListCategoriesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "categories", kind: "message", T: Category, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCategoriesResponse {
    return new ListCategoriesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCategoriesResponse {
    return new ListCategoriesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCategoriesResponse {
    return new ListCategoriesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListCategoriesResponse | PlainMessage<ListCategoriesResponse> | undefined, b: ListCategoriesResponse | PlainMessage<ListCategoriesResponse> | undefined): boolean {
    return proto3.util.equals(ListCategoriesResponse, a, b);
  }
}

/**
 * @generated from message asset.v1.category.UpdateCategoryRequest
 */
export class UpdateCategoryRequest extends Message<UpdateCategoryRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: repeated string labels = 3;
   */
  labels: string[] = [];

  /**
   * @generated from field: asset.v1.types.JsonForm json_form = 4;
   */
  jsonForm?: JsonForm;

  /**
   * @generated from field: map<string, asset.v1.types.CategoryRelation> relations = 5;
   */
  relations: { [key: string]: CategoryRelation } = {};

  /**
   * @generated from field: int64 icon_size = 6;
   */
  iconSize = protoInt64.zero;

  constructor(data?: PartialMessage<UpdateCategoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.UpdateCategoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "labels", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "json_form", kind: "message", T: JsonForm },
    { no: 5, name: "relations", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: CategoryRelation} },
    { no: 6, name: "icon_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCategoryRequest {
    return new UpdateCategoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCategoryRequest {
    return new UpdateCategoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCategoryRequest {
    return new UpdateCategoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCategoryRequest | PlainMessage<UpdateCategoryRequest> | undefined, b: UpdateCategoryRequest | PlainMessage<UpdateCategoryRequest> | undefined): boolean {
    return proto3.util.equals(UpdateCategoryRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.UpdateCategoryResponse
 */
export class UpdateCategoryResponse extends Message<UpdateCategoryResponse> {
  /**
   * @generated from field: asset.v1.types.Category category = 1;
   */
  category?: Category;

  constructor(data?: PartialMessage<UpdateCategoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.UpdateCategoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category", kind: "message", T: Category },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCategoryResponse {
    return new UpdateCategoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCategoryResponse {
    return new UpdateCategoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCategoryResponse {
    return new UpdateCategoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCategoryResponse | PlainMessage<UpdateCategoryResponse> | undefined, b: UpdateCategoryResponse | PlainMessage<UpdateCategoryResponse> | undefined): boolean {
    return proto3.util.equals(UpdateCategoryResponse, a, b);
  }
}

/**
 * @generated from message asset.v1.category.DeleteCategoryRequest
 */
export class DeleteCategoryRequest extends Message<DeleteCategoryRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  constructor(data?: PartialMessage<DeleteCategoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.DeleteCategoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteCategoryRequest {
    return new DeleteCategoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteCategoryRequest {
    return new DeleteCategoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteCategoryRequest {
    return new DeleteCategoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteCategoryRequest | PlainMessage<DeleteCategoryRequest> | undefined, b: DeleteCategoryRequest | PlainMessage<DeleteCategoryRequest> | undefined): boolean {
    return proto3.util.equals(DeleteCategoryRequest, a, b);
  }
}

