// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/sypher/v1/service/access_history/access_history.proto (package sypher.v1.access_history, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { AccessHistory, LastEvaluated } from "../../types/types_pb";

/**
 * @generated from message sypher.v1.access_history.ListAccessHistoryRequest
 */
export class ListAccessHistoryRequest extends Message<ListAccessHistoryRequest> {
  /**
   * @generated from field: string source_id = 1;
   */
  sourceId = "";

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_access_history = 2;
   */
  lastEvaluatedAccessHistory?: LastEvaluated;

  constructor(data?: PartialMessage<ListAccessHistoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.access_history.ListAccessHistoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "source_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_evaluated_access_history", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAccessHistoryRequest {
    return new ListAccessHistoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAccessHistoryRequest {
    return new ListAccessHistoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAccessHistoryRequest {
    return new ListAccessHistoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAccessHistoryRequest | PlainMessage<ListAccessHistoryRequest> | undefined, b: ListAccessHistoryRequest | PlainMessage<ListAccessHistoryRequest> | undefined): boolean {
    return proto3.util.equals(ListAccessHistoryRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.access_history.ListAccessHistoryResponse
 */
export class ListAccessHistoryResponse extends Message<ListAccessHistoryResponse> {
  /**
   * @generated from field: repeated sypher.v1.types.AccessHistory access_histories = 1;
   */
  accessHistories: AccessHistory[] = [];

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_access_history = 2;
   */
  lastEvaluatedAccessHistory?: LastEvaluated;

  constructor(data?: PartialMessage<ListAccessHistoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.access_history.ListAccessHistoryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_histories", kind: "message", T: AccessHistory, repeated: true },
    { no: 2, name: "last_evaluated_access_history", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAccessHistoryResponse {
    return new ListAccessHistoryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAccessHistoryResponse {
    return new ListAccessHistoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAccessHistoryResponse {
    return new ListAccessHistoryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAccessHistoryResponse | PlainMessage<ListAccessHistoryResponse> | undefined, b: ListAccessHistoryResponse | PlainMessage<ListAccessHistoryResponse> | undefined): boolean {
    return proto3.util.equals(ListAccessHistoryResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.access_history.ListAccessHistoryByUsernameRequest
 */
export class ListAccessHistoryByUsernameRequest extends Message<ListAccessHistoryByUsernameRequest> {
  /**
   * @generated from field: string start_date = 1;
   */
  startDate = "";

  /**
   * @generated from field: string end_date = 2;
   */
  endDate = "";

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_access_history = 3;
   */
  lastEvaluatedAccessHistory?: LastEvaluated;

  constructor(data?: PartialMessage<ListAccessHistoryByUsernameRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.access_history.ListAccessHistoryByUsernameRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "end_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_evaluated_access_history", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAccessHistoryByUsernameRequest {
    return new ListAccessHistoryByUsernameRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAccessHistoryByUsernameRequest {
    return new ListAccessHistoryByUsernameRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAccessHistoryByUsernameRequest {
    return new ListAccessHistoryByUsernameRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAccessHistoryByUsernameRequest | PlainMessage<ListAccessHistoryByUsernameRequest> | undefined, b: ListAccessHistoryByUsernameRequest | PlainMessage<ListAccessHistoryByUsernameRequest> | undefined): boolean {
    return proto3.util.equals(ListAccessHistoryByUsernameRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.access_history.ListAccessHistoryByUsernameResponse
 */
export class ListAccessHistoryByUsernameResponse extends Message<ListAccessHistoryByUsernameResponse> {
  /**
   * @generated from field: repeated sypher.v1.types.AccessHistory access_histories = 1;
   */
  accessHistories: AccessHistory[] = [];

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_access_history = 2;
   */
  lastEvaluatedAccessHistory?: LastEvaluated;

  constructor(data?: PartialMessage<ListAccessHistoryByUsernameResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.access_history.ListAccessHistoryByUsernameResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_histories", kind: "message", T: AccessHistory, repeated: true },
    { no: 2, name: "last_evaluated_access_history", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAccessHistoryByUsernameResponse {
    return new ListAccessHistoryByUsernameResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAccessHistoryByUsernameResponse {
    return new ListAccessHistoryByUsernameResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAccessHistoryByUsernameResponse {
    return new ListAccessHistoryByUsernameResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAccessHistoryByUsernameResponse | PlainMessage<ListAccessHistoryByUsernameResponse> | undefined, b: ListAccessHistoryByUsernameResponse | PlainMessage<ListAccessHistoryByUsernameResponse> | undefined): boolean {
    return proto3.util.equals(ListAccessHistoryByUsernameResponse, a, b);
  }
}

