import { defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from "@dnd-kit/utilities";
import React from 'react'
import { DroppableItemProps } from '../../../types/dnd-types';
import { Box, ChakraProps } from '@chakra-ui/react';

interface Props extends Omit<DroppableItemProps, 'transition'>, ChakraProps, Omit<React.ComponentProps<'div'>, 'children' | 'color' | 'id'> {
  
}

export default function SortableItem({
    children,
    id,
    animateLayoutChanges = defaultAnimateLayoutChanges,
    data,
    disabled,
    getNewIndex,
    resizeObserverConfig,
    strategy,
    ...rest
  }: Props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
      } = useSortable({
        id,
        animateLayoutChanges,
        data,
        disabled,
        getNewIndex,
        resizeObserverConfig,
        strategy,
      });
      const style = {
        transform: CSS.Translate.toString(transform),
        transition,
      }
  return (
    <Box ref={setNodeRef} {...attributes} {...listeners} {...rest} style={style}>{children}</Box>
  )
}
