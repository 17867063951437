// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "../horizon/Card";
// Assets
import React from "react";
import { HiExternalLink } from "react-icons/hi";
import CustomDocHeading from "../custom-styled-components/CustomDocHeading";
import CustomDescription from "../custom-styled-components/CustomDescription";
import CustomNormalIcon from "../custom-styled-components/CustomNormalIcon";

export default function Documentation(props) {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "brand.400");
  const bgBar = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  const bg = useColorModeValue("secondaryGray.300", "navy.700");
  const textColorSecondary = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.500"
  );
  const textColorTertiary = useColorModeValue("secondaryGray.600", "white");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const { ...rest } = props;
  const iconSize = useBreakpointValue({
    base: "22px",
    xl: "15px",
    "2xl": "22px",
  });
  return (
    <Card maxH="max-content" {...rest}>
      <CustomDocHeading mt="20px" mb="4px">
        Share Data
      </CustomDocHeading>
      <CustomDescription mb="30px">
        You can share data with anyone and can track who access your data with
        our new tracking system.
      </CustomDescription>
      <Flex alignItems={"center"}>
        <CustomDocHeading>Kyber Encryption Details</CustomDocHeading>
        <CustomNormalIcon
          ml="1"
          as={HiExternalLink}
          color={textColor}
          onClick={() =>
            window.open("https://pq-crystals.org/kyber/", "_blank").focus()
          }
        />
      </Flex>
      <CustomDescription>
        Read about the post-quantum encryption used by secureshare to safe guard
        your data {"\n"}
      </CustomDescription>
    </Card>
  );
}
