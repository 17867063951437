// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/asset/v1/service/lifecycle/lifecycle.proto (package asset.v1.category, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddAssetToStageRequest, CreateLifecycleRequest, CreateLifecycleResponse, CreateStageRequest, CreateStageResponse, CreateStageTransitionRequest, DeleteLifecycleRequest, DeleteStageRequest, DeleteStageTransitionRequest, GetLifecycleRequest, GetLifecycleResponse, ListLifecyclesRequest, ListLifecyclesResponse, ListStageAssetsRequest, ListStageAssetsResponse, ListStagesRequest, ListStagesResponse, RemoveAssetFromStageRequest, UpdateLifecycleRequest, UpdateLifecycleResponse, UpdateStageRequest, UpdateStageResponse, UpdateStageTransitionRequest } from "./lifecycle_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service asset.v1.category.LifecycleService
 */
export const LifecycleService = {
  typeName: "asset.v1.category.LifecycleService",
  methods: {
    /**
     * @generated from rpc asset.v1.category.LifecycleService.CreateLifecycle
     */
    createLifecycle: {
      name: "CreateLifecycle",
      I: CreateLifecycleRequest,
      O: CreateLifecycleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.LifecycleService.GetLifecycle
     */
    getLifecycle: {
      name: "GetLifecycle",
      I: GetLifecycleRequest,
      O: GetLifecycleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.LifecycleService.ListLifecycles
     */
    listLifecycles: {
      name: "ListLifecycles",
      I: ListLifecyclesRequest,
      O: ListLifecyclesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.LifecycleService.UpdateLifecycle
     */
    updateLifecycle: {
      name: "UpdateLifecycle",
      I: UpdateLifecycleRequest,
      O: UpdateLifecycleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.LifecycleService.DeleteLifecycle
     */
    deleteLifecycle: {
      name: "DeleteLifecycle",
      I: DeleteLifecycleRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.LifecycleService.CreateStage
     */
    createStage: {
      name: "CreateStage",
      I: CreateStageRequest,
      O: CreateStageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.LifecycleService.ListStages
     */
    listStages: {
      name: "ListStages",
      I: ListStagesRequest,
      O: ListStagesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.LifecycleService.UpdateStage
     */
    updateStage: {
      name: "UpdateStage",
      I: UpdateStageRequest,
      O: UpdateStageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.LifecycleService.DeleteStage
     */
    deleteStage: {
      name: "DeleteStage",
      I: DeleteStageRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.LifecycleService.CreateStageTransition
     */
    createStageTransition: {
      name: "CreateStageTransition",
      I: CreateStageTransitionRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.LifecycleService.UpdateStageTransition
     */
    updateStageTransition: {
      name: "UpdateStageTransition",
      I: UpdateStageTransitionRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.LifecycleService.DeleteStageTransition
     */
    deleteStageTransition: {
      name: "DeleteStageTransition",
      I: DeleteStageTransitionRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.LifecycleService.AddAssetToStage
     */
    addAssetToStage: {
      name: "AddAssetToStage",
      I: AddAssetToStageRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.LifecycleService.RemoveAssetFromStage
     */
    removeAssetFromStage: {
      name: "RemoveAssetFromStage",
      I: RemoveAssetFromStageRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.LifecycleService.ListStageAssets
     */
    listStageAssets: {
      name: "ListStageAssets",
      I: ListStageAssetsRequest,
      O: ListStageAssetsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

