import React, { useState } from "react";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { SymDecryptMessage } from "@/utils/encrypt";
import { useAuth } from "@/context/auth";
import { showErrorToast, showServerError } from "@/services/toast-service";
import { requestClient, setupClient } from "@/grpc/client";
import { toast } from "sonner";
import FileUploader from "@/components/file-uploader";
// import { FileUploader } from "@/components/file-uploader";

// Validation Schemas
const phraseSchema = z.object({
  phrase: z.string().min(1, "Phrase can't be blank")
});

const forgotSchema = z.object({
  file: z.instanceof(File, { message: "File is required" })
});

interface EnterPhraseProps {
  onSuccess?: (data: {
    phrase?: string | null;
    root_key: string;
    backup?: boolean;
  }) => void;
  root_key: string;
}

export function EnterPhrase({ onSuccess, root_key }: EnterPhraseProps) {
  const [activeTab, setActiveTab] = useState<"phrase" | "forgot">("phrase");
  const { user } = useAuth();

  // Phrase Form
  const phraseForm = useForm<z.infer<typeof phraseSchema>>({
    resolver: zodResolver(phraseSchema),
    defaultValues: { phrase: "" }
  });

  // Forgot Phrase Form
  const forgotForm = useForm<z.infer<typeof forgotSchema>>({
    resolver: zodResolver(forgotSchema),
    defaultValues: { file: undefined }
  });

  const handlePhraseSubmit = async (values: z.infer<typeof phraseSchema>) => {
    try {
      const decryptedRootKey = await SymDecryptMessage(values.phrase, root_key);

      if (decryptedRootKey) {
        onSuccess?.({
          phrase: values.phrase,
          root_key: decryptedRootKey
        });
      } else {
        showErrorToast("The phrase you entered is incorrect.");
      }
    } catch (error) {
      showServerError(error)
    }
  };

  const handleForgotSubmit = async (values: z.infer<typeof forgotSchema>) => {
    try {
      const res = await setupClient.getRootKey({});
      const rootKey = res?.rootKey;

      const backupPhrase = await values.file.text();
      const decryptedRootKey = await SymDecryptMessage(backupPhrase, rootKey);

      if (decryptedRootKey) {
        onSuccess?.({
          phrase: null,
          root_key: decryptedRootKey,
          backup: true
        });
      } else {
        toast.error("Invalid Backup Phrase", {
          description: "The file you uploaded is incorrect.",
        });
      }
    } catch (error) {
      showServerError(error)
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-green-400 to-green-600">
      <Card className="w-full max-w-md">
        <Tabs value={activeTab} onValueChange={(val) => setActiveTab(val as "phrase" | "forgot")}>
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="phrase">Security Phrase</TabsTrigger>
            <TabsTrigger value="forgot">Forgot Phrase</TabsTrigger>
          </TabsList>

          <TabsContent value="phrase">
            <CardHeader>
              <CardTitle>Enter Security Phrase</CardTitle>
              <CardDescription>
                Security phrase is used as an encryption key that stays in your browser.
                All data in the request feature is encrypted using this key.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Form {...phraseForm}>
                <form onSubmit={phraseForm.handleSubmit(handlePhraseSubmit)} className="space-y-4">
                  <FormField
                    control={phraseForm.control}
                    name="phrase"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Security Phrase</FormLabel>
                        <FormControl>
                          <Input
                            type="password"
                            placeholder="Enter your security phrase"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Button type="submit" className="w-full">
                    Submit
                  </Button>
                </form>
              </Form>
            </CardContent>
          </TabsContent>

          <TabsContent value="forgot">
            <CardHeader>
              <CardTitle>Reset Phrase</CardTitle>
              <CardDescription>
                Forgot your security phrase? Upload your backup key and we'll help you reset it.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Form {...forgotForm}>
                <form onSubmit={forgotForm.handleSubmit(handleForgotSubmit)} className="space-y-4">
                  <FormField
                    control={forgotForm.control}
                    name="file"
                    render={({ field: { value, onChange } }) => (
                      <FormItem>
                        <FormLabel>Backup Key File</FormLabel>
                        <FormControl>
                          <FileUploader
                            value={value}
                            onChange={onChange}
                            accept=".txt"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Button type="submit" className="w-full">
                    Reset Phrase
                  </Button>
                </form>
              </Form>
            </CardContent>
          </TabsContent>
        </Tabs>
      </Card>
    </div>
  );
}

export default EnterPhrase;