// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/group/group.proto (package iam.v1.group, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddPoliciesToGroupsRequest, AddUsersToGroupsRequest, CreateGroupRequest, CreateGroupResponse, DeleteGroupsRequest, GetGroupRequest, GetGroupResponse, ListGroupPoliciesRequest, ListGroupPoliciesResponse, ListGroupsRequest, ListGroupsResponse, ListGroupUsersRequest, ListGroupUsersResponse, RemovePoliciesFromGroupsRequest, RemoveUsersFromGroupsRequest, UpdateGroupRequest, UpdateGroupResponse } from "./group_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service iam.v1.group.GroupService
 */
export const GroupService = {
  typeName: "iam.v1.group.GroupService",
  methods: {
    /**
     * @generated from rpc iam.v1.group.GroupService.CreateGroup
     */
    createGroup: {
      name: "CreateGroup",
      I: CreateGroupRequest,
      O: CreateGroupResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.GetGroup
     */
    getGroup: {
      name: "GetGroup",
      I: GetGroupRequest,
      O: GetGroupResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.ListGroups
     */
    listGroups: {
      name: "ListGroups",
      I: ListGroupsRequest,
      O: ListGroupsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.UpdateGroup
     */
    updateGroup: {
      name: "UpdateGroup",
      I: UpdateGroupRequest,
      O: UpdateGroupResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.DeleteGroups
     */
    deleteGroups: {
      name: "DeleteGroups",
      I: DeleteGroupsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.AddPoliciesToGroups
     */
    addPoliciesToGroups: {
      name: "AddPoliciesToGroups",
      I: AddPoliciesToGroupsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.RemovePoliciesFromGroups
     */
    removePoliciesFromGroups: {
      name: "RemovePoliciesFromGroups",
      I: RemovePoliciesFromGroupsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.AddUsersToGroups
     */
    addUsersToGroups: {
      name: "AddUsersToGroups",
      I: AddUsersToGroupsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.RemoveUsersFromGroups
     */
    removeUsersFromGroups: {
      name: "RemoveUsersFromGroups",
      I: RemoveUsersFromGroupsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.ListGroupPolicies
     */
    listGroupPolicies: {
      name: "ListGroupPolicies",
      I: ListGroupPoliciesRequest,
      O: ListGroupPoliciesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.group.GroupService.ListGroupUsers
     */
    listGroupUsers: {
      name: "ListGroupUsers",
      I: ListGroupUsersRequest,
      O: ListGroupUsersResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

