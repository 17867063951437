import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Heading,
  Flex,
  Spinner,
  VStack,
  useColorModeValue,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Grid,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";

import { useAuth } from "../context/auth";
import { getResponse } from "@defense-station/api-service";
import { useParams } from "react-router-dom";
import Documentation from "../components/secure-share/Documentation";
import * as timeago from "timeago.js";
import { format, isAfter, parseISO } from "date-fns";
import { GoPrimitiveDot } from "react-icons/go";
import { BiCircle } from "react-icons/bi";
import { useRootKey } from "../context/request/root-key";
import { readAndDecryptFileContentFromURL } from "../utils/custom-function";
import { SERVICE_BASE_PATH_FOR_DROPZONE } from "../constants/constants";
import { useReadResponse } from "../context/dropzone/read-dropzone-context";
import banner from "../assets/img/auth/banner.png";
import avatar from "../assets/img/avatars/avatar4.png";
import Banner from "../components/secure-share/response/details/Banner";
import ResponseDetails from "../components/secure-share/response/details/ResponseDetails";
import ResponseNotReceivedComponent from "../components/ResponseNotReceivedComponent";
import LinkExpiredComponent from "../components/LinkExpiredComponent";
import { useErrors } from "../context/errors";
import { showServerError } from "../components/toast";

const DropzoneReadResponse = () => {
  const { user, setUser, setHasPermission } = useAuth();
  const params = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const [data, setData] = useState();
  const [schema, setSchema] = useState();
  const [formData, setFormData] = useState();
  const [isLoading, setLoading] = useState(true);
  const { root_key, setRootKey, restoreRootKey } = useRootKey();
  const [linkBroken, setLinkBroke] = useState(false);
  const [privateKey, setPrivateKey] = useState(null);
  const [note, setNote] = useState("");
  const { setFiles, setDecKey, files } = useReadResponse();
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getResponse(
        user?.account_id,
        user?.username,
        params.requester_id,
        params.respondent_id
      );
      if (res.errors) {
        const error = res?.errors[0]?.message;
        const code = error?.split(" ")?.[0];
        if (code == 7) {
        }
        const errors = res.errors;
        errors.map((error) => {
          if (error.message.includes("access denied")) setLinkBroke(true);
        });
      }
      let data = res?.data?.sypher_v1_GetResponse?.response;
      const signedURLs = res?.data?.sypher_v1_GetResponse?.signed_urls;
      if (data?.template) {
        const prvKey = data?.enc_dec_key;
        setPrivateKey(prvKey);
        const template = data?.template;
        const formSchema = JSON.parse(
          String.fromCharCode(...template?.data?.data)
        );
        setSchema(formSchema);
        const dataFile = signedURLs["data"];
        const decryptedDataFile = await readAndDecryptFileContentFromURL(
          dataFile,
          prvKey
        );
        console.log({dataFile, decryptedDataFile})
        setFormData(JSON.parse(decryptedDataFile));
        const files = {};
        Object.keys(signedURLs)?.map((key) => {
          if (key != "data") {
            files[key] = {
              key: key,
              value: signedURLs[key],
            };
          }
        });
        setDecKey(prvKey);
        setFiles(files);
        setData(data);
        setLoading(false);
      } else {
        setData(null);
        setLoading(false);
      }
    } catch (e) {
      if (e.message.includes("access denied")) {
        setLinkBroke(true);
      } else {
        showServerError(e);
      }
    }
  };
  
  const init = async () => {
    if (!params?.requester_id || !params?.respondent_id) {
      setLinkBroke(true);
      setLoading(false);
      return true;
    }
    if (user) {
      fetchData();
    }
  };
  useEffect(() => {
    init();
  }, [user, root_key, user?.userPhrase]);
  const onPhraseSuccess = ({ root_key, phrase }) => {
    let newUserData = { ...user, userPhrase: phrase, root_key: root_key };
    setRootKey(root_key);
    setUser(newUserData);
  };
  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorLink = useColorModeValue("blue.500", "white");
  const textColorSecondary = "gray.400";
  const parsedEndDate = data ? parseISO(data?.end_date) : "";
  const parsedStartDate = data ? parseISO(data?.start_date) : "";
  const expired = data ? isAfter(new Date(), parsedEndDate) : "";
  const live = data
    ? isAfter(new Date(), parsedStartDate) && isAfter(parsedEndDate, new Date())
    : "";
  const idle = data
    ? isAfter(parsedStartDate, new Date()) && isAfter(parsedEndDate, new Date())
    : "";
  const retryLimit = data?.access_limit ? data?.access_limit : 0;
  let selectedColor = null;
  let icon = null;
  let colorScheme = null;
  let status = null;
  let rowClass = "";
  if (data?.responder_controls) {
    if (expired) {
      selectedColor = "red.400";
      icon = GoPrimitiveDot;
      colorScheme = "red";
      status = "Expired";
    } else if (live) {
      selectedColor = "brand.600";
      icon = GoPrimitiveDot;
      colorScheme = "brand";
      status = "Live";
    } else if (idle) {
      selectedColor = "brand.600";
      icon = BiCircle;
      colorScheme = "gray";
      status = "Idle";
    }
  } else {
    selectedColor = "brand.600";
    icon = GoPrimitiveDot;
    colorScheme = "brand";
    status = "Live";
  }
  const { isOpen, onOpen, onClose } = useDisclosure();
  const createdAt = useMemo(() => {
    return data?.created_at
      ? format(parseISO(data?.created_at), "MMM d, yyyy, h:mm a")
      : "";
  }, [data]);
  const startDate = useMemo(() => {
    return data?.start_date ? timeago.format(data?.start_date) : "";
  }, [data]);
  const endDate = useMemo(() => {
    return data?.end_date ? timeago.format(data?.end_date) : "";
  }, [data]);
  console.log({formData, schema})
  return linkBroken ? (
    <Flex h={"100vh"} justifyContent={"center"} alignItems="center">
      <VStack>
        <LinkExpiredComponent />
      </VStack>
    </Flex>
  ) : (
    <>
      {isLoading && (
        <Flex h={"100vh"} justifyContent={"center"} alignItems="center">
          <Spinner />
        </Flex>
      )}
      {!isLoading &&
        (data ? (
          <Box p={{ base: "6", xl: "4", "2xl": "6" }} flex={1}>
            <Box mb={{ sm: "8px", md: "0px" }}>
              <Breadcrumb>
                <BreadcrumbItem color={secondaryText} fontSize="sm">
                  <BreadcrumbLink href="/" color={secondaryText}>
                    Sypher
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem color={secondaryText} fontSize="sm">
                  <BreadcrumbLink
                    href={`${SERVICE_BASE_PATH_FOR_DROPZONE}responses`}
                    color={secondaryText}
                  >
                    Responses
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem color={secondaryText} fontSize="sm">
                  <BreadcrumbLink
                    href={`${SERVICE_BASE_PATH_FOR_DROPZONE}responses/${params?.requester_id}/${params?.respondent_id}`}
                    color={secondaryText}
                  >
                    {params?.requester_id}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
              {/* <Link
                    color={mainText}
                    href="#"
                    bg="inherit"
                    borderRadius="inherit"
                    fontWeight="bold"
                    fontSize="34px"
                    _hover={{ color: { mainText } }}
                    _active={{
                      bg: "inherit",
                      transform: "none",
                      borderColor: "transparent",
                    }}
                    _focus={{
                      boxShadow: "none",
                    }}
                  >
                    {searchParams.get("email")}
                  </Link> */}
            </Box>
            <Box>
              <Grid
                mb="20px"
                display={{ base: "block", "2xl": "grid" }}
                templateColumns={{ base: "2.4fr 1fr", lg: "2.4fr 1fr" }}
                gap="4"
                direction="column"
              >
                <Box gridArea={{ base: "1 / 1 / 2 / 3", lg: "1 / 1 / 2 / 2" }}>
                  <SimpleGrid spacing={"2"}>
                    <Banner
                      banner={banner}
                      avatar={avatar}
                      name={data?.requester_id}
                      job={createdAt}
                      startDate={startDate}
                      endDate={endDate}
                      responderControls={data?.responder_controls}
                      statusColor={colorScheme}
                      status={status}
                    />
                    <ResponseDetails
                      isLoading={isLoading}
                      response={data}
                      schema={schema}
                      formData={formData}
                    />
                  </SimpleGrid>
                </Box>

                <Box
                  mt={{ base: "20px", lg: "0px" }}
                  zIndex="0"
                  gridArea={{ base: "2 / 1 / 3 / 3", lg: "1 / 2 / 2 / 3" }}
                  display={{ base: "none", "2xl": "block" }}
                >
                  <Documentation
                    minH="341px"
                    maxH={{ base: "100%", lg: "341px" }}
                    mb="4"
                  />
                </Box>
              </Grid>
            </Box>
          </Box>
        ) : (
          <Flex height={"95vh"} justifyContent={"center"} alignItems="center">
            <VStack>
              <ResponseNotReceivedComponent />
            </VStack>
          </Flex>
        ))}
    </>
  );
};

export default DropzoneReadResponse;

// {JSON.stringify(values)}
// {`start : ${range[0]?.start_date?.toISOString()}`}
// {`end : ${range[0]?.end_date?.toISOString()}`}
