import { Td, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import CustomText from "./CustomText";

export default function CustomTd({ children, raw, ...rest }) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Td
      minW={{ sm: "150px", md: "200px", lg: "auto" }}
      borderColor="transparent"
    >
      {raw ? (
        children
      ) : (
        <CustomText color={textColor} fontWeight="700">
          {children}
        </CustomText>
      )}
    </Td>
  );
}
