import { Interceptor } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import { ClientConfig, createAliasServiceClient, createGroupServiceClient, createRequestServiceClient, createSendServiceClient, createSetupServiceClient, createUserServiceClient, EmptyCredentialProvider, JwtCredentialProvider } from "@/ds-apis"
import { createDocumentServiceClient, createFolderServiceClient, createOnboardingServiceClient, createRoleServiceClient, createTemplateServiceClient, createWorkflowServiceClient } from "@/ds-apis";

// Function to check token validity (can be extended to check expiration or make an API call)
const getToken = () => {
    const token = localStorage.getItem("authToken"); // Retrieve token from localStorage
    return token;
};

// Redirect function to login page
const redirectToLogin = () => {
    window.location.href = "/signin"; // Redirect to your login route
};

const authMiddleware: Interceptor = (next) => async (req) => {
    const token = getToken();

    // If no token, redirect to login
    if (!token) {
        redirectToLogin();
        // return Promise.reject(new Error("No valid token, redirecting to login"));
        return;
    }

    // Set the Authorization header
    req.header.set("Authorization", `Bearer ${token}`);

    try {
        // Continue with the request
        return await next(req);
    } catch (error: any) {
        // Check if error is 401 Unauthorized
        if (error.code === "Unauthenticated" || error.statusCode === 401) {
            redirectToLogin(); // Redirect if unauthorized
            return;
        }
        else if (error.message.includes("user not logged in")) {
            redirectToLogin();
            return;
        }
        throw error; // Re-throw other errors
    }
};

const transport = createConnectTransport({
    baseUrl: "https://demo.connectrpc.com",
    interceptors: [
        authMiddleware
    ]
});

const config: ClientConfig = {
    region: "",
    service: "",
    endpoint: "https://sypher.us.api.dev.defencestation.ca/",
    ssl: false,
}


const iamConfig: ClientConfig = {
    region: "",
    service: "",
    endpoint: "https://iam.us.api.dev.defencestation.ca/",
    ssl: false,
}



const clientCredentials = new JwtCredentialProvider({
    token: getToken() || '',
})
// Here we make the client itself, combining the service
// definition with the transport.
export const userClient = createUserServiceClient(iamConfig, clientCredentials, [authMiddleware])

const emptyCredentials: EmptyCredentialProvider = new EmptyCredentialProvider();
// Here we make the client itself, combining the service
// definition with the transport.
export const authClient = createUserServiceClient(iamConfig, emptyCredentials)

// Here we make the client itself, combining the service
// definition with the transport.

export const sendClient = createSendServiceClient(config, clientCredentials, [authMiddleware])

export const requestClient = createRequestServiceClient(config, clientCredentials, [authMiddleware])

export const setupClient = createSetupServiceClient(config, clientCredentials, [authMiddleware])

export const templateClient = createTemplateServiceClient(config, clientCredentials, [authMiddleware])
