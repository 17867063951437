import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

export default function LoadingScreen() {
  return (
    <Flex height={"100vh"} justifyContent={"center"} alignItems="center">
      <Spinner />
    </Flex>
  );
}
