import React, { useState } from "react";
import { cells, renderers } from "@reactjsonforms/chakra-renderers";
import { JsonForms } from "@reactjsonforms/react";
import {
  // FileCell,
  TextCell,
  // fileCellTester,
  textCellTester,
} from "./json-form-builder/main";
import FileCell, { fileCellTester } from "./jsonforms-renderer/FileCell";

const mycells = [
  ...cells,
  {
    tester: textCellTester,
    cell: TextCell,
  },
  {
    tester: fileCellTester,
    cell: FileCell,
  },
];

const schema = {
  type: "object",
  properties: {
    json_creds: {
      type: "file",
    },
    customer_id: {
      type: "string",
    },
    admin_email: {
      type: "string",
      format: "email",
    },
    users: {
      type: "boolean",
    },
    groups: {
      type: "boolean",
    },
    org_units: {
      type: "boolean",
    },
    domains: {
      type: "boolean",
    },
    devices: {
      type: "boolean",
    },
  },
  required: ["json_creds", "customer_id", "admin_email", "users"],
};
const uischema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Group",
      label: "Credentials",
      elements: [
        {
          type: "VerticalLayout",
          elements: [
            {
              type: "Control",
              label: "JSON Creds",
              scope: "#/properties/json_creds",
            },
            {
              type: "Control",
              label: "Customer ID",
              scope: "#/properties/customer_id",
            },
            {
              type: "Control",
              label: "Admin Email",
              scope: "#/properties/admin_email",
            },
          ],
        },
      ],
    },
    {
      type: "Group",
      label: "Select Data",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              label: "Users",
              scope: "#/properties/users",
            },
            {
              type: "Control",
              label: "Groups",
              scope: "#/properties/groups",
            },
            {
              type: "Control",
              label: "Org Units",
              scope: "#/properties/org_units",
            },
            {
              type: "Control",
              label: "Domains",
              scope: "#/properties/domains",
            },
            {
              type: "Control",
              label: "Devices",
              scope: "#/properties/devices",
            },
          ],
        },
      ],
    },
  ],
};

const initialData = null;

export default function JSONForm({
  schema,
  uischema,
  data = initialData,
  errors,
  onChange,
  validationMode,
  isReadOnly,
}) {
  return (
    <JsonForms
      schema={schema}
      uischema={uischema}
      data={data}
      renderers={renderers}
      cells={mycells}
      validationMode={validationMode}
      additionalErrors={errors}
      onChange={onChange}
      readonly={isReadOnly}
    />
  );
}
