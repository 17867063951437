export const tableColumnsManagement = [
  {
    Header: "Name",
    accessor: "first_name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Updated At",
    accessor: "updated_at",
  },
  {
    Header: " "
  }
];
