import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EnterPhrase from "../components/secure-share/request/EnterPhrase";
import { useAuth } from "../context/auth";
import { useRootKey } from "../context/request/root-key";
import { readRootKey } from "@defense-station/api-service";
import LoadingScreen from "./LoadingScreen";
import { setupClient } from "@/grpc/client";
import { showServerError } from "@/services/toast-service";

export default function PassPhrase() {
  const [isLoading, setIsLoading] = useState(true);
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const { root_key, setRootKey, restoreRootKey, setRestoreRootKey } =
    useRootKey();
  const getRootKey = async () => {
    try {
      const res = await setupClient.getRootKey({});
      const rootKey = res?.rootKey;
      if (rootKey) {
        setRootKey(rootKey);
      } else {
        navigate("/root-key", { replace: true });
      }
    } catch (e) {
      if (e.message.includes("5 NOT_FOUND: no key registered")) {
        navigate("/root-key", { replace: true });
        return;
      } else {
        showServerError(e);
      }
    }
    setIsLoading(false);
  };
  const onSubmit = ({ root_key, phrase, backup }) => {
    if (backup) {
      setRestoreRootKey(true);
      navigate("/root-key", { replace: true });
    } else {
      let newUserData = { ...user, userPhrase: phrase, root_key: root_key };
      setUser(newUserData);
      setRootKey(root_key);
      navigate(-1);
    }
  };
  useEffect(() => {
    if (user) {
      if (root_key) {
        setIsLoading(false);
      } else {
        getRootKey();
      }
    }
  }, [user]);
  return isLoading ? (
    <LoadingScreen />
  ) : (
    <EnterPhrase root_key={root_key} onSubmit={onSubmit} />
  );
}
