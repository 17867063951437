import { IconButton, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

export default function CustomActionIconButton({ children, ...rest }) {
  const buttonSize = useBreakpointValue({
    base: "md",
    xl: "sm",
    "2xl": "md",
  });
  return <IconButton size={buttonSize} {...rest} />;
}
