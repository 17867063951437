// Chakra imports
import {
  Box,
  Flex,
  Text,
  Icon,
  Link,
  Image,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";
// Custom components
import Card from "../horizon/Card";
import React from "react";
import Features from "./Features";
import { HiExternalLink } from "react-icons/hi";

export default function DocSideBar(props) {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "brand.400");
  const bgBar = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  const bg = useColorModeValue("secondaryGray.300", "navy.700");
  const textColorSecondary = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.500"
  );
  const textColorTertiary = useColorModeValue("secondaryGray.600", "white");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const { ...rest } = props;
  return (
    <Card maxH='max-content' {...rest} >
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='20px'
        mb='4px'>
        You should own your data
      </Text>
      <Text color={textColor} fontSize='md' fontWeight='400' mb='30px'>
      We believe that when it’s your data you should be in full control. In the sprit of this, all the data encryption happens on your browser. “Data never leaves your computer un-encrypted”. The Keys to decrypt the data never reaches our server in any form or shape.
      </Text>
      <Flex flexDirection={"row"} alignItems="center" mb='16px'>
      <Text color={textColor} fontSize='xl' fontWeight='700' >
      Kyber Encryption Details
      
      </Text>
      <Icon ml="1" as={HiExternalLink} color={textColor} h='22px' w='22px' onClick={() => window.open("https://pq-crystals.org/kyber/", '_blank').focus()}/>
      </Flex>
      <Text color={textColor} fontSize='md' fontWeight='400' >
      Read about the post-quantum encryption used by secureshare to safe guard your data {"\n"}
      </Text>
      <Text mb='30px' color={brandColor}>
      <Link href={"https://pq-crystals.org/kyber/"} isExternal color={brandColor} fontSize='md' fontWeight='400' mb='30px'>
        {"Read More"}
      </Link>
      </Text>
      <Features />
    </Card>
  );
}
