import {
  Box,
  Flex,
  Icon,
  IconButton,
  Stack,
  StackItem,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { parseISO, format } from "date-fns";
import React from "react";
import { HiExternalLink } from "react-icons/hi";

export default function TimelineRow(props) {
  const { icon, last, text, date, link, iconColor, lineColor } = props;
  const textSecondaryNonCurrent = useColorModeValue(
    "secondaryGray.600",
    "white"
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const onLinkClick = () => window.open(link);
  const fontSize = useBreakpointValue({
    base: "sm",
    xl: "xs",
    "2xl": "sm",
  });
  const iconSize = useBreakpointValue({
    base: "25px",
    xl: "15",
    "2xl": "25px",
  });
  const height = useBreakpointValue({
    base: "70px",
    xl: "50px",
    "2xl": "70px",
  });
  return (
    <Flex
      onClick={onLinkClick}
      cursor="pointer"
      minH={height}
      height={height}
      mb="5px"
      justifyContent={"space-between"}
      alignItems="flex-start"
    >
      <Flex
        height={"100%"}
        justifyContent={"flex-start"}
        alignItems="flex-start"
      >
        <Flex
          flexDirection={"column"}
          alignItems="center"
          height={"100%"}
          mt="3px"
          mr="2"
        >
          <Icon color={iconColor} as={icon} h={iconSize} w={iconSize} />
          {!last && (
            <Box
              width={"2px"}
              background={lineColor ? lineColor : "brand.200"}
              height={"100%"}
            />
          )}
        </Flex>
        <Flex flexDirection={"column"}>
          <Text
            _hover={{ color: iconColor }}
            fontSize={fontSize}
            fontWeight="bold"
            color={textColor}
          >
            {text}
          </Text>
          <Text fontSize={fontSize} color={textSecondaryNonCurrent}>
            {date ? format(parseISO(date), "MMM d, yyyy, h:mm a") : ""}
          </Text>
        </Flex>
      </Flex>
      {/* <Flex mt="3px">
            {link?<Icon  color='secondaryGray.500' h='18px' w='18px' as={HiExternalLink} onClick={onLinkClick} />:null}
        </Flex> */}
    </Flex>
  );
}
