// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Progress,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import React from "react";
// Assets
import { MdOutlineCloudDone } from "react-icons/md";
import Card from "../card/Card";
import IconBox from "../icons/IconBox";

export default function Storage(props) {
  const { used, total, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const imageSize = useBreakpointValue({
    base: "100px",
    xl: "50px",
    "2xl": "100px",
  });
  const iconSize = useBreakpointValue({
    base: "46px",
    xl: "35px",
    "2xl": "46px",
  });
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align="center" {...rest}>
      <IconBox
        mx="auto"
        h={imageSize}
        w={imageSize}
        icon={
          <Icon
            as={MdOutlineCloudDone}
            color={brandColor}
            h={iconSize}
            w={iconSize}
          />
        }
        bg={box}
      />
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize={{
          base: "2xl",
          xl: "md",
          "2xl": "2xl",
        }}
        mt={{
          base: "10px",
          xl: "5px",
          "2xl": "10px",
        }}
      >
        Your storage
      </Text>
      <Text
        color={textColorSecondary}
        fontSize={{
          base: "md",
          xl: "xs",
          "2xl": "md",
        }}
        maxW={{ base: "100%", xl: "80%", "3xl": "60%" }}
        mx="auto"
      >
        Supervise your drive space in the easiest way
      </Text>
      <Box w="100%" mt="auto">
        <Flex w="100%" justify="space-between" mb="10px">
          <Text
            color={textColorSecondary}
            fontSize={{
              base: "sm",
              xl: "xs",
              "2xl": "sm",
            }}
            maxW="40%"
          >
            {used} GB
          </Text>
          <Text
            color={textColorSecondary}
            fontSize={{
              base: "sm",
              xl: "xs",
              "2xl": "sm",
            }}
            maxW="40%"
          >
            {total} GB
          </Text>
        </Flex>
        <Progress
          align="start"
          colorScheme="brand"
          value={(used / total) * 100}
          w="100%"
        />
      </Box>
    </Card>
  );
}
