import React, { useState } from "react";
import {
  useBreakpointValue,
  Flex,
  Button,
  ButtonGroup,
} from "@chakra-ui/react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import styled from "@emotion/styled";
import { useWindowSize } from "react-use";
import "../../css/main.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const StyledDocument = styled(Document)`
  padding-top: 10px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-left: 30px;
`;

const PdfViewer = ({ sourceFile }) => {
  // const val = useBreakpointValue({ base: 'outline', md: 'solid' });
  const { width, height } = useWindowSize();
  const [numPages, setNumPages] = useState(null);

  const [page, setPage] = useState(1);

  const onNext = () => {
    if (page < numPages) {
      setPage(page + 1);
    }
  };

  const onBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };
  return (
    <>
      <Flex justifyContent="space-between">
        <Button variant={"brand"} onClick={onBack}>
          Back
        </Button>
        <Button variant={"brand"} onClick={onNext}>
          Next
        </Button>
      </Flex>

      <Flex justifyContent="center" m={10}>
        <StyledDocument
          renderMode="canvas"
          file={sourceFile}
          onLoadSuccess={onDocumentLoadSuccess}
          onContextMenu={(e) => e.preventDefault()}
          className="pdf-container"
        >
          {/*  <Page pageNumber={3} /> */}
          <Page
            height={height - height * 0.2}
            width={Math.min(width - width * 0.4, 937)}
            canvasBackground="transparent"
            pageNumber={page}
          />
        </StyledDocument>
      </Flex>
    </>
  );
};

export default PdfViewer;
