import { PostQAsymDecryptFile, SymDecryptFile } from "./encrypt";

export const randomString = (length: number, chars: string) => {
  var mask = "";
  if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
  if (chars.indexOf("A") > -1) mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  if (chars.indexOf("#") > -1) mask += "0123456789";
  if (chars.indexOf("!") > -1) mask += "~`!@#$%^&*()_+-={}[]:\";'<>?,./|\\";
  var result = "";
  for (var i = length; i > 0; --i)
    result += mask[Math.round(Math.random() * (mask.length - 1))];
  return result;
};

export const isPermissionError = (error: string) => {
  return error.includes("7 PERMISSION_DENIED");
};

export const buildFileFromString = (string: string, name = "message.txt") => {
  const file = new File([string], name, { type: "text/plain" });
  return file;
};

export const readAndDecryptFileContentFromURL = async (
  url: string,
  dec_key: string,
  title = "file.txt"
) => {
  const res = await fetch(url);

  const fullData = await res.blob();
  const defile = new File([fullData], title);

  const decryptBlob = await PostQAsymDecryptFile(dec_key, defile);
  const content = decryptBlob.text();
  return content;
};

export const readAndDecryptFileBlobFromURL = async (
  url: string,
  dec_key?: string,
  trackProgress?: (progress: number) => void,
  title: string = "file.txt",
  signal?: AbortSignal
) => {
  const response = await fetch(url, { cache: "no-cache", signal: signal });
  const contentLength: number = +response.headers.get("Content-Length")!;
  let loadedSize = 0;
  let receivedLength: number = 0; // received that many bytes at the moment
  let chunks = []; // array of received binary chunks (comprises the body)
  const reader = response?.body?.getReader();
  while (true) {
    // done is true for the last chunk
    // value is Uint8Array of the chunk bytes
    if(reader){
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      chunks.push(value);
      receivedLength += value.length;
      trackProgress?.((receivedLength / contentLength) * 100);
    }

    
   
  }

  // Step 4: concatenate chunks into single Uint8Array
  let chunksAll = new Uint8Array(receivedLength); // (4.1)
  let position = 0;
  for (let chunk of chunks) {
    chunksAll.set(chunk, position); // (4.2)
    position += chunk.length;
  }
  // Step 5: decode into a string
  // let result = new TextDecoder("utf-8").decode(chunksAll);

  const fullData = new Blob([chunksAll]);
  const defile = new File([fullData], title);

  const decryptBlob = await PostQAsymDecryptFile(dec_key, defile);
  return decryptBlob;
};

export const symDecryptFile = async (
  url: string,
  encryptionKey: string,
  title = "message.txt"
) => {
  const res = await fetch(url);

  const fullData = await res.blob();
  const defile = new File([fullData], title);

  const decryptBlob = await SymDecryptFile(encryptionKey, defile);
  return decryptBlob;
};

export const loadCaptchaScript = (onLoadFunction: () => void) => {
  const script = document.createElement("script");
  script.src =
    "https://www.google.com/recaptcha/api.js?render=6Lcrd9ofAAAAAEWDXKc8RFx5UZ9v2txrM3FvRpL-";
  script.async = true;
  document.body.appendChild(script);
  //@ts-ignore
  const callback = () => window.grecaptcha.ready(() => onLoadFunction());
  script.addEventListener("load", callback);
  return () => {
    script?.removeEventListener("load", callback);
    const captchaBadges = document.getElementsByClassName("grecaptcha-badge");
    for (let i = 0; i < captchaBadges.length; i++) {
      captchaBadges[i]?.remove();
    }
    document.body.removeChild(script);
  };
};

export const findBounds = (points: any) => {
  var n = points.length;
  if (n === 0) {
    return [];
  }
  var d = points[0].length;
  var lo = points[0].slice();
  var hi = points[0].slice();
  for (var i = 1; i < n; ++i) {
    var p = points[i];
    for (var j = 0; j < d; ++j) {
      var x = p[j];
      lo[j] = Math.min(lo[j], x);
      hi[j] = Math.max(hi[j], x);
    }
  }
  return [lo, hi];
};

export const futch = (url: string, opts: any = {}, onProgress: any) => {
  console.log({ opts });
  return new Promise((res, rej) => {
    var xhr = new XMLHttpRequest();
    xhr.open(opts.method || "get", url);
    for (var k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k]);
    xhr.onload = (e: any) => {
      if (xhr.status == 200) {
        res(e.target.responseText);
      } else {
        rej({
          code: xhr.status,
          message: `${xhr.statusText}: Error while uploading file.`,
        });
      }
    };
    xhr.onerror = rej;
    if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
    xhr.send(opts.body);
  });
};

export const buildJSONSchema = (
  requested_fields: Array<any>,
  MAX_FILE_SIZE: number = 1073741824
) => {
  let json: any = {};
  json.title = "";
  json.type = "object";
  let properties = {};
  let required: Array<any> = [];
  let filesObj: Array<any> = [];
  requested_fields?.map((field, index) => {
    const name = `field${index}`;
    if (field.field_required) {
      required.push(name);
    }
    if (field.type == "file") {
      //@ts-ignore
      properties[name] = {
        title: field?.field_name,
        type: "string",
        format: "file",
        maxSize: MAX_FILE_SIZE,
        minLength: 3,
      };
      filesObj.push({
        key: name,
        value: {
          allowed_ext: ["png", "jpg"],
          max_size: 30000,
        },
      });
    } else {
      //@ts-ignore
      properties[name] = {
        title: field?.field_name,
        type: field.type,
        minLength: 3,
      };
    }
  });
  filesObj.push({
    key: "data",
    value: {
      allowed_ext: ["png", "jpg"],
      max_size: MAX_FILE_SIZE,
    },
  });
  json.properties = properties;
  json.required = required;
  json.files = filesObj;
  return json;
};

export const getRandomNumber = () => Math.floor(Math.random() * 10000) + 1;

export function getMimeTypeAndBase64(dataUrl: string) {
  // Extract the MIME type
  const mimeTypeMatch = dataUrl.match(/data:([^;]+);base64,/);
  if (!mimeTypeMatch) {
    throw new Error("Invalid data URL");
  }
  const mimeType = mimeTypeMatch[1];

  const extension = mimeType.split("/")?.[1];

  // Extract the base64 data
  const base64Data = dataUrl.split(",")[1];

  return { mimeType, base64Data, extension };
}

export const base64toBlob = (data: string) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr(
    "data:application/pdf;base64,".length
  );

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
};


export const generateCSV = (data: string[][], fileName: string) => {
  if (data.length === 0) {
    console.error('Data array is empty.');
    return;
  }

  try {
    // Create a Blob containing the CSV data
    const blob = new Blob([data.join('\n')], {
      type: 'text/csv;charset=utf-8',
    });

    // // Create a temporary URL for the Blob
    // const url = window.URL.createObjectURL(blob);

    // // Create a temporary <a> element to trigger the download
    // const link = document.createElement('a');
    // link.href = url;
    // link.download = fileName;

    // // Programmatically click the link to trigger the download
    // link.click();

    // // Clean up by revoking the URL
    // window.URL.revokeObjectURL(url);
    return blob;
  } catch (error) {
    console.error('Error generating CSV:', error);
  }
};

export const extractFilesFromJSONSchema = (jsonSchema: any) => {
  const keys = Object.keys(jsonSchema.schema.properties);
  let filesObj:any = {};
  keys.forEach((key) => {
    if (jsonSchema.schema.properties[key]?.format == "data-url") {
      filesObj[key] = {
        allowed_ext: ["png", "jpg"],
        max_size: 30000,
      };
    }
  });
  return filesObj;
};

