// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/policy/v1/service/onboarding/onboarding.proto (package policy.v1.onboarding, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetOnboardingQuestionsRequest, GetOnboardingQuestionsResponse, OptInRequest, OptInResponse, SubmitOnboardingAnswersRequest, SubmitOnboardingAnswersResponse } from "./onboarding_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service policy.v1.onboarding.OnboardingService
 */
export const OnboardingService = {
  typeName: "policy.v1.onboarding.OnboardingService",
  methods: {
    /**
     * @generated from rpc policy.v1.onboarding.OnboardingService.OptIn
     */
    optIn: {
      name: "OptIn",
      I: OptInRequest,
      O: OptInResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.onboarding.OnboardingService.GetOnboardingQuestions
     */
    getOnboardingQuestions: {
      name: "GetOnboardingQuestions",
      I: GetOnboardingQuestionsRequest,
      O: GetOnboardingQuestionsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.onboarding.OnboardingService.SubmitOnboardingAnswers
     */
    submitOnboardingAnswers: {
      name: "SubmitOnboardingAnswers",
      I: SubmitOnboardingAnswersRequest,
      O: SubmitOnboardingAnswersResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

