import { Select, useBreakpointValue } from '@chakra-ui/react';
import React from 'react'

export default function CustomSelect({ children, ...rest }) {
    const size = useBreakpointValue({
      base: "md",
      xl: "md",
      "2xl": "md",
    });
    const textSize = useBreakpointValue({
        base: "sm",
        xl: "xs",
        "2xl": "sm",
      });
      const marginBottom = useBreakpointValue({
        base: "30px",
        xl: "15px",
        "2xl": "30px",
      });
      const fieldHeight = useBreakpointValue({
        base: "44px",
        xl: "30px",
        "2xl": "44px",
      });
      const fieldPadding = useBreakpointValue({
        base: "20px",
        xl: "15px",
        "2xl": "20px",
      });
    return (
      <Select 
      fontSize={textSize}
      h={fieldHeight}
      maxh={fieldHeight}
      {...rest}>
        {children}
      </Select>
    );
}
