import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import jsonPlayerData from "../assets/link-expired.json";
import { Text } from "@chakra-ui/react";

export default function LinkExpiredComponent() {
  return (
    <>
      <Player
        autoplay
        keepLastFrame
        src={jsonPlayerData}
        style={{ height: "300px", width: "300px" }}
      ></Player>
      <Text fontSize={"2xl"} fontWeight="500">
        Link has been expired.
      </Text>
    </>
  );
}
