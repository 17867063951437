import { Text } from "@chakra-ui/react";
import React from "react";

export default function TextContent({ children, ...rest }) {
  const textColorSecondary = "gray.400";
  return (
    <Text
      fontSize={{ base: "lg", xl: "sm", "2xl": "lg" }}
      {...rest}
    >
      {children}
    </Text>
  );
}
