import { createContext, useContext, useEffect, useState } from "react"

(() => {
  const originalSetItem = localStorage.setItem;
  const originalRemoveItem = localStorage.removeItem;

  // Define the payload structure for the custom event
  interface LocalStorageChangeDetail {
    key: string | null;
    newValue: string | null;
    oldValue: string | null;
  }

  // Custom event type
  type LocalStorageChangeEvent = CustomEvent<LocalStorageChangeDetail>;

  // Helper to trigger the custom event
  const triggerLocalStorageChange = (key: string | null, newValue: string | null, oldValue: string | null) => {
    const event: LocalStorageChangeEvent = new CustomEvent('localStorageChange', {
      detail: { key, newValue, oldValue },
    });
    window.dispatchEvent(event);
  };

  // Override the setItem method
  localStorage.setItem = function (key: string, value: string): void {
    const oldValue = localStorage.getItem(key);
    originalSetItem.call(localStorage, key, value);
    if (oldValue !== value) {
      triggerLocalStorageChange(key, value, oldValue);
    }
  };

  // Override the removeItem method
  localStorage.removeItem = function (key: string): void {
    const oldValue = localStorage.getItem(key);
    originalRemoveItem.call(localStorage, key);
    triggerLocalStorageChange(key, null, oldValue);
  };
})();

type Theme = "dark" | "light" | "system"

type ThemeProviderProps = {
  children: React.ReactNode
  defaultTheme?: Theme
  storageKey?: string
}

type ThemeProviderState = {
  theme: Theme
  setTheme: (theme: Theme) => void
}

const initialState: ThemeProviderState = {
  theme: "system",
  setTheme: () => null,
}

const ThemeProviderContext = createContext<ThemeProviderState>(initialState)

export function ThemeProvider({
  children,
  defaultTheme = "system",
  storageKey = "vite-ui-theme",
  ...props
}: ThemeProviderProps) {
  const [theme, setTheme] = useState<Theme>(
    () => (localStorage.getItem(storageKey) as Theme) || defaultTheme
  )

  useEffect(() => {
    const root = window.document.documentElement

    root.classList.remove("light", "dark")

    if (theme === "system") {
      const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
        .matches
        ? "dark"
        : "light"

      root.classList.add(systemTheme)
      return
    }

    root.classList.add(theme)
  }, [theme])

  const value = {
    theme,
    setTheme: (theme: Theme) => {
      localStorage.setItem(storageKey, theme)
      setTheme(theme)
    },
  }

  const storageEventHandler = (e: StorageEvent) => {
    const customEvent = e as CustomEvent<{ key: string | null; newValue: string | null; oldValue: string | null }>;
    if(customEvent.detail.newValue) {
      setTheme(customEvent.detail.newValue as 'light' | 'dark' || 'light')
    }
  }


  useEffect(() => {
    window.addEventListener('localStorageChange', storageEventHandler)
    return () => {
      window.removeEventListener("localStorageChange", storageEventHandler);
    }
  }, [])

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  )
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext)

  if (context === undefined)
    throw new Error("useTheme must be used within a ThemeProvider")

  return context
}
