// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/user/user.proto (package iam.v1.user, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { AccountType, Group, Policy, User, UserAccessType } from "../../types/types_pb";

/**
 * @generated from message iam.v1.user.CreateRootUserRequest
 */
export class CreateRootUserRequest extends Message<CreateRootUserRequest> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 2;
   */
  lastName = "";

  /**
   * @generated from field: iam.v1.types.AccountType account_type = 3;
   */
  accountType = AccountType.UNSPECIFIED;

  constructor(data?: PartialMessage<CreateRootUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.CreateRootUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "account_type", kind: "enum", T: proto3.getEnumType(AccountType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRootUserRequest {
    return new CreateRootUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRootUserRequest {
    return new CreateRootUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRootUserRequest {
    return new CreateRootUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRootUserRequest | PlainMessage<CreateRootUserRequest> | undefined, b: CreateRootUserRequest | PlainMessage<CreateRootUserRequest> | undefined): boolean {
    return proto3.util.equals(CreateRootUserRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.user.CreateRootUserResponse
 */
export class CreateRootUserResponse extends Message<CreateRootUserResponse> {
  /**
   * @generated from field: iam.v1.types.User user = 1;
   */
  user?: User;

  /**
   * @generated from field: string auth_token = 2;
   */
  authToken = "";

  constructor(data?: PartialMessage<CreateRootUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.CreateRootUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
    { no: 2, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRootUserResponse {
    return new CreateRootUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRootUserResponse {
    return new CreateRootUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRootUserResponse {
    return new CreateRootUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRootUserResponse | PlainMessage<CreateRootUserResponse> | undefined, b: CreateRootUserResponse | PlainMessage<CreateRootUserResponse> | undefined): boolean {
    return proto3.util.equals(CreateRootUserResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.user.CreateUserRequest
 */
export class CreateUserRequest extends Message<CreateUserRequest> {
  /**
   * @generated from field: string username = 1;
   */
  username = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string first_name = 3;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 4;
   */
  lastName = "";

  /**
   * @generated from field: iam.v1.types.UserAccessType access_type = 5;
   */
  accessType = UserAccessType.MANAGEMENT_UNSPECIFIED;

  constructor(data?: PartialMessage<CreateUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.CreateUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "access_type", kind: "enum", T: proto3.getEnumType(UserAccessType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateUserRequest {
    return new CreateUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateUserRequest {
    return new CreateUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateUserRequest {
    return new CreateUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateUserRequest | PlainMessage<CreateUserRequest> | undefined, b: CreateUserRequest | PlainMessage<CreateUserRequest> | undefined): boolean {
    return proto3.util.equals(CreateUserRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.user.CreateUserResponse
 */
export class CreateUserResponse extends Message<CreateUserResponse> {
  /**
   * @generated from field: iam.v1.types.User user = 1;
   */
  user?: User;

  constructor(data?: PartialMessage<CreateUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.CreateUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateUserResponse {
    return new CreateUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateUserResponse {
    return new CreateUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateUserResponse {
    return new CreateUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateUserResponse | PlainMessage<CreateUserResponse> | undefined, b: CreateUserResponse | PlainMessage<CreateUserResponse> | undefined): boolean {
    return proto3.util.equals(CreateUserResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.user.GetUserRequest
 */
export class GetUserRequest extends Message<GetUserRequest> {
  /**
   * @generated from field: string user_dri = 1;
   */
  userDri = "";

  constructor(data?: PartialMessage<GetUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.GetUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserRequest {
    return new GetUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserRequest {
    return new GetUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserRequest {
    return new GetUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserRequest | PlainMessage<GetUserRequest> | undefined, b: GetUserRequest | PlainMessage<GetUserRequest> | undefined): boolean {
    return proto3.util.equals(GetUserRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.user.GetUserResponse
 */
export class GetUserResponse extends Message<GetUserResponse> {
  /**
   * @generated from field: iam.v1.types.User user = 1;
   */
  user?: User;

  constructor(data?: PartialMessage<GetUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.GetUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserResponse {
    return new GetUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserResponse {
    return new GetUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserResponse {
    return new GetUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserResponse | PlainMessage<GetUserResponse> | undefined, b: GetUserResponse | PlainMessage<GetUserResponse> | undefined): boolean {
    return proto3.util.equals(GetUserResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.user.ListUsersRequest
 */
export class ListUsersRequest extends Message<ListUsersRequest> {
  /**
   * @generated from field: string search = 1;
   */
  search = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 3;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListUsersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.ListUsersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUsersRequest {
    return new ListUsersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUsersRequest {
    return new ListUsersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUsersRequest {
    return new ListUsersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListUsersRequest | PlainMessage<ListUsersRequest> | undefined, b: ListUsersRequest | PlainMessage<ListUsersRequest> | undefined): boolean {
    return proto3.util.equals(ListUsersRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.user.ListUsersResponse
 */
export class ListUsersResponse extends Message<ListUsersResponse> {
  /**
   * @generated from field: repeated iam.v1.types.User users = 1;
   */
  users: User[] = [];

  constructor(data?: PartialMessage<ListUsersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.ListUsersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "users", kind: "message", T: User, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUsersResponse {
    return new ListUsersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUsersResponse {
    return new ListUsersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUsersResponse {
    return new ListUsersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListUsersResponse | PlainMessage<ListUsersResponse> | undefined, b: ListUsersResponse | PlainMessage<ListUsersResponse> | undefined): boolean {
    return proto3.util.equals(ListUsersResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.user.UpdateUserRequest
 */
export class UpdateUserRequest extends Message<UpdateUserRequest> {
  /**
   * @generated from field: string user_dri = 1;
   */
  userDri = "";

  /**
   * @generated from field: string first_name = 2;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 3;
   */
  lastName = "";

  /**
   * @generated from field: iam.v1.types.UserAccessType access_type = 4;
   */
  accessType = UserAccessType.MANAGEMENT_UNSPECIFIED;

  constructor(data?: PartialMessage<UpdateUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.UpdateUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "access_type", kind: "enum", T: proto3.getEnumType(UserAccessType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateUserRequest {
    return new UpdateUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateUserRequest {
    return new UpdateUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateUserRequest {
    return new UpdateUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateUserRequest | PlainMessage<UpdateUserRequest> | undefined, b: UpdateUserRequest | PlainMessage<UpdateUserRequest> | undefined): boolean {
    return proto3.util.equals(UpdateUserRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.user.UpdateUserResponse
 */
export class UpdateUserResponse extends Message<UpdateUserResponse> {
  /**
   * @generated from field: iam.v1.types.User user = 1;
   */
  user?: User;

  constructor(data?: PartialMessage<UpdateUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.UpdateUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateUserResponse {
    return new UpdateUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateUserResponse {
    return new UpdateUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateUserResponse {
    return new UpdateUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateUserResponse | PlainMessage<UpdateUserResponse> | undefined, b: UpdateUserResponse | PlainMessage<UpdateUserResponse> | undefined): boolean {
    return proto3.util.equals(UpdateUserResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.user.DeleteUsersRequest
 */
export class DeleteUsersRequest extends Message<DeleteUsersRequest> {
  /**
   * @generated from field: repeated string user_dris = 1;
   */
  userDris: string[] = [];

  constructor(data?: PartialMessage<DeleteUsersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.DeleteUsersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteUsersRequest {
    return new DeleteUsersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteUsersRequest {
    return new DeleteUsersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteUsersRequest {
    return new DeleteUsersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteUsersRequest | PlainMessage<DeleteUsersRequest> | undefined, b: DeleteUsersRequest | PlainMessage<DeleteUsersRequest> | undefined): boolean {
    return proto3.util.equals(DeleteUsersRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.user.RequestOTPRequest
 */
export class RequestOTPRequest extends Message<RequestOTPRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string account_id = 2;
   */
  accountId = "";

  /**
   * @generated from field: string username = 3;
   */
  username = "";

  /**
   * @generated from field: string recaptcha_code = 4;
   */
  recaptchaCode = "";

  /**
   * @generated from field: string alias = 5;
   */
  alias = "";

  constructor(data?: PartialMessage<RequestOTPRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.RequestOTPRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "recaptcha_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "alias", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestOTPRequest {
    return new RequestOTPRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestOTPRequest {
    return new RequestOTPRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestOTPRequest {
    return new RequestOTPRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RequestOTPRequest | PlainMessage<RequestOTPRequest> | undefined, b: RequestOTPRequest | PlainMessage<RequestOTPRequest> | undefined): boolean {
    return proto3.util.equals(RequestOTPRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.user.RequestOTPResponse
 */
export class RequestOTPResponse extends Message<RequestOTPResponse> {
  /**
   * @generated from field: string device_token = 1;
   */
  deviceToken = "";

  constructor(data?: PartialMessage<RequestOTPResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.RequestOTPResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "device_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestOTPResponse {
    return new RequestOTPResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestOTPResponse {
    return new RequestOTPResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestOTPResponse {
    return new RequestOTPResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RequestOTPResponse | PlainMessage<RequestOTPResponse> | undefined, b: RequestOTPResponse | PlainMessage<RequestOTPResponse> | undefined): boolean {
    return proto3.util.equals(RequestOTPResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.user.GetAuthTokenRequest
 */
export class GetAuthTokenRequest extends Message<GetAuthTokenRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string account_id = 2;
   */
  accountId = "";

  /**
   * @generated from field: string username = 3;
   */
  username = "";

  /**
   * @generated from field: string otp = 4;
   */
  otp = "";

  /**
   * @generated from field: string device_token = 5;
   */
  deviceToken = "";

  /**
   * @generated from field: string recaptcha_code = 6;
   */
  recaptchaCode = "";

  constructor(data?: PartialMessage<GetAuthTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.GetAuthTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "otp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "device_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "recaptcha_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAuthTokenRequest {
    return new GetAuthTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAuthTokenRequest {
    return new GetAuthTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAuthTokenRequest {
    return new GetAuthTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAuthTokenRequest | PlainMessage<GetAuthTokenRequest> | undefined, b: GetAuthTokenRequest | PlainMessage<GetAuthTokenRequest> | undefined): boolean {
    return proto3.util.equals(GetAuthTokenRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.user.GetAuthTokenResponse
 */
export class GetAuthTokenResponse extends Message<GetAuthTokenResponse> {
  /**
   * @generated from field: string auth_token = 1;
   */
  authToken = "";

  /**
   * @generated from field: bool sign_up = 2;
   */
  signUp = false;

  constructor(data?: PartialMessage<GetAuthTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.GetAuthTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sign_up", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAuthTokenResponse {
    return new GetAuthTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAuthTokenResponse {
    return new GetAuthTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAuthTokenResponse {
    return new GetAuthTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAuthTokenResponse | PlainMessage<GetAuthTokenResponse> | undefined, b: GetAuthTokenResponse | PlainMessage<GetAuthTokenResponse> | undefined): boolean {
    return proto3.util.equals(GetAuthTokenResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.user.GetAuthInfoRequest
 */
export class GetAuthInfoRequest extends Message<GetAuthInfoRequest> {
  constructor(data?: PartialMessage<GetAuthInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.GetAuthInfoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAuthInfoRequest {
    return new GetAuthInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAuthInfoRequest {
    return new GetAuthInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAuthInfoRequest {
    return new GetAuthInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAuthInfoRequest | PlainMessage<GetAuthInfoRequest> | undefined, b: GetAuthInfoRequest | PlainMessage<GetAuthInfoRequest> | undefined): boolean {
    return proto3.util.equals(GetAuthInfoRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.user.GetAuthInfoResponse
 */
export class GetAuthInfoResponse extends Message<GetAuthInfoResponse> {
  /**
   * @generated from field: iam.v1.types.User user = 1;
   */
  user?: User;

  constructor(data?: PartialMessage<GetAuthInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.GetAuthInfoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAuthInfoResponse {
    return new GetAuthInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAuthInfoResponse {
    return new GetAuthInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAuthInfoResponse {
    return new GetAuthInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAuthInfoResponse | PlainMessage<GetAuthInfoResponse> | undefined, b: GetAuthInfoResponse | PlainMessage<GetAuthInfoResponse> | undefined): boolean {
    return proto3.util.equals(GetAuthInfoResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.user.AcceptInviteRequest
 */
export class AcceptInviteRequest extends Message<AcceptInviteRequest> {
  constructor(data?: PartialMessage<AcceptInviteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.AcceptInviteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcceptInviteRequest {
    return new AcceptInviteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcceptInviteRequest {
    return new AcceptInviteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AcceptInviteRequest {
    return new AcceptInviteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AcceptInviteRequest | PlainMessage<AcceptInviteRequest> | undefined, b: AcceptInviteRequest | PlainMessage<AcceptInviteRequest> | undefined): boolean {
    return proto3.util.equals(AcceptInviteRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.user.AcceptInviteResponse
 */
export class AcceptInviteResponse extends Message<AcceptInviteResponse> {
  /**
   * @generated from field: string status = 1;
   */
  status = "";

  /**
   * @generated from field: string auth_token = 2;
   */
  authToken = "";

  constructor(data?: PartialMessage<AcceptInviteResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.AcceptInviteResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcceptInviteResponse {
    return new AcceptInviteResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcceptInviteResponse {
    return new AcceptInviteResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AcceptInviteResponse {
    return new AcceptInviteResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AcceptInviteResponse | PlainMessage<AcceptInviteResponse> | undefined, b: AcceptInviteResponse | PlainMessage<AcceptInviteResponse> | undefined): boolean {
    return proto3.util.equals(AcceptInviteResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.user.ListUserPoliciesRequest
 */
export class ListUserPoliciesRequest extends Message<ListUserPoliciesRequest> {
  /**
   * @generated from field: string user_dri = 1;
   */
  userDri = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 3;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListUserPoliciesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.ListUserPoliciesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUserPoliciesRequest {
    return new ListUserPoliciesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUserPoliciesRequest {
    return new ListUserPoliciesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUserPoliciesRequest {
    return new ListUserPoliciesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListUserPoliciesRequest | PlainMessage<ListUserPoliciesRequest> | undefined, b: ListUserPoliciesRequest | PlainMessage<ListUserPoliciesRequest> | undefined): boolean {
    return proto3.util.equals(ListUserPoliciesRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.user.ListUserPoliciesResponse
 */
export class ListUserPoliciesResponse extends Message<ListUserPoliciesResponse> {
  /**
   * @generated from field: repeated iam.v1.types.Policy policies = 1;
   */
  policies: Policy[] = [];

  constructor(data?: PartialMessage<ListUserPoliciesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.ListUserPoliciesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policies", kind: "message", T: Policy, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUserPoliciesResponse {
    return new ListUserPoliciesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUserPoliciesResponse {
    return new ListUserPoliciesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUserPoliciesResponse {
    return new ListUserPoliciesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListUserPoliciesResponse | PlainMessage<ListUserPoliciesResponse> | undefined, b: ListUserPoliciesResponse | PlainMessage<ListUserPoliciesResponse> | undefined): boolean {
    return proto3.util.equals(ListUserPoliciesResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.user.ListUserGroupsRequest
 */
export class ListUserGroupsRequest extends Message<ListUserGroupsRequest> {
  /**
   * @generated from field: string user_dri = 1;
   */
  userDri = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 3;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListUserGroupsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.ListUserGroupsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUserGroupsRequest {
    return new ListUserGroupsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUserGroupsRequest {
    return new ListUserGroupsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUserGroupsRequest {
    return new ListUserGroupsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListUserGroupsRequest | PlainMessage<ListUserGroupsRequest> | undefined, b: ListUserGroupsRequest | PlainMessage<ListUserGroupsRequest> | undefined): boolean {
    return proto3.util.equals(ListUserGroupsRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.user.ListUserGroupsResponse
 */
export class ListUserGroupsResponse extends Message<ListUserGroupsResponse> {
  /**
   * @generated from field: repeated iam.v1.types.Group groups = 1;
   */
  groups: Group[] = [];

  constructor(data?: PartialMessage<ListUserGroupsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.user.ListUserGroupsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "groups", kind: "message", T: Group, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListUserGroupsResponse {
    return new ListUserGroupsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListUserGroupsResponse {
    return new ListUserGroupsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListUserGroupsResponse {
    return new ListUserGroupsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListUserGroupsResponse | PlainMessage<ListUserGroupsResponse> | undefined, b: ListUserGroupsResponse | PlainMessage<ListUserGroupsResponse> | undefined): boolean {
    return proto3.util.equals(ListUserGroupsResponse, a, b);
  }
}

