import { Flex, Th, useColorModeValue } from "@chakra-ui/react";
import React from "react";

export default function CustomTh({ children, ...rest }) {
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Th
      pe="10px"
      borderColor={borderColor}
      whiteSpace="nowrap"
      scope="col"
      {...rest}
    >
      <Flex
        justify="space-between"
        align="center"
        fontSize={{ sm: "10px", "2xl": "12px" }}
        color="gray.400"
      >
        {children}
      </Flex>
    </Th>
  );
}
