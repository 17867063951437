// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/policy/v1/service/document/document.proto (package policy.v1.document, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddRolesToDocumentRequest, CreateDocumentRequest, CreateDocumentResponse, DeleteDocumentRequest, GetDocumentRequest, GetDocumentResponse, ListDocumentRolesRequest, ListDocumentRolesResponse, MoveDocumentRequest, MoveDocumentResponse, RemoveRolesFromDocumentRequest, SearchDocumentsRequest, SearchDocumentsResponse, UpdateDocumentClassificationRequest, UpdateDocumentRequest, UpdateDocumentResponse } from "./document_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service policy.v1.document.DocumentService
 */
export const DocumentService = {
  typeName: "policy.v1.document.DocumentService",
  methods: {
    /**
     * @generated from rpc policy.v1.document.DocumentService.CreateDocument
     */
    createDocument: {
      name: "CreateDocument",
      I: CreateDocumentRequest,
      O: CreateDocumentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.document.DocumentService.GetDocument
     */
    getDocument: {
      name: "GetDocument",
      I: GetDocumentRequest,
      O: GetDocumentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.document.DocumentService.UpdateDocument
     */
    updateDocument: {
      name: "UpdateDocument",
      I: UpdateDocumentRequest,
      O: UpdateDocumentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.document.DocumentService.DeleteDocument
     */
    deleteDocument: {
      name: "DeleteDocument",
      I: DeleteDocumentRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.document.DocumentService.SearchDocuments
     */
    searchDocuments: {
      name: "SearchDocuments",
      I: SearchDocumentsRequest,
      O: SearchDocumentsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.document.DocumentService.UpdateDocumentClassification
     */
    updateDocumentClassification: {
      name: "UpdateDocumentClassification",
      I: UpdateDocumentClassificationRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.document.DocumentService.MoveDocument
     */
    moveDocument: {
      name: "MoveDocument",
      I: MoveDocumentRequest,
      O: MoveDocumentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.document.DocumentService.AddRolesToDocument
     */
    addRolesToDocument: {
      name: "AddRolesToDocument",
      I: AddRolesToDocumentRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.document.DocumentService.RemoveRolesFromDocument
     */
    removeRolesFromDocument: {
      name: "RemoveRolesFromDocument",
      I: RemoveRolesFromDocumentRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.document.DocumentService.ListDocumentRoles
     */
    listDocumentRoles: {
      name: "ListDocumentRoles",
      I: ListDocumentRolesRequest,
      O: ListDocumentRolesResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

