// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/policy/v1/service/settings/settings.proto (package policy.v1.settings, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { DefaultSettings, DocumentSettings, DocumentSupervisors, DocumentType, OrganizationProfile, SecurityClassification } from "../../types/types_pb";

/**
 * @generated from message policy.v1.settings.GetOrganizationProfileRequest
 */
export class GetOrganizationProfileRequest extends Message<GetOrganizationProfileRequest> {
  constructor(data?: PartialMessage<GetOrganizationProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.settings.GetOrganizationProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrganizationProfileRequest {
    return new GetOrganizationProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrganizationProfileRequest {
    return new GetOrganizationProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrganizationProfileRequest {
    return new GetOrganizationProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrganizationProfileRequest | PlainMessage<GetOrganizationProfileRequest> | undefined, b: GetOrganizationProfileRequest | PlainMessage<GetOrganizationProfileRequest> | undefined): boolean {
    return proto3.util.equals(GetOrganizationProfileRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.settings.GetOrganizationProfileResponse
 */
export class GetOrganizationProfileResponse extends Message<GetOrganizationProfileResponse> {
  /**
   * @generated from field: policy.v1.types.OrganizationProfile organization_profile = 1;
   */
  organizationProfile?: OrganizationProfile;

  constructor(data?: PartialMessage<GetOrganizationProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.settings.GetOrganizationProfileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_profile", kind: "message", T: OrganizationProfile },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrganizationProfileResponse {
    return new GetOrganizationProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrganizationProfileResponse {
    return new GetOrganizationProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrganizationProfileResponse {
    return new GetOrganizationProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrganizationProfileResponse | PlainMessage<GetOrganizationProfileResponse> | undefined, b: GetOrganizationProfileResponse | PlainMessage<GetOrganizationProfileResponse> | undefined): boolean {
    return proto3.util.equals(GetOrganizationProfileResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.settings.UpdateOrganizationProfileRequest
 */
export class UpdateOrganizationProfileRequest extends Message<UpdateOrganizationProfileRequest> {
  /**
   * @generated from field: string org_name = 1;
   */
  orgName = "";

  /**
   * @generated from field: repeated string locations = 2;
   */
  locations: string[] = [];

  /**
   * @generated from field: repeated string security_emails = 3;
   */
  securityEmails: string[] = [];

  /**
   * @generated from field: repeated string industries = 4;
   */
  industries: string[] = [];

  /**
   * @generated from field: string org_size = 5;
   */
  orgSize = "";

  constructor(data?: PartialMessage<UpdateOrganizationProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.settings.UpdateOrganizationProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "org_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "locations", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "security_emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "industries", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "org_size", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateOrganizationProfileRequest {
    return new UpdateOrganizationProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateOrganizationProfileRequest {
    return new UpdateOrganizationProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateOrganizationProfileRequest {
    return new UpdateOrganizationProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateOrganizationProfileRequest | PlainMessage<UpdateOrganizationProfileRequest> | undefined, b: UpdateOrganizationProfileRequest | PlainMessage<UpdateOrganizationProfileRequest> | undefined): boolean {
    return proto3.util.equals(UpdateOrganizationProfileRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.settings.UpdateOrganizationProfileResponse
 */
export class UpdateOrganizationProfileResponse extends Message<UpdateOrganizationProfileResponse> {
  /**
   * @generated from field: policy.v1.types.OrganizationProfile organization_profile = 1;
   */
  organizationProfile?: OrganizationProfile;

  constructor(data?: PartialMessage<UpdateOrganizationProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.settings.UpdateOrganizationProfileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_profile", kind: "message", T: OrganizationProfile },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateOrganizationProfileResponse {
    return new UpdateOrganizationProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateOrganizationProfileResponse {
    return new UpdateOrganizationProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateOrganizationProfileResponse {
    return new UpdateOrganizationProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateOrganizationProfileResponse | PlainMessage<UpdateOrganizationProfileResponse> | undefined, b: UpdateOrganizationProfileResponse | PlainMessage<UpdateOrganizationProfileResponse> | undefined): boolean {
    return proto3.util.equals(UpdateOrganizationProfileResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.settings.SetDefaultSettingsRequest
 */
export class SetDefaultSettingsRequest extends Message<SetDefaultSettingsRequest> {
  /**
   * DRI of the parent folder
   *
   * @generated from field: string parent_folder_dri = 1;
   */
  parentFolderDri = "";

  /**
   * Type of the document
   *
   * @generated from field: policy.v1.types.DocumentType type = 2;
   */
  type = DocumentType.OTHER_UNSPECIFIED;

  /**
   * @generated from field: policy.v1.types.SecurityClassification security_classification = 3;
   */
  securityClassification = SecurityClassification.INTERNAL_UNSPECIFIED;

  /**
   * @generated from field: policy.v1.types.DocumentSettings settings = 4;
   */
  settings?: DocumentSettings;

  /**
   * @generated from field: policy.v1.types.DocumentSupervisors supervisors = 5;
   */
  supervisors?: DocumentSupervisors;

  constructor(data?: PartialMessage<SetDefaultSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.settings.SetDefaultSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent_folder_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(DocumentType) },
    { no: 3, name: "security_classification", kind: "enum", T: proto3.getEnumType(SecurityClassification) },
    { no: 4, name: "settings", kind: "message", T: DocumentSettings },
    { no: 5, name: "supervisors", kind: "message", T: DocumentSupervisors },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetDefaultSettingsRequest {
    return new SetDefaultSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetDefaultSettingsRequest {
    return new SetDefaultSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetDefaultSettingsRequest {
    return new SetDefaultSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetDefaultSettingsRequest | PlainMessage<SetDefaultSettingsRequest> | undefined, b: SetDefaultSettingsRequest | PlainMessage<SetDefaultSettingsRequest> | undefined): boolean {
    return proto3.util.equals(SetDefaultSettingsRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.settings.SetDefaultSettingsResponse
 */
export class SetDefaultSettingsResponse extends Message<SetDefaultSettingsResponse> {
  /**
   * @generated from field: policy.v1.types.DefaultSettings default_settings = 1;
   */
  defaultSettings?: DefaultSettings;

  constructor(data?: PartialMessage<SetDefaultSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.settings.SetDefaultSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "default_settings", kind: "message", T: DefaultSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetDefaultSettingsResponse {
    return new SetDefaultSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetDefaultSettingsResponse {
    return new SetDefaultSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetDefaultSettingsResponse {
    return new SetDefaultSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetDefaultSettingsResponse | PlainMessage<SetDefaultSettingsResponse> | undefined, b: SetDefaultSettingsResponse | PlainMessage<SetDefaultSettingsResponse> | undefined): boolean {
    return proto3.util.equals(SetDefaultSettingsResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.settings.GetDefaultSettingsRequest
 */
export class GetDefaultSettingsRequest extends Message<GetDefaultSettingsRequest> {
  constructor(data?: PartialMessage<GetDefaultSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.settings.GetDefaultSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDefaultSettingsRequest {
    return new GetDefaultSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDefaultSettingsRequest {
    return new GetDefaultSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDefaultSettingsRequest {
    return new GetDefaultSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetDefaultSettingsRequest | PlainMessage<GetDefaultSettingsRequest> | undefined, b: GetDefaultSettingsRequest | PlainMessage<GetDefaultSettingsRequest> | undefined): boolean {
    return proto3.util.equals(GetDefaultSettingsRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.settings.GetDefaultSettingsResponse
 */
export class GetDefaultSettingsResponse extends Message<GetDefaultSettingsResponse> {
  /**
   * @generated from field: policy.v1.types.DefaultSettings default_settings = 1;
   */
  defaultSettings?: DefaultSettings;

  constructor(data?: PartialMessage<GetDefaultSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.settings.GetDefaultSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "default_settings", kind: "message", T: DefaultSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDefaultSettingsResponse {
    return new GetDefaultSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDefaultSettingsResponse {
    return new GetDefaultSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDefaultSettingsResponse {
    return new GetDefaultSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetDefaultSettingsResponse | PlainMessage<GetDefaultSettingsResponse> | undefined, b: GetDefaultSettingsResponse | PlainMessage<GetDefaultSettingsResponse> | undefined): boolean {
    return proto3.util.equals(GetDefaultSettingsResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.settings.UpdateDefaultSettingsRequest
 */
export class UpdateDefaultSettingsRequest extends Message<UpdateDefaultSettingsRequest> {
  /**
   * DRI of the parent folder
   *
   * @generated from field: string parent_folder_dri = 1;
   */
  parentFolderDri = "";

  /**
   * Type of the document
   *
   * @generated from field: policy.v1.types.DocumentType type = 2;
   */
  type = DocumentType.OTHER_UNSPECIFIED;

  /**
   * @generated from field: policy.v1.types.SecurityClassification security_classification = 3;
   */
  securityClassification = SecurityClassification.INTERNAL_UNSPECIFIED;

  /**
   * @generated from field: policy.v1.types.DocumentSettings settings = 4;
   */
  settings?: DocumentSettings;

  /**
   * @generated from field: policy.v1.types.DocumentSupervisors supervisors = 5;
   */
  supervisors?: DocumentSupervisors;

  constructor(data?: PartialMessage<UpdateDefaultSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.settings.UpdateDefaultSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent_folder_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(DocumentType) },
    { no: 3, name: "security_classification", kind: "enum", T: proto3.getEnumType(SecurityClassification) },
    { no: 4, name: "settings", kind: "message", T: DocumentSettings },
    { no: 5, name: "supervisors", kind: "message", T: DocumentSupervisors },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateDefaultSettingsRequest {
    return new UpdateDefaultSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateDefaultSettingsRequest {
    return new UpdateDefaultSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateDefaultSettingsRequest {
    return new UpdateDefaultSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateDefaultSettingsRequest | PlainMessage<UpdateDefaultSettingsRequest> | undefined, b: UpdateDefaultSettingsRequest | PlainMessage<UpdateDefaultSettingsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateDefaultSettingsRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.settings.UpdateDefaultSettingsResponse
 */
export class UpdateDefaultSettingsResponse extends Message<UpdateDefaultSettingsResponse> {
  /**
   * @generated from field: policy.v1.types.DefaultSettings default_settings = 1;
   */
  defaultSettings?: DefaultSettings;

  constructor(data?: PartialMessage<UpdateDefaultSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.settings.UpdateDefaultSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "default_settings", kind: "message", T: DefaultSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateDefaultSettingsResponse {
    return new UpdateDefaultSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateDefaultSettingsResponse {
    return new UpdateDefaultSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateDefaultSettingsResponse {
    return new UpdateDefaultSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateDefaultSettingsResponse | PlainMessage<UpdateDefaultSettingsResponse> | undefined, b: UpdateDefaultSettingsResponse | PlainMessage<UpdateDefaultSettingsResponse> | undefined): boolean {
    return proto3.util.equals(UpdateDefaultSettingsResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.settings.DeleteDefaultSettingsRequest
 */
export class DeleteDefaultSettingsRequest extends Message<DeleteDefaultSettingsRequest> {
  constructor(data?: PartialMessage<DeleteDefaultSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.settings.DeleteDefaultSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteDefaultSettingsRequest {
    return new DeleteDefaultSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteDefaultSettingsRequest {
    return new DeleteDefaultSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteDefaultSettingsRequest {
    return new DeleteDefaultSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteDefaultSettingsRequest | PlainMessage<DeleteDefaultSettingsRequest> | undefined, b: DeleteDefaultSettingsRequest | PlainMessage<DeleteDefaultSettingsRequest> | undefined): boolean {
    return proto3.util.equals(DeleteDefaultSettingsRequest, a, b);
  }
}

