// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/sypher/v1/service/template/template.proto (package sypher.v1.template, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateTemplateRequest, CreateTemplateResponse, DeleteTemplateRequest, GetTemplateRequest, GetTemplateResponse, ListTemplatesRequest, ListTemplatesResponse, UpdateTemplateRequest } from "./template_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service sypher.v1.template.TemplateService
 */
export const TemplateService = {
  typeName: "sypher.v1.template.TemplateService",
  methods: {
    /**
     * @generated from rpc sypher.v1.template.TemplateService.CreateTemplate
     */
    createTemplate: {
      name: "CreateTemplate",
      I: CreateTemplateRequest,
      O: CreateTemplateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.template.TemplateService.GetTemplate
     */
    getTemplate: {
      name: "GetTemplate",
      I: GetTemplateRequest,
      O: GetTemplateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.template.TemplateService.ListTemplates
     */
    listTemplates: {
      name: "ListTemplates",
      I: ListTemplatesRequest,
      O: ListTemplatesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.template.TemplateService.UpdateTemplate
     */
    updateTemplate: {
      name: "UpdateTemplate",
      I: UpdateTemplateRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.template.TemplateService.DeleteTemplate
     */
    deleteTemplate: {
      name: "DeleteTemplate",
      I: DeleteTemplateRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

