// Chakra imports
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  Badge,
  Icon,
} from "@chakra-ui/react";
// Custom components
// Assets
import React from "react";
import { MdOutlineTimer } from "react-icons/md";
import IconBox from "../icons/IconBox";
import Card from "../card/Card";

export default function ServiceCard(props) {
  const {
    icon,
    title,
    desc,
    date,
    day,
    time,
    topics,
    bgBox,
    bgSize,
    onClick = () => null,
  } = props;
  const textColor = useColorModeValue("white", "white");
  const textBrand = useColorModeValue("brand.500", "white");
  const bgBadge = useColorModeValue("secondaryGray.300", "whiteAlpha.50");
  return (
    <Card
      bgImage={bgBox}
      bgSize={bgSize}
      cursor="pointer"
      onClick={onClick}
      alignItems="center"
      p="20px"
      minH={{ md: "450px", xl: "auto" }}
      _hover={{
        transform: "translateY(-10px) scale(1.03)",
      }}
      transition={"all .2s ease-in-out"}
    >
      <Flex direction={{ base: "column", md: "column", xl: "row" }}>
        <IconBox
          icon={icon}
          minW={{ base: "100%", xl: "150px" }}
          minH={{ base: "200px", xl: "150px" }}
          borderRadius="20px"
          me="34px"
        />
        <Flex
          justify="space-between"
          flexDirection="column"
          mb="auto"
          py="30px"
          pb={{ base: "0px", md: "0px" }}
        >
          <Flex display={{ base: "block", xl: "flex" }}>
            <Box direction="column" mb="25px">
              <Text
                color={textColor}
                fontSize={{
                  base: "xl",
                  md: "xl",
                  xl: "xl",
                  "2xl": "28px",
                }}
                mb="10px"
                fontWeight="700"
              >
                {title}
              </Text>
              <Text
                color="white"
                fontSize={{
                  base: "md",
                }}
                fontWeight="400"
                me="14px"
              >
                {desc}
              </Text>
            </Box>
          </Flex>
          <Flex w="100%" flexWrap="wrap" gap={"5px"}>
            {topics.map((topic, key) => (
              <Badge
                key={key}
                bg={bgBadge}
                textAlign="center"
                mb={{ base: "20px", md: "0px" }}
                color={textBrand}
                h="max-content"
              >
                {topic}
              </Badge>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
