import {
    Textarea as MultlineInput,
    FormControl,
    FormLabel,
  } from "@chakra-ui/react";
import { FieldProps } from "../../../../types/dnd-types";
import BaseComponent from "../BaseComponent";
  
  
  export default function TextArea(props: FieldProps) {
    return (
      <BaseComponent  keyName={props.keyName} onDelete={props.onDelete}>
        <FormControl isRequired={props.required}>
          <FormLabel>{props.title}</FormLabel>
          <MultlineInput
            name=""
            placeholder={props.placeholder}
            minLength={props.minLength}
            isDisabled={true}
          />
        </FormControl>
      </BaseComponent>
    );
  }