// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/sypher/v1/service/send/send.proto (package sypher.v1.send, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { AccessSettings, File, LastEvaluated, Send } from "../../types/types_pb";

/**
 * don't incluce encrypted data in Send, only time we send encrypted data is at getresponse.
 *
 * @generated from message sypher.v1.send.CreateSendRequest
 */
export class CreateSendRequest extends Message<CreateSendRequest> {
  /**
   * @generated from field: string label = 1;
   */
  label = "";

  /**
   * @generated from field: sypher.v1.types.AccessSettings access_settings = 2;
   */
  accessSettings?: AccessSettings;

  /**
   * @generated from field: string encrypted_data = 3;
   */
  encryptedData = "";

  /**
   * @generated from field: repeated sypher.v1.types.File files = 4;
   */
  files: File[] = [];

  /**
   * @generated from field: bool enable_notifications = 5;
   */
  enableNotifications = false;

  constructor(data?: PartialMessage<CreateSendRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.send.CreateSendRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "access_settings", kind: "message", T: AccessSettings },
    { no: 3, name: "encrypted_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "files", kind: "message", T: File, repeated: true },
    { no: 5, name: "enable_notifications", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSendRequest {
    return new CreateSendRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSendRequest {
    return new CreateSendRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSendRequest {
    return new CreateSendRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSendRequest | PlainMessage<CreateSendRequest> | undefined, b: CreateSendRequest | PlainMessage<CreateSendRequest> | undefined): boolean {
    return proto3.util.equals(CreateSendRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.send.CreateSendResponse
 */
export class CreateSendResponse extends Message<CreateSendResponse> {
  /**
   * @generated from field: string send_id = 1;
   */
  sendId = "";

  /**
   * @generated from field: map<string, string> signed_urls = 2;
   */
  signedUrls: { [key: string]: string } = {};

  constructor(data?: PartialMessage<CreateSendResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.send.CreateSendResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "send_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "signed_urls", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSendResponse {
    return new CreateSendResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSendResponse {
    return new CreateSendResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSendResponse {
    return new CreateSendResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSendResponse | PlainMessage<CreateSendResponse> | undefined, b: CreateSendResponse | PlainMessage<CreateSendResponse> | undefined): boolean {
    return proto3.util.equals(CreateSendResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.send.GetSendRequest
 */
export class GetSendRequest extends Message<GetSendRequest> {
  /**
   * @generated from field: string send_dri = 1;
   */
  sendDri = "";

  constructor(data?: PartialMessage<GetSendRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.send.GetSendRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "send_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSendRequest {
    return new GetSendRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSendRequest {
    return new GetSendRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSendRequest {
    return new GetSendRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSendRequest | PlainMessage<GetSendRequest> | undefined, b: GetSendRequest | PlainMessage<GetSendRequest> | undefined): boolean {
    return proto3.util.equals(GetSendRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.send.GetSendResponse
 */
export class GetSendResponse extends Message<GetSendResponse> {
  /**
   * @generated from field: sypher.v1.types.Send send = 1;
   */
  send?: Send;

  constructor(data?: PartialMessage<GetSendResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.send.GetSendResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "send", kind: "message", T: Send },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSendResponse {
    return new GetSendResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSendResponse {
    return new GetSendResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSendResponse {
    return new GetSendResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSendResponse | PlainMessage<GetSendResponse> | undefined, b: GetSendResponse | PlainMessage<GetSendResponse> | undefined): boolean {
    return proto3.util.equals(GetSendResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.send.ListSendsRequest
 */
export class ListSendsRequest extends Message<ListSendsRequest> {
  /**
   * @generated from field: string start_date = 1;
   */
  startDate = "";

  /**
   * @generated from field: string end_date = 2;
   */
  endDate = "";

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_send = 3;
   */
  lastEvaluatedSend?: LastEvaluated;

  constructor(data?: PartialMessage<ListSendsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.send.ListSendsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "end_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_evaluated_send", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSendsRequest {
    return new ListSendsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSendsRequest {
    return new ListSendsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSendsRequest {
    return new ListSendsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListSendsRequest | PlainMessage<ListSendsRequest> | undefined, b: ListSendsRequest | PlainMessage<ListSendsRequest> | undefined): boolean {
    return proto3.util.equals(ListSendsRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.send.ListSendsResponse
 */
export class ListSendsResponse extends Message<ListSendsResponse> {
  /**
   * @generated from field: repeated sypher.v1.types.Send sends = 1;
   */
  sends: Send[] = [];

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_send = 2;
   */
  lastEvaluatedSend?: LastEvaluated;

  constructor(data?: PartialMessage<ListSendsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.send.ListSendsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sends", kind: "message", T: Send, repeated: true },
    { no: 2, name: "last_evaluated_send", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListSendsResponse {
    return new ListSendsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListSendsResponse {
    return new ListSendsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListSendsResponse {
    return new ListSendsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListSendsResponse | PlainMessage<ListSendsResponse> | undefined, b: ListSendsResponse | PlainMessage<ListSendsResponse> | undefined): boolean {
    return proto3.util.equals(ListSendsResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.send.UpdateSendRequest
 */
export class UpdateSendRequest extends Message<UpdateSendRequest> {
  /**
   * @generated from field: string send_dri = 1;
   */
  sendDri = "";

  /**
   * @generated from field: string label = 2;
   */
  label = "";

  /**
   * @generated from field: sypher.v1.types.AccessSettings access_settings = 3;
   */
  accessSettings?: AccessSettings;

  /**
   * @generated from field: optional bool enable_notifications = 4;
   */
  enableNotifications?: boolean;

  constructor(data?: PartialMessage<UpdateSendRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.send.UpdateSendRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "send_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "access_settings", kind: "message", T: AccessSettings },
    { no: 4, name: "enable_notifications", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSendRequest {
    return new UpdateSendRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSendRequest {
    return new UpdateSendRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSendRequest {
    return new UpdateSendRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSendRequest | PlainMessage<UpdateSendRequest> | undefined, b: UpdateSendRequest | PlainMessage<UpdateSendRequest> | undefined): boolean {
    return proto3.util.equals(UpdateSendRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.send.AccessSendRequest
 */
export class AccessSendRequest extends Message<AccessSendRequest> {
  /**
   * @generated from field: string send_dri = 1;
   */
  sendDri = "";

  constructor(data?: PartialMessage<AccessSendRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.send.AccessSendRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "send_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccessSendRequest {
    return new AccessSendRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccessSendRequest {
    return new AccessSendRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccessSendRequest {
    return new AccessSendRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AccessSendRequest | PlainMessage<AccessSendRequest> | undefined, b: AccessSendRequest | PlainMessage<AccessSendRequest> | undefined): boolean {
    return proto3.util.equals(AccessSendRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.send.AccessSendResponse
 */
export class AccessSendResponse extends Message<AccessSendResponse> {
  /**
   * @generated from field: sypher.v1.types.Send send = 1;
   */
  send?: Send;

  /**
   * @generated from field: map<string, string> signed_urls = 2;
   */
  signedUrls: { [key: string]: string } = {};

  constructor(data?: PartialMessage<AccessSendResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.send.AccessSendResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "send", kind: "message", T: Send },
    { no: 2, name: "signed_urls", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccessSendResponse {
    return new AccessSendResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccessSendResponse {
    return new AccessSendResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccessSendResponse {
    return new AccessSendResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AccessSendResponse | PlainMessage<AccessSendResponse> | undefined, b: AccessSendResponse | PlainMessage<AccessSendResponse> | undefined): boolean {
    return proto3.util.equals(AccessSendResponse, a, b);
  }
}

