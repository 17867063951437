import { Icon, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

export default function CustomNormalIcon({ ...rest }) {
  const iconSize = useBreakpointValue({
    base: "22px",
    xl: "15px",
    "2xl": "22px",
  });
  return <Icon height={iconSize} w={iconSize} {...rest} />;
}
