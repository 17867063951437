// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/sypher/v1/service/dropzone/dropzone.proto (package sypher.v1.dropzone, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { AccessSettings, AsymKeyPair, Dropzone, LastEvaluated, TemplateData } from "../../types/types_pb";

/**
 * @generated from message sypher.v1.dropzone.CreateDropzoneRequest
 */
export class CreateDropzoneRequest extends Message<CreateDropzoneRequest> {
  /**
   * @generated from field: string dropzone_name = 1;
   */
  dropzoneName = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * public key set with public, and private key will be saved against user in user_private_keys map
   *
   * @generated from field: sypher.v1.types.AsymKeyPair key_pair = 3;
   */
  keyPair?: AsymKeyPair;

  /**
   * @generated from field: sypher.v1.types.TemplateData template = 4;
   */
  template?: TemplateData;

  /**
   * @generated from field: sypher.v1.types.AccessSettings access_settings = 5;
   */
  accessSettings?: AccessSettings;

  constructor(data?: PartialMessage<CreateDropzoneRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.dropzone.CreateDropzoneRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dropzone_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "key_pair", kind: "message", T: AsymKeyPair },
    { no: 4, name: "template", kind: "message", T: TemplateData },
    { no: 5, name: "access_settings", kind: "message", T: AccessSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateDropzoneRequest {
    return new CreateDropzoneRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateDropzoneRequest {
    return new CreateDropzoneRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateDropzoneRequest {
    return new CreateDropzoneRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateDropzoneRequest | PlainMessage<CreateDropzoneRequest> | undefined, b: CreateDropzoneRequest | PlainMessage<CreateDropzoneRequest> | undefined): boolean {
    return proto3.util.equals(CreateDropzoneRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.dropzone.CreateDropzoneResponse
 */
export class CreateDropzoneResponse extends Message<CreateDropzoneResponse> {
  /**
   * @generated from field: sypher.v1.types.Dropzone dropzone = 1;
   */
  dropzone?: Dropzone;

  constructor(data?: PartialMessage<CreateDropzoneResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.dropzone.CreateDropzoneResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dropzone", kind: "message", T: Dropzone },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateDropzoneResponse {
    return new CreateDropzoneResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateDropzoneResponse {
    return new CreateDropzoneResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateDropzoneResponse {
    return new CreateDropzoneResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateDropzoneResponse | PlainMessage<CreateDropzoneResponse> | undefined, b: CreateDropzoneResponse | PlainMessage<CreateDropzoneResponse> | undefined): boolean {
    return proto3.util.equals(CreateDropzoneResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.dropzone.GetDropzoneRequest
 */
export class GetDropzoneRequest extends Message<GetDropzoneRequest> {
  /**
   * @generated from field: string dropzone_dri = 1;
   */
  dropzoneDri = "";

  constructor(data?: PartialMessage<GetDropzoneRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.dropzone.GetDropzoneRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dropzone_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDropzoneRequest {
    return new GetDropzoneRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDropzoneRequest {
    return new GetDropzoneRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDropzoneRequest {
    return new GetDropzoneRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetDropzoneRequest | PlainMessage<GetDropzoneRequest> | undefined, b: GetDropzoneRequest | PlainMessage<GetDropzoneRequest> | undefined): boolean {
    return proto3.util.equals(GetDropzoneRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.dropzone.GetDropzoneResponse
 */
export class GetDropzoneResponse extends Message<GetDropzoneResponse> {
  /**
   * @generated from field: sypher.v1.types.Dropzone dropzone = 1;
   */
  dropzone?: Dropzone;

  constructor(data?: PartialMessage<GetDropzoneResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.dropzone.GetDropzoneResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dropzone", kind: "message", T: Dropzone },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDropzoneResponse {
    return new GetDropzoneResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDropzoneResponse {
    return new GetDropzoneResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDropzoneResponse {
    return new GetDropzoneResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetDropzoneResponse | PlainMessage<GetDropzoneResponse> | undefined, b: GetDropzoneResponse | PlainMessage<GetDropzoneResponse> | undefined): boolean {
    return proto3.util.equals(GetDropzoneResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.dropzone.ListDropzonesRequest
 */
export class ListDropzonesRequest extends Message<ListDropzonesRequest> {
  /**
   * @generated from field: string start_date = 1;
   */
  startDate = "";

  /**
   * @generated from field: string end_date = 2;
   */
  endDate = "";

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_dropzone = 3;
   */
  lastEvaluatedDropzone?: LastEvaluated;

  constructor(data?: PartialMessage<ListDropzonesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.dropzone.ListDropzonesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "end_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_evaluated_dropzone", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDropzonesRequest {
    return new ListDropzonesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDropzonesRequest {
    return new ListDropzonesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDropzonesRequest {
    return new ListDropzonesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListDropzonesRequest | PlainMessage<ListDropzonesRequest> | undefined, b: ListDropzonesRequest | PlainMessage<ListDropzonesRequest> | undefined): boolean {
    return proto3.util.equals(ListDropzonesRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.dropzone.ListDropzonesResponse
 */
export class ListDropzonesResponse extends Message<ListDropzonesResponse> {
  /**
   * @generated from field: repeated sypher.v1.types.Dropzone dropzones = 1;
   */
  dropzones: Dropzone[] = [];

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_dropzone = 2;
   */
  lastEvaluatedDropzone?: LastEvaluated;

  constructor(data?: PartialMessage<ListDropzonesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.dropzone.ListDropzonesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dropzones", kind: "message", T: Dropzone, repeated: true },
    { no: 2, name: "last_evaluated_dropzone", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDropzonesResponse {
    return new ListDropzonesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDropzonesResponse {
    return new ListDropzonesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDropzonesResponse {
    return new ListDropzonesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListDropzonesResponse | PlainMessage<ListDropzonesResponse> | undefined, b: ListDropzonesResponse | PlainMessage<ListDropzonesResponse> | undefined): boolean {
    return proto3.util.equals(ListDropzonesResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.dropzone.UpdateDropzoneRequest
 */
export class UpdateDropzoneRequest extends Message<UpdateDropzoneRequest> {
  /**
   * @generated from field: string dropzone_dri = 1;
   */
  dropzoneDri = "";

  /**
   * @generated from field: string dropzone_name = 2;
   */
  dropzoneName = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: sypher.v1.types.TemplateData template = 4;
   */
  template?: TemplateData;

  /**
   * @generated from field: optional bool reset_dropzone_key = 5;
   */
  resetDropzoneKey?: boolean;

  /**
   * @generated from field: optional sypher.v1.types.AccessSettings access_settings = 6;
   */
  accessSettings?: AccessSettings;

  constructor(data?: PartialMessage<UpdateDropzoneRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.dropzone.UpdateDropzoneRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dropzone_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "dropzone_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "template", kind: "message", T: TemplateData },
    { no: 5, name: "reset_dropzone_key", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 6, name: "access_settings", kind: "message", T: AccessSettings, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateDropzoneRequest {
    return new UpdateDropzoneRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateDropzoneRequest {
    return new UpdateDropzoneRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateDropzoneRequest {
    return new UpdateDropzoneRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateDropzoneRequest | PlainMessage<UpdateDropzoneRequest> | undefined, b: UpdateDropzoneRequest | PlainMessage<UpdateDropzoneRequest> | undefined): boolean {
    return proto3.util.equals(UpdateDropzoneRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.dropzone.UpdateDropzoneResponse
 */
export class UpdateDropzoneResponse extends Message<UpdateDropzoneResponse> {
  /**
   * @generated from field: string dropzone_key = 1;
   */
  dropzoneKey = "";

  constructor(data?: PartialMessage<UpdateDropzoneResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.dropzone.UpdateDropzoneResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dropzone_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateDropzoneResponse {
    return new UpdateDropzoneResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateDropzoneResponse {
    return new UpdateDropzoneResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateDropzoneResponse {
    return new UpdateDropzoneResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateDropzoneResponse | PlainMessage<UpdateDropzoneResponse> | undefined, b: UpdateDropzoneResponse | PlainMessage<UpdateDropzoneResponse> | undefined): boolean {
    return proto3.util.equals(UpdateDropzoneResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.dropzone.AccessDropzoneRequest
 */
export class AccessDropzoneRequest extends Message<AccessDropzoneRequest> {
  /**
   * @generated from field: string dropzone_dri = 1;
   */
  dropzoneDri = "";

  /**
   * @generated from field: string dropzone_key = 2;
   */
  dropzoneKey = "";

  /**
   * @generated from field: string respondent_id = 3;
   */
  respondentId = "";

  constructor(data?: PartialMessage<AccessDropzoneRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.dropzone.AccessDropzoneRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dropzone_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "dropzone_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "respondent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccessDropzoneRequest {
    return new AccessDropzoneRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccessDropzoneRequest {
    return new AccessDropzoneRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccessDropzoneRequest {
    return new AccessDropzoneRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AccessDropzoneRequest | PlainMessage<AccessDropzoneRequest> | undefined, b: AccessDropzoneRequest | PlainMessage<AccessDropzoneRequest> | undefined): boolean {
    return proto3.util.equals(AccessDropzoneRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.dropzone.AccessDropzoneResponse
 */
export class AccessDropzoneResponse extends Message<AccessDropzoneResponse> {
  /**
   * @generated from field: sypher.v1.types.Dropzone dropzone = 1;
   */
  dropzone?: Dropzone;

  /**
   * @generated from field: string session = 2;
   */
  session = "";

  constructor(data?: PartialMessage<AccessDropzoneResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.dropzone.AccessDropzoneResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dropzone", kind: "message", T: Dropzone },
    { no: 2, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccessDropzoneResponse {
    return new AccessDropzoneResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccessDropzoneResponse {
    return new AccessDropzoneResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccessDropzoneResponse {
    return new AccessDropzoneResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AccessDropzoneResponse | PlainMessage<AccessDropzoneResponse> | undefined, b: AccessDropzoneResponse | PlainMessage<AccessDropzoneResponse> | undefined): boolean {
    return proto3.util.equals(AccessDropzoneResponse, a, b);
  }
}

