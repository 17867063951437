// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/policy/v1/service/folder/folder.proto (package policy.v1.folder, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Folder, FolderObejct, FolderTypeInfo } from "../../types/types_pb";

/**
 * @generated from message policy.v1.folder.CreateFolderRequest
 */
export class CreateFolderRequest extends Message<CreateFolderRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: string parent_dri = 3;
   */
  parentDri = "";

  /**
   * @generated from field: policy.v1.types.FolderTypeInfo type_info = 4;
   */
  typeInfo?: FolderTypeInfo;

  constructor(data?: PartialMessage<CreateFolderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.folder.CreateFolderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "parent_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "type_info", kind: "message", T: FolderTypeInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFolderRequest {
    return new CreateFolderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFolderRequest {
    return new CreateFolderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFolderRequest {
    return new CreateFolderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFolderRequest | PlainMessage<CreateFolderRequest> | undefined, b: CreateFolderRequest | PlainMessage<CreateFolderRequest> | undefined): boolean {
    return proto3.util.equals(CreateFolderRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.folder.CreateFolderResponse
 */
export class CreateFolderResponse extends Message<CreateFolderResponse> {
  /**
   * @generated from field: policy.v1.types.Folder folder = 1;
   */
  folder?: Folder;

  constructor(data?: PartialMessage<CreateFolderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.folder.CreateFolderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "folder", kind: "message", T: Folder },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFolderResponse {
    return new CreateFolderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFolderResponse {
    return new CreateFolderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFolderResponse {
    return new CreateFolderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFolderResponse | PlainMessage<CreateFolderResponse> | undefined, b: CreateFolderResponse | PlainMessage<CreateFolderResponse> | undefined): boolean {
    return proto3.util.equals(CreateFolderResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.folder.GetFolderRequest
 */
export class GetFolderRequest extends Message<GetFolderRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  constructor(data?: PartialMessage<GetFolderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.folder.GetFolderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFolderRequest {
    return new GetFolderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFolderRequest {
    return new GetFolderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFolderRequest {
    return new GetFolderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetFolderRequest | PlainMessage<GetFolderRequest> | undefined, b: GetFolderRequest | PlainMessage<GetFolderRequest> | undefined): boolean {
    return proto3.util.equals(GetFolderRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.folder.GetFolderResponse
 */
export class GetFolderResponse extends Message<GetFolderResponse> {
  /**
   * @generated from field: policy.v1.types.Folder folder = 1;
   */
  folder?: Folder;

  constructor(data?: PartialMessage<GetFolderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.folder.GetFolderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "folder", kind: "message", T: Folder },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFolderResponse {
    return new GetFolderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFolderResponse {
    return new GetFolderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFolderResponse {
    return new GetFolderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetFolderResponse | PlainMessage<GetFolderResponse> | undefined, b: GetFolderResponse | PlainMessage<GetFolderResponse> | undefined): boolean {
    return proto3.util.equals(GetFolderResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.folder.UpdateFolderRequest
 */
export class UpdateFolderRequest extends Message<UpdateFolderRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: policy.v1.types.FolderTypeInfo type_info = 4;
   */
  typeInfo?: FolderTypeInfo;

  constructor(data?: PartialMessage<UpdateFolderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.folder.UpdateFolderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "type_info", kind: "message", T: FolderTypeInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFolderRequest {
    return new UpdateFolderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFolderRequest {
    return new UpdateFolderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateFolderRequest {
    return new UpdateFolderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateFolderRequest | PlainMessage<UpdateFolderRequest> | undefined, b: UpdateFolderRequest | PlainMessage<UpdateFolderRequest> | undefined): boolean {
    return proto3.util.equals(UpdateFolderRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.folder.UpdateFolderResponse
 */
export class UpdateFolderResponse extends Message<UpdateFolderResponse> {
  /**
   * @generated from field: policy.v1.types.Folder folder = 1;
   */
  folder?: Folder;

  constructor(data?: PartialMessage<UpdateFolderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.folder.UpdateFolderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "folder", kind: "message", T: Folder },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFolderResponse {
    return new UpdateFolderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFolderResponse {
    return new UpdateFolderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateFolderResponse {
    return new UpdateFolderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateFolderResponse | PlainMessage<UpdateFolderResponse> | undefined, b: UpdateFolderResponse | PlainMessage<UpdateFolderResponse> | undefined): boolean {
    return proto3.util.equals(UpdateFolderResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.folder.DeleteFolderRequest
 */
export class DeleteFolderRequest extends Message<DeleteFolderRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  constructor(data?: PartialMessage<DeleteFolderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.folder.DeleteFolderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteFolderRequest {
    return new DeleteFolderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteFolderRequest {
    return new DeleteFolderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteFolderRequest {
    return new DeleteFolderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteFolderRequest | PlainMessage<DeleteFolderRequest> | undefined, b: DeleteFolderRequest | PlainMessage<DeleteFolderRequest> | undefined): boolean {
    return proto3.util.equals(DeleteFolderRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.folder.GetFolderObjectsRequest
 */
export class GetFolderObjectsRequest extends Message<GetFolderObjectsRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 3;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<GetFolderObjectsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.folder.GetFolderObjectsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFolderObjectsRequest {
    return new GetFolderObjectsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFolderObjectsRequest {
    return new GetFolderObjectsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFolderObjectsRequest {
    return new GetFolderObjectsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetFolderObjectsRequest | PlainMessage<GetFolderObjectsRequest> | undefined, b: GetFolderObjectsRequest | PlainMessage<GetFolderObjectsRequest> | undefined): boolean {
    return proto3.util.equals(GetFolderObjectsRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.folder.GetFolderObjectsResponse
 */
export class GetFolderObjectsResponse extends Message<GetFolderObjectsResponse> {
  /**
   * @generated from field: repeated policy.v1.types.FolderObejct objects = 1;
   */
  objects: FolderObejct[] = [];

  constructor(data?: PartialMessage<GetFolderObjectsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.folder.GetFolderObjectsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "objects", kind: "message", T: FolderObejct, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFolderObjectsResponse {
    return new GetFolderObjectsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFolderObjectsResponse {
    return new GetFolderObjectsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFolderObjectsResponse {
    return new GetFolderObjectsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetFolderObjectsResponse | PlainMessage<GetFolderObjectsResponse> | undefined, b: GetFolderObjectsResponse | PlainMessage<GetFolderObjectsResponse> | undefined): boolean {
    return proto3.util.equals(GetFolderObjectsResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.folder.MoveFolderRequest
 */
export class MoveFolderRequest extends Message<MoveFolderRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string destination_parent_dri = 2;
   */
  destinationParentDri = "";

  constructor(data?: PartialMessage<MoveFolderRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.folder.MoveFolderRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "destination_parent_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MoveFolderRequest {
    return new MoveFolderRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MoveFolderRequest {
    return new MoveFolderRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MoveFolderRequest {
    return new MoveFolderRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MoveFolderRequest | PlainMessage<MoveFolderRequest> | undefined, b: MoveFolderRequest | PlainMessage<MoveFolderRequest> | undefined): boolean {
    return proto3.util.equals(MoveFolderRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.folder.MoveFolderResponse
 */
export class MoveFolderResponse extends Message<MoveFolderResponse> {
  /**
   * @generated from field: string parent_dri = 1;
   */
  parentDri = "";

  constructor(data?: PartialMessage<MoveFolderResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.folder.MoveFolderResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MoveFolderResponse {
    return new MoveFolderResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MoveFolderResponse {
    return new MoveFolderResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MoveFolderResponse {
    return new MoveFolderResponse().fromJsonString(jsonString, options);
  }

  static equals(a: MoveFolderResponse | PlainMessage<MoveFolderResponse> | undefined, b: MoveFolderResponse | PlainMessage<MoveFolderResponse> | undefined): boolean {
    return proto3.util.equals(MoveFolderResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.folder.ListTrashRequest
 */
export class ListTrashRequest extends Message<ListTrashRequest> {
  /**
   * @generated from field: bool deleted_by_me = 1;
   */
  deletedByMe = false;

  constructor(data?: PartialMessage<ListTrashRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.folder.ListTrashRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "deleted_by_me", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTrashRequest {
    return new ListTrashRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTrashRequest {
    return new ListTrashRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTrashRequest {
    return new ListTrashRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListTrashRequest | PlainMessage<ListTrashRequest> | undefined, b: ListTrashRequest | PlainMessage<ListTrashRequest> | undefined): boolean {
    return proto3.util.equals(ListTrashRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.folder.ListTrashResponse
 */
export class ListTrashResponse extends Message<ListTrashResponse> {
  /**
   * @generated from field: repeated policy.v1.types.FolderObejct objects = 1;
   */
  objects: FolderObejct[] = [];

  constructor(data?: PartialMessage<ListTrashResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.folder.ListTrashResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "objects", kind: "message", T: FolderObejct, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTrashResponse {
    return new ListTrashResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTrashResponse {
    return new ListTrashResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTrashResponse {
    return new ListTrashResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListTrashResponse | PlainMessage<ListTrashResponse> | undefined, b: ListTrashResponse | PlainMessage<ListTrashResponse> | undefined): boolean {
    return proto3.util.equals(ListTrashResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.folder.RecoverFromTrashRequest
 */
export class RecoverFromTrashRequest extends Message<RecoverFromTrashRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string destination_folder_dri = 2;
   */
  destinationFolderDri = "";

  constructor(data?: PartialMessage<RecoverFromTrashRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.folder.RecoverFromTrashRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "destination_folder_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecoverFromTrashRequest {
    return new RecoverFromTrashRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecoverFromTrashRequest {
    return new RecoverFromTrashRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecoverFromTrashRequest {
    return new RecoverFromTrashRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RecoverFromTrashRequest | PlainMessage<RecoverFromTrashRequest> | undefined, b: RecoverFromTrashRequest | PlainMessage<RecoverFromTrashRequest> | undefined): boolean {
    return proto3.util.equals(RecoverFromTrashRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.folder.RecoverFromTrashResponse
 */
export class RecoverFromTrashResponse extends Message<RecoverFromTrashResponse> {
  /**
   * @generated from field: string status = 2;
   */
  status = "";

  constructor(data?: PartialMessage<RecoverFromTrashResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.folder.RecoverFromTrashResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecoverFromTrashResponse {
    return new RecoverFromTrashResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecoverFromTrashResponse {
    return new RecoverFromTrashResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecoverFromTrashResponse {
    return new RecoverFromTrashResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RecoverFromTrashResponse | PlainMessage<RecoverFromTrashResponse> | undefined, b: RecoverFromTrashResponse | PlainMessage<RecoverFromTrashResponse> | undefined): boolean {
    return proto3.util.equals(RecoverFromTrashResponse, a, b);
  }
}

