// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/sypher/v1/service/setup/setup.proto (package sypher.v1.setup, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message sypher.v1.setup.CreateRootKeyRequest
 */
export class CreateRootKeyRequest extends Message<CreateRootKeyRequest> {
  /**
   * @generated from field: string root_key = 1;
   */
  rootKey = "";

  /**
   * @generated from field: string back_root_key = 2;
   */
  backRootKey = "";

  constructor(data?: PartialMessage<CreateRootKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.setup.CreateRootKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "root_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "back_root_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRootKeyRequest {
    return new CreateRootKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRootKeyRequest {
    return new CreateRootKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRootKeyRequest {
    return new CreateRootKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRootKeyRequest | PlainMessage<CreateRootKeyRequest> | undefined, b: CreateRootKeyRequest | PlainMessage<CreateRootKeyRequest> | undefined): boolean {
    return proto3.util.equals(CreateRootKeyRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.setup.CreateRootKeyResponse
 */
export class CreateRootKeyResponse extends Message<CreateRootKeyResponse> {
  /**
   * @generated from field: string root_key = 1;
   */
  rootKey = "";

  constructor(data?: PartialMessage<CreateRootKeyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.setup.CreateRootKeyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "root_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRootKeyResponse {
    return new CreateRootKeyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRootKeyResponse {
    return new CreateRootKeyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRootKeyResponse {
    return new CreateRootKeyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRootKeyResponse | PlainMessage<CreateRootKeyResponse> | undefined, b: CreateRootKeyResponse | PlainMessage<CreateRootKeyResponse> | undefined): boolean {
    return proto3.util.equals(CreateRootKeyResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.setup.GetRootKeyRequest
 */
export class GetRootKeyRequest extends Message<GetRootKeyRequest> {
  /**
   * @generated from field: bool backup = 1;
   */
  backup = false;

  constructor(data?: PartialMessage<GetRootKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.setup.GetRootKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "backup", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRootKeyRequest {
    return new GetRootKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRootKeyRequest {
    return new GetRootKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRootKeyRequest {
    return new GetRootKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRootKeyRequest | PlainMessage<GetRootKeyRequest> | undefined, b: GetRootKeyRequest | PlainMessage<GetRootKeyRequest> | undefined): boolean {
    return proto3.util.equals(GetRootKeyRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.setup.GetRootKeyResponse
 */
export class GetRootKeyResponse extends Message<GetRootKeyResponse> {
  /**
   * @generated from field: string root_key = 1;
   */
  rootKey = "";

  constructor(data?: PartialMessage<GetRootKeyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.setup.GetRootKeyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "root_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRootKeyResponse {
    return new GetRootKeyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRootKeyResponse {
    return new GetRootKeyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRootKeyResponse {
    return new GetRootKeyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRootKeyResponse | PlainMessage<GetRootKeyResponse> | undefined, b: GetRootKeyResponse | PlainMessage<GetRootKeyResponse> | undefined): boolean {
    return proto3.util.equals(GetRootKeyResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.setup.UpdateRootKeyRequest
 */
export class UpdateRootKeyRequest extends Message<UpdateRootKeyRequest> {
  /**
   * @generated from field: string root_key = 1;
   */
  rootKey = "";

  /**
   * @generated from field: string back_root_key = 2;
   */
  backRootKey = "";

  constructor(data?: PartialMessage<UpdateRootKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.setup.UpdateRootKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "root_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "back_root_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRootKeyRequest {
    return new UpdateRootKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRootKeyRequest {
    return new UpdateRootKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRootKeyRequest {
    return new UpdateRootKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRootKeyRequest | PlainMessage<UpdateRootKeyRequest> | undefined, b: UpdateRootKeyRequest | PlainMessage<UpdateRootKeyRequest> | undefined): boolean {
    return proto3.util.equals(UpdateRootKeyRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.setup.UpdateRootKeyResponse
 */
export class UpdateRootKeyResponse extends Message<UpdateRootKeyResponse> {
  /**
   * @generated from field: string root_key = 1;
   */
  rootKey = "";

  constructor(data?: PartialMessage<UpdateRootKeyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.setup.UpdateRootKeyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "root_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRootKeyResponse {
    return new UpdateRootKeyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRootKeyResponse {
    return new UpdateRootKeyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRootKeyResponse {
    return new UpdateRootKeyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRootKeyResponse | PlainMessage<UpdateRootKeyResponse> | undefined, b: UpdateRootKeyResponse | PlainMessage<UpdateRootKeyResponse> | undefined): boolean {
    return proto3.util.equals(UpdateRootKeyResponse, a, b);
  }
}

