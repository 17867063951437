import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RootKeyForm from "../components/secure-share/request/RootKeyForm";
import { useAuth } from "../context/auth";
import { useRootKey } from "../context/request/root-key";
import LoadingScreen from "./LoadingScreen";
import { readRootKey } from "@defense-station/api-service";
import toast, { showServerError } from "../components/toast";

export default function RootKey() {
  const [isLoading, setIsLoading] = useState(true);
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const { root_key, setRootKey, restoreRootKey } = useRootKey();
  const getRootKey = async () => {
    try {
      const res = await readRootKey(user?.account_id, user?.username);
      const rootKey = res?.data?.sypher_v1_GetRootKey?.root_key;
      if (rootKey) {
        setRootKey(rootKey);
        navigate(-1);
      }
    } catch (e) {
      if (e.message.includes("5 NOT_FOUND: no key registered")) {
      } else {
        showServerError(e);
      }
    }
    setIsLoading(false);
  };

  const onSubmit = ({ root_key, phrase, backup }) => {
    let newUserData = { ...user, userPhrase: phrase, root_key: root_key };
    setUser(newUserData);
    setRootKey(root_key);
    if (!backup) {
      setTimeout(() => {
        navigate(-1);
      }, 500);
    }
  };
  const init = () => {
    if (restoreRootKey) {
      setIsLoading(false);
      return;
    }
    if (root_key) {
      navigate(-1);
      return;
    }
    if (user && !root_key) {
      getRootKey();
    }
  };
  useEffect(() => {
    init();
  }, [user]);
  return isLoading ? <LoadingScreen /> : <RootKeyForm onSuccess={onSubmit} />;
}
