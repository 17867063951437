import {
  and,
  CellProps,
  formatIs,
  isStringControl,
  RankedTester,
  rankWith,
  schemaMatches,
} from '@reactjsonforms/core';
import { Flex, FormControl, Input, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { withJsonFormsCellProps } from '@reactjsonforms/react';
import merge from 'lodash/merge';
import { VanillaRendererProps, withVanillaCellProps } from '@reactjsonforms/chakra-renderers';
import { ChangeEvent, useMemo, useState } from 'react';
import FileView from '../../fileview';
import CustomFormLabel from '../../custom-styled-components/CustomFormLabel';
import ViewFile from '../../rjsf/ViewFile';
import { FaFileInvoice } from 'react-icons/fa';
import { base64toBlob, getMimeTypeAndBase64, readAndDecryptFileBlobFromURL } from '../../../utils/custom-function';
import { showErrorToast } from '../../../services/toast-service';
import { Dropzone } from '../../Dropzone';
import { useSubmitData } from '../../../context/request/submit-data';
import { useReadResponse } from '../../../context/dropzone/read-dropzone-context';

export const FileCell = (props: CellProps & VanillaRendererProps) => {
  const {
    config,
    data,
    className,
    id,
    enabled,
    uischema,
    schema,
    path,
    handleChange,
  } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [fileName, setFileName] = useState<string | null>(null);
  const MAX_FILE_SIZE = uischema?.options?.maxSize;
  const MAX_FILE_HEADING = uischema?.options?.maxSize == 1073741824 ? "1GB" : "5GB";
  const { addFile, removeFile, files, addFilesWithKeys, setFiles } =
    useSubmitData();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [viewFileName, setViewFileName] = useState<string | null>(null);
  const [viewFileURL, setViewFileURL] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const isFile = props.schema.format == "file";
  const isMultifile = props.schema.format == "multifile";
  const readRequest = useReadResponse();
  const decKey = readRequest.decKey;
  const readFiles = readRequest.files;
  const [fileUrl, setFileUrl] = useState("");
  const [controller, setController] = useState(new AbortController());
  const [downloadingProgress, setDownloadingProgress] = useState(0);
  const [viewProgress, setViewProgress] = useState(0);
  const onDownloadProgress = (value: number) => setDownloadingProgress(value);
  const onViewProgress = (value: number) => setViewProgress(value);
  const onDownload = async (name = null, fileName = null) => {
    try {
      setIsDownloading(true);
      const decryptBlob = await readAndDecryptFileBlobFromURL(
        readFiles[path]?.value,
        decKey,
        onDownloadProgress,
        path,
        controller.signal
      );
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(decryptBlob);

      a.download = data;
      a.click();
    } catch (e) {
      throw e;
    }
    setIsDownloading(false);
    setDownloadingProgress(0);
  };

  const onView = async (name = null, fileName = null) => {
    try {
      setIsLoading(true);
      const decryptBlob = await readAndDecryptFileBlobFromURL(
        readFiles[path]?.value,
        decKey,
        onViewProgress,
        path,
        controller.signal
      );
      const url = window.URL.createObjectURL(decryptBlob);
      setViewFileName(fileName);
      setFileUrl(url);
      onOpen();
    } catch (e) {
      throw e;
    }
    setIsLoading(false);
    setViewProgress(0);
  };
  // const onFileChange = (file) => {
  //   if (file) {
  //     if (MAX_FILE_SIZE) {
  //       if (file?.size >= MAX_FILE_SIZE) {
  //         showErrorToast(
  //           "Error!",
  //           `File size cannot be larger than ${MAX_FILE_HEADING}.`
  //         );
  //         return;
  //       }
  //     }

  //     let reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onloadend = () => {
  //       setFileName(`${file.path} (${file.size} bytes)`);
  //       props.onChange(file?.name);
  //     };
  //   } else {
  //     props.onChange(file);
  //     setFileName(null);
  //   }
  //   addFile(file, props.name);
  // };
  // const onMultiFileChange = (selectedFiles) => {
  //   if (selectedFiles?.length) {
  //     let newFilesObject = {};
  //     Object.keys(files)?.map((key) => {
  //       if (!key.includes(props.name)) {
  //         newFilesObject[key] = files[key];
  //       }
  //     });
  //     setFiles(newFilesObject);
  //     let newFiles = {};
  //     let fileNames = [];
  //     for (let i = 0; i < selectedFiles.length; i++) {
  //       const file = selectedFiles[i];
  //       let reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onloadend = () => {
  //         setFileName(`${file.path} (${file.size} bytes)`);
  //       };
  //       fileNames.push(file?.name);
  //       newFiles[props.name + [i]] = file;
  //       if (i + 1 > props.schema?.maxLength) {
  //         break;
  //       }
  //     }
  //     props.onChange(fileNames.join(","));
  //     addFilesWithKeys(newFiles);
  //   } else if (selectedFiles == undefined) {
  //     let newFilesObject = {};
  //     Object.keys(files)?.map((key) => {
  //       if (!key.includes(props.name)) {
  //         newFilesObject[key] = files[key];
  //       }
  //     });
  //     setFiles(newFilesObject);
  //     props.onChange(undefined);
  //     setFileName(null);
  //   } else {
  //   }
  // };
  // useEffect(() => {
  //   return () => {
  //     controller.abort();
  //   };
  // }, []);

  // if (isMultifile) {
  //   let filesToBeShown = [];
  //   let fileNames = props.formData?.split(",");
  //   if (props.readonly) {
  //     const fileKeys = Object.keys(readFiles);
  //     for (let index = 0; index < props.schema.maxLength; index++) {
  //       const name = props.name + index;
  //       if (fileKeys.includes(name)) {
  //         filesToBeShown.push(readFiles[name]);
  //       }
  //     }
  //   }

  const maxLength = schema.maxLength;
  const appliedUiSchemaOptions = merge({}, config, uischema.options);

  const size = appliedUiSchemaOptions.trim ? maxLength : undefined;
  //@ts-ignore
  // const { base64Data, extension, mimeType } = useMemo(() => data ? getMimeTypeAndBase64(data) : {}, [data])
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const file = event.target.files?.[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          handleChange(path, e.target?.result as string);
        };
        reader.readAsDataURL(file);
      }
    }
  }
  // const onDownload = async (name = null, fileName = null) => {
  //   try {
  //     setIsDownloading(true);
  //     // const decryptBlob = await readAndDecryptFileBlobFromURL(
  //     //   readFiles[name ? name : props.name]?.value,
  //     //   decKey,
  //     //   onDownloadProgress,
  //     //   fileName ? fileName : props.formData,
  //     //   controller.signal
  //     // );
  //     const a = document.createElement("a");
  //     a.href = data;

  //     a.download = fileName ? fileName : `${schema.title}.${extension}`;
  //     a.click();
  //   } catch (e) {
  //     throw e;
  //   }
  //   setIsDownloading(false);
  //   setDownloadingProgress(0);
  // };

  // const onView = async (name = null, fileName = null) => {
  //   try {
  //     setIsLoading(true);
  //     setViewFileName(schema.title);
  //     const blob = base64toBlob(data);
  //     const url = URL.createObjectURL(blob);
  //     setFileUrl(url);
  //     onOpen();
  //   } catch (e) {
  //     throw e;
  //   }
  //   setIsLoading(false);
  //   setViewProgress(0);
  // };
  const onFileChange = (file: File) => {
    if (file) {
      if (MAX_FILE_SIZE) {
        if (file?.size >= MAX_FILE_SIZE) {
          showErrorToast(
            "Error!",
            `File size cannot be larger than ${MAX_FILE_HEADING}.`
          );
          return;
        }
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFileName(`${file.name} (${file.size} bytes)`);
        handleChange(path, file?.name);
      };
    } else {
      handleChange(path, file);
      setFileName(null);
    }
    console.log("Add file", file, path)
    addFile(file, path);
  };

  if (!data && !enabled) {
    return <Flex
      borderWidth={1}
      p={2}
      borderRadius="lg"
      borderColor="secondaryGray.400"
      justifyContent={"space-between"}
    >
      No file uploaded.
    </Flex>;
  }

  return (enabled ?
    <>
      <Dropzone
        onChange={onFileChange}
        // isRequired={props.required}
        name={schema.title || ''}
        values={fileName ? { [schema.title || '']: { name: fileName } } : null}
        width="full"
      />
    </>


    : (data && (
      <>
        {isOpen && (
          <FileView
            isOpen={isOpen}
            onClose={onClose}
            file_name={data}
            fileLocalUrl={fileUrl}
          />
        )}
        <ViewFile
          name={data}
          url="https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI"
          onView={onView}
          onDownload={onDownload}
          isDownloading={isDownloading}
          isLoading={isLoading}
          viewProgress={viewProgress}
          downloadingProgress={downloadingProgress}
          downloadAllowed
          icon={<FaFileInvoice />}
        />
      </>
    ))
  );
};

/**
 * Default tester for text-based/string controls.
 * @type {RankedTester}
 */
export const fileCellTester: RankedTester = rankWith(4, and(
  isStringControl,
  formatIs("data-url")
));

export default withJsonFormsCellProps(withVanillaCellProps(FileCell));
