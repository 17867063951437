import {
    Input,
    FormControl,
    FormLabel,
  } from "@chakra-ui/react";
import { FieldProps } from "../../../../types/dnd-types";
import BaseComponent from "../BaseComponent";
  
  export default function NumberField(props: FieldProps) {
    return (
      <BaseComponent  keyName={props.keyName} onDelete={props.onDelete}>
        <FormControl isRequired={props.required}>
          <FormLabel>{props.title}</FormLabel>
          <Input
            type="number"
            name=""
            placeholder={props.placeholder}
            min={props.minimum}
            max={props.maximum}
            isDisabled={true}
          />
        </FormControl>
      </BaseComponent>
    );
  }