import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import PdfViewer from "./PdfViewer";

const FileView = ({ fileLocalUrl, file_name, isOpen, onClose }) => {
  const fileType = file_name.split(".").pop().toLowerCase();
  const modalSize = useBreakpointValue({
    base: "full",
    md: fileType === "pdf" ? "6xl" : "2xl",
  });
  return (
    <Modal onClose={onClose} size={modalSize} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent overflow="auto" maxHeight={"90vh"}>
        <ModalHeader>{file_name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {fileType === "pdf" && <PdfViewer sourceFile={fileLocalUrl} />}
          {fileType === "jpeg" && <img src={fileLocalUrl} />}
          {fileType === "jpg" && <img src={fileLocalUrl} />}
          {fileType === "png" && <img src={fileLocalUrl} />}
        </ModalBody>
      </ModalContent>
      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default FileView;
