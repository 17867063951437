// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "DM Sans", sans-serif!important;
}

option {
  color: black;
}

::-webkit-scrollbar {
  height: 10px;
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0); 
  border-radius: 10px;
}
/* 
::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
} */
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #E2E8F0; 
  border-radius: 10px;
}

Handle on hover
::-webkit-scrollbar-thumb:hover {
  background: #38a16998; 
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/App.css"],"names":[],"mappings":"AACA;EACE,4CAA4C;AAC9C;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,UAAU;AACZ;;AAEA,UAAU;AACV;EACE,gDAAgD;EAChD,mBAAmB;AACrB;AACA;;;;GAIG;;AAEH,WAAW;AACX;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;;EAEE,qBAAqB;AACvB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap\");\nbody {\n  font-family: \"DM Sans\", sans-serif!important;\n}\n\noption {\n  color: black;\n}\n\n::-webkit-scrollbar {\n  height: 10px;\n  width: 7px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0); \n  border-radius: 10px;\n}\n/* \n::-webkit-scrollbar-track:hover {\n  box-shadow: inset 0 0 5px grey; \n  border-radius: 10px;\n} */\n \n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: #E2E8F0; \n  border-radius: 10px;\n}\n\nHandle on hover\n::-webkit-scrollbar-thumb:hover {\n  background: #38a16998; \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
