import {
    Box,
    Button,
    Flex,
    Heading,
    Text,
    LightMode,
    useColorModeValue,
    ButtonGroup,
  } from "@chakra-ui/react";
  import React, { useState } from "react";
  import Card from "../../card/Card";
  import {setRedirectionBackActionForLogin} from '@defense-station/auth'
import {navigateToUrl} from 'single-spa'
  
  export default function LoginScreen(props) {
    const {stats} = props
    const {setAttempts, attempts} = props;
    const { onSubmit } = props;
    const [show, setShow] = useState(false);
    const textColorSecondary = "gray.400";
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const onClick = () => {
        setRedirectionBackActionForLogin();
        navigateToUrl("/login");
    }
    return (
      <Flex
        direction='column'
        alignSelf='center'
        justifySelf='center'
        overflow='hidden'
        position={'relative'}
        width="100%"
        mx={{ base: "10px", lg: "0px" }}
        minH='100vh'>
        <Box
          position='absolute'
          minH={{ base: "45vh", md: "45vh" }}
          maxH={{ base: "45vh", md: "45vh" }}
          w={{ md: "calc(95%)" }}
          maxW={{ md: "calc(95%)" }}
          left='0'
          right='0'
          top="4"
          bgRepeat='no-repeat'
          overflow='hidden'
          zIndex='-1'
          bgImage={"linear-gradient(135deg, #9AE6B4 0%, #48BB78 100%)"}
          bgSize='cover'
          borderRadius='30px'
          mx={{ md: "auto" }}></Box>
          
        <Card
          w={{ base: "100%", md: "max-content" }}
          h='max-content'
          mx='auto'
          maxW='100%'
          position={'relative'}
          mt={{ base: "140px", md: "30vh" }}
          mb={{ base: "50px", lg: "auto" }}
          p={{ base: "10px", md: "45px" }}
          pt={{ base: "30px", md: "50px" }}>
           <Flex
          w='100%'
          maxW='max-content'
          me='auto'
          h='100%'
          alignItems='start'
          position={"relative"}
          justifyContent='center'
          px={{ base: "25px", md: "0px" }}
          flexDirection='column'>
          <Box me='auto' mb='27px'>
            <Heading
                textAlign={"center"}
              color={textColor}
              fontSize={{ base: "3xl", md: "36px" }}
              mb='16px'>
              Login Required
            </Heading>
            <Text
            textAlign={"center"}
              color={textColorSecondary}
              fontSize='md'
              w={{ base: "100%", lg: "456px" }}
              maxW='100%'>
              You must be logged in to submit the data.
            </Text>
           
           
          </Box>
            <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", lg: "456px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}
            align='start'>
                <Flex flex="1" justifyContent="center" w="full">
  
<ButtonGroup width={"full"} >
                                        
      <Button
      size="md"
      width={"full"}
      colorScheme="brand"
      variant={"outline"}
      mr={3}
      onClick={() => navigateToUrl("/signup")}
    >
      Sign Up
    </Button>
    <Button
    size="md"
    width={"full"}
    colorScheme="brand"
    mr={3}
    onClick={() => navigateToUrl("/login")}
  >
     Login
  </Button>
    </ButtonGroup>
</Flex>
              
              
              </Flex>
            </Flex>
            
        </Card>
        
      </Flex>
    );
  }
  