// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/sypher/v1/service/access_history/access_history.proto (package sypher.v1.access_history, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ListAccessHistoryByUsernameRequest, ListAccessHistoryByUsernameResponse, ListAccessHistoryRequest, ListAccessHistoryResponse } from "./access_history_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service sypher.v1.access_history.AccessHistoryService
 */
export const AccessHistoryService = {
  typeName: "sypher.v1.access_history.AccessHistoryService",
  methods: {
    /**
     * @generated from rpc sypher.v1.access_history.AccessHistoryService.ListAccessHistory
     */
    listAccessHistory: {
      name: "ListAccessHistory",
      I: ListAccessHistoryRequest,
      O: ListAccessHistoryResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.access_history.AccessHistoryService.ListAccessHistoryByUsername
     */
    listAccessHistoryByUsername: {
      name: "ListAccessHistoryByUsername",
      I: ListAccessHistoryByUsernameRequest,
      O: ListAccessHistoryByUsernameResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

