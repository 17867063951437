// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/sypher/v1/types/types.proto (package sypher.v1.types, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * type of storage allowed
 *
 * @generated from enum sypher.v1.types.StorageType
 */
export enum StorageType {
  /**
   * @generated from enum value: AWS_S3 = 0;
   */
  AWS_S3 = 0,

  /**
   * @generated from enum value: AZURE_BLOB = 1;
   */
  AZURE_BLOB = 1,

  /**
   * @generated from enum value: GCP_STORAGE = 2;
   */
  GCP_STORAGE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(StorageType)
proto3.util.setEnumType(StorageType, "sypher.v1.types.StorageType", [
  { no: 0, name: "AWS_S3" },
  { no: 1, name: "AZURE_BLOB" },
  { no: 2, name: "GCP_STORAGE" },
]);

/**
 * THERE SHOULD BE NO SERVICE IN THIS FILE.
 * Global Messages for this service
 *
 * @generated from message sypher.v1.types.LastEvaluated
 */
export class LastEvaluated extends Message<LastEvaluated> {
  /**
   * @generated from field: string Pk = 1;
   */
  Pk = "";

  /**
   * @generated from field: string Sk = 2;
   */
  Sk = "";

  /**
   * @generated from field: string Gsi1Pk = 3;
   */
  Gsi1Pk = "";

  /**
   * @generated from field: string Gsi1Sk = 4;
   */
  Gsi1Sk = "";

  constructor(data?: PartialMessage<LastEvaluated>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.LastEvaluated";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "Pk", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Sk", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Gsi1Pk", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "Gsi1Sk", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LastEvaluated {
    return new LastEvaluated().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LastEvaluated {
    return new LastEvaluated().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LastEvaluated {
    return new LastEvaluated().fromJsonString(jsonString, options);
  }

  static equals(a: LastEvaluated | PlainMessage<LastEvaluated> | undefined, b: LastEvaluated | PlainMessage<LastEvaluated> | undefined): boolean {
    return proto3.util.equals(LastEvaluated, a, b);
  }
}

/**
 * ip-api fields added
 * status,message,continent,continentCode,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,offset,isp,org,as,asname,mobile,proxy,hosting,query
 *
 * @generated from message sypher.v1.types.AccessHistory
 */
export class AccessHistory extends Message<AccessHistory> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string access_id = 2;
   */
  accessId = "";

  /**
   * @generated from field: string client_ip = 3;
   */
  clientIp = "";

  /**
   * @generated from field: string user_agent = 4;
   */
  userAgent = "";

  /**
   * @generated from field: string created_at = 5;
   */
  createdAt = "";

  /**
   * @generated from field: string status = 6;
   */
  status = "";

  /**
   * @generated from field: string continent = 7;
   */
  continent = "";

  /**
   * @generated from field: string continent_code = 8;
   */
  continentCode = "";

  /**
   * @generated from field: string country = 9;
   */
  country = "";

  /**
   * @generated from field: string country_code = 10;
   */
  countryCode = "";

  /**
   * @generated from field: string region = 11;
   */
  region = "";

  /**
   * @generated from field: string region_name = 12;
   */
  regionName = "";

  /**
   * @generated from field: string city = 13;
   */
  city = "";

  /**
   * @generated from field: string district = 14;
   */
  district = "";

  /**
   * @generated from field: string zip = 15;
   */
  zip = "";

  /**
   * @generated from field: float lat = 16;
   */
  lat = 0;

  /**
   * @generated from field: float lon = 17;
   */
  lon = 0;

  /**
   * @generated from field: string timezone = 18;
   */
  timezone = "";

  /**
   * @generated from field: sint64 offset = 19;
   */
  offset = protoInt64.zero;

  /**
   * @generated from field: string isp = 20;
   */
  isp = "";

  /**
   * @generated from field: string org = 21;
   */
  org = "";

  /**
   * @generated from field: string as = 22;
   */
  as = "";

  /**
   * @generated from field: string asname = 23;
   */
  asname = "";

  /**
   * @generated from field: bool mobile = 24;
   */
  mobile = false;

  /**
   * @generated from field: bool proxy = 25;
   */
  proxy = false;

  /**
   * @generated from field: bool hosting = 26;
   */
  hosting = false;

  constructor(data?: PartialMessage<AccessHistory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.AccessHistory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "access_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "client_ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "user_agent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "continent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "continent_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "region_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "district", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "zip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "lat", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 17, name: "lon", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 18, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "offset", kind: "scalar", T: 18 /* ScalarType.SINT64 */ },
    { no: 20, name: "isp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "org", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "as", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "asname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "mobile", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 25, name: "proxy", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 26, name: "hosting", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccessHistory {
    return new AccessHistory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccessHistory {
    return new AccessHistory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccessHistory {
    return new AccessHistory().fromJsonString(jsonString, options);
  }

  static equals(a: AccessHistory | PlainMessage<AccessHistory> | undefined, b: AccessHistory | PlainMessage<AccessHistory> | undefined): boolean {
    return proto3.util.equals(AccessHistory, a, b);
  }
}

/**
 * files
 *
 * @generated from message sypher.v1.types.File
 */
export class File extends Message<File> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int64 size = 2;
   */
  size = protoInt64.zero;

  constructor(data?: PartialMessage<File>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.File";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): File {
    return new File().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): File {
    return new File().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): File {
    return new File().fromJsonString(jsonString, options);
  }

  static equals(a: File | PlainMessage<File> | undefined, b: File | PlainMessage<File> | undefined): boolean {
    return proto3.util.equals(File, a, b);
  }
}

/**
 * @generated from message sypher.v1.types.AccessSettings
 */
export class AccessSettings extends Message<AccessSettings> {
  /**
   * @generated from field: string access_device = 1;
   */
  accessDevice = "";

  /**
   * @generated from field: int32 access_limit = 2;
   */
  accessLimit = 0;

  /**
   * @generated from field: string start_date = 3;
   */
  startDate = "";

  /**
   * @generated from field: string end_date = 4;
   */
  endDate = "";

  /**
   * @generated from field: bool download_allowed = 5;
   */
  downloadAllowed = false;

  /**
   * @generated from field: bool login_required = 6;
   */
  loginRequired = false;

  /**
   * @generated from field: bool hide_stats = 7;
   */
  hideStats = false;

  /**
   * @generated from field: bool hide_identity = 8;
   */
  hideIdentity = false;

  /**
   * @generated from field: bool password_set = 9;
   */
  passwordSet = false;

  /**
   * @generated from field: bool responder_controls = 10;
   */
  responderControls = false;

  /**
   * @generated from field: repeated string allowed_origins = 11;
   */
  allowedOrigins: string[] = [];

  constructor(data?: PartialMessage<AccessSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.AccessSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "access_limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "start_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "end_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "download_allowed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "login_required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "hide_stats", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "hide_identity", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "password_set", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "responder_controls", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "allowed_origins", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccessSettings {
    return new AccessSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccessSettings {
    return new AccessSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccessSettings {
    return new AccessSettings().fromJsonString(jsonString, options);
  }

  static equals(a: AccessSettings | PlainMessage<AccessSettings> | undefined, b: AccessSettings | PlainMessage<AccessSettings> | undefined): boolean {
    return proto3.util.equals(AccessSettings, a, b);
  }
}

/**
 * @generated from message sypher.v1.types.AsymKeyPair
 */
export class AsymKeyPair extends Message<AsymKeyPair> {
  /**
   * @generated from field: string public_key = 1;
   */
  publicKey = "";

  /**
   * @generated from field: string enc_private_key = 2;
   */
  encPrivateKey = "";

  constructor(data?: PartialMessage<AsymKeyPair>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.AsymKeyPair";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "public_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "enc_private_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AsymKeyPair {
    return new AsymKeyPair().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AsymKeyPair {
    return new AsymKeyPair().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AsymKeyPair {
    return new AsymKeyPair().fromJsonString(jsonString, options);
  }

  static equals(a: AsymKeyPair | PlainMessage<AsymKeyPair> | undefined, b: AsymKeyPair | PlainMessage<AsymKeyPair> | undefined): boolean {
    return proto3.util.equals(AsymKeyPair, a, b);
  }
}

/**
 * don't incluce encrypted data in Send, only time we send encrypted data is at getresponse.
 *
 * @generated from message sypher.v1.types.Send
 */
export class Send extends Message<Send> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string send_id = 2;
   */
  sendId = "";

  /**
   * @generated from field: string label = 3;
   */
  label = "";

  /**
   * @generated from field: sypher.v1.types.AccessSettings access_settings = 4;
   */
  accessSettings?: AccessSettings;

  /**
   * @generated from field: repeated sypher.v1.types.File files = 5;
   */
  files: File[] = [];

  /**
   * @generated from field: string encrypted_data = 6;
   */
  encryptedData = "";

  /**
   * @generated from field: string created_at = 7;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 8;
   */
  updatedAt = "";

  /**
   * @generated from field: string account_id = 9;
   */
  accountId = "";

  /**
   * @generated from field: string username = 10;
   */
  username = "";

  /**
   * @generated from field: bool enable_notifications = 11;
   */
  enableNotifications = false;

  constructor(data?: PartialMessage<Send>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.Send";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "send_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "access_settings", kind: "message", T: AccessSettings },
    { no: 5, name: "files", kind: "message", T: File, repeated: true },
    { no: 6, name: "encrypted_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "enable_notifications", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Send {
    return new Send().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Send {
    return new Send().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Send {
    return new Send().fromJsonString(jsonString, options);
  }

  static equals(a: Send | PlainMessage<Send> | undefined, b: Send | PlainMessage<Send> | undefined): boolean {
    return proto3.util.equals(Send, a, b);
  }
}

/**
 * @generated from message sypher.v1.types.Request
 */
export class Request extends Message<Request> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string request_id = 2;
   */
  requestId = "";

  /**
   * @generated from field: string respondent_id = 3;
   */
  respondentId = "";

  /**
   * to send the email(sharely or when response submitted- notification)
   *
   * @generated from field: string notification_email = 4;
   */
  notificationEmail = "";

  /**
   * @generated from field: string label = 5;
   */
  label = "";

  /**
   * @generated from field: string message = 6;
   */
  message = "";

  /**
   * @generated from field: sypher.v1.types.AsymKeyPair key_pair = 7;
   */
  keyPair?: AsymKeyPair;

  /**
   * @generated from field: sypher.v1.types.TemplateData template = 8;
   */
  template?: TemplateData;

  /**
   * @generated from field: sypher.v1.types.AccessSettings access_settings = 9;
   */
  accessSettings?: AccessSettings;

  /**
   * @generated from field: string created_at = 10;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 11;
   */
  updatedAt = "";

  /**
   * @generated from field: string account_id = 12;
   */
  accountId = "";

  /**
   * @generated from field: string username = 13;
   */
  username = "";

  /**
   * @generated from field: bool enable_notifications = 14;
   */
  enableNotifications = false;

  constructor(data?: PartialMessage<Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "respondent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "notification_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "key_pair", kind: "message", T: AsymKeyPair },
    { no: 8, name: "template", kind: "message", T: TemplateData },
    { no: 9, name: "access_settings", kind: "message", T: AccessSettings },
    { no: 10, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "enable_notifications", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Request {
    return new Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Request {
    return new Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Request {
    return new Request().fromJsonString(jsonString, options);
  }

  static equals(a: Request | PlainMessage<Request> | undefined, b: Request | PlainMessage<Request> | undefined): boolean {
    return proto3.util.equals(Request, a, b);
  }
}

/**
 * Dropzone type
 *
 * @generated from message sypher.v1.types.Dropzone
 */
export class Dropzone extends Message<Dropzone> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string dropzone_id = 2;
   */
  dropzoneId = "";

  /**
   * @generated from field: string dropzone_name = 3;
   */
  dropzoneName = "";

  /**
   * @generated from field: string dropzone_key = 4;
   */
  dropzoneKey = "";

  /**
   * @generated from field: string description = 5;
   */
  description = "";

  /**
   * @generated from field: string notification_email = 6;
   */
  notificationEmail = "";

  /**
   * @generated from field: string public_key = 7;
   */
  publicKey = "";

  /**
   * @generated from field: map<string, string> user_private_keys = 8;
   */
  userPrivateKeys: { [key: string]: string } = {};

  /**
   * @generated from field: sypher.v1.types.TemplateData template = 9;
   */
  template?: TemplateData;

  /**
   * @generated from field: sypher.v1.types.AccessSettings access_settings = 10;
   */
  accessSettings?: AccessSettings;

  /**
   * @generated from field: string created_at = 11;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 12;
   */
  updatedAt = "";

  /**
   * @generated from field: string account_id = 13;
   */
  accountId = "";

  /**
   * @generated from field: string username = 14;
   */
  username = "";

  constructor(data?: PartialMessage<Dropzone>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.Dropzone";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "dropzone_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "dropzone_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "dropzone_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "notification_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "public_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "user_private_keys", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 9, name: "template", kind: "message", T: TemplateData },
    { no: 10, name: "access_settings", kind: "message", T: AccessSettings },
    { no: 11, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Dropzone {
    return new Dropzone().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Dropzone {
    return new Dropzone().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Dropzone {
    return new Dropzone().fromJsonString(jsonString, options);
  }

  static equals(a: Dropzone | PlainMessage<Dropzone> | undefined, b: Dropzone | PlainMessage<Dropzone> | undefined): boolean {
    return proto3.util.equals(Dropzone, a, b);
  }
}

/**
 * @generated from message sypher.v1.types.Response
 */
export class Response extends Message<Response> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string response_id = 2;
   */
  responseId = "";

  /**
   * @generated from field: string respondent_id = 3;
   */
  respondentId = "";

  /**
   * @generated from field: string requester_id = 4;
   */
  requesterId = "";

  /**
   * @generated from field: string requester_type = 5;
   */
  requesterType = "";

  /**
   * @generated from field: string notification_email = 6;
   */
  notificationEmail = "";

  /**
   * @generated from field: string enc_private_key = 7;
   */
  encPrivateKey = "";

  /**
   * @generated from field: sypher.v1.types.TemplateData template = 8;
   */
  template?: TemplateData;

  /**
   * @generated from field: sypher.v1.types.AccessSettings access_settings = 9;
   */
  accessSettings?: AccessSettings;

  /**
   * @generated from field: repeated sypher.v1.types.File files = 10;
   */
  files: File[] = [];

  /**
   * @generated from field: string created_at = 11;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 12;
   */
  updatedAt = "";

  /**
   * @generated from field: string account_id = 13;
   */
  accountId = "";

  /**
   * @generated from field: string username = 14;
   */
  username = "";

  constructor(data?: PartialMessage<Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "response_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "respondent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "requester_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "requester_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "notification_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "enc_private_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "template", kind: "message", T: TemplateData },
    { no: 9, name: "access_settings", kind: "message", T: AccessSettings },
    { no: 10, name: "files", kind: "message", T: File, repeated: true },
    { no: 11, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Response {
    return new Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Response {
    return new Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Response {
    return new Response().fromJsonString(jsonString, options);
  }

  static equals(a: Response | PlainMessage<Response> | undefined, b: Response | PlainMessage<Response> | undefined): boolean {
    return proto3.util.equals(Response, a, b);
  }
}

/**
 * these are used to unmarshal files struct from template
 *
 * @generated from message sypher.v1.types.TemplateFile
 */
export class TemplateFile extends Message<TemplateFile> {
  /**
   * @generated from field: int64 max_size = 1;
   */
  maxSize = protoInt64.zero;

  /**
   * @generated from field: repeated string allowed_ext = 2;
   */
  allowedExt: string[] = [];

  constructor(data?: PartialMessage<TemplateFile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.TemplateFile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "max_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "allowed_ext", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TemplateFile {
    return new TemplateFile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TemplateFile {
    return new TemplateFile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TemplateFile {
    return new TemplateFile().fromJsonString(jsonString, options);
  }

  static equals(a: TemplateFile | PlainMessage<TemplateFile> | undefined, b: TemplateFile | PlainMessage<TemplateFile> | undefined): boolean {
    return proto3.util.equals(TemplateFile, a, b);
  }
}

/**
 * @generated from message sypher.v1.types.JsonForm
 */
export class JsonForm extends Message<JsonForm> {
  /**
   * @generated from field: bytes schema = 1;
   */
  schema = new Uint8Array(0);

  /**
   * @generated from field: bytes ui_schema = 2;
   */
  uiSchema = new Uint8Array(0);

  constructor(data?: PartialMessage<JsonForm>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.JsonForm";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "schema", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "ui_schema", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JsonForm {
    return new JsonForm().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JsonForm {
    return new JsonForm().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JsonForm {
    return new JsonForm().fromJsonString(jsonString, options);
  }

  static equals(a: JsonForm | PlainMessage<JsonForm> | undefined, b: JsonForm | PlainMessage<JsonForm> | undefined): boolean {
    return proto3.util.equals(JsonForm, a, b);
  }
}

/**
 * @generated from message sypher.v1.types.TemplateData
 */
export class TemplateData extends Message<TemplateData> {
  /**
   * @generated from field: sypher.v1.types.JsonForm json_form = 1;
   */
  jsonForm?: JsonForm;

  /**
   * @generated from field: map<string, sypher.v1.types.TemplateFile> signer_files = 2;
   */
  signerFiles: { [key: string]: TemplateFile } = {};

  constructor(data?: PartialMessage<TemplateData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.TemplateData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "json_form", kind: "message", T: JsonForm },
    { no: 2, name: "signer_files", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: TemplateFile} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TemplateData {
    return new TemplateData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TemplateData {
    return new TemplateData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TemplateData {
    return new TemplateData().fromJsonString(jsonString, options);
  }

  static equals(a: TemplateData | PlainMessage<TemplateData> | undefined, b: TemplateData | PlainMessage<TemplateData> | undefined): boolean {
    return proto3.util.equals(TemplateData, a, b);
  }
}

/**
 * template
 *
 * @generated from message sypher.v1.types.Template
 */
export class Template extends Message<Template> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: sypher.v1.types.TemplateData template = 4;
   */
  template?: TemplateData;

  /**
   * @generated from field: string created_at = 5;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 6;
   */
  updatedAt = "";

  constructor(data?: PartialMessage<Template>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.Template";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "template", kind: "message", T: TemplateData },
    { no: 5, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Template {
    return new Template().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Template {
    return new Template().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Template {
    return new Template().fromJsonString(jsonString, options);
  }

  static equals(a: Template | PlainMessage<Template> | undefined, b: Template | PlainMessage<Template> | undefined): boolean {
    return proto3.util.equals(Template, a, b);
  }
}

/**
 * @generated from message sypher.v1.types.DefaultSettings
 */
export class DefaultSettings extends Message<DefaultSettings> {
  /**
   * @generated from field: sypher.v1.types.SendDefaultSettings send_default_settings = 1;
   */
  sendDefaultSettings?: SendDefaultSettings;

  /**
   * @generated from field: sypher.v1.types.RequestDefaultSettings request_default_settings = 2;
   */
  requestDefaultSettings?: RequestDefaultSettings;

  /**
   * @generated from field: sypher.v1.types.DropzoneDefaultSettings dropzone_default_settings = 3;
   */
  dropzoneDefaultSettings?: DropzoneDefaultSettings;

  /**
   * @generated from field: string created_at = 4;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 5;
   */
  updatedAt = "";

  constructor(data?: PartialMessage<DefaultSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.DefaultSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "send_default_settings", kind: "message", T: SendDefaultSettings },
    { no: 2, name: "request_default_settings", kind: "message", T: RequestDefaultSettings },
    { no: 3, name: "dropzone_default_settings", kind: "message", T: DropzoneDefaultSettings },
    { no: 4, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DefaultSettings {
    return new DefaultSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DefaultSettings {
    return new DefaultSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DefaultSettings {
    return new DefaultSettings().fromJsonString(jsonString, options);
  }

  static equals(a: DefaultSettings | PlainMessage<DefaultSettings> | undefined, b: DefaultSettings | PlainMessage<DefaultSettings> | undefined): boolean {
    return proto3.util.equals(DefaultSettings, a, b);
  }
}

/**
 * @generated from message sypher.v1.types.SendDefaultSettings
 */
export class SendDefaultSettings extends Message<SendDefaultSettings> {
  /**
   * @generated from field: int32 days_after_create = 1;
   */
  daysAfterCreate = 0;

  /**
   * @generated from field: int32 days_after_start = 2;
   */
  daysAfterStart = 0;

  /**
   * @generated from field: sypher.v1.types.AccessSettings access_settings = 3;
   */
  accessSettings?: AccessSettings;

  /**
   * @generated from field: optional bool enable_notifications = 4;
   */
  enableNotifications?: boolean;

  constructor(data?: PartialMessage<SendDefaultSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.SendDefaultSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "days_after_create", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "days_after_start", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "access_settings", kind: "message", T: AccessSettings },
    { no: 4, name: "enable_notifications", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendDefaultSettings {
    return new SendDefaultSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendDefaultSettings {
    return new SendDefaultSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendDefaultSettings {
    return new SendDefaultSettings().fromJsonString(jsonString, options);
  }

  static equals(a: SendDefaultSettings | PlainMessage<SendDefaultSettings> | undefined, b: SendDefaultSettings | PlainMessage<SendDefaultSettings> | undefined): boolean {
    return proto3.util.equals(SendDefaultSettings, a, b);
  }
}

/**
 * @generated from message sypher.v1.types.RequestDefaultSettings
 */
export class RequestDefaultSettings extends Message<RequestDefaultSettings> {
  /**
   * @generated from field: repeated string respondent_ids = 1;
   */
  respondentIds: string[] = [];

  /**
   * @generated from field: string message = 2;
   */
  message = "";

  /**
   * @generated from field: string template_name = 3;
   */
  templateName = "";

  /**
   * @generated from field: int32 days_after_create = 4;
   */
  daysAfterCreate = 0;

  /**
   * @generated from field: int32 days_after_start = 5;
   */
  daysAfterStart = 0;

  /**
   * @generated from field: sypher.v1.types.AccessSettings access_settings = 6;
   */
  accessSettings?: AccessSettings;

  /**
   * @generated from field: optional bool enable_notifications = 7;
   */
  enableNotifications?: boolean;

  constructor(data?: PartialMessage<RequestDefaultSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.RequestDefaultSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "respondent_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "template_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "days_after_create", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "days_after_start", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "access_settings", kind: "message", T: AccessSettings },
    { no: 7, name: "enable_notifications", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestDefaultSettings {
    return new RequestDefaultSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestDefaultSettings {
    return new RequestDefaultSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestDefaultSettings {
    return new RequestDefaultSettings().fromJsonString(jsonString, options);
  }

  static equals(a: RequestDefaultSettings | PlainMessage<RequestDefaultSettings> | undefined, b: RequestDefaultSettings | PlainMessage<RequestDefaultSettings> | undefined): boolean {
    return proto3.util.equals(RequestDefaultSettings, a, b);
  }
}

/**
 * @generated from message sypher.v1.types.DropzoneDefaultSettings
 */
export class DropzoneDefaultSettings extends Message<DropzoneDefaultSettings> {
  /**
   * @generated from field: string description = 1;
   */
  description = "";

  /**
   * @generated from field: string template_name = 2;
   */
  templateName = "";

  /**
   * @generated from field: int32 days_after_create = 3;
   */
  daysAfterCreate = 0;

  /**
   * @generated from field: int32 days_after_start = 4;
   */
  daysAfterStart = 0;

  /**
   * @generated from field: sypher.v1.types.AccessSettings access_settings = 5;
   */
  accessSettings?: AccessSettings;

  constructor(data?: PartialMessage<DropzoneDefaultSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.DropzoneDefaultSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "template_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "days_after_create", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "days_after_start", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "access_settings", kind: "message", T: AccessSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DropzoneDefaultSettings {
    return new DropzoneDefaultSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DropzoneDefaultSettings {
    return new DropzoneDefaultSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DropzoneDefaultSettings {
    return new DropzoneDefaultSettings().fromJsonString(jsonString, options);
  }

  static equals(a: DropzoneDefaultSettings | PlainMessage<DropzoneDefaultSettings> | undefined, b: DropzoneDefaultSettings | PlainMessage<DropzoneDefaultSettings> | undefined): boolean {
    return proto3.util.equals(DropzoneDefaultSettings, a, b);
  }
}

/**
 * @generated from message sypher.v1.types.BackendStorage
 */
export class BackendStorage extends Message<BackendStorage> {
  /**
   * @generated from field: string account_id = 1;
   */
  accountId = "";

  /**
   * @generated from field: string bucket_name = 2;
   */
  bucketName = "";

  /**
   * @generated from field: string access_key = 3;
   */
  accessKey = "";

  /**
   * @generated from field: string secret_key = 4;
   */
  secretKey = "";

  /**
   * @generated from field: string region = 5;
   */
  region = "";

  /**
   * @generated from field: string created_at = 6;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 7;
   */
  updatedAt = "";

  /**
   * @generated from field: sypher.v1.types.StorageType storage_type = 8;
   */
  storageType = StorageType.AWS_S3;

  constructor(data?: PartialMessage<BackendStorage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.types.BackendStorage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bucket_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "access_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "secret_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "storage_type", kind: "enum", T: proto3.getEnumType(StorageType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BackendStorage {
    return new BackendStorage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BackendStorage {
    return new BackendStorage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BackendStorage {
    return new BackendStorage().fromJsonString(jsonString, options);
  }

  static equals(a: BackendStorage | PlainMessage<BackendStorage> | undefined, b: BackendStorage | PlainMessage<BackendStorage> | undefined): boolean {
    return proto3.util.equals(BackendStorage, a, b);
  }
}

