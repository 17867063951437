// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/role/role.proto (package iam.v1.role, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Policy, Role, RoleTrustStatement } from "../../types/types_pb";

/**
 * @generated from message iam.v1.role.CreateRoleRequest
 */
export class CreateRoleRequest extends Message<CreateRoleRequest> {
  /**
   * @generated from field: string role_name = 1;
   */
  roleName = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: repeated iam.v1.types.RoleTrustStatement trust_statements = 3;
   */
  trustStatements: RoleTrustStatement[] = [];

  constructor(data?: PartialMessage<CreateRoleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.role.CreateRoleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "trust_statements", kind: "message", T: RoleTrustStatement, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRoleRequest {
    return new CreateRoleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRoleRequest {
    return new CreateRoleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRoleRequest {
    return new CreateRoleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRoleRequest | PlainMessage<CreateRoleRequest> | undefined, b: CreateRoleRequest | PlainMessage<CreateRoleRequest> | undefined): boolean {
    return proto3.util.equals(CreateRoleRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.role.CreateRoleResponse
 */
export class CreateRoleResponse extends Message<CreateRoleResponse> {
  /**
   * @generated from field: iam.v1.types.Role role = 1;
   */
  role?: Role;

  constructor(data?: PartialMessage<CreateRoleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.role.CreateRoleResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role", kind: "message", T: Role },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRoleResponse {
    return new CreateRoleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRoleResponse {
    return new CreateRoleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRoleResponse {
    return new CreateRoleResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRoleResponse | PlainMessage<CreateRoleResponse> | undefined, b: CreateRoleResponse | PlainMessage<CreateRoleResponse> | undefined): boolean {
    return proto3.util.equals(CreateRoleResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.role.GetRoleRequest
 */
export class GetRoleRequest extends Message<GetRoleRequest> {
  /**
   * @generated from field: string role_dri = 1;
   */
  roleDri = "";

  constructor(data?: PartialMessage<GetRoleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.role.GetRoleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRoleRequest {
    return new GetRoleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRoleRequest {
    return new GetRoleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRoleRequest {
    return new GetRoleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRoleRequest | PlainMessage<GetRoleRequest> | undefined, b: GetRoleRequest | PlainMessage<GetRoleRequest> | undefined): boolean {
    return proto3.util.equals(GetRoleRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.role.GetRoleResponse
 */
export class GetRoleResponse extends Message<GetRoleResponse> {
  /**
   * @generated from field: iam.v1.types.Role role = 1;
   */
  role?: Role;

  constructor(data?: PartialMessage<GetRoleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.role.GetRoleResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role", kind: "message", T: Role },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRoleResponse {
    return new GetRoleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRoleResponse {
    return new GetRoleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRoleResponse {
    return new GetRoleResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRoleResponse | PlainMessage<GetRoleResponse> | undefined, b: GetRoleResponse | PlainMessage<GetRoleResponse> | undefined): boolean {
    return proto3.util.equals(GetRoleResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.role.ListRolesRequest
 */
export class ListRolesRequest extends Message<ListRolesRequest> {
  /**
   * @generated from field: string role_dri = 1;
   */
  roleDri = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 3;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListRolesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.role.ListRolesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRolesRequest {
    return new ListRolesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRolesRequest {
    return new ListRolesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRolesRequest {
    return new ListRolesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRolesRequest | PlainMessage<ListRolesRequest> | undefined, b: ListRolesRequest | PlainMessage<ListRolesRequest> | undefined): boolean {
    return proto3.util.equals(ListRolesRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.role.ListRolesResponse
 */
export class ListRolesResponse extends Message<ListRolesResponse> {
  /**
   * @generated from field: repeated iam.v1.types.Role roles = 1;
   */
  roles: Role[] = [];

  constructor(data?: PartialMessage<ListRolesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.role.ListRolesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "roles", kind: "message", T: Role, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRolesResponse {
    return new ListRolesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRolesResponse {
    return new ListRolesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRolesResponse {
    return new ListRolesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRolesResponse | PlainMessage<ListRolesResponse> | undefined, b: ListRolesResponse | PlainMessage<ListRolesResponse> | undefined): boolean {
    return proto3.util.equals(ListRolesResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.role.UpdateRoleRequest
 */
export class UpdateRoleRequest extends Message<UpdateRoleRequest> {
  /**
   * @generated from field: string role_dri = 1;
   */
  roleDri = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: repeated iam.v1.types.RoleTrustStatement trust_statements = 3;
   */
  trustStatements: RoleTrustStatement[] = [];

  constructor(data?: PartialMessage<UpdateRoleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.role.UpdateRoleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "trust_statements", kind: "message", T: RoleTrustStatement, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRoleRequest {
    return new UpdateRoleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRoleRequest {
    return new UpdateRoleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRoleRequest {
    return new UpdateRoleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRoleRequest | PlainMessage<UpdateRoleRequest> | undefined, b: UpdateRoleRequest | PlainMessage<UpdateRoleRequest> | undefined): boolean {
    return proto3.util.equals(UpdateRoleRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.role.UpdateRoleResponse
 */
export class UpdateRoleResponse extends Message<UpdateRoleResponse> {
  /**
   * @generated from field: iam.v1.types.Role role = 1;
   */
  role?: Role;

  constructor(data?: PartialMessage<UpdateRoleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.role.UpdateRoleResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role", kind: "message", T: Role },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRoleResponse {
    return new UpdateRoleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRoleResponse {
    return new UpdateRoleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRoleResponse {
    return new UpdateRoleResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRoleResponse | PlainMessage<UpdateRoleResponse> | undefined, b: UpdateRoleResponse | PlainMessage<UpdateRoleResponse> | undefined): boolean {
    return proto3.util.equals(UpdateRoleResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.role.DeleteRolesRequest
 */
export class DeleteRolesRequest extends Message<DeleteRolesRequest> {
  /**
   * @generated from field: repeated string role_dris = 1;
   */
  roleDris: string[] = [];

  constructor(data?: PartialMessage<DeleteRolesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.role.DeleteRolesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteRolesRequest {
    return new DeleteRolesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteRolesRequest {
    return new DeleteRolesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteRolesRequest {
    return new DeleteRolesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteRolesRequest | PlainMessage<DeleteRolesRequest> | undefined, b: DeleteRolesRequest | PlainMessage<DeleteRolesRequest> | undefined): boolean {
    return proto3.util.equals(DeleteRolesRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.role.AddPoliciesToRolesRequest
 */
export class AddPoliciesToRolesRequest extends Message<AddPoliciesToRolesRequest> {
  /**
   * @generated from field: repeated string role_dris = 1;
   */
  roleDris: string[] = [];

  /**
   * @generated from field: repeated string policy_dris = 2;
   */
  policyDris: string[] = [];

  constructor(data?: PartialMessage<AddPoliciesToRolesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.role.AddPoliciesToRolesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "policy_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddPoliciesToRolesRequest {
    return new AddPoliciesToRolesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddPoliciesToRolesRequest {
    return new AddPoliciesToRolesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddPoliciesToRolesRequest {
    return new AddPoliciesToRolesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddPoliciesToRolesRequest | PlainMessage<AddPoliciesToRolesRequest> | undefined, b: AddPoliciesToRolesRequest | PlainMessage<AddPoliciesToRolesRequest> | undefined): boolean {
    return proto3.util.equals(AddPoliciesToRolesRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.role.RemovePoliciesFromRolesRequest
 */
export class RemovePoliciesFromRolesRequest extends Message<RemovePoliciesFromRolesRequest> {
  /**
   * @generated from field: repeated string role_dris = 1;
   */
  roleDris: string[] = [];

  /**
   * @generated from field: repeated string policy_dris = 2;
   */
  policyDris: string[] = [];

  constructor(data?: PartialMessage<RemovePoliciesFromRolesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.role.RemovePoliciesFromRolesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "policy_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemovePoliciesFromRolesRequest {
    return new RemovePoliciesFromRolesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemovePoliciesFromRolesRequest {
    return new RemovePoliciesFromRolesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemovePoliciesFromRolesRequest {
    return new RemovePoliciesFromRolesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemovePoliciesFromRolesRequest | PlainMessage<RemovePoliciesFromRolesRequest> | undefined, b: RemovePoliciesFromRolesRequest | PlainMessage<RemovePoliciesFromRolesRequest> | undefined): boolean {
    return proto3.util.equals(RemovePoliciesFromRolesRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.role.ListRolePoliciesRequest
 */
export class ListRolePoliciesRequest extends Message<ListRolePoliciesRequest> {
  /**
   * @generated from field: string role_dri = 1;
   */
  roleDri = "";

  /**
   * @generated from field: string search = 2;
   */
  search = "";

  /**
   * @generated from field: int32 page_size = 3;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 4;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListRolePoliciesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.role.ListRolePoliciesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRolePoliciesRequest {
    return new ListRolePoliciesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRolePoliciesRequest {
    return new ListRolePoliciesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRolePoliciesRequest {
    return new ListRolePoliciesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRolePoliciesRequest | PlainMessage<ListRolePoliciesRequest> | undefined, b: ListRolePoliciesRequest | PlainMessage<ListRolePoliciesRequest> | undefined): boolean {
    return proto3.util.equals(ListRolePoliciesRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.role.ListRolePoliciesResponse
 */
export class ListRolePoliciesResponse extends Message<ListRolePoliciesResponse> {
  /**
   * @generated from field: repeated iam.v1.types.Policy policies = 1;
   */
  policies: Policy[] = [];

  constructor(data?: PartialMessage<ListRolePoliciesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.role.ListRolePoliciesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "policies", kind: "message", T: Policy, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRolePoliciesResponse {
    return new ListRolePoliciesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRolePoliciesResponse {
    return new ListRolePoliciesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRolePoliciesResponse {
    return new ListRolePoliciesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRolePoliciesResponse | PlainMessage<ListRolePoliciesResponse> | undefined, b: ListRolePoliciesResponse | PlainMessage<ListRolePoliciesResponse> | undefined): boolean {
    return proto3.util.equals(ListRolePoliciesResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.role.AssumeRoleRequest
 */
export class AssumeRoleRequest extends Message<AssumeRoleRequest> {
  /**
   * @generated from field: string role_dri = 1;
   */
  roleDri = "";

  constructor(data?: PartialMessage<AssumeRoleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.role.AssumeRoleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssumeRoleRequest {
    return new AssumeRoleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssumeRoleRequest {
    return new AssumeRoleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssumeRoleRequest {
    return new AssumeRoleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AssumeRoleRequest | PlainMessage<AssumeRoleRequest> | undefined, b: AssumeRoleRequest | PlainMessage<AssumeRoleRequest> | undefined): boolean {
    return proto3.util.equals(AssumeRoleRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.role.AssumeRoleResponse
 */
export class AssumeRoleResponse extends Message<AssumeRoleResponse> {
  /**
   * @generated from field: string role_auth_token = 1;
   */
  roleAuthToken = "";

  constructor(data?: PartialMessage<AssumeRoleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.role.AssumeRoleResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role_auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssumeRoleResponse {
    return new AssumeRoleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssumeRoleResponse {
    return new AssumeRoleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssumeRoleResponse {
    return new AssumeRoleResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AssumeRoleResponse | PlainMessage<AssumeRoleResponse> | undefined, b: AssumeRoleResponse | PlainMessage<AssumeRoleResponse> | undefined): boolean {
    return proto3.util.equals(AssumeRoleResponse, a, b);
  }
}

