import { Switch, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

export default function CustomSwitch({ children, ...rest }) {
  const size = useBreakpointValue({
    base: "md",
    xl: "sm",
    "2xl": "md",
  });
  return (
    <Switch size={size} {...rest}>
      {children}
    </Switch>
  );
}
