// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/policy/v1/service/folder/folder.proto (package policy.v1.folder, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateFolderRequest, CreateFolderResponse, DeleteFolderRequest, GetFolderObjectsRequest, GetFolderObjectsResponse, GetFolderRequest, GetFolderResponse, ListTrashRequest, ListTrashResponse, MoveFolderRequest, MoveFolderResponse, RecoverFromTrashRequest, RecoverFromTrashResponse, UpdateFolderRequest, UpdateFolderResponse } from "./folder_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service policy.v1.folder.FolderService
 */
export const FolderService = {
  typeName: "policy.v1.folder.FolderService",
  methods: {
    /**
     * @generated from rpc policy.v1.folder.FolderService.CreateFolder
     */
    createFolder: {
      name: "CreateFolder",
      I: CreateFolderRequest,
      O: CreateFolderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.folder.FolderService.GetFolder
     */
    getFolder: {
      name: "GetFolder",
      I: GetFolderRequest,
      O: GetFolderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.folder.FolderService.UpdateFolder
     */
    updateFolder: {
      name: "UpdateFolder",
      I: UpdateFolderRequest,
      O: UpdateFolderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.folder.FolderService.DeleteFolder
     */
    deleteFolder: {
      name: "DeleteFolder",
      I: DeleteFolderRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.folder.FolderService.GetFolderObjects
     */
    getFolderObjects: {
      name: "GetFolderObjects",
      I: GetFolderObjectsRequest,
      O: GetFolderObjectsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.folder.FolderService.MoveFolder
     */
    moveFolder: {
      name: "MoveFolder",
      I: MoveFolderRequest,
      O: MoveFolderResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.folder.FolderService.ListTrash
     */
    listTrash: {
      name: "ListTrash",
      I: ListTrashRequest,
      O: ListTrashResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.folder.FolderService.RecoverFromTrash
     */
    recoverFromTrash: {
      name: "RecoverFromTrash",
      I: RecoverFromTrashRequest,
      O: RecoverFromTrashResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

