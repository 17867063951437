// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/policy/policy.proto (package iam.v1.policy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreatePolicyRequest, CreatePolicyResponse, DeletePoliciesRequest, GetAlowedPolicyAttributesRequest, GetAlowedPolicyAttributesResponse, GetPolicyRequest, GetPolicyResponse, InternalListUserPoliciesRequest, InternalListUserPoliciesResponse, ListPoliciesRequest, ListPoliciesResponse, UpdatePolicyRequest, UpdatePolicyResponse } from "./policy_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service iam.v1.policy.PolicyService
 */
export const PolicyService = {
  typeName: "iam.v1.policy.PolicyService",
  methods: {
    /**
     * @generated from rpc iam.v1.policy.PolicyService.CreatePolicy
     */
    createPolicy: {
      name: "CreatePolicy",
      I: CreatePolicyRequest,
      O: CreatePolicyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.policy.PolicyService.GetPolicy
     */
    getPolicy: {
      name: "GetPolicy",
      I: GetPolicyRequest,
      O: GetPolicyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.policy.PolicyService.ListPolicies
     */
    listPolicies: {
      name: "ListPolicies",
      I: ListPoliciesRequest,
      O: ListPoliciesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.policy.PolicyService.UpdatePolicy
     */
    updatePolicy: {
      name: "UpdatePolicy",
      I: UpdatePolicyRequest,
      O: UpdatePolicyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.policy.PolicyService.DeletePolicies
     */
    deletePolicies: {
      name: "DeletePolicies",
      I: DeletePoliciesRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.policy.PolicyService.InternalListUserPolicies
     */
    internalListUserPolicies: {
      name: "InternalListUserPolicies",
      I: InternalListUserPoliciesRequest,
      O: InternalListUserPoliciesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.policy.PolicyService.GetAlowedPolicyAttributes
     */
    getAlowedPolicyAttributes: {
      name: "GetAlowedPolicyAttributes",
      I: GetAlowedPolicyAttributesRequest,
      O: GetAlowedPolicyAttributesResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

