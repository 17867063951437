// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/policy/v1/service/settings/settings.proto (package policy.v1.settings, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DeleteDefaultSettingsRequest, GetDefaultSettingsRequest, GetDefaultSettingsResponse, GetOrganizationProfileRequest, GetOrganizationProfileResponse, SetDefaultSettingsRequest, SetDefaultSettingsResponse, UpdateDefaultSettingsRequest, UpdateDefaultSettingsResponse, UpdateOrganizationProfileRequest, UpdateOrganizationProfileResponse } from "./settings_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service policy.v1.settings.SettingsService
 */
export const SettingsService = {
  typeName: "policy.v1.settings.SettingsService",
  methods: {
    /**
     * @generated from rpc policy.v1.settings.SettingsService.GetOrganizationProfile
     */
    getOrganizationProfile: {
      name: "GetOrganizationProfile",
      I: GetOrganizationProfileRequest,
      O: GetOrganizationProfileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.settings.SettingsService.UpdateOrganizationProfile
     */
    updateOrganizationProfile: {
      name: "UpdateOrganizationProfile",
      I: UpdateOrganizationProfileRequest,
      O: UpdateOrganizationProfileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.settings.SettingsService.SetDefaultSettings
     */
    setDefaultSettings: {
      name: "SetDefaultSettings",
      I: SetDefaultSettingsRequest,
      O: SetDefaultSettingsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.settings.SettingsService.GetDefaultSettings
     */
    getDefaultSettings: {
      name: "GetDefaultSettings",
      I: GetDefaultSettingsRequest,
      O: GetDefaultSettingsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.settings.SettingsService.UpdateDefaultSettings
     */
    updateDefaultSettings: {
      name: "UpdateDefaultSettings",
      I: UpdateDefaultSettingsRequest,
      O: UpdateDefaultSettingsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.settings.SettingsService.DeleteDefaultSettings
     */
    deleteDefaultSettings: {
      name: "DeleteDefaultSettings",
      I: DeleteDefaultSettingsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

