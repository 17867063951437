import { FormControl, FormLabel, HStack, Checkbox } from "@chakra-ui/react";
import { FieldProps } from "../../../../types/dnd-types";
import BaseComponent from "../BaseComponent";

export default function CheckboxField(props: FieldProps) {
  return (
    <BaseComponent keyName={props.keyName} onDelete={props.onDelete}>
      <FormControl isRequired={props.required}>
        <HStack>
          <Checkbox isDisabled={true} />
          <FormLabel m="0">{props.title}</FormLabel>
        </HStack>
      </FormControl>
    </BaseComponent>
  );
}
