import {
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { BsCheckLg } from "react-icons/bs";
import { ImCross } from "react-icons/im";

export default function CheckboxRead(props) {
  const { name, isChecked, isRequired, ...rest } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  return (
    <>
      <Flex p={2} justifyContent={"space-between"}>
        <FormControl isRequired={isRequired} width={"100%"}>
          <FormLabel
            display="flex"
            mb="0px"
            color={textColorPrimary}
            fontSize={"md"}
            fontWeight="bold"
            _hover={{ cursor: "pointer" }}
          >
            {name}
          </FormLabel>
        </FormControl>
        <Icon
          justifySelf={"flex-end"}
          boxSize={{base: "4", xl: "3", "2xl": "4"}}
          alignSelf="center"
          color={isChecked ? "brand.500" : "red.500"}
          marginRight={{ base: "0px", md: "20px" }}
          as={isChecked ? BsCheckLg : ImCross}
        />
      </Flex>
    </>
  );
}
