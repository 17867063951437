import React from "react";
import Map, { Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useColorModeValue } from "@chakra-ui/react";
import { findBounds } from "../../utils/custom-function";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiaGFycGFsLXNpbmdoIiwiYSI6ImNsOWNieHNxdTA0Ym4zdm9naTE2NW5haTcifQ.QoGr8yzui28QDWGxQzPqtg"; // Set your mapbox token her

export default function MapBox(props) {
  const { bounds, disableMarkers, style = {}, ...rest } = props;
  const mapStyles = useColorModeValue(
    "mapbox://styles/harpal-singh/cl9ccbv0w006z14pa0v4f122g",
    "mapbox://styles/harpal-singh/cl9ccbv0w006z14pa0v4f122g"
  );
  const newBounds = bounds?.length
    ? {
        bounds: findBounds(bounds),
        fitBoundsOptions:
          bounds?.length == 1
            ? {
                animate: true,
                padding: { top: 125, bottom: 125, left: 125, right: 125 },
                zoom: 15,
              }
            : {
                animate: true,
                padding: { top: 125, bottom: 125, left: 125, right: 125 },
              },
      }
    : {
        bounds: [
          [76.78890228271484, 30.73390007019043],
          [-79.51280212402344, 43.85029983520508],
        ],
        fitBoundsOptions: {
          animate: true,
          padding: { top: 125, bottom: 125, left: 125, right: 125 },
        },
      };
  return (
    <Map
      initialViewState={newBounds}
      mapStyle={mapStyles}
      style={{
        borderRadius: "20px",
        width: "100%",
        minHeight: "370px",
        ...style,
      }}
      mapboxAccessToken={MAPBOX_TOKEN}
      {...rest}
    >
      {disableMarkers
        ? null
        : bounds?.map((bound, index) => (
            <Marker
              key={index}
              color="#38A169"
              longitude={bound[0]}
              latitude={bound[1]}
              anchor="bottom"
            ></Marker>
          ))}
    </Map>
  );
}
