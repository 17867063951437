// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/sypher/v1/service/response/response.proto (package sypher.v1.response, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateResponseRequest, CreateResponseResponse, GetResponseRequest, GetResponseResponse, ListDropZoneResponsesRequest, ListDropZoneResponsesResponse, ListResponsesByRespondentRequest, ListResponsesByRespondentResponse, ListResponsesRequest, ListResponsesResponse } from "./response_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service sypher.v1.response.ResponseService
 */
export const ResponseService = {
  typeName: "sypher.v1.response.ResponseService",
  methods: {
    /**
     * @generated from rpc sypher.v1.response.ResponseService.CreateResponse
     */
    createResponse: {
      name: "CreateResponse",
      I: CreateResponseRequest,
      O: CreateResponseResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.response.ResponseService.GetResponse
     */
    getResponse: {
      name: "GetResponse",
      I: GetResponseRequest,
      O: GetResponseResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.response.ResponseService.ListResponsesByRespondent
     */
    listResponsesByRespondent: {
      name: "ListResponsesByRespondent",
      I: ListResponsesByRespondentRequest,
      O: ListResponsesByRespondentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.response.ResponseService.ListResponses
     */
    listResponses: {
      name: "ListResponses",
      I: ListResponsesRequest,
      O: ListResponsesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.response.ResponseService.ListDropZoneResponses
     */
    listDropZoneResponses: {
      name: "ListDropZoneResponses",
      I: ListDropZoneResponsesRequest,
      O: ListDropZoneResponsesResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

