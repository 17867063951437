// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/asset/v1/service/lifecycle/lifecycle.proto (package asset.v1.category, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Asset, Lifecycle, Stage, StageRelation, StageTransition } from "../../types/types_pb";

/**
 * @generated from message asset.v1.category.CreateLifecycleRequest
 */
export class CreateLifecycleRequest extends Message<CreateLifecycleRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  constructor(data?: PartialMessage<CreateLifecycleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.CreateLifecycleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateLifecycleRequest {
    return new CreateLifecycleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateLifecycleRequest {
    return new CreateLifecycleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateLifecycleRequest {
    return new CreateLifecycleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateLifecycleRequest | PlainMessage<CreateLifecycleRequest> | undefined, b: CreateLifecycleRequest | PlainMessage<CreateLifecycleRequest> | undefined): boolean {
    return proto3.util.equals(CreateLifecycleRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.CreateLifecycleResponse
 */
export class CreateLifecycleResponse extends Message<CreateLifecycleResponse> {
  /**
   * @generated from field: asset.v1.types.Lifecycle lifecycle = 1;
   */
  lifecycle?: Lifecycle;

  /**
   * @generated from field: asset.v1.types.Stage initial_stage = 2;
   */
  initialStage?: Stage;

  /**
   * @generated from field: asset.v1.types.Stage final_stage = 3;
   */
  finalStage?: Stage;

  constructor(data?: PartialMessage<CreateLifecycleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.CreateLifecycleResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lifecycle", kind: "message", T: Lifecycle },
    { no: 2, name: "initial_stage", kind: "message", T: Stage },
    { no: 3, name: "final_stage", kind: "message", T: Stage },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateLifecycleResponse {
    return new CreateLifecycleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateLifecycleResponse {
    return new CreateLifecycleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateLifecycleResponse {
    return new CreateLifecycleResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateLifecycleResponse | PlainMessage<CreateLifecycleResponse> | undefined, b: CreateLifecycleResponse | PlainMessage<CreateLifecycleResponse> | undefined): boolean {
    return proto3.util.equals(CreateLifecycleResponse, a, b);
  }
}

/**
 * @generated from message asset.v1.category.GetLifecycleRequest
 */
export class GetLifecycleRequest extends Message<GetLifecycleRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  constructor(data?: PartialMessage<GetLifecycleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.GetLifecycleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLifecycleRequest {
    return new GetLifecycleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLifecycleRequest {
    return new GetLifecycleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLifecycleRequest {
    return new GetLifecycleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetLifecycleRequest | PlainMessage<GetLifecycleRequest> | undefined, b: GetLifecycleRequest | PlainMessage<GetLifecycleRequest> | undefined): boolean {
    return proto3.util.equals(GetLifecycleRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.GetLifecycleResponse
 */
export class GetLifecycleResponse extends Message<GetLifecycleResponse> {
  /**
   * @generated from field: asset.v1.types.Lifecycle lifecycle = 1;
   */
  lifecycle?: Lifecycle;

  constructor(data?: PartialMessage<GetLifecycleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.GetLifecycleResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lifecycle", kind: "message", T: Lifecycle },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLifecycleResponse {
    return new GetLifecycleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLifecycleResponse {
    return new GetLifecycleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLifecycleResponse {
    return new GetLifecycleResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetLifecycleResponse | PlainMessage<GetLifecycleResponse> | undefined, b: GetLifecycleResponse | PlainMessage<GetLifecycleResponse> | undefined): boolean {
    return proto3.util.equals(GetLifecycleResponse, a, b);
  }
}

/**
 * @generated from message asset.v1.category.ListLifecyclesRequest
 */
export class ListLifecyclesRequest extends Message<ListLifecyclesRequest> {
  /**
   * @generated from field: string search_name = 1;
   */
  searchName = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 3;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListLifecyclesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.ListLifecyclesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "search_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListLifecyclesRequest {
    return new ListLifecyclesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListLifecyclesRequest {
    return new ListLifecyclesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListLifecyclesRequest {
    return new ListLifecyclesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListLifecyclesRequest | PlainMessage<ListLifecyclesRequest> | undefined, b: ListLifecyclesRequest | PlainMessage<ListLifecyclesRequest> | undefined): boolean {
    return proto3.util.equals(ListLifecyclesRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.ListLifecyclesResponse
 */
export class ListLifecyclesResponse extends Message<ListLifecyclesResponse> {
  /**
   * @generated from field: repeated asset.v1.types.Lifecycle lifecycles = 1;
   */
  lifecycles: Lifecycle[] = [];

  constructor(data?: PartialMessage<ListLifecyclesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.ListLifecyclesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lifecycles", kind: "message", T: Lifecycle, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListLifecyclesResponse {
    return new ListLifecyclesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListLifecyclesResponse {
    return new ListLifecyclesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListLifecyclesResponse {
    return new ListLifecyclesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListLifecyclesResponse | PlainMessage<ListLifecyclesResponse> | undefined, b: ListLifecyclesResponse | PlainMessage<ListLifecyclesResponse> | undefined): boolean {
    return proto3.util.equals(ListLifecyclesResponse, a, b);
  }
}

/**
 * @generated from message asset.v1.category.UpdateLifecycleRequest
 */
export class UpdateLifecycleRequest extends Message<UpdateLifecycleRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  constructor(data?: PartialMessage<UpdateLifecycleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.UpdateLifecycleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateLifecycleRequest {
    return new UpdateLifecycleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateLifecycleRequest {
    return new UpdateLifecycleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateLifecycleRequest {
    return new UpdateLifecycleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateLifecycleRequest | PlainMessage<UpdateLifecycleRequest> | undefined, b: UpdateLifecycleRequest | PlainMessage<UpdateLifecycleRequest> | undefined): boolean {
    return proto3.util.equals(UpdateLifecycleRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.UpdateLifecycleResponse
 */
export class UpdateLifecycleResponse extends Message<UpdateLifecycleResponse> {
  /**
   * @generated from field: asset.v1.types.Lifecycle lifecycle = 1;
   */
  lifecycle?: Lifecycle;

  constructor(data?: PartialMessage<UpdateLifecycleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.UpdateLifecycleResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lifecycle", kind: "message", T: Lifecycle },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateLifecycleResponse {
    return new UpdateLifecycleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateLifecycleResponse {
    return new UpdateLifecycleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateLifecycleResponse {
    return new UpdateLifecycleResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateLifecycleResponse | PlainMessage<UpdateLifecycleResponse> | undefined, b: UpdateLifecycleResponse | PlainMessage<UpdateLifecycleResponse> | undefined): boolean {
    return proto3.util.equals(UpdateLifecycleResponse, a, b);
  }
}

/**
 * @generated from message asset.v1.category.DeleteLifecycleRequest
 */
export class DeleteLifecycleRequest extends Message<DeleteLifecycleRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  constructor(data?: PartialMessage<DeleteLifecycleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.DeleteLifecycleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteLifecycleRequest {
    return new DeleteLifecycleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteLifecycleRequest {
    return new DeleteLifecycleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteLifecycleRequest {
    return new DeleteLifecycleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteLifecycleRequest | PlainMessage<DeleteLifecycleRequest> | undefined, b: DeleteLifecycleRequest | PlainMessage<DeleteLifecycleRequest> | undefined): boolean {
    return proto3.util.equals(DeleteLifecycleRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.CreateStageRequest
 */
export class CreateStageRequest extends Message<CreateStageRequest> {
  /**
   * @generated from field: string lifecycle_dri = 1;
   */
  lifecycleDri = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  constructor(data?: PartialMessage<CreateStageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.CreateStageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lifecycle_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateStageRequest {
    return new CreateStageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateStageRequest {
    return new CreateStageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateStageRequest {
    return new CreateStageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateStageRequest | PlainMessage<CreateStageRequest> | undefined, b: CreateStageRequest | PlainMessage<CreateStageRequest> | undefined): boolean {
    return proto3.util.equals(CreateStageRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.CreateStageResponse
 */
export class CreateStageResponse extends Message<CreateStageResponse> {
  /**
   * @generated from field: asset.v1.types.Stage stage = 1;
   */
  stage?: Stage;

  constructor(data?: PartialMessage<CreateStageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.CreateStageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stage", kind: "message", T: Stage },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateStageResponse {
    return new CreateStageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateStageResponse {
    return new CreateStageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateStageResponse {
    return new CreateStageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateStageResponse | PlainMessage<CreateStageResponse> | undefined, b: CreateStageResponse | PlainMessage<CreateStageResponse> | undefined): boolean {
    return proto3.util.equals(CreateStageResponse, a, b);
  }
}

/**
 * @generated from message asset.v1.category.ListStagesRequest
 */
export class ListStagesRequest extends Message<ListStagesRequest> {
  /**
   * @generated from field: string search_name = 1;
   */
  searchName = "";

  constructor(data?: PartialMessage<ListStagesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.ListStagesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "search_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListStagesRequest {
    return new ListStagesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListStagesRequest {
    return new ListStagesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListStagesRequest {
    return new ListStagesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListStagesRequest | PlainMessage<ListStagesRequest> | undefined, b: ListStagesRequest | PlainMessage<ListStagesRequest> | undefined): boolean {
    return proto3.util.equals(ListStagesRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.ListStagesResponse
 */
export class ListStagesResponse extends Message<ListStagesResponse> {
  /**
   * @generated from field: repeated asset.v1.types.Stage stages = 1;
   */
  stages: Stage[] = [];

  /**
   * @generated from field: repeated asset.v1.types.StageRelation relations = 2;
   */
  relations: StageRelation[] = [];

  constructor(data?: PartialMessage<ListStagesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.ListStagesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stages", kind: "message", T: Stage, repeated: true },
    { no: 2, name: "relations", kind: "message", T: StageRelation, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListStagesResponse {
    return new ListStagesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListStagesResponse {
    return new ListStagesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListStagesResponse {
    return new ListStagesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListStagesResponse | PlainMessage<ListStagesResponse> | undefined, b: ListStagesResponse | PlainMessage<ListStagesResponse> | undefined): boolean {
    return proto3.util.equals(ListStagesResponse, a, b);
  }
}

/**
 * @generated from message asset.v1.category.UpdateStageRequest
 */
export class UpdateStageRequest extends Message<UpdateStageRequest> {
  /**
   * @generated from field: string lifecycle_dri = 1;
   */
  lifecycleDri = "";

  /**
   * @generated from field: string dri = 2;
   */
  dri = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  constructor(data?: PartialMessage<UpdateStageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.UpdateStageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lifecycle_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStageRequest {
    return new UpdateStageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStageRequest {
    return new UpdateStageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStageRequest {
    return new UpdateStageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateStageRequest | PlainMessage<UpdateStageRequest> | undefined, b: UpdateStageRequest | PlainMessage<UpdateStageRequest> | undefined): boolean {
    return proto3.util.equals(UpdateStageRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.UpdateStageResponse
 */
export class UpdateStageResponse extends Message<UpdateStageResponse> {
  /**
   * @generated from field: asset.v1.types.Stage stage = 1;
   */
  stage?: Stage;

  constructor(data?: PartialMessage<UpdateStageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.UpdateStageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stage", kind: "message", T: Stage },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStageResponse {
    return new UpdateStageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStageResponse {
    return new UpdateStageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStageResponse {
    return new UpdateStageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateStageResponse | PlainMessage<UpdateStageResponse> | undefined, b: UpdateStageResponse | PlainMessage<UpdateStageResponse> | undefined): boolean {
    return proto3.util.equals(UpdateStageResponse, a, b);
  }
}

/**
 * @generated from message asset.v1.category.DeleteStageRequest
 */
export class DeleteStageRequest extends Message<DeleteStageRequest> {
  /**
   * @generated from field: string lifecycle_dri = 1;
   */
  lifecycleDri = "";

  /**
   * @generated from field: string dri = 2;
   */
  dri = "";

  constructor(data?: PartialMessage<DeleteStageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.DeleteStageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lifecycle_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteStageRequest {
    return new DeleteStageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteStageRequest {
    return new DeleteStageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteStageRequest {
    return new DeleteStageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteStageRequest | PlainMessage<DeleteStageRequest> | undefined, b: DeleteStageRequest | PlainMessage<DeleteStageRequest> | undefined): boolean {
    return proto3.util.equals(DeleteStageRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.CreateStageTransitionRequest
 */
export class CreateStageTransitionRequest extends Message<CreateStageTransitionRequest> {
  /**
   * @generated from field: string lifecycle_dri = 3;
   */
  lifecycleDri = "";

  /**
   * @generated from field: string start_stage_dri = 4;
   */
  startStageDri = "";

  /**
   * @generated from field: string end_stage_dri = 5;
   */
  endStageDri = "";

  /**
   * @generated from field: asset.v1.types.StageTransition transition = 6;
   */
  transition?: StageTransition;

  constructor(data?: PartialMessage<CreateStageTransitionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.CreateStageTransitionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "lifecycle_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "start_stage_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "end_stage_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "transition", kind: "message", T: StageTransition },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateStageTransitionRequest {
    return new CreateStageTransitionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateStageTransitionRequest {
    return new CreateStageTransitionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateStageTransitionRequest {
    return new CreateStageTransitionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateStageTransitionRequest | PlainMessage<CreateStageTransitionRequest> | undefined, b: CreateStageTransitionRequest | PlainMessage<CreateStageTransitionRequest> | undefined): boolean {
    return proto3.util.equals(CreateStageTransitionRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.UpdateStageTransitionRequest
 */
export class UpdateStageTransitionRequest extends Message<UpdateStageTransitionRequest> {
  /**
   * @generated from field: string lifecycle_dri = 3;
   */
  lifecycleDri = "";

  /**
   * @generated from field: string start_stage_dri = 4;
   */
  startStageDri = "";

  /**
   * @generated from field: string end_stage_dri = 5;
   */
  endStageDri = "";

  /**
   * @generated from field: asset.v1.types.StageTransition transition = 6;
   */
  transition?: StageTransition;

  constructor(data?: PartialMessage<UpdateStageTransitionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.UpdateStageTransitionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "lifecycle_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "start_stage_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "end_stage_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "transition", kind: "message", T: StageTransition },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStageTransitionRequest {
    return new UpdateStageTransitionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStageTransitionRequest {
    return new UpdateStageTransitionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStageTransitionRequest {
    return new UpdateStageTransitionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateStageTransitionRequest | PlainMessage<UpdateStageTransitionRequest> | undefined, b: UpdateStageTransitionRequest | PlainMessage<UpdateStageTransitionRequest> | undefined): boolean {
    return proto3.util.equals(UpdateStageTransitionRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.DeleteStageTransitionRequest
 */
export class DeleteStageTransitionRequest extends Message<DeleteStageTransitionRequest> {
  /**
   * @generated from field: string lifecycle_dri = 1;
   */
  lifecycleDri = "";

  /**
   * @generated from field: string start_stage_dri = 2;
   */
  startStageDri = "";

  /**
   * @generated from field: string end_stage_dri = 3;
   */
  endStageDri = "";

  constructor(data?: PartialMessage<DeleteStageTransitionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.DeleteStageTransitionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lifecycle_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "start_stage_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "end_stage_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteStageTransitionRequest {
    return new DeleteStageTransitionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteStageTransitionRequest {
    return new DeleteStageTransitionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteStageTransitionRequest {
    return new DeleteStageTransitionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteStageTransitionRequest | PlainMessage<DeleteStageTransitionRequest> | undefined, b: DeleteStageTransitionRequest | PlainMessage<DeleteStageTransitionRequest> | undefined): boolean {
    return proto3.util.equals(DeleteStageTransitionRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.AddAssetToStageRequest
 */
export class AddAssetToStageRequest extends Message<AddAssetToStageRequest> {
  /**
   * @generated from field: string lifecycle_dri = 1;
   */
  lifecycleDri = "";

  /**
   * @generated from field: string stage_dri = 2;
   */
  stageDri = "";

  /**
   * @generated from field: string asset_dri = 3;
   */
  assetDri = "";

  constructor(data?: PartialMessage<AddAssetToStageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.AddAssetToStageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lifecycle_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "stage_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "asset_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddAssetToStageRequest {
    return new AddAssetToStageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddAssetToStageRequest {
    return new AddAssetToStageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddAssetToStageRequest {
    return new AddAssetToStageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddAssetToStageRequest | PlainMessage<AddAssetToStageRequest> | undefined, b: AddAssetToStageRequest | PlainMessage<AddAssetToStageRequest> | undefined): boolean {
    return proto3.util.equals(AddAssetToStageRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.RemoveAssetFromStageRequest
 */
export class RemoveAssetFromStageRequest extends Message<RemoveAssetFromStageRequest> {
  /**
   * @generated from field: string lifecycle_dri = 1;
   */
  lifecycleDri = "";

  /**
   * @generated from field: string stage_dri = 2;
   */
  stageDri = "";

  /**
   * @generated from field: string asset_dri = 3;
   */
  assetDri = "";

  constructor(data?: PartialMessage<RemoveAssetFromStageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.RemoveAssetFromStageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lifecycle_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "stage_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "asset_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveAssetFromStageRequest {
    return new RemoveAssetFromStageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveAssetFromStageRequest {
    return new RemoveAssetFromStageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveAssetFromStageRequest {
    return new RemoveAssetFromStageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveAssetFromStageRequest | PlainMessage<RemoveAssetFromStageRequest> | undefined, b: RemoveAssetFromStageRequest | PlainMessage<RemoveAssetFromStageRequest> | undefined): boolean {
    return proto3.util.equals(RemoveAssetFromStageRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.ListStageAssetsRequest
 */
export class ListStageAssetsRequest extends Message<ListStageAssetsRequest> {
  /**
   * @generated from field: string lifecycle_dri = 1;
   */
  lifecycleDri = "";

  /**
   * @generated from field: string stage_dri = 2;
   */
  stageDri = "";

  /**
   * @generated from field: int32 page_size = 3;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 4;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListStageAssetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.ListStageAssetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lifecycle_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "stage_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListStageAssetsRequest {
    return new ListStageAssetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListStageAssetsRequest {
    return new ListStageAssetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListStageAssetsRequest {
    return new ListStageAssetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListStageAssetsRequest | PlainMessage<ListStageAssetsRequest> | undefined, b: ListStageAssetsRequest | PlainMessage<ListStageAssetsRequest> | undefined): boolean {
    return proto3.util.equals(ListStageAssetsRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.category.ListStageAssetsResponse
 */
export class ListStageAssetsResponse extends Message<ListStageAssetsResponse> {
  /**
   * @generated from field: repeated asset.v1.types.Asset assets = 1;
   */
  assets: Asset[] = [];

  constructor(data?: PartialMessage<ListStageAssetsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.category.ListStageAssetsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assets", kind: "message", T: Asset, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListStageAssetsResponse {
    return new ListStageAssetsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListStageAssetsResponse {
    return new ListStageAssetsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListStageAssetsResponse {
    return new ListStageAssetsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListStageAssetsResponse | PlainMessage<ListStageAssetsResponse> | undefined, b: ListStageAssetsResponse | PlainMessage<ListStageAssetsResponse> | undefined): boolean {
    return proto3.util.equals(ListStageAssetsResponse, a, b);
  }
}

