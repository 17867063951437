import React from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";

export default function Message(props) {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.700", "white");
  const textColorTertiary = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.500"
  );
  const borderColor = useColorModeValue("secondaryGray.500", "white");
  const hover = useColorModeValue(
    { bg: "secondaryGray.100" },
    { bg: "whiteAlpha.100" }
  );
  const { message, ...rest } = props;
  return (
    <>
      <Flex align="center" w="100%">
        <Text fontSize="lg" color={textColor} fontWeight="500" mb="12px">
          Message
        </Text>
      </Flex>
      <SimpleGrid
        columns="1"
        gap="40px"
        borderWidth={"1px"}
        padding="20px"
        borderRadius="10px"
        borderColor="secondaryGray.500"
        {...rest}
        maxW="100%"
        w={{ base: "100%", md: "unset" }}
      >
        <Text fontSize="lg" color={textColorSecondary} fontWeight="400">
          {message}
        </Text>
      </SimpleGrid>
    </>
  );
}
