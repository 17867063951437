import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";
import jsonPlayerData from "../assets/data-submitted.json";

export default function DataSubmittedComponent() {
  return (
    <Player
      autoplay
      keepLastFrame
      src={jsonPlayerData}
      style={{ height: "300px", width: "300px" }}
    ></Player>
  );
}
