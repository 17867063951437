// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/sypher/v1/service/request/request.proto (package sypher.v1.request, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { AccessSettings, AsymKeyPair, LastEvaluated, Request, TemplateData } from "../../types/types_pb";

/**
 * @generated from message sypher.v1.request.CreateResponseMap
 */
export class CreateResponseMap extends Message<CreateResponseMap> {
  /**
   * @generated from field: string respondent_id = 1;
   */
  respondentId = "";

  /**
   * @generated from field: string request_id = 2;
   */
  requestId = "";

  constructor(data?: PartialMessage<CreateResponseMap>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.request.CreateResponseMap";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "respondent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateResponseMap {
    return new CreateResponseMap().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateResponseMap {
    return new CreateResponseMap().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateResponseMap {
    return new CreateResponseMap().fromJsonString(jsonString, options);
  }

  static equals(a: CreateResponseMap | PlainMessage<CreateResponseMap> | undefined, b: CreateResponseMap | PlainMessage<CreateResponseMap> | undefined): boolean {
    return proto3.util.equals(CreateResponseMap, a, b);
  }
}

/**
 * not adding notification email: because email should be get from user
 *
 * @generated from message sypher.v1.request.CreateRequestRequest
 */
export class CreateRequestRequest extends Message<CreateRequestRequest> {
  /**
   * @generated from field: repeated string respondent_ids = 1;
   */
  respondentIds: string[] = [];

  /**
   * @generated from field: string label = 2;
   */
  label = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: sypher.v1.types.AsymKeyPair key_pair = 4;
   */
  keyPair?: AsymKeyPair;

  /**
   * @generated from field: sypher.v1.types.TemplateData template = 5;
   */
  template?: TemplateData;

  /**
   * @generated from field: sypher.v1.types.AccessSettings access_settings = 6;
   */
  accessSettings?: AccessSettings;

  /**
   * @generated from field: bool enable_notifications = 7;
   */
  enableNotifications = false;

  constructor(data?: PartialMessage<CreateRequestRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.request.CreateRequestRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "respondent_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "key_pair", kind: "message", T: AsymKeyPair },
    { no: 5, name: "template", kind: "message", T: TemplateData },
    { no: 6, name: "access_settings", kind: "message", T: AccessSettings },
    { no: 7, name: "enable_notifications", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRequestRequest {
    return new CreateRequestRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRequestRequest {
    return new CreateRequestRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRequestRequest {
    return new CreateRequestRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRequestRequest | PlainMessage<CreateRequestRequest> | undefined, b: CreateRequestRequest | PlainMessage<CreateRequestRequest> | undefined): boolean {
    return proto3.util.equals(CreateRequestRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.request.CreateRequestResponse
 */
export class CreateRequestResponse extends Message<CreateRequestResponse> {
  /**
   * @generated from field: repeated sypher.v1.request.CreateResponseMap request_ids = 1;
   */
  requestIds: CreateResponseMap[] = [];

  constructor(data?: PartialMessage<CreateRequestResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.request.CreateRequestResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_ids", kind: "message", T: CreateResponseMap, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRequestResponse {
    return new CreateRequestResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRequestResponse {
    return new CreateRequestResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRequestResponse {
    return new CreateRequestResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRequestResponse | PlainMessage<CreateRequestResponse> | undefined, b: CreateRequestResponse | PlainMessage<CreateRequestResponse> | undefined): boolean {
    return proto3.util.equals(CreateRequestResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.request.GetRequestRequest
 */
export class GetRequestRequest extends Message<GetRequestRequest> {
  /**
   * @generated from field: string request_dri = 1;
   */
  requestDri = "";

  constructor(data?: PartialMessage<GetRequestRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.request.GetRequestRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRequestRequest {
    return new GetRequestRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRequestRequest {
    return new GetRequestRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRequestRequest {
    return new GetRequestRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRequestRequest | PlainMessage<GetRequestRequest> | undefined, b: GetRequestRequest | PlainMessage<GetRequestRequest> | undefined): boolean {
    return proto3.util.equals(GetRequestRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.request.GetRequestResponse
 */
export class GetRequestResponse extends Message<GetRequestResponse> {
  /**
   * @generated from field: sypher.v1.types.Request request = 1;
   */
  request?: Request;

  constructor(data?: PartialMessage<GetRequestResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.request.GetRequestResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request", kind: "message", T: Request },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRequestResponse {
    return new GetRequestResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRequestResponse {
    return new GetRequestResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRequestResponse {
    return new GetRequestResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRequestResponse | PlainMessage<GetRequestResponse> | undefined, b: GetRequestResponse | PlainMessage<GetRequestResponse> | undefined): boolean {
    return proto3.util.equals(GetRequestResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.request.ListRequestsRequest
 */
export class ListRequestsRequest extends Message<ListRequestsRequest> {
  /**
   * @generated from field: string start_date = 1;
   */
  startDate = "";

  /**
   * @generated from field: string end_date = 2;
   */
  endDate = "";

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_request = 3;
   */
  lastEvaluatedRequest?: LastEvaluated;

  constructor(data?: PartialMessage<ListRequestsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.request.ListRequestsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "end_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_evaluated_request", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRequestsRequest {
    return new ListRequestsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRequestsRequest {
    return new ListRequestsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRequestsRequest {
    return new ListRequestsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRequestsRequest | PlainMessage<ListRequestsRequest> | undefined, b: ListRequestsRequest | PlainMessage<ListRequestsRequest> | undefined): boolean {
    return proto3.util.equals(ListRequestsRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.request.ListRequestsResponse
 */
export class ListRequestsResponse extends Message<ListRequestsResponse> {
  /**
   * @generated from field: repeated sypher.v1.types.Request requests = 1;
   */
  requests: Request[] = [];

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_request = 2;
   */
  lastEvaluatedRequest?: LastEvaluated;

  constructor(data?: PartialMessage<ListRequestsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.request.ListRequestsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "requests", kind: "message", T: Request, repeated: true },
    { no: 2, name: "last_evaluated_request", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRequestsResponse {
    return new ListRequestsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRequestsResponse {
    return new ListRequestsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRequestsResponse {
    return new ListRequestsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRequestsResponse | PlainMessage<ListRequestsResponse> | undefined, b: ListRequestsResponse | PlainMessage<ListRequestsResponse> | undefined): boolean {
    return proto3.util.equals(ListRequestsResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.request.UpdateRequestRequest
 */
export class UpdateRequestRequest extends Message<UpdateRequestRequest> {
  /**
   * @generated from field: string request_dri = 1;
   */
  requestDri = "";

  /**
   * @generated from field: string label = 2;
   */
  label = "";

  /**
   * @generated from field: sypher.v1.types.TemplateData template = 3;
   */
  template?: TemplateData;

  /**
   * @generated from field: sypher.v1.types.AccessSettings access_settings = 4;
   */
  accessSettings?: AccessSettings;

  /**
   * @generated from field: optional bool enable_notifications = 5;
   */
  enableNotifications?: boolean;

  constructor(data?: PartialMessage<UpdateRequestRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.request.UpdateRequestRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "template", kind: "message", T: TemplateData },
    { no: 4, name: "access_settings", kind: "message", T: AccessSettings },
    { no: 5, name: "enable_notifications", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRequestRequest {
    return new UpdateRequestRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRequestRequest {
    return new UpdateRequestRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRequestRequest {
    return new UpdateRequestRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRequestRequest | PlainMessage<UpdateRequestRequest> | undefined, b: UpdateRequestRequest | PlainMessage<UpdateRequestRequest> | undefined): boolean {
    return proto3.util.equals(UpdateRequestRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.request.AccessRequestRequest
 */
export class AccessRequestRequest extends Message<AccessRequestRequest> {
  /**
   * @generated from field: string request_dri = 1;
   */
  requestDri = "";

  constructor(data?: PartialMessage<AccessRequestRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.request.AccessRequestRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccessRequestRequest {
    return new AccessRequestRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccessRequestRequest {
    return new AccessRequestRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccessRequestRequest {
    return new AccessRequestRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AccessRequestRequest | PlainMessage<AccessRequestRequest> | undefined, b: AccessRequestRequest | PlainMessage<AccessRequestRequest> | undefined): boolean {
    return proto3.util.equals(AccessRequestRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.request.AccessRequestResponse
 */
export class AccessRequestResponse extends Message<AccessRequestResponse> {
  /**
   * @generated from field: sypher.v1.types.Request request = 1;
   */
  request?: Request;

  /**
   * @generated from field: string session = 2;
   */
  session = "";

  constructor(data?: PartialMessage<AccessRequestResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.request.AccessRequestResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request", kind: "message", T: Request },
    { no: 2, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccessRequestResponse {
    return new AccessRequestResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccessRequestResponse {
    return new AccessRequestResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccessRequestResponse {
    return new AccessRequestResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AccessRequestResponse | PlainMessage<AccessRequestResponse> | undefined, b: AccessRequestResponse | PlainMessage<AccessRequestResponse> | undefined): boolean {
    return proto3.util.equals(AccessRequestResponse, a, b);
  }
}

