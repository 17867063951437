// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/policy/v1/service/workflow/workflow.proto (package policy.v1.workflow, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Supervisor } from "../../types/types_pb";

/**
 * reviewer messages
 *
 * @generated from message policy.v1.workflow.AddReviewersRequest
 */
export class AddReviewersRequest extends Message<AddReviewersRequest> {
  /**
   * @generated from field: string document_dri = 1;
   */
  documentDri = "";

  /**
   * @generated from field: repeated string reviewer_emails = 2;
   */
  reviewerEmails: string[] = [];

  constructor(data?: PartialMessage<AddReviewersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.AddReviewersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "reviewer_emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddReviewersRequest {
    return new AddReviewersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddReviewersRequest {
    return new AddReviewersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddReviewersRequest {
    return new AddReviewersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddReviewersRequest | PlainMessage<AddReviewersRequest> | undefined, b: AddReviewersRequest | PlainMessage<AddReviewersRequest> | undefined): boolean {
    return proto3.util.equals(AddReviewersRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.AddReviewersResponse
 */
export class AddReviewersResponse extends Message<AddReviewersResponse> {
  /**
   * @generated from field: repeated policy.v1.types.Supervisor supervisors = 1;
   */
  supervisors: Supervisor[] = [];

  /**
   * @generated from field: string document_status = 2;
   */
  documentStatus = "";

  constructor(data?: PartialMessage<AddReviewersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.AddReviewersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "supervisors", kind: "message", T: Supervisor, repeated: true },
    { no: 2, name: "document_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddReviewersResponse {
    return new AddReviewersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddReviewersResponse {
    return new AddReviewersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddReviewersResponse {
    return new AddReviewersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddReviewersResponse | PlainMessage<AddReviewersResponse> | undefined, b: AddReviewersResponse | PlainMessage<AddReviewersResponse> | undefined): boolean {
    return proto3.util.equals(AddReviewersResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.RemoveReviewersRequest
 */
export class RemoveReviewersRequest extends Message<RemoveReviewersRequest> {
  /**
   * @generated from field: string document_dri = 1;
   */
  documentDri = "";

  /**
   * @generated from field: repeated string reviewer_emails = 2;
   */
  reviewerEmails: string[] = [];

  constructor(data?: PartialMessage<RemoveReviewersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.RemoveReviewersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "reviewer_emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveReviewersRequest {
    return new RemoveReviewersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveReviewersRequest {
    return new RemoveReviewersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveReviewersRequest {
    return new RemoveReviewersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveReviewersRequest | PlainMessage<RemoveReviewersRequest> | undefined, b: RemoveReviewersRequest | PlainMessage<RemoveReviewersRequest> | undefined): boolean {
    return proto3.util.equals(RemoveReviewersRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.RemoveReviewersResponse
 */
export class RemoveReviewersResponse extends Message<RemoveReviewersResponse> {
  constructor(data?: PartialMessage<RemoveReviewersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.RemoveReviewersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveReviewersResponse {
    return new RemoveReviewersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveReviewersResponse {
    return new RemoveReviewersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveReviewersResponse {
    return new RemoveReviewersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveReviewersResponse | PlainMessage<RemoveReviewersResponse> | undefined, b: RemoveReviewersResponse | PlainMessage<RemoveReviewersResponse> | undefined): boolean {
    return proto3.util.equals(RemoveReviewersResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.ListReviewersRequest
 */
export class ListReviewersRequest extends Message<ListReviewersRequest> {
  /**
   * @generated from field: string document_dri = 1;
   */
  documentDri = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 3;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListReviewersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.ListReviewersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListReviewersRequest {
    return new ListReviewersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListReviewersRequest {
    return new ListReviewersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListReviewersRequest {
    return new ListReviewersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListReviewersRequest | PlainMessage<ListReviewersRequest> | undefined, b: ListReviewersRequest | PlainMessage<ListReviewersRequest> | undefined): boolean {
    return proto3.util.equals(ListReviewersRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.ListReviewersResponse
 */
export class ListReviewersResponse extends Message<ListReviewersResponse> {
  /**
   * @generated from field: repeated policy.v1.types.Supervisor supervisors = 1;
   */
  supervisors: Supervisor[] = [];

  constructor(data?: PartialMessage<ListReviewersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.ListReviewersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "supervisors", kind: "message", T: Supervisor, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListReviewersResponse {
    return new ListReviewersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListReviewersResponse {
    return new ListReviewersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListReviewersResponse {
    return new ListReviewersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListReviewersResponse | PlainMessage<ListReviewersResponse> | undefined, b: ListReviewersResponse | PlainMessage<ListReviewersResponse> | undefined): boolean {
    return proto3.util.equals(ListReviewersResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.SendForReviewRequest
 */
export class SendForReviewRequest extends Message<SendForReviewRequest> {
  /**
   * @generated from field: string document_dri = 1;
   */
  documentDri = "";

  /**
   * @generated from field: string document_name = 2;
   */
  documentName = "";

  constructor(data?: PartialMessage<SendForReviewRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.SendForReviewRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "document_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendForReviewRequest {
    return new SendForReviewRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendForReviewRequest {
    return new SendForReviewRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendForReviewRequest {
    return new SendForReviewRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendForReviewRequest | PlainMessage<SendForReviewRequest> | undefined, b: SendForReviewRequest | PlainMessage<SendForReviewRequest> | undefined): boolean {
    return proto3.util.equals(SendForReviewRequest, a, b);
  }
}

/**
 * approver messates
 *
 * @generated from message policy.v1.workflow.AddApproversRequest
 */
export class AddApproversRequest extends Message<AddApproversRequest> {
  /**
   * @generated from field: string document_dri = 1;
   */
  documentDri = "";

  /**
   * @generated from field: repeated string approver_emails = 2;
   */
  approverEmails: string[] = [];

  constructor(data?: PartialMessage<AddApproversRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.AddApproversRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "approver_emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddApproversRequest {
    return new AddApproversRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddApproversRequest {
    return new AddApproversRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddApproversRequest {
    return new AddApproversRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddApproversRequest | PlainMessage<AddApproversRequest> | undefined, b: AddApproversRequest | PlainMessage<AddApproversRequest> | undefined): boolean {
    return proto3.util.equals(AddApproversRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.AddApproversResponse
 */
export class AddApproversResponse extends Message<AddApproversResponse> {
  /**
   * @generated from field: repeated policy.v1.types.Supervisor supervisors = 1;
   */
  supervisors: Supervisor[] = [];

  /**
   * @generated from field: string document_status = 2;
   */
  documentStatus = "";

  constructor(data?: PartialMessage<AddApproversResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.AddApproversResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "supervisors", kind: "message", T: Supervisor, repeated: true },
    { no: 2, name: "document_status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddApproversResponse {
    return new AddApproversResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddApproversResponse {
    return new AddApproversResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddApproversResponse {
    return new AddApproversResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddApproversResponse | PlainMessage<AddApproversResponse> | undefined, b: AddApproversResponse | PlainMessage<AddApproversResponse> | undefined): boolean {
    return proto3.util.equals(AddApproversResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.RemoveApproversRequest
 */
export class RemoveApproversRequest extends Message<RemoveApproversRequest> {
  /**
   * @generated from field: string document_dri = 1;
   */
  documentDri = "";

  /**
   * @generated from field: repeated string approver_emails = 2;
   */
  approverEmails: string[] = [];

  constructor(data?: PartialMessage<RemoveApproversRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.RemoveApproversRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "approver_emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveApproversRequest {
    return new RemoveApproversRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveApproversRequest {
    return new RemoveApproversRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveApproversRequest {
    return new RemoveApproversRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveApproversRequest | PlainMessage<RemoveApproversRequest> | undefined, b: RemoveApproversRequest | PlainMessage<RemoveApproversRequest> | undefined): boolean {
    return proto3.util.equals(RemoveApproversRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.RemoveApproversResponse
 */
export class RemoveApproversResponse extends Message<RemoveApproversResponse> {
  constructor(data?: PartialMessage<RemoveApproversResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.RemoveApproversResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveApproversResponse {
    return new RemoveApproversResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveApproversResponse {
    return new RemoveApproversResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveApproversResponse {
    return new RemoveApproversResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveApproversResponse | PlainMessage<RemoveApproversResponse> | undefined, b: RemoveApproversResponse | PlainMessage<RemoveApproversResponse> | undefined): boolean {
    return proto3.util.equals(RemoveApproversResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.ListApproversRequest
 */
export class ListApproversRequest extends Message<ListApproversRequest> {
  /**
   * @generated from field: string document_dri = 1;
   */
  documentDri = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 3;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListApproversRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.ListApproversRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListApproversRequest {
    return new ListApproversRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListApproversRequest {
    return new ListApproversRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListApproversRequest {
    return new ListApproversRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListApproversRequest | PlainMessage<ListApproversRequest> | undefined, b: ListApproversRequest | PlainMessage<ListApproversRequest> | undefined): boolean {
    return proto3.util.equals(ListApproversRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.ListApproversResponse
 */
export class ListApproversResponse extends Message<ListApproversResponse> {
  /**
   * @generated from field: repeated policy.v1.types.Supervisor supervisors = 1;
   */
  supervisors: Supervisor[] = [];

  constructor(data?: PartialMessage<ListApproversResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.ListApproversResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "supervisors", kind: "message", T: Supervisor, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListApproversResponse {
    return new ListApproversResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListApproversResponse {
    return new ListApproversResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListApproversResponse {
    return new ListApproversResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListApproversResponse | PlainMessage<ListApproversResponse> | undefined, b: ListApproversResponse | PlainMessage<ListApproversResponse> | undefined): boolean {
    return proto3.util.equals(ListApproversResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.SendForApprovalRequest
 */
export class SendForApprovalRequest extends Message<SendForApprovalRequest> {
  /**
   * @generated from field: string document_dri = 1;
   */
  documentDri = "";

  /**
   * @generated from field: string document_name = 2;
   */
  documentName = "";

  constructor(data?: PartialMessage<SendForApprovalRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.SendForApprovalRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "document_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendForApprovalRequest {
    return new SendForApprovalRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendForApprovalRequest {
    return new SendForApprovalRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendForApprovalRequest {
    return new SendForApprovalRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendForApprovalRequest | PlainMessage<SendForApprovalRequest> | undefined, b: SendForApprovalRequest | PlainMessage<SendForApprovalRequest> | undefined): boolean {
    return proto3.util.equals(SendForApprovalRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.NotifySupervisorsRequest
 */
export class NotifySupervisorsRequest extends Message<NotifySupervisorsRequest> {
  /**
   * @generated from field: string document_dri = 1;
   */
  documentDri = "";

  /**
   * @generated from field: repeated policy.v1.types.Supervisor supervisors = 2;
   */
  supervisors: Supervisor[] = [];

  constructor(data?: PartialMessage<NotifySupervisorsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.NotifySupervisorsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "supervisors", kind: "message", T: Supervisor, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NotifySupervisorsRequest {
    return new NotifySupervisorsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NotifySupervisorsRequest {
    return new NotifySupervisorsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NotifySupervisorsRequest {
    return new NotifySupervisorsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NotifySupervisorsRequest | PlainMessage<NotifySupervisorsRequest> | undefined, b: NotifySupervisorsRequest | PlainMessage<NotifySupervisorsRequest> | undefined): boolean {
    return proto3.util.equals(NotifySupervisorsRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.ReviewDocumentRequest
 */
export class ReviewDocumentRequest extends Message<ReviewDocumentRequest> {
  /**
   * context will have a principal-- can get email from it
   *
   * @generated from field: string document_dri = 1;
   */
  documentDri = "";

  constructor(data?: PartialMessage<ReviewDocumentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.ReviewDocumentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReviewDocumentRequest {
    return new ReviewDocumentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReviewDocumentRequest {
    return new ReviewDocumentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReviewDocumentRequest {
    return new ReviewDocumentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ReviewDocumentRequest | PlainMessage<ReviewDocumentRequest> | undefined, b: ReviewDocumentRequest | PlainMessage<ReviewDocumentRequest> | undefined): boolean {
    return proto3.util.equals(ReviewDocumentRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.ReviewDocumentResponse
 */
export class ReviewDocumentResponse extends Message<ReviewDocumentResponse> {
  /**
   * @generated from field: string status = 1;
   */
  status = "";

  constructor(data?: PartialMessage<ReviewDocumentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.ReviewDocumentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReviewDocumentResponse {
    return new ReviewDocumentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReviewDocumentResponse {
    return new ReviewDocumentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReviewDocumentResponse {
    return new ReviewDocumentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ReviewDocumentResponse | PlainMessage<ReviewDocumentResponse> | undefined, b: ReviewDocumentResponse | PlainMessage<ReviewDocumentResponse> | undefined): boolean {
    return proto3.util.equals(ReviewDocumentResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.ApproveDocumentRequest
 */
export class ApproveDocumentRequest extends Message<ApproveDocumentRequest> {
  /**
   * @generated from field: string document_dri = 1;
   */
  documentDri = "";

  constructor(data?: PartialMessage<ApproveDocumentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.ApproveDocumentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApproveDocumentRequest {
    return new ApproveDocumentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApproveDocumentRequest {
    return new ApproveDocumentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApproveDocumentRequest {
    return new ApproveDocumentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ApproveDocumentRequest | PlainMessage<ApproveDocumentRequest> | undefined, b: ApproveDocumentRequest | PlainMessage<ApproveDocumentRequest> | undefined): boolean {
    return proto3.util.equals(ApproveDocumentRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.ApproveDocumentResponse
 */
export class ApproveDocumentResponse extends Message<ApproveDocumentResponse> {
  /**
   * @generated from field: string status = 1;
   */
  status = "";

  constructor(data?: PartialMessage<ApproveDocumentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.ApproveDocumentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApproveDocumentResponse {
    return new ApproveDocumentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApproveDocumentResponse {
    return new ApproveDocumentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApproveDocumentResponse {
    return new ApproveDocumentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ApproveDocumentResponse | PlainMessage<ApproveDocumentResponse> | undefined, b: ApproveDocumentResponse | PlainMessage<ApproveDocumentResponse> | undefined): boolean {
    return proto3.util.equals(ApproveDocumentResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.PublishDocumentRequest
 */
export class PublishDocumentRequest extends Message<PublishDocumentRequest> {
  /**
   * @generated from field: string document_dri = 1;
   */
  documentDri = "";

  /**
   * @generated from field: string effective_date = 2;
   */
  effectiveDate = "";

  /**
   * @generated from field: int64 markdown_size = 3;
   */
  markdownSize = protoInt64.zero;

  constructor(data?: PartialMessage<PublishDocumentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.PublishDocumentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "effective_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "markdown_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PublishDocumentRequest {
    return new PublishDocumentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PublishDocumentRequest {
    return new PublishDocumentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PublishDocumentRequest {
    return new PublishDocumentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PublishDocumentRequest | PlainMessage<PublishDocumentRequest> | undefined, b: PublishDocumentRequest | PlainMessage<PublishDocumentRequest> | undefined): boolean {
    return proto3.util.equals(PublishDocumentRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.workflow.PublishDocumentResponse
 */
export class PublishDocumentResponse extends Message<PublishDocumentResponse> {
  /**
   * @generated from field: string status = 1;
   */
  status = "";

  /**
   * @generated from field: string markdown_signed_url = 2;
   */
  markdownSignedUrl = "";

  constructor(data?: PartialMessage<PublishDocumentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.workflow.PublishDocumentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "markdown_signed_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PublishDocumentResponse {
    return new PublishDocumentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PublishDocumentResponse {
    return new PublishDocumentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PublishDocumentResponse {
    return new PublishDocumentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PublishDocumentResponse | PlainMessage<PublishDocumentResponse> | undefined, b: PublishDocumentResponse | PlainMessage<PublishDocumentResponse> | undefined): boolean {
    return proto3.util.equals(PublishDocumentResponse, a, b);
  }
}

