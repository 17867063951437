// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/sypher/v1/service/response/response.proto (package sypher.v1.response, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { File, LastEvaluated, Response } from "../../types/types_pb";

/**
 * @generated from message sypher.v1.response.CreateResponseRequest
 */
export class CreateResponseRequest extends Message<CreateResponseRequest> {
  /**
   * @generated from field: repeated sypher.v1.types.File files = 1;
   */
  files: File[] = [];

  /**
   * @generated from field: string session = 2;
   */
  session = "";

  /**
   * @generated from field: string start_date = 3;
   */
  startDate = "";

  /**
   * @generated from field: string end_date = 4;
   */
  endDate = "";

  /**
   * @generated from field: int32 access_limit = 5;
   */
  accessLimit = 0;

  constructor(data?: PartialMessage<CreateResponseRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.response.CreateResponseRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "files", kind: "message", T: File, repeated: true },
    { no: 2, name: "session", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "start_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "end_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "access_limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateResponseRequest {
    return new CreateResponseRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateResponseRequest {
    return new CreateResponseRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateResponseRequest {
    return new CreateResponseRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateResponseRequest | PlainMessage<CreateResponseRequest> | undefined, b: CreateResponseRequest | PlainMessage<CreateResponseRequest> | undefined): boolean {
    return proto3.util.equals(CreateResponseRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.response.CreateResponseResponse
 */
export class CreateResponseResponse extends Message<CreateResponseResponse> {
  /**
   * @generated from field: map<string, string> signed_urls = 1;
   */
  signedUrls: { [key: string]: string } = {};

  constructor(data?: PartialMessage<CreateResponseResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.response.CreateResponseResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "signed_urls", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateResponseResponse {
    return new CreateResponseResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateResponseResponse {
    return new CreateResponseResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateResponseResponse {
    return new CreateResponseResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateResponseResponse | PlainMessage<CreateResponseResponse> | undefined, b: CreateResponseResponse | PlainMessage<CreateResponseResponse> | undefined): boolean {
    return proto3.util.equals(CreateResponseResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.response.GetResponseRequest
 */
export class GetResponseRequest extends Message<GetResponseRequest> {
  /**
   * @generated from field: string requester_id = 1;
   */
  requesterId = "";

  /**
   * @generated from field: string respondent_id = 2;
   */
  respondentId = "";

  constructor(data?: PartialMessage<GetResponseRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.response.GetResponseRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "requester_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "respondent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetResponseRequest {
    return new GetResponseRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetResponseRequest {
    return new GetResponseRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetResponseRequest {
    return new GetResponseRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetResponseRequest | PlainMessage<GetResponseRequest> | undefined, b: GetResponseRequest | PlainMessage<GetResponseRequest> | undefined): boolean {
    return proto3.util.equals(GetResponseRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.response.GetResponseResponse
 */
export class GetResponseResponse extends Message<GetResponseResponse> {
  /**
   * @generated from field: sypher.v1.types.Response response = 1;
   */
  response?: Response;

  /**
   * @generated from field: map<string, string> signed_urls = 2;
   */
  signedUrls: { [key: string]: string } = {};

  constructor(data?: PartialMessage<GetResponseResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.response.GetResponseResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "response", kind: "message", T: Response },
    { no: 2, name: "signed_urls", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetResponseResponse {
    return new GetResponseResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetResponseResponse {
    return new GetResponseResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetResponseResponse {
    return new GetResponseResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetResponseResponse | PlainMessage<GetResponseResponse> | undefined, b: GetResponseResponse | PlainMessage<GetResponseResponse> | undefined): boolean {
    return proto3.util.equals(GetResponseResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.response.ListResponsesByRespondentRequest
 */
export class ListResponsesByRespondentRequest extends Message<ListResponsesByRespondentRequest> {
  /**
   * @generated from field: string respondent_id = 1;
   */
  respondentId = "";

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_response = 2;
   */
  lastEvaluatedResponse?: LastEvaluated;

  constructor(data?: PartialMessage<ListResponsesByRespondentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.response.ListResponsesByRespondentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "respondent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_evaluated_response", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListResponsesByRespondentRequest {
    return new ListResponsesByRespondentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListResponsesByRespondentRequest {
    return new ListResponsesByRespondentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListResponsesByRespondentRequest {
    return new ListResponsesByRespondentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListResponsesByRespondentRequest | PlainMessage<ListResponsesByRespondentRequest> | undefined, b: ListResponsesByRespondentRequest | PlainMessage<ListResponsesByRespondentRequest> | undefined): boolean {
    return proto3.util.equals(ListResponsesByRespondentRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.response.ListResponsesByRespondentResponse
 */
export class ListResponsesByRespondentResponse extends Message<ListResponsesByRespondentResponse> {
  /**
   * @generated from field: repeated sypher.v1.types.Response responses = 1;
   */
  responses: Response[] = [];

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_response = 2;
   */
  lastEvaluatedResponse?: LastEvaluated;

  constructor(data?: PartialMessage<ListResponsesByRespondentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.response.ListResponsesByRespondentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "responses", kind: "message", T: Response, repeated: true },
    { no: 2, name: "last_evaluated_response", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListResponsesByRespondentResponse {
    return new ListResponsesByRespondentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListResponsesByRespondentResponse {
    return new ListResponsesByRespondentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListResponsesByRespondentResponse {
    return new ListResponsesByRespondentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListResponsesByRespondentResponse | PlainMessage<ListResponsesByRespondentResponse> | undefined, b: ListResponsesByRespondentResponse | PlainMessage<ListResponsesByRespondentResponse> | undefined): boolean {
    return proto3.util.equals(ListResponsesByRespondentResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.response.ListResponsesRequest
 */
export class ListResponsesRequest extends Message<ListResponsesRequest> {
  /**
   * @generated from field: string start_date = 1;
   */
  startDate = "";

  /**
   * @generated from field: string end_date = 2;
   */
  endDate = "";

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_response = 3;
   */
  lastEvaluatedResponse?: LastEvaluated;

  constructor(data?: PartialMessage<ListResponsesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.response.ListResponsesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "end_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_evaluated_response", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListResponsesRequest {
    return new ListResponsesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListResponsesRequest {
    return new ListResponsesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListResponsesRequest {
    return new ListResponsesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListResponsesRequest | PlainMessage<ListResponsesRequest> | undefined, b: ListResponsesRequest | PlainMessage<ListResponsesRequest> | undefined): boolean {
    return proto3.util.equals(ListResponsesRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.response.ListResponsesResponse
 */
export class ListResponsesResponse extends Message<ListResponsesResponse> {
  /**
   * @generated from field: repeated sypher.v1.types.Response responses = 1;
   */
  responses: Response[] = [];

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_response = 2;
   */
  lastEvaluatedResponse?: LastEvaluated;

  constructor(data?: PartialMessage<ListResponsesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.response.ListResponsesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "responses", kind: "message", T: Response, repeated: true },
    { no: 2, name: "last_evaluated_response", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListResponsesResponse {
    return new ListResponsesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListResponsesResponse {
    return new ListResponsesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListResponsesResponse {
    return new ListResponsesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListResponsesResponse | PlainMessage<ListResponsesResponse> | undefined, b: ListResponsesResponse | PlainMessage<ListResponsesResponse> | undefined): boolean {
    return proto3.util.equals(ListResponsesResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.response.ListDropZoneResponsesRequest
 */
export class ListDropZoneResponsesRequest extends Message<ListDropZoneResponsesRequest> {
  /**
   * @generated from field: string dropzone_dri = 1;
   */
  dropzoneDri = "";

  /**
   * @generated from field: string start_date = 2;
   */
  startDate = "";

  /**
   * @generated from field: string end_date = 3;
   */
  endDate = "";

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_response = 4;
   */
  lastEvaluatedResponse?: LastEvaluated;

  constructor(data?: PartialMessage<ListDropZoneResponsesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.response.ListDropZoneResponsesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dropzone_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "start_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "end_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last_evaluated_response", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDropZoneResponsesRequest {
    return new ListDropZoneResponsesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDropZoneResponsesRequest {
    return new ListDropZoneResponsesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDropZoneResponsesRequest {
    return new ListDropZoneResponsesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListDropZoneResponsesRequest | PlainMessage<ListDropZoneResponsesRequest> | undefined, b: ListDropZoneResponsesRequest | PlainMessage<ListDropZoneResponsesRequest> | undefined): boolean {
    return proto3.util.equals(ListDropZoneResponsesRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.response.ListDropZoneResponsesResponse
 */
export class ListDropZoneResponsesResponse extends Message<ListDropZoneResponsesResponse> {
  /**
   * @generated from field: repeated sypher.v1.types.Response responses = 1;
   */
  responses: Response[] = [];

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_response = 2;
   */
  lastEvaluatedResponse?: LastEvaluated;

  constructor(data?: PartialMessage<ListDropZoneResponsesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.response.ListDropZoneResponsesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "responses", kind: "message", T: Response, repeated: true },
    { no: 2, name: "last_evaluated_response", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDropZoneResponsesResponse {
    return new ListDropZoneResponsesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDropZoneResponsesResponse {
    return new ListDropZoneResponsesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDropZoneResponsesResponse {
    return new ListDropZoneResponsesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListDropZoneResponsesResponse | PlainMessage<ListDropZoneResponsesResponse> | undefined, b: ListDropZoneResponsesResponse | PlainMessage<ListDropZoneResponsesResponse> | undefined): boolean {
    return proto3.util.equals(ListDropZoneResponsesResponse, a, b);
  }
}

