// Chakra imports
import {
    Box,
    Button,
    Flex,
    Icon,
    Image,
    Link,
    Text,
    useColorModeValue,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "../card/Card";
  import React from "react";
  // Assets
  import { HiExternalLink } from "react-icons/hi";
  import {Link as RouterLink, useNavigate} from 'react-router-dom'
  
  export default function Feature(props) {
    const { title, subtitle, ranking, link, linkText, image, ...rest } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "gray.400";
    const brandColor = useColorModeValue("brand.500", "white");
    const bg = useColorModeValue("white", "navy.700");
    const navigate = useNavigate();
    const onClick = () => navigate(link)
    return (
      <Card bg={bg} {...rest} p='14px'>
        <Flex align='center' direction={{ base: "column", md: "row" }}>
          <Image h='80px' w='80px' src={image} borderRadius='8px' me='20px' />
          <Box mt={{ base: "10px", md: "0" }}>
            <Text
              color={textColorPrimary}
              fontWeight='500'
              fontSize='md'
              mb='4px'>
              {title}
            </Text>
            <Text
              fontWeight='500'
              color={textColorSecondary}
              fontSize='sm'
              me='4px'>
              {subtitle} •{" "}
              <Button variant={"link"} fontWeight='500' color={brandColor} onClick={onClick} fontSize='sm'>
                {linkText}
              </Button>
            </Text>
          </Box>
          <Link
            onClick={onClick}
            variant='no-hover'
            me='16px'
            ms='auto'
            p='0px !important'>
            <Icon as={HiExternalLink} color='secondaryGray.500' h='18px' w='18px' onClick={onClick}/>
          </Link>
        </Flex>
      </Card>
    );
  }
  