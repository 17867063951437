import {
  Box,
  Divider,
  Flex,
  Stack,
  StackDivider,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import Card from "../../card/Card";
import CustomText from "../../custom-styled-components/CustomText";

export default function MessageContent({ message, username, hide, ...rest }) {
  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorLink = useColorModeValue("blue.500", "white");
  const textColorSecondary = "gray.400";
  return (
    <Card {...rest}>
      <Flex
        direction="column"
        mb={{ base: "25px", xl: "5px", "2xl": "25px" }}
        ms="10px"
      >
        <Text
          fontSize={{ base: "xl", xl: "sm", "2xl": "xl" }}
          color={textColorPrimary}
          fontWeight="bold"
        >
          {hide ? "Anonymous" : username}
        </Text>
        <CustomText color={textColorSecondary}>Note from sender.</CustomText>
      </Flex>
      <Box overflow="auto">
        <CustomText
          color={textColorPrimary}
          fontWeight="400"
          ms="10px"
          mb="50px"
        >
          {message}
        </CustomText>
      </Box>
    </Card>
  );
}
