import { ChakraProvider, ChakraTheme } from "@chakra-ui/react";
import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragStartEvent,
  KeyboardSensor,
  DragOverlay,
  MouseSensor,
  PointerSensor,
  UniqueIdentifier,
  rectIntersection,
  useSensor,
  useSensors,
  Active,
  pointerWithin,
} from "@dnd-kit/core";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useJSONBuilderContext } from "../../context/dnd-context";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { snapCenterToCursor } from "@dnd-kit/modifiers";
import { Item } from "../../types/dnd-types";
import JsonBuilderContext from "../JsonBuilderContextWrapper";
import { JsonSchema, UISchemaElement } from "@reactjsonforms/core";
import { convertSchemas } from "../../utils/util-function";

interface OverlayComponentType {
  active: Active;
}

interface OnChangeSchemaFunctionParams {
  schema: JsonSchema;
  uischema: UISchemaElement;
}

interface EditorContextProp extends React.PropsWithChildren {
  initialData?: { schema: JsonSchema; uischema: UISchemaElement };
  renderOverlay?: (props: OverlayComponentType) => React.ReactNode;
  onChange?: (data: OnChangeSchemaFunctionParams) => void;
  chakraTheme?: ChakraTheme;
}

function Component({
  children,
  renderOverlay,
  onChange,
  initialData,
  chakraTheme,
}: EditorContextProp) {
  const [isInitialized, setInitialized] = useState(false);
  const {
    uiSchema,
    jsonSchema,
    active,
    setActive,
    items,
    setItems,
    setSelectedItem,
    clonedItems,
    setClonedItems,
    setSidebardKey,
  } = useJSONBuilderContext();

  useEffect(() => {
    if (initialData?.schema && initialData.uischema) {
      const initialItems = convertSchemas(initialData?.schema, {
        elements: [initialData.uischema],
        type: "",
      });
      setItems(initialItems);
    }
  }, [initialData]);

  const recentlyMovedToNewContainer = useRef(false);

  const sensors = useSensors(
    // useSensor(MouseSensor, {
    //   activationConstraint: {
    //     delay: 100,
    //     tolerance: 50,
    //   },
    // }),
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 200,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (isInitialized) {
      onChange?.({ schema: jsonSchema as JsonSchema, uischema: uiSchema });
    } else {
      setInitialized(true);
    }
  }, [jsonSchema, uiSchema]);

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  function findItem(id: UniqueIdentifier): Item | undefined {
    return items.find((item) => item.id === id);
  }

  function findItemIndex(id: UniqueIdentifier): number {
    return items.findIndex((item) => item.id === id);
  }

  const onDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActive(active);
    const activeItem = findItem(active.id);
    if (activeItem) setSelectedItem(activeItem);
    setClonedItems(items);
  };

  const filterUniqueObjects = (arr: Item[]): Item[] => {
    const seenIds = new Set();
    return arr.filter((obj) => {
      if (!seenIds.has(obj.id)) {
        seenIds.add(obj.id);
        return true;
      }
      return false;
    });
  };

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    const overId = over?.id;

    if (overId == null) {
      setActive(undefined);
      return;
    }
    const activeIndex = items.findIndex((item) => item.id === active.id);
    const overIndex = items.findIndex((item) => item.id === overId);

    if (activeIndex !== overIndex) {
      setItems((items) => {
        return arrayMove(items, activeIndex, overIndex);
      });
    }

    setActive(undefined);
    setSidebardKey(Date.now());
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems);
    }

    setActive(undefined);
    setClonedItems(undefined);
  };

  const handleDragOver = ({ over }: DragMoveEvent) => {
    const overId = over?.id;
    if (!active) return;

    if (overId == null) {
      return;
    }
    if (!over) return;
    //Not in the list of items of editor.
    const overItem = findItem(overId);
    //Not in the list of items of editor.
    const activeItem = findItem(active?.id);

    /*
        New item adding. 
        If overitem is not in the list, it means that is the main container.
        if no activeItem then it means that needs to be added to the main container first time.
        */
    if (!overItem && !activeItem) {
      setItems((items) => [
        ...items,
        {
          ...active.data.current,
          fromPallete: false,
          parentId: over?.id,
        } as unknown as Item,
      ]);
      return;
    }

    // if no overItem. It means it is the main container. And activeItem should be moved to the main container.
    if (!overItem && activeItem) {
      setItems((items) => {
        return items.map((item) => {
          if (item.id === activeItem.id) {
            item.parentId = over?.id;
          }
          return item;
        });
      });
    }

    if (overItem && !activeItem) {
      let overContainerID = overItem.id;
      if (!overItem.isLayoutElement && overItem.parentId) {
        overContainerID = overItem.parentId;
      }
      setItems((items) => [
        ...items,
        {
          ...active.data.current,
          fromPallete: false,
          parentId: overContainerID,
        } as unknown as Item,
      ]);
      return;
    }

    if (overItem?.id === activeItem?.id) return;

    /*
        Conditions: 
        1) If overItem and activeItems is in the the list
        2) overItems is a container
        3) activeItem is not the child of overItem.
        Then move the activeItem to the container.
        */
    if (overItem && activeItem) {
      let overContainerID = overId;
      if (!overItem.isLayoutElement && overItem.parentId) {
        overContainerID = overItem.parentId;
      }
      if (overContainerID === activeItem.parentId) return;
      setItems((items) => {
        const overIndex = findItemIndex(overItem.id);
        const activeIndex = findItemIndex(active.id);
        const newItems = items.map((item) => {
          if (item.id === activeItem.id) {
            item.parentId = overContainerID;
          }
          return item;
        });

        return arrayMove(newItems, activeIndex, overIndex);
      });
      return;
    }

    if (overItem?.isLayoutElement) {
      if (
        overItem.id !== activeItem?.parentId &&
        overItem.id !== activeItem?.id
      ) {
        setItems((items) => {
          const overItems = items;
          const overIndex = findItemIndex(overId);

          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top >
              over.rect.top + over.rect.height;

          const modifier = isBelowOverItem ? 1 : 0;

          const newIndex: number =
            overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
          const newItem: Item = {
            ...findItem(active.id),
            parentId: overItem.isLayoutElement
              ? overItem.id
              : overItem.parentId,
          } as unknown as Item;

          recentlyMovedToNewContainer.current = true;
          return filterUniqueObjects([
            ...items.slice(0, newIndex),
            newItem,
            ...items.slice(newIndex, items.length),
          ]);
        });
      }
    }
  };
  return (
    <ChakraProvider theme={chakraTheme}>
      <DndContext
        sensors={sensors}
        collisionDetection={pointerWithin}
        onDragStart={onDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={onDragCancel}
        modifiers={[snapCenterToCursor]}
      >
        {children}
        {createPortal(
          <DragOverlay
            className="dnd-drag-overlay"
            style={{ zIndex: 9999999 }}
            zIndex={1600}
          >
            {active &&
              active?.id &&
              (renderOverlay ? (
                renderOverlay({ active })
              ) : (
                <p>
                  {active?.data?.current?.isLayoutElement
                    ? active.data.current?.title
                    : active.data.current?.title}
                </p>
              ))}
          </DragOverlay>
        ,document.body)}
      </DndContext>
    </ChakraProvider>
  );
}

const EditorContext = (props: EditorContextProp) => (
  <JsonBuilderContext>
    <Component {...props} />
  </JsonBuilderContext>
);

export default EditorContext;
