import { JsonForms } from '@reactjsonforms/react';
import { cells, renderers } from '@reactjsonforms/chakra-renderers';
import { JsonFormsCore, JsonSchema, UISchemaElement } from '@reactjsonforms/core';
import TextCell, { textCellTester } from '../cells/TextCell';
import FileCell, { fileCellTester } from '../cells/FileCell';
import Ajv from 'ajv';

const ajv = new Ajv();
ajv.addFormat('email', {
  type: 'string',
  validate: (data) => {
    console.log({data}, "From validator")
      return true;
  },
})
ajv.addFormat('data-url', {
  type: 'string',
  validate: (data) => {
    console.log({data}, "From file validator")
      return true;
  },
})

const mycells = [
  ...cells,
  {
    tester: textCellTester,
    cell: TextCell
  },
  {
    tester: fileCellTester,
    cell: FileCell
  },
]

type JsonFormViewerProps = {
  uiSchema: UISchemaElement;
  jsonSchema: JsonSchema;
  onSubmit?: (args: Pick<JsonFormsCore, "data" | "errors">) => void
  data?: unknown;
}

export default function JsonFormViewer({ jsonSchema, data, uiSchema, onSubmit }: JsonFormViewerProps) {
  return (
    <JsonForms
      schema={jsonSchema}
      uischema={uiSchema}
      data={data}
      renderers={renderers}
      cells={mycells}
      onChange={onSubmit}
      ajv={ajv}
    />
  );
}
