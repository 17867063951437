import { toast as toastify } from "react-toastify";

const toast = (data) => {
  switch (data?.status) {
    case "success":
      toastify.success(data?.description);
      break;
    case "info":
      toastify.info(data?.description);
      break;
    case "warning":
      toastify.warning(data?.description);
      break;
    case "error":
      toastify.error(data?.description);
      break;
    default:
      toastify(data?.description);
      break;
  }
};

export const showServerError = (e) => {
  console.log(e);
  toast({
    description: e?.message,
    status: "error",
  });
};

export default toast;
