import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Stack,
  Switch,
  TagCloseButton,
  TagLabel,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { addDays, endOfDay, startOfDay } from "date-fns";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { FREE_TIER, SOMETHING_WENT_WRONG } from "../../constants/constants";
import { useAuth } from "../../context/auth";
import { showErrorToast } from "../../services/toast-service";
import CardHeaderText from "../custom-styled-components/CardHeaderText";
import CustomFormLabel from "../custom-styled-components/CustomFormLabel";
import NumberField from "../fields/NumberField";
import TextField from "../fields/TextField";
import CustomButton from "../custom-styled-components/CustomButton";
import CustomTag from "../custom-styled-components/CustomTag";
import toast from "../toast";
import InputRequestedFields from "../secure-share/dropzone/InputRequestedFields";
import ReactMultiURL from "../multi-url/ReactMultiURL";
import isURL from "../multi-url/isURL";
import { buildJSONSchema } from "../../utils/custom-function";

const MAX_EMAIL_LENGTH = 20;

export default function DropzoneSettings({settings}) {
  const MAX_FILE_SIZE =
    user?.billing_status == FREE_TIER ? 1073741824 : 5368709120;
  const { user } = useAuth();
  const baseDate = new Date();
  const [range, setRange] = useState([
    {
      start_date: startOfDay(baseDate),
      end_date: endOfDay(addDays(baseDate, 3)),
      startDate: startOfDay(baseDate),
      endDate: endOfDay(addDays(baseDate, 3)),
      key: "selection",
    },
  ]);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  let bg = useColorModeValue("brand.500", "brand.400");
  const multiEmailFieldHeight = useBreakpointValue({
    base: "100px",
    xl: "70px",
    "2xl": "100px",
  });
  const onSubmit = async (values) => {
    try {
      let schema = null;
      let data = undefined;
      if(values?.requested_fields?.length) {
        schema = buildJSONSchema(values?.requested_fields, MAX_FILE_SIZE)
        data = btoa(JSON.stringify(schema));
      }
      const variables = {
        days_after_create: values?.days_after_create,
        days_after_start: values?.days_after_start,
        login_required: values?.login_required,
        description: values?.description,
        responder_controls: values?.responder_controls,
        allowed_origins: values?.allowed_origins,
        password: values?.is_password,
        data: data,
        signer_files: schema?.files,
        account_id: user?.account_id,
        username: user?.username,
      };
      if (settings) {
        const { createDropzoneDefaultSettings } = await import("@defense-station/api-service");
        await createDropzoneDefaultSettings(variables)
      }
      else {
        const { updateDropzoneDefaultSettings } = await import("@defense-station/api-service");
        await updateDropzoneDefaultSettings(variables)
      }
      toast({
        description: `Setttings has been ${settings?"updated":"created"}.`,
        status: "success"
      })
    } catch (e) {
      console.log(e)
      showErrorToast("Error", SOMETHING_WENT_WRONG);
    }
  };
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  return (
    <Stack spacing={"2"}>
      <Formik
        initialValues={settings?settings:{
          click_allowed: 14,
          hide_stats: true,
          days_after_create: 0,
          days_after_start: 0,
          login_required: false,
          download_allowed: true,
          hide_identity: false,
          is_device: false,
          requested_fields: [],
          allowed_origins: [],
        }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, errors, isSubmitting, setFieldValue, handleChange }) => (
          <Form style={{ width: "100%" }}>
            <Stack>
              <TextField
                h="85px"
                name="message"
                label="Message"
                value={values.message}
                placeholder="Enter a message(Do not add any sensitive information.)"
                onChange={handleChange}
                error={errors?.message}
                data-gramm="false"
              />
              <FormControl>
                          <CustomFormLabel
                            display="flex"
                            ms="10px"
                            color={textColorPrimary}
                            fontWeight="bold"
                            _hover={{ cursor: "pointer" }}
                          >
                            Allowed Origins
                            <Flex
                              ml="2"
                              alignItems={"center"}
                              justifyContent="center"
                            >
                              <Tooltip label="Origins that can drop data on dropzone.">
                                <InfoOutlineIcon color={"brand.600"} />
                              </Tooltip>
                            </Flex>
                          </CustomFormLabel>
                          <InputGroup size="md">
                            <ReactMultiURL
                              style={{
                                minHeight: "80px",
                                height: "50px",
                                borderColor: "#E2E8F0",
                                marginBottom: "10px",
                                borderRadius: "16px",
                              }}
                              emails={values?.allowed_origins}
                              variant="main"
                              getLabel={(email, index, removeEmail) => {
                                return (
                                  <CustomTag
                                    mb="6px"
                                    me="6px"
                                    borderRadius="12px"
                                    variant="solid"
                                    bg={bg}
                                    key={index}
                                  >
                                    <TagLabel w="100%">{email}</TagLabel>
                                    <TagCloseButton
                                      justifySelf="flex-end"
                                      color="white"
                                      onClick={() => removeEmail(index)}
                                    />
                                  </CustomTag>
                                );
                              }}
                              onChange={(_allowed_origins) => {
                                setFieldValue(
                                  "allowed_origins",
                                  _allowed_origins
                                );
                              }}
                              validateURL={(url) => {
                                if (isURL(url)) {
                                  if (
                                    values.allowed_origins.length ==
                                    MAX_EMAIL_LENGTH
                                  ) {
                                    toast({
                                      title: "Error",
                                      description:
                                        "Maximum 20 origins are allowed.",
                                      status: "error",
                                      duration: 4000,
                                      isClosable: true,
                                      position: "top",
                                    });
                                    return false;
                                  }
                                  return true;
                                }
                                return false;
                              }}
                            />
                          </InputGroup>
                          {errors.allowed_origins && (
                            <CustomText color={"red.400"}>
                              {errors.allowed_origins}
                            </CustomText>
                          )}
                        </FormControl>
              <Stack direction={{ base: "column", md: "row" }}>
              <NumberField
                  mb="0px"
                  pr="4.5rem"
                  max={365}
                  label="Days From Create"
                  name="days_after_create"
                  min={0}
                  onChange={(n) => {
                    setFieldValue("days_after_create", n);
                  }}
                  value={values.days_after_create}
                  p="3"
                  width="80px"
                />
                <NumberField
                  mb="0px"
                  pr="4.5rem"
                  max={365}
                  label="Day From Start"
                  name="days_after_start"
                  min={0}
                  onChange={(n) => {
                    setFieldValue("days_after_start", n);
                  }}
                  value={values.days_after_start}
                  p="3"
                  width="80px"
                />
              </Stack>
                <NumberField
                  mb="0px"
                  pr="4.5rem"
                  max={user?.billing_status == FREE_TIER ? 14 : 100}
                  label="Allowed Opens"
                  name="click_allowed"
                  min={1}
                  onChange={(n) => {
                    setFieldValue("click_allowed", n);
                  }}
                  value={values.click_allowed}
                  p="3"
                  width="80px"
                />
              <InputRequestedFields
                          maxFieldCount={10}
                          values={values}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          errors={errors}
                        />
              <Accordion
              defaultIndex={[0]}
              allowToggle
              w={"100%"}
              borderColor={"brand.500"}
              borderWidth={"1px"}
              borderRadius={"15px"}
              mb='16px'>
                <AccordionItem border='none'>
                <AccordionButton
                  _focus='none'
                  _hover='none'
                  p='5px 20px 5px 0px'>
                  <Box flex='1' textAlign='left'>
                    <CustomFormLabel htmlFor="login" mb="0" fontWeight='700'
                      fontSize={{ sm: "md", lg: "md" }}>
                        Advance Settings
                    </CustomFormLabel>
                  </Box>
                  <AccordionIcon color='gray.500' />
                </AccordionButton>
                <AccordionPanel p='0px 0px 10px 0px'>
                <SimpleGrid columns={{base: 1, md: 2, "2xl": 3}}>
                <FormControl display="flex" alignItems="center" p="8px">
                  <Switch
                    name="responder_controls"
                    colorScheme="brand"
                    mr={4}
                    isChecked={values?.responder_controls}
                    onChange={(v) => {
                      setFieldValue(
                        "responder_controls",
                        v?.currentTarget.checked
                      );
                    }}
                  />
                  <CustomFormLabel htmlFor="responder_controls" mb="0">
                    Responder Controls
                  </CustomFormLabel>
                  <Tooltip label="Enable responder controls to allow user to set access limits.">
                    <InfoOutlineIcon color={"brand.600"} />
                  </Tooltip>
                </FormControl>
                <FormControl display="flex" alignItems="center" p="8px">
                  <Switch
                    colorScheme="brand"
                    id="login"
                    mr={4}
                    name="login_required"
                    isChecked={values?.login_required}
                    onChange={(v) => {
                      setFieldValue("login_required", v?.currentTarget.checked);
                    }}
                  />
                  <CustomFormLabel htmlFor="login_required" mb="0">
                    Login Required
                  </CustomFormLabel>
                  <Tooltip label="User will need to login to view the shared content.">
                    <InfoOutlineIcon color={"brand.600"} />
                  </Tooltip>
                </FormControl>
                <FormControl
                    display="flex"
                    alignItems="center"
                    p="8px"
                  >
                    <Switch
                      id="password"
                      colorScheme="brand"
                      mr={4}
                      isChecked={values?.is_password}
                      onChange={(v) => {
                        setFieldValue(
                          "is_password",
                          v?.currentTarget.checked
                        );
                      }}
                    />
                    <CustomFormLabel htmlFor="password" mb="0">
                      Set Password
                    </CustomFormLabel>
                    <Tooltip label="User will need to enter password to view the shared content.">
                      <InfoOutlineIcon color={"brand.600"} />
                    </Tooltip>
                  </FormControl>
              </SimpleGrid>
              {values.is_password && (
                          <SimpleGrid
                            columns={{ base: 1, md: 2 }}
                            columnGap={3}
                            rowGap={6}
                            width={"full"}
                          >
                            <GridItem>
                              <FormControl isInvalid={errors.password}>
                                <InputGroup size="md">
                                  <Input
                                    autoFocus
                                    pr="4.5rem"
                                    type={show ? "text" : "password"}
                                    placeholder="Enter password"
                                    name="password"
                                    borderRadius={"15"}
                                    _placeholder={{
                                      fontWeight: "400",
                                      color: "secondaryGray.600",
                                    }}
                                    value={values.password}
                                    onChange={handleChange}
                                  />

                                  <InputRightElement width="4.5rem">
                                    <Button
                                      h="1.75rem"
                                      size="sm"
                                      onClick={handleClick}
                                    >
                                      {show ? "Hide" : "Show"}
                                    </Button>
                                  </InputRightElement>
                                </InputGroup>
                                {errors.password && (
                                  <FormErrorMessage>
                                    {errors.password}
                                  </FormErrorMessage>
                                )}
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl isInvalid={errors.confirm_password}>
                                <InputGroup size="md">
                                  <Input
                                    pr="4.5rem"
                                    type={"password"}
                                    placeholder="Confirm password"
                                    borderRadius={"15"}
                                    _placeholder={{
                                      fontWeight: "400",
                                      color: "secondaryGray.600",
                                    }}
                                    name="confirm_password"
                                    value={values.confirm_password}
                                    onChange={handleChange}
                                  />
                                </InputGroup>
                                {errors.confirm_password && (
                                  <FormErrorMessage>
                                    {errors.confirm_password}
                                  </FormErrorMessage>
                                )}
                              </FormControl>
                            </GridItem>
                          </SimpleGrid>
                        )}
                        </AccordionPanel>
                        </AccordionItem>
                        </Accordion>
              <CustomButton type="submit" isLoading={isSubmitting} colorScheme="brand" w="full">
                Create
              </CustomButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
}
