import React, { createContext, useContext, useEffect, useState, ReactNode } from "react";

// Define the shape of the error context value
interface ErrorContextType {
  isServerError: boolean;
  setIsServerError: (value: boolean) => void;
  emitError: (error: any) => void;
  clearServerError: () => void;
  error: any; // Replace `any` with a specific type if you know the structure of your error
}

// Provide a default context value to satisfy TypeScript, though it will be overwritten by the provider
const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

// Define the provider's props
interface ErrorsProviderProps {
  children: ReactNode;
}

export const ErrorsProvider: React.FC<ErrorsProviderProps> = ({ children }) => {
  const [isServerError, setIsServerError] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined); // Replace `any` with the specific type of your error if known

  const emitError = (error: any) => {
    setError(error);
    setIsServerError(true);
  };

  const clearServerError = () => {
    setError(undefined);
    setIsServerError(false);
  };

  const value: ErrorContextType = {
    isServerError,
    setIsServerError,
    emitError,
    clearServerError,
    error,
  };

  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
};

export const useErrors = (): ErrorContextType => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error("useErrors must be used within an ErrorsProvider");
  }
  return context;
};
