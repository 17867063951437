import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spinner,
  Select,
} from "@chakra-ui/react";

// Assets
import * as timeago from "timeago.js";
import { startOfDay, addDays, parseISO, differenceInDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import { IoMdDocument } from "react-icons/io";
import { DOC_TYPES } from "../../constants/constants";
import CustomText from "../custom-styled-components/CustomText";
import CustomActionIconButton from "../custom-styled-components/CustomActionIconButton";
import Card from "../card/Card";
import { navigateToUrl } from "single-spa"

const COLOR_SCHEME = {
  "A+": "whatsapp",
  A: "green",
  "B-": "orange",
  C: "yellow",
  D: "red",
};

const dateList = [
  {
    name: "Today",
    date: startOfDay(new Date()).toISOString(),
  },
  {
    name: "3 Days",
    date: addDays(new Date(), -3).toISOString(),
  },
  {
    name: "7 Days",
    date: addDays(new Date(), -7).toISOString(),
  },
  {
    name: "1 Month",
    date: addDays(new Date(), -30).toISOString(),
  },
];

const TableRow = ({ row, onDelete, onEdit }) => {
  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

  const onEditClick = () => {
    onEdit(row.original);
  };

  const navigate = useNavigate();

  const daysOld = differenceInDays(
    new Date(),
    parseISO(row.original.updated_at)
  );
  const color = "green";
  if (daysOld > 1 && daysOld <= 3) {
    color = "yellow";
  }
  if (daysOld > 3 && daysOld <= 7) {
    color = "orange";
  }
  if (daysOld > 7) {
    color = "red";
  }

  const onViewDocument = (e) => {
    e.stopPropagation();
    const doc_type = Object.keys(DOC_TYPES).find(
      (key) => DOC_TYPES[key] == row.original?.type
    );
    navigateToUrl(`/policy/documents/${doc_type}/edit/${row.original?.document_id}`);
  };
  return (
    <>
      <Tr
        role="group"
        px="40px"
        _hover={{ bg: `secondaryGray.100` }}
        {...row.getRowProps()}
        cursor={"pointer"}
        onClick={onEditClick}
      >
        {row.cells.map((cell, index) => {
          let data = "";
          if (cell.column.Header === "Name") {
            data = (
              <CustomText color={textColor} fontWeight="600">
                {cell.value}
              </CustomText>
            );
          } else if (cell.column.Header === "Email") {
            data = (
              <CustomText color={textColor} fontWeight="600">
                {cell.value}
              </CustomText>
            );
          } else if (cell.column.Header === "Type") {
            data = (
              <CustomText color={textColor} fontWeight="600">
                {cell.value}
              </CustomText>
            );
          } else if (cell.column.Header === "Document") {
            data = (
              <CustomText color={textColor} fontWeight="600">
                {cell.value}
              </CustomText>
            );
          } else if (cell.column.Header === "Created At") {
            data = (
              <CustomText color={textColor} fontWeight="600">
                {timeago.format(cell.value)}
              </CustomText>
            );
          } else if (cell.column.Header === "Updated At") {
            data = (
              <CustomText
                _groupHover={{ color: textColor }}
                color={`${color}.700`}
                fontWeight="600"
              >
                {timeago.format(cell.value)}
              </CustomText>
            );
          } else if (cell.column.Header === " ") {
            data = (
              <CustomActionIconButton
                variant="outline"
                size={"sm"}
                onClick={onViewDocument}
                icon={<IoMdDocument />}
                colorScheme="brand"
              />
            );
          }
          return (
            <Td
              {...cell.getCellProps()}
              key={index}
              fontSize={{ sm: "14px" }}
              minW={{ sm: "120px", md: "200px", lg: "auto" }}
              borderColor="transparent"
              mt="20px !important"
              py="10px !important"
            >
              {data}
            </Td>
          );
        })}
      </Tr>
    </>
  );
};

function ManagementTable(props) {
  const {
    columnsData,
    tableData,
    onEdit = () => null,
    isLoading,
    onDateChange,
  } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

  return (
    <Card p="0" overflow="hidden" pb="20px">
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex
          align={{ lg: "center" }}
          justify={{ base: "space-between" }}
          w="100%"
          p="20px"
          px="40px"
        >
          <Text
            color={textColor}
            fontSize="xl"
            fontWeight="600"
            lineHeight="100%"
          >
            Non Compliant Users
          </Text>
          <Select
            w="max-content"
            onChange={(v) => onDateChange(v.target.value)}
          >
            {dateList.map((date) => (
              <option key={date.name} value={date.date}>
                {date.name}
              </option>
            ))}
          </Select>
        </Flex>
        <Table {...getTableProps()} variant="simple" color="gray.500">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return <TableRow key={index} row={row} onEdit={onEdit} />;
            })}
          </Tbody>
        </Table>
        {isLoading ? (
          <Flex w="full" justifyContent={"center"}>
            <Spinner />
          </Flex>
        ) : !data?.length ? (
          <Flex w="full" justify={"center"} mt="5">
            <CustomText
              color={textColor}
              textAlign={"center"}
              fontSize="sm"
              fontWeight="600"
            >
              No employee found
            </CustomText>
          </Flex>
        ) : null}
      </Flex>
    </Card>
  );
}

export default ManagementTable;
