// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/asset/v1/service/asset/asset.proto (package asset.v1.asset, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Struct } from "@bufbuild/protobuf";
import { Asset, Stage } from "../../types/types_pb";

/**
 * @generated from message asset.v1.asset.CreateAssetRequest
 */
export class CreateAssetRequest extends Message<CreateAssetRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string category_dri = 2;
   */
  categoryDri = "";

  /**
   * @generated from field: repeated string labels = 3;
   */
  labels: string[] = [];

  /**
   * @generated from field: google.protobuf.Struct props = 4;
   */
  props?: Struct;

  constructor(data?: PartialMessage<CreateAssetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.asset.CreateAssetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "category_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "labels", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "props", kind: "message", T: Struct },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAssetRequest {
    return new CreateAssetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAssetRequest {
    return new CreateAssetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAssetRequest {
    return new CreateAssetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAssetRequest | PlainMessage<CreateAssetRequest> | undefined, b: CreateAssetRequest | PlainMessage<CreateAssetRequest> | undefined): boolean {
    return proto3.util.equals(CreateAssetRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.asset.CreateAssetResponse
 */
export class CreateAssetResponse extends Message<CreateAssetResponse> {
  /**
   * @generated from field: asset.v1.types.Asset asset = 1;
   */
  asset?: Asset;

  constructor(data?: PartialMessage<CreateAssetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.asset.CreateAssetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "asset", kind: "message", T: Asset },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAssetResponse {
    return new CreateAssetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAssetResponse {
    return new CreateAssetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAssetResponse {
    return new CreateAssetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAssetResponse | PlainMessage<CreateAssetResponse> | undefined, b: CreateAssetResponse | PlainMessage<CreateAssetResponse> | undefined): boolean {
    return proto3.util.equals(CreateAssetResponse, a, b);
  }
}

/**
 * @generated from message asset.v1.asset.GetAssetRequest
 */
export class GetAssetRequest extends Message<GetAssetRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  constructor(data?: PartialMessage<GetAssetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.asset.GetAssetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssetRequest {
    return new GetAssetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssetRequest {
    return new GetAssetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssetRequest {
    return new GetAssetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAssetRequest | PlainMessage<GetAssetRequest> | undefined, b: GetAssetRequest | PlainMessage<GetAssetRequest> | undefined): boolean {
    return proto3.util.equals(GetAssetRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.asset.GetAssetResponse
 */
export class GetAssetResponse extends Message<GetAssetResponse> {
  /**
   * @generated from field: asset.v1.types.Asset asset = 1;
   */
  asset?: Asset;

  constructor(data?: PartialMessage<GetAssetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.asset.GetAssetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "asset", kind: "message", T: Asset },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssetResponse {
    return new GetAssetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssetResponse {
    return new GetAssetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssetResponse {
    return new GetAssetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAssetResponse | PlainMessage<GetAssetResponse> | undefined, b: GetAssetResponse | PlainMessage<GetAssetResponse> | undefined): boolean {
    return proto3.util.equals(GetAssetResponse, a, b);
  }
}

/**
 * @generated from message asset.v1.asset.ListAssetsRequest
 */
export class ListAssetsRequest extends Message<ListAssetsRequest> {
  /**
   * @generated from field: repeated string search_labels = 1;
   */
  searchLabels: string[] = [];

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 3;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListAssetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.asset.ListAssetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "search_labels", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAssetsRequest {
    return new ListAssetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAssetsRequest {
    return new ListAssetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAssetsRequest {
    return new ListAssetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAssetsRequest | PlainMessage<ListAssetsRequest> | undefined, b: ListAssetsRequest | PlainMessage<ListAssetsRequest> | undefined): boolean {
    return proto3.util.equals(ListAssetsRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.asset.ListAssetsResponse
 */
export class ListAssetsResponse extends Message<ListAssetsResponse> {
  /**
   * @generated from field: repeated asset.v1.types.Asset assets = 1;
   */
  assets: Asset[] = [];

  constructor(data?: PartialMessage<ListAssetsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.asset.ListAssetsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assets", kind: "message", T: Asset, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAssetsResponse {
    return new ListAssetsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAssetsResponse {
    return new ListAssetsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAssetsResponse {
    return new ListAssetsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAssetsResponse | PlainMessage<ListAssetsResponse> | undefined, b: ListAssetsResponse | PlainMessage<ListAssetsResponse> | undefined): boolean {
    return proto3.util.equals(ListAssetsResponse, a, b);
  }
}

/**
 * @generated from message asset.v1.asset.UpdateAssetRequest
 */
export class UpdateAssetRequest extends Message<UpdateAssetRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: google.protobuf.Struct props = 2;
   */
  props?: Struct;

  /**
   * @generated from field: repeated string add_labels = 3;
   */
  addLabels: string[] = [];

  /**
   * @generated from field: repeated string remove_labels = 4;
   */
  removeLabels: string[] = [];

  constructor(data?: PartialMessage<UpdateAssetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.asset.UpdateAssetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "props", kind: "message", T: Struct },
    { no: 3, name: "add_labels", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "remove_labels", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAssetRequest {
    return new UpdateAssetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAssetRequest {
    return new UpdateAssetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAssetRequest {
    return new UpdateAssetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAssetRequest | PlainMessage<UpdateAssetRequest> | undefined, b: UpdateAssetRequest | PlainMessage<UpdateAssetRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAssetRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.asset.UpdateAssetResponse
 */
export class UpdateAssetResponse extends Message<UpdateAssetResponse> {
  /**
   * @generated from field: asset.v1.types.Asset asset = 1;
   */
  asset?: Asset;

  constructor(data?: PartialMessage<UpdateAssetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.asset.UpdateAssetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "asset", kind: "message", T: Asset },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAssetResponse {
    return new UpdateAssetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAssetResponse {
    return new UpdateAssetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAssetResponse {
    return new UpdateAssetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAssetResponse | PlainMessage<UpdateAssetResponse> | undefined, b: UpdateAssetResponse | PlainMessage<UpdateAssetResponse> | undefined): boolean {
    return proto3.util.equals(UpdateAssetResponse, a, b);
  }
}

/**
 * @generated from message asset.v1.asset.DeleteAssetsRequest
 */
export class DeleteAssetsRequest extends Message<DeleteAssetsRequest> {
  /**
   * @generated from field: repeated string dris = 1;
   */
  dris: string[] = [];

  constructor(data?: PartialMessage<DeleteAssetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.asset.DeleteAssetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAssetsRequest {
    return new DeleteAssetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAssetsRequest {
    return new DeleteAssetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAssetsRequest {
    return new DeleteAssetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAssetsRequest | PlainMessage<DeleteAssetsRequest> | undefined, b: DeleteAssetsRequest | PlainMessage<DeleteAssetsRequest> | undefined): boolean {
    return proto3.util.equals(DeleteAssetsRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.asset.ListAssetStagesRequest
 */
export class ListAssetStagesRequest extends Message<ListAssetStagesRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 3;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListAssetStagesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.asset.ListAssetStagesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAssetStagesRequest {
    return new ListAssetStagesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAssetStagesRequest {
    return new ListAssetStagesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAssetStagesRequest {
    return new ListAssetStagesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAssetStagesRequest | PlainMessage<ListAssetStagesRequest> | undefined, b: ListAssetStagesRequest | PlainMessage<ListAssetStagesRequest> | undefined): boolean {
    return proto3.util.equals(ListAssetStagesRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.asset.ListAssetStagesResponse
 */
export class ListAssetStagesResponse extends Message<ListAssetStagesResponse> {
  /**
   * @generated from field: repeated asset.v1.types.Stage stages = 1;
   */
  stages: Stage[] = [];

  constructor(data?: PartialMessage<ListAssetStagesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.asset.ListAssetStagesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stages", kind: "message", T: Stage, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAssetStagesResponse {
    return new ListAssetStagesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAssetStagesResponse {
    return new ListAssetStagesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAssetStagesResponse {
    return new ListAssetStagesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAssetStagesResponse | PlainMessage<ListAssetStagesResponse> | undefined, b: ListAssetStagesResponse | PlainMessage<ListAssetStagesResponse> | undefined): boolean {
    return proto3.util.equals(ListAssetStagesResponse, a, b);
  }
}

/**
 * @generated from message asset.v1.asset.CreateRelationRequest
 */
export class CreateRelationRequest extends Message<CreateRelationRequest> {
  /**
   * @generated from field: string start_asset_dri = 1;
   */
  startAssetDri = "";

  /**
   * @generated from field: repeated string end_asset_dris = 2;
   */
  endAssetDris: string[] = [];

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  /**
   * @generated from field: google.protobuf.Struct props = 4;
   */
  props?: Struct;

  constructor(data?: PartialMessage<CreateRelationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.asset.CreateRelationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_asset_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "end_asset_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "props", kind: "message", T: Struct },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRelationRequest {
    return new CreateRelationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRelationRequest {
    return new CreateRelationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRelationRequest {
    return new CreateRelationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRelationRequest | PlainMessage<CreateRelationRequest> | undefined, b: CreateRelationRequest | PlainMessage<CreateRelationRequest> | undefined): boolean {
    return proto3.util.equals(CreateRelationRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.asset.CreateRelationResponse
 */
export class CreateRelationResponse extends Message<CreateRelationResponse> {
  /**
   * @generated from field: int32 success_relation_count = 1;
   */
  successRelationCount = 0;

  constructor(data?: PartialMessage<CreateRelationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.asset.CreateRelationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "success_relation_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRelationResponse {
    return new CreateRelationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRelationResponse {
    return new CreateRelationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRelationResponse {
    return new CreateRelationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRelationResponse | PlainMessage<CreateRelationResponse> | undefined, b: CreateRelationResponse | PlainMessage<CreateRelationResponse> | undefined): boolean {
    return proto3.util.equals(CreateRelationResponse, a, b);
  }
}

/**
 * @generated from message asset.v1.asset.UpdateRelationRequest
 */
export class UpdateRelationRequest extends Message<UpdateRelationRequest> {
  /**
   * @generated from field: string start_asset_dri = 1;
   */
  startAssetDri = "";

  /**
   * @generated from field: string end_asset_dri = 2;
   */
  endAssetDri = "";

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  /**
   * @generated from field: google.protobuf.Struct update_props = 4;
   */
  updateProps?: Struct;

  constructor(data?: PartialMessage<UpdateRelationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.asset.UpdateRelationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_asset_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "end_asset_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "update_props", kind: "message", T: Struct },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRelationRequest {
    return new UpdateRelationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRelationRequest {
    return new UpdateRelationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRelationRequest {
    return new UpdateRelationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRelationRequest | PlainMessage<UpdateRelationRequest> | undefined, b: UpdateRelationRequest | PlainMessage<UpdateRelationRequest> | undefined): boolean {
    return proto3.util.equals(UpdateRelationRequest, a, b);
  }
}

/**
 * @generated from message asset.v1.asset.DeleteRelationRequest
 */
export class DeleteRelationRequest extends Message<DeleteRelationRequest> {
  /**
   * @generated from field: string start_asset_dri = 1;
   */
  startAssetDri = "";

  /**
   * @generated from field: string end_asset_dri = 2;
   */
  endAssetDri = "";

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  constructor(data?: PartialMessage<DeleteRelationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "asset.v1.asset.DeleteRelationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_asset_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "end_asset_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteRelationRequest {
    return new DeleteRelationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteRelationRequest {
    return new DeleteRelationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteRelationRequest {
    return new DeleteRelationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteRelationRequest | PlainMessage<DeleteRelationRequest> | undefined, b: DeleteRelationRequest | PlainMessage<DeleteRelationRequest> | undefined): boolean {
    return proto3.util.equals(DeleteRelationRequest, a, b);
  }
}

