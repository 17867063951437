// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/sypher/v1/service/setup/setup.proto (package sypher.v1.setup, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateRootKeyRequest, CreateRootKeyResponse, GetRootKeyRequest, GetRootKeyResponse, UpdateRootKeyRequest, UpdateRootKeyResponse } from "./setup_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service sypher.v1.setup.SetupService
 */
export const SetupService = {
  typeName: "sypher.v1.setup.SetupService",
  methods: {
    /**
     * @generated from rpc sypher.v1.setup.SetupService.CreateRootKey
     */
    createRootKey: {
      name: "CreateRootKey",
      I: CreateRootKeyRequest,
      O: CreateRootKeyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.setup.SetupService.GetRootKey
     */
    getRootKey: {
      name: "GetRootKey",
      I: GetRootKeyRequest,
      O: GetRootKeyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.setup.SetupService.UpdateRootKey
     */
    updateRootKey: {
      name: "UpdateRootKey",
      I: UpdateRootKeyRequest,
      O: UpdateRootKeyResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

