// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/access_key/access_key.proto (package iam.v1.access_key, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { AccessKey } from "../../types/types_pb";

/**
 * @generated from message iam.v1.access_key.CreateAccessKeyRequest
 */
export class CreateAccessKeyRequest extends Message<CreateAccessKeyRequest> {
  /**
   * @generated from field: string user_dri = 1;
   */
  userDri = "";

  constructor(data?: PartialMessage<CreateAccessKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.access_key.CreateAccessKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAccessKeyRequest {
    return new CreateAccessKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAccessKeyRequest {
    return new CreateAccessKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAccessKeyRequest {
    return new CreateAccessKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAccessKeyRequest | PlainMessage<CreateAccessKeyRequest> | undefined, b: CreateAccessKeyRequest | PlainMessage<CreateAccessKeyRequest> | undefined): boolean {
    return proto3.util.equals(CreateAccessKeyRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.access_key.CreateAccessKeyResponse
 */
export class CreateAccessKeyResponse extends Message<CreateAccessKeyResponse> {
  /**
   * @generated from field: iam.v1.types.AccessKey access_key = 1;
   */
  accessKey?: AccessKey;

  constructor(data?: PartialMessage<CreateAccessKeyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.access_key.CreateAccessKeyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_key", kind: "message", T: AccessKey },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAccessKeyResponse {
    return new CreateAccessKeyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAccessKeyResponse {
    return new CreateAccessKeyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAccessKeyResponse {
    return new CreateAccessKeyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAccessKeyResponse | PlainMessage<CreateAccessKeyResponse> | undefined, b: CreateAccessKeyResponse | PlainMessage<CreateAccessKeyResponse> | undefined): boolean {
    return proto3.util.equals(CreateAccessKeyResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.access_key.GetAccessKeyRequest
 */
export class GetAccessKeyRequest extends Message<GetAccessKeyRequest> {
  /**
   * @generated from field: string access_key_dri = 1;
   */
  accessKeyDri = "";

  constructor(data?: PartialMessage<GetAccessKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.access_key.GetAccessKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_key_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAccessKeyRequest {
    return new GetAccessKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAccessKeyRequest {
    return new GetAccessKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAccessKeyRequest {
    return new GetAccessKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAccessKeyRequest | PlainMessage<GetAccessKeyRequest> | undefined, b: GetAccessKeyRequest | PlainMessage<GetAccessKeyRequest> | undefined): boolean {
    return proto3.util.equals(GetAccessKeyRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.access_key.GetAccessKeyResponse
 */
export class GetAccessKeyResponse extends Message<GetAccessKeyResponse> {
  /**
   * @generated from field: iam.v1.types.AccessKey access_key = 1;
   */
  accessKey?: AccessKey;

  constructor(data?: PartialMessage<GetAccessKeyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.access_key.GetAccessKeyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_key", kind: "message", T: AccessKey },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAccessKeyResponse {
    return new GetAccessKeyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAccessKeyResponse {
    return new GetAccessKeyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAccessKeyResponse {
    return new GetAccessKeyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAccessKeyResponse | PlainMessage<GetAccessKeyResponse> | undefined, b: GetAccessKeyResponse | PlainMessage<GetAccessKeyResponse> | undefined): boolean {
    return proto3.util.equals(GetAccessKeyResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.access_key.ListAccessKeysRequest
 */
export class ListAccessKeysRequest extends Message<ListAccessKeysRequest> {
  /**
   * @generated from field: string user_dri = 1;
   */
  userDri = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 3;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListAccessKeysRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.access_key.ListAccessKeysRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAccessKeysRequest {
    return new ListAccessKeysRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAccessKeysRequest {
    return new ListAccessKeysRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAccessKeysRequest {
    return new ListAccessKeysRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAccessKeysRequest | PlainMessage<ListAccessKeysRequest> | undefined, b: ListAccessKeysRequest | PlainMessage<ListAccessKeysRequest> | undefined): boolean {
    return proto3.util.equals(ListAccessKeysRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.access_key.ListAccessKeysResponse
 */
export class ListAccessKeysResponse extends Message<ListAccessKeysResponse> {
  /**
   * @generated from field: repeated iam.v1.types.AccessKey access_keys = 1;
   */
  accessKeys: AccessKey[] = [];

  constructor(data?: PartialMessage<ListAccessKeysResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.access_key.ListAccessKeysResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_keys", kind: "message", T: AccessKey, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAccessKeysResponse {
    return new ListAccessKeysResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAccessKeysResponse {
    return new ListAccessKeysResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAccessKeysResponse {
    return new ListAccessKeysResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAccessKeysResponse | PlainMessage<ListAccessKeysResponse> | undefined, b: ListAccessKeysResponse | PlainMessage<ListAccessKeysResponse> | undefined): boolean {
    return proto3.util.equals(ListAccessKeysResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.access_key.ActivateAccessKeyRequest
 */
export class ActivateAccessKeyRequest extends Message<ActivateAccessKeyRequest> {
  /**
   * @generated from field: string access_key_dri = 1;
   */
  accessKeyDri = "";

  constructor(data?: PartialMessage<ActivateAccessKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.access_key.ActivateAccessKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_key_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActivateAccessKeyRequest {
    return new ActivateAccessKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActivateAccessKeyRequest {
    return new ActivateAccessKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActivateAccessKeyRequest {
    return new ActivateAccessKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ActivateAccessKeyRequest | PlainMessage<ActivateAccessKeyRequest> | undefined, b: ActivateAccessKeyRequest | PlainMessage<ActivateAccessKeyRequest> | undefined): boolean {
    return proto3.util.equals(ActivateAccessKeyRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.access_key.DeactivateAccessKeyRequest
 */
export class DeactivateAccessKeyRequest extends Message<DeactivateAccessKeyRequest> {
  /**
   * @generated from field: string access_key_dri = 1;
   */
  accessKeyDri = "";

  constructor(data?: PartialMessage<DeactivateAccessKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.access_key.DeactivateAccessKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_key_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeactivateAccessKeyRequest {
    return new DeactivateAccessKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeactivateAccessKeyRequest {
    return new DeactivateAccessKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeactivateAccessKeyRequest {
    return new DeactivateAccessKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeactivateAccessKeyRequest | PlainMessage<DeactivateAccessKeyRequest> | undefined, b: DeactivateAccessKeyRequest | PlainMessage<DeactivateAccessKeyRequest> | undefined): boolean {
    return proto3.util.equals(DeactivateAccessKeyRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.access_key.DeleteAccessKeyRequest
 */
export class DeleteAccessKeyRequest extends Message<DeleteAccessKeyRequest> {
  /**
   * @generated from field: string access_key_dri = 1;
   */
  accessKeyDri = "";

  constructor(data?: PartialMessage<DeleteAccessKeyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.access_key.DeleteAccessKeyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_key_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAccessKeyRequest {
    return new DeleteAccessKeyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAccessKeyRequest {
    return new DeleteAccessKeyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAccessKeyRequest {
    return new DeleteAccessKeyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAccessKeyRequest | PlainMessage<DeleteAccessKeyRequest> | undefined, b: DeleteAccessKeyRequest | PlainMessage<DeleteAccessKeyRequest> | undefined): boolean {
    return proto3.util.equals(DeleteAccessKeyRequest, a, b);
  }
}

