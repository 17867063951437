// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/sso/sso.proto (package iam.v1.sso, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { SSOClaimRoleMapping, SSOConfig, SSOProvier } from "../../types/types_pb";

/**
 * @generated from message iam.v1.sso.SetupSSORequest
 */
export class SetupSSORequest extends Message<SetupSSORequest> {
  /**
   * @generated from field: iam.v1.types.SSOConfig sso_config = 1;
   */
  ssoConfig?: SSOConfig;

  /**
   * @generated from field: map<string, iam.v1.types.SSOClaimRoleMapping> claim_mappings = 2;
   */
  claimMappings: { [key: string]: SSOClaimRoleMapping } = {};

  constructor(data?: PartialMessage<SetupSSORequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.sso.SetupSSORequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_config", kind: "message", T: SSOConfig },
    { no: 2, name: "claim_mappings", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: SSOClaimRoleMapping} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetupSSORequest {
    return new SetupSSORequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetupSSORequest {
    return new SetupSSORequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetupSSORequest {
    return new SetupSSORequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetupSSORequest | PlainMessage<SetupSSORequest> | undefined, b: SetupSSORequest | PlainMessage<SetupSSORequest> | undefined): boolean {
    return proto3.util.equals(SetupSSORequest, a, b);
  }
}

/**
 * @generated from message iam.v1.sso.SetupSSOResponse
 */
export class SetupSSOResponse extends Message<SetupSSOResponse> {
  /**
   * @generated from field: iam.v1.types.SSOProvier sso_provider = 1;
   */
  ssoProvider?: SSOProvier;

  constructor(data?: PartialMessage<SetupSSOResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.sso.SetupSSOResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_provider", kind: "message", T: SSOProvier },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetupSSOResponse {
    return new SetupSSOResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetupSSOResponse {
    return new SetupSSOResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetupSSOResponse {
    return new SetupSSOResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetupSSOResponse | PlainMessage<SetupSSOResponse> | undefined, b: SetupSSOResponse | PlainMessage<SetupSSOResponse> | undefined): boolean {
    return proto3.util.equals(SetupSSOResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.sso.UpdateSSORequest
 */
export class UpdateSSORequest extends Message<UpdateSSORequest> {
  /**
   * @generated from field: iam.v1.types.SSOConfig sso_config = 1;
   */
  ssoConfig?: SSOConfig;

  /**
   * @generated from field: map<string, iam.v1.types.SSOClaimRoleMapping> claim_mappings = 2;
   */
  claimMappings: { [key: string]: SSOClaimRoleMapping } = {};

  constructor(data?: PartialMessage<UpdateSSORequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.sso.UpdateSSORequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_config", kind: "message", T: SSOConfig },
    { no: 2, name: "claim_mappings", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: SSOClaimRoleMapping} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSSORequest {
    return new UpdateSSORequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSSORequest {
    return new UpdateSSORequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSSORequest {
    return new UpdateSSORequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSSORequest | PlainMessage<UpdateSSORequest> | undefined, b: UpdateSSORequest | PlainMessage<UpdateSSORequest> | undefined): boolean {
    return proto3.util.equals(UpdateSSORequest, a, b);
  }
}

/**
 * @generated from message iam.v1.sso.UpdateSSOResponse
 */
export class UpdateSSOResponse extends Message<UpdateSSOResponse> {
  /**
   * @generated from field: iam.v1.types.SSOProvier sso_provider = 1;
   */
  ssoProvider?: SSOProvier;

  constructor(data?: PartialMessage<UpdateSSOResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.sso.UpdateSSOResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_provider", kind: "message", T: SSOProvier },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSSOResponse {
    return new UpdateSSOResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSSOResponse {
    return new UpdateSSOResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSSOResponse {
    return new UpdateSSOResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSSOResponse | PlainMessage<UpdateSSOResponse> | undefined, b: UpdateSSOResponse | PlainMessage<UpdateSSOResponse> | undefined): boolean {
    return proto3.util.equals(UpdateSSOResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.sso.DeleteSSORequest
 */
export class DeleteSSORequest extends Message<DeleteSSORequest> {
  constructor(data?: PartialMessage<DeleteSSORequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.sso.DeleteSSORequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteSSORequest {
    return new DeleteSSORequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteSSORequest {
    return new DeleteSSORequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteSSORequest {
    return new DeleteSSORequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteSSORequest | PlainMessage<DeleteSSORequest> | undefined, b: DeleteSSORequest | PlainMessage<DeleteSSORequest> | undefined): boolean {
    return proto3.util.equals(DeleteSSORequest, a, b);
  }
}

/**
 * @generated from message iam.v1.sso.GetSSORequest
 */
export class GetSSORequest extends Message<GetSSORequest> {
  constructor(data?: PartialMessage<GetSSORequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.sso.GetSSORequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSSORequest {
    return new GetSSORequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSSORequest {
    return new GetSSORequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSSORequest {
    return new GetSSORequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSSORequest | PlainMessage<GetSSORequest> | undefined, b: GetSSORequest | PlainMessage<GetSSORequest> | undefined): boolean {
    return proto3.util.equals(GetSSORequest, a, b);
  }
}

/**
 * @generated from message iam.v1.sso.GetSSOResponse
 */
export class GetSSOResponse extends Message<GetSSOResponse> {
  /**
   * @generated from field: iam.v1.types.SSOProvier sso_provider = 1;
   */
  ssoProvider?: SSOProvier;

  constructor(data?: PartialMessage<GetSSOResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.sso.GetSSOResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_provider", kind: "message", T: SSOProvier },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSSOResponse {
    return new GetSSOResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSSOResponse {
    return new GetSSOResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSSOResponse {
    return new GetSSOResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSSOResponse | PlainMessage<GetSSOResponse> | undefined, b: GetSSOResponse | PlainMessage<GetSSOResponse> | undefined): boolean {
    return proto3.util.equals(GetSSOResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.sso.SSOLoginRequest
 */
export class SSOLoginRequest extends Message<SSOLoginRequest> {
  /**
   * @generated from field: string account_alias = 1;
   */
  accountAlias = "";

  constructor(data?: PartialMessage<SSOLoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.sso.SSOLoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account_alias", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SSOLoginRequest {
    return new SSOLoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SSOLoginRequest {
    return new SSOLoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SSOLoginRequest {
    return new SSOLoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SSOLoginRequest | PlainMessage<SSOLoginRequest> | undefined, b: SSOLoginRequest | PlainMessage<SSOLoginRequest> | undefined): boolean {
    return proto3.util.equals(SSOLoginRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.sso.SSOLoginResponse
 */
export class SSOLoginResponse extends Message<SSOLoginResponse> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * @generated from field: string type = 2;
   */
  type = "";

  constructor(data?: PartialMessage<SSOLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.sso.SSOLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SSOLoginResponse {
    return new SSOLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SSOLoginResponse {
    return new SSOLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SSOLoginResponse {
    return new SSOLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SSOLoginResponse | PlainMessage<SSOLoginResponse> | undefined, b: SSOLoginResponse | PlainMessage<SSOLoginResponse> | undefined): boolean {
    return proto3.util.equals(SSOLoginResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.sso.SSOCallBackRequest
 */
export class SSOCallBackRequest extends Message<SSOCallBackRequest> {
  /**
   * @generated from field: string state = 1;
   */
  state = "";

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  constructor(data?: PartialMessage<SSOCallBackRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.sso.SSOCallBackRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SSOCallBackRequest {
    return new SSOCallBackRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SSOCallBackRequest {
    return new SSOCallBackRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SSOCallBackRequest {
    return new SSOCallBackRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SSOCallBackRequest | PlainMessage<SSOCallBackRequest> | undefined, b: SSOCallBackRequest | PlainMessage<SSOCallBackRequest> | undefined): boolean {
    return proto3.util.equals(SSOCallBackRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.sso.SSOCallBackResponse
 */
export class SSOCallBackResponse extends Message<SSOCallBackResponse> {
  /**
   * @generated from field: string auth_token = 1;
   */
  authToken = "";

  /**
   * @generated from field: repeated string role_dris = 2;
   */
  roleDris: string[] = [];

  constructor(data?: PartialMessage<SSOCallBackResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.sso.SSOCallBackResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SSOCallBackResponse {
    return new SSOCallBackResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SSOCallBackResponse {
    return new SSOCallBackResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SSOCallBackResponse {
    return new SSOCallBackResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SSOCallBackResponse | PlainMessage<SSOCallBackResponse> | undefined, b: SSOCallBackResponse | PlainMessage<SSOCallBackResponse> | undefined): boolean {
    return proto3.util.equals(SSOCallBackResponse, a, b);
  }
}

