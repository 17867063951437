// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/sypher/v1/service/send/send.proto (package sypher.v1.send, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AccessSendRequest, AccessSendResponse, CreateSendRequest, CreateSendResponse, GetSendRequest, GetSendResponse, ListSendsRequest, ListSendsResponse, UpdateSendRequest } from "./send_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service sypher.v1.send.SendService
 */
export const SendService = {
  typeName: "sypher.v1.send.SendService",
  methods: {
    /**
     * @generated from rpc sypher.v1.send.SendService.CreateSend
     */
    createSend: {
      name: "CreateSend",
      I: CreateSendRequest,
      O: CreateSendResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.send.SendService.GetSend
     */
    getSend: {
      name: "GetSend",
      I: GetSendRequest,
      O: GetSendResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.send.SendService.ListSends
     */
    listSends: {
      name: "ListSends",
      I: ListSendsRequest,
      O: ListSendsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.send.SendService.UpdateSend
     */
    updateSend: {
      name: "UpdateSend",
      I: UpdateSendRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.send.SendService.AccessSend
     */
    accessSend: {
      name: "AccessSend",
      I: AccessSendRequest,
      O: AccessSendResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

