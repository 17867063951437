// Chakra Imports
import React, {useEffect} from 'react';
import { useColorMode } from "@chakra-ui/react";

export default function FixedPlugin(props) {
  const { toggleColorMode } = useColorMode();
  const handleToggleColorMode = (event) => {
    toggleColorMode()
  }
  useEffect(() => {
    window.addEventListener("onToggleColorMode", handleToggleColorMode) 
    return () => window.removeEventListener("onToggleColorMode", handleToggleColorMode)
  },[handleToggleColorMode])
  return (
    null
  );
}