import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Grid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import toast from "../components/toast";
import { SOMETHING_WENT_WRONG } from "../constants/constants";
import { getDefaultSettings } from "@defense-station/api-service";
import { useAuth } from "../context/auth";
import SendSettings from "../components/settings/SendSettings";
import RequestSettings from "../components/settings/RequestSettings";
import DropzoneSettings from "../components/settings/DropzoneSettings";
import Card from "../components/card/Card";

export default function Settings() {
  let secondaryText = useColorModeValue("gray.700", "white");
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [sendSettings, setSendSettings] = useState(null);
  const [requestSettings, setRequestSettings] = useState(null);
  const [dropzoneSettings, setDropzoneSettings] = useState(null);
  const [settings, setSettings] = useState(null);

  const fetchSettings = async () => {
    try {
      const data = await getDefaultSettings(user?.account_id, user?.username);
      setSettings(data?.data?.sypher_v1_CreateDefaultSettings);
      setSendSettings(
        data?.data?.sypher_v1_CreateDefaultSettings?.send_default_settings
      );
      setRequestSettings(
        data?.data?.sypher_v1_CreateDefaultSettings?.request_default_settings
      );
      setDropzoneSettings(
        data?.data?.sypher_v1_CreateDefaultSettings?.dropzone_default_settings
      );
    } catch (e) {
      console.log(e)
      toast({
        description: SOMETHING_WENT_WRONG,
        status: "error",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (user) {
      fetchSettings();
    }
  }, [user]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  return (
    <Grid
      maxW="100%"
      gap="4"
      p={{ base: "6", xl: "6", "2xl": "6" }}
      gridTemplateAreas={`"send send"
                          "request dropzone"`}   
      display={{base: "block", "2xl": "grid"}}      
    >
      <Box gridArea="send" mb={{ base: "20px", "2xl": "0px" }}>
        <Box mb={{ sm: "8px", md: "0px" }}>
          <Breadcrumb>
            <BreadcrumbItem color={secondaryText} fontSize="sm">
              <BreadcrumbLink href="/" color={secondaryText}>
                Sypher
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem color={secondaryText} fontSize="sm">
              <BreadcrumbLink href="settings" color={secondaryText}>
                Settings
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          {/* <Link
            color={mainText}
            href="#"
            bg="inherit"
            borderRadius="inherit"
            fontWeight="bold"
            fontSize="34px"
            _hover={{ color: { mainText } }}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
          >
            Send
          </Link> */}
          <Card>
          <Accordion
              defaultIndex={[0]}
              allowToggle
              w={{ sm: "100%", md: "100%", xl: "94%" }}
              mb='16px'>
                <AccordionItem border='none'>
                <AccordionButton
                  _focus='none'
                  _hover='none'
                  p='20px 0px 20px 0px'
                  borderBottom='1px solid'
                  borderColor={borderColor}>
                  <Box flex='1' textAlign='left'>
                    <Text
                      color={textColor}
                      fontWeight='700'
                      fontSize={{ sm: "md", lg: "md" }}>
                      Send Default Settings
                    </Text>
                  </Box>
                  <AccordionIcon color='gray.500' />
                </AccordionButton>
                <AccordionPanel p='18px 0px 10px 0px'>
                  <SendSettings settings={sendSettings} />
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem border='none'>
                <AccordionButton
                  _focus='none'
                  _hover='none'
                  p='20px 0px 20px 0px'
                  borderBottom='1px solid'
                  borderColor={borderColor}>
                  <Box flex='1' textAlign='left'>
                    <Text
                      color={textColor}
                      fontWeight='700'
                      fontSize={{ sm: "md", lg: "md" }}>
                      Request Default Settings
                    </Text>
                  </Box>
                  <AccordionIcon color='gray.500' />
                </AccordionButton>
                <AccordionPanel p='18px 0px 10px 0px'>
                  <RequestSettings settings={requestSettings} />
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem border='none'>
                <AccordionButton
                  _focus='none'
                  _hover='none'
                  p='20px 0px 20px 0px'
                  borderBottom='1px solid'
                  borderColor={borderColor}>
                  <Box flex='1' textAlign='left'>
                    <Text
                      color={textColor}
                      fontWeight='700'
                      fontSize={{ sm: "md", lg: "md" }}>
                       Dropzone Default Settings
                    </Text>
                  </Box>
                  <AccordionIcon color='gray.500' />
                </AccordionButton>
                <AccordionPanel p='18px 0px 10px 0px'>
                  <DropzoneSettings settings={dropzoneSettings} />
                </AccordionPanel>
              </AccordionItem>
          </Accordion>
          </Card>
        </Box>
      </Box>
    </Grid>
  );
}
