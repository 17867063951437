// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/policy/v1/types/types.proto (package policy.v1.types, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from enum policy.v1.types.SupervisorType
 */
export enum SupervisorType {
  /**
   * @generated from enum value: SUPERVISOR_TYPE_EDITOR_UNSPECIFIED = 0;
   */
  EDITOR_UNSPECIFIED = 0,

  /**
   * @generated from enum value: SUPERVISOR_TYPE_REVIEWER = 1;
   */
  REVIEWER = 1,

  /**
   * @generated from enum value: SUPERVISOR_TYPE_APRROVER = 2;
   */
  APRROVER = 2,

  /**
   * @generated from enum value: SUPERVISOR_TYPE_OWNER = 3;
   */
  OWNER = 3,

  /**
   * @generated from enum value: SUPERVISOR_TYPE_PUBLISHER = 4;
   */
  PUBLISHER = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(SupervisorType)
proto3.util.setEnumType(SupervisorType, "policy.v1.types.SupervisorType", [
  { no: 0, name: "SUPERVISOR_TYPE_EDITOR_UNSPECIFIED" },
  { no: 1, name: "SUPERVISOR_TYPE_REVIEWER" },
  { no: 2, name: "SUPERVISOR_TYPE_APRROVER" },
  { no: 3, name: "SUPERVISOR_TYPE_OWNER" },
  { no: 4, name: "SUPERVISOR_TYPE_PUBLISHER" },
]);

/**
 * @generated from enum policy.v1.types.FolderType
 */
export enum FolderType {
  /**
   * @generated from enum value: FOLDER_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: FOLDER_TYPE_STANDARD = 1;
   */
  STANDARD = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(FolderType)
proto3.util.setEnumType(FolderType, "policy.v1.types.FolderType", [
  { no: 0, name: "FOLDER_TYPE_UNSPECIFIED" },
  { no: 1, name: "FOLDER_TYPE_STANDARD" },
]);

/**
 * @generated from enum policy.v1.types.FolderIcon
 */
export enum FolderIcon {
  /**
   * @generated from enum value: FOLDER_ICON_OTHER_UNSPECIFIED = 0;
   */
  OTHER_UNSPECIFIED = 0,

  /**
   * @generated from enum value: FOLDER_ICON_POLICY = 1;
   */
  POLICY = 1,

  /**
   * @generated from enum value: FOLDER_ICON_STANDARD = 2;
   */
  STANDARD = 2,

  /**
   * @generated from enum value: FOLDER_ICON_PROCEDURE = 4;
   */
  PROCEDURE = 4,

  /**
   * @generated from enum value: FOLDER_ICON_GUIDELINE = 5;
   */
  GUIDELINE = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(FolderIcon)
proto3.util.setEnumType(FolderIcon, "policy.v1.types.FolderIcon", [
  { no: 0, name: "FOLDER_ICON_OTHER_UNSPECIFIED" },
  { no: 1, name: "FOLDER_ICON_POLICY" },
  { no: 2, name: "FOLDER_ICON_STANDARD" },
  { no: 4, name: "FOLDER_ICON_PROCEDURE" },
  { no: 5, name: "FOLDER_ICON_GUIDELINE" },
]);

/**
 * @generated from enum policy.v1.types.DocumentType
 */
export enum DocumentType {
  /**
   * @generated from enum value: DOCUMENT_TYPE_OTHER_UNSPECIFIED = 0;
   */
  OTHER_UNSPECIFIED = 0,

  /**
   * @generated from enum value: DOCUMENT_TYPE_POLICY = 1;
   */
  POLICY = 1,

  /**
   * @generated from enum value: DOCUMENT_TYPE_STANDARD = 2;
   */
  STANDARD = 2,

  /**
   * @generated from enum value: DOCUMENT_TYPE_PROCEDURE = 3;
   */
  PROCEDURE = 3,

  /**
   * @generated from enum value: DOCUMENT_TYPE_GUIDELINE = 4;
   */
  GUIDELINE = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(DocumentType)
proto3.util.setEnumType(DocumentType, "policy.v1.types.DocumentType", [
  { no: 0, name: "DOCUMENT_TYPE_OTHER_UNSPECIFIED" },
  { no: 1, name: "DOCUMENT_TYPE_POLICY" },
  { no: 2, name: "DOCUMENT_TYPE_STANDARD" },
  { no: 3, name: "DOCUMENT_TYPE_PROCEDURE" },
  { no: 4, name: "DOCUMENT_TYPE_GUIDELINE" },
]);

/**
 * @generated from enum policy.v1.types.SecurityClassification
 */
export enum SecurityClassification {
  /**
   * @generated from enum value: SECURITY_CLASSIFICATION_INTERNAL_UNSPECIFIED = 0;
   */
  INTERNAL_UNSPECIFIED = 0,

  /**
   * @generated from enum value: SECURITY_CLASSIFICATION_PUBLIC = 1;
   */
  PUBLIC = 1,

  /**
   * @generated from enum value: SECURITY_CLASSIFICATION_CONFIDENTIAL = 2;
   */
  CONFIDENTIAL = 2,

  /**
   * @generated from enum value: SECURITY_CLASSIFICATION_SECRET = 3;
   */
  SECRET = 3,

  /**
   * @generated from enum value: SECURITY_CLASSIFICATION_TOP_SECRET = 4;
   */
  TOP_SECRET = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(SecurityClassification)
proto3.util.setEnumType(SecurityClassification, "policy.v1.types.SecurityClassification", [
  { no: 0, name: "SECURITY_CLASSIFICATION_INTERNAL_UNSPECIFIED" },
  { no: 1, name: "SECURITY_CLASSIFICATION_PUBLIC" },
  { no: 2, name: "SECURITY_CLASSIFICATION_CONFIDENTIAL" },
  { no: 3, name: "SECURITY_CLASSIFICATION_SECRET" },
  { no: 4, name: "SECURITY_CLASSIFICATION_TOP_SECRET" },
]);

/**
 * @generated from enum policy.v1.types.DocumentStatus
 */
export enum DocumentStatus {
  /**
   * @generated from enum value: DOCUMENT_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DOCUMENT_STATUS_DRAFT = 1;
   */
  DRAFT = 1,

  /**
   * @generated from enum value: DOCUMENT_STATUS_CHANGE_REQUESTED = 2;
   */
  CHANGE_REQUESTED = 2,

  /**
   * @generated from enum value: DOCUMENT_STATUS_PENDING_REVIEW = 3;
   */
  PENDING_REVIEW = 3,

  /**
   * @generated from enum value: DOCUMENT_STATUS_REVIEWED = 4;
   */
  REVIEWED = 4,

  /**
   * @generated from enum value: DOCUMENT_STATUS_PENDING_APPROVAL = 5;
   */
  PENDING_APPROVAL = 5,

  /**
   * @generated from enum value: DOCUMENT_STATUS_APPROVED = 6;
   */
  APPROVED = 6,

  /**
   * @generated from enum value: DOCUMENT_STATUS_PUBLISHED = 7;
   */
  PUBLISHED = 7,

  /**
   * @generated from enum value: DOCUMENT_STATUS_ACTIVE = 8;
   */
  ACTIVE = 8,

  /**
   * @generated from enum value: DOCUMENT_STATUS_ARCHIVED = 9;
   */
  ARCHIVED = 9,

  /**
   * @generated from enum value: DOCUMENT_STATUS_DEPRECATED = 10;
   */
  DEPRECATED = 10,
}
// Retrieve enum metadata with: proto3.getEnumType(DocumentStatus)
proto3.util.setEnumType(DocumentStatus, "policy.v1.types.DocumentStatus", [
  { no: 0, name: "DOCUMENT_STATUS_UNSPECIFIED" },
  { no: 1, name: "DOCUMENT_STATUS_DRAFT" },
  { no: 2, name: "DOCUMENT_STATUS_CHANGE_REQUESTED" },
  { no: 3, name: "DOCUMENT_STATUS_PENDING_REVIEW" },
  { no: 4, name: "DOCUMENT_STATUS_REVIEWED" },
  { no: 5, name: "DOCUMENT_STATUS_PENDING_APPROVAL" },
  { no: 6, name: "DOCUMENT_STATUS_APPROVED" },
  { no: 7, name: "DOCUMENT_STATUS_PUBLISHED" },
  { no: 8, name: "DOCUMENT_STATUS_ACTIVE" },
  { no: 9, name: "DOCUMENT_STATUS_ARCHIVED" },
  { no: 10, name: "DOCUMENT_STATUS_DEPRECATED" },
]);

/**
 * @generated from enum policy.v1.types.AnswerType
 */
export enum AnswerType {
  /**
   * @generated from enum value: ANSWER_TYPE_STRING = 0;
   */
  STRING = 0,

  /**
   * @generated from enum value: ANSWER_TYPE_STRING_ARRAY = 1;
   */
  STRING_ARRAY = 1,

  /**
   * @generated from enum value: ANSWER_TYPE_BOOL = 2;
   */
  BOOL = 2,

  /**
   * @generated from enum value: ANSWER_TYPE_INT = 3;
   */
  INT = 3,

  /**
   * @generated from enum value: ANSWER_TYPE_INT_ARRAY = 4;
   */
  INT_ARRAY = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(AnswerType)
proto3.util.setEnumType(AnswerType, "policy.v1.types.AnswerType", [
  { no: 0, name: "ANSWER_TYPE_STRING" },
  { no: 1, name: "ANSWER_TYPE_STRING_ARRAY" },
  { no: 2, name: "ANSWER_TYPE_BOOL" },
  { no: 3, name: "ANSWER_TYPE_INT" },
  { no: 4, name: "ANSWER_TYPE_INT_ARRAY" },
]);

/**
 * this is for test
 *
 * @generated from message policy.v1.types.MetaUser
 */
export class MetaUser extends Message<MetaUser> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string first_name = 3;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 4;
   */
  lastName = "";

  constructor(data?: PartialMessage<MetaUser>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.MetaUser";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MetaUser {
    return new MetaUser().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MetaUser {
    return new MetaUser().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MetaUser {
    return new MetaUser().fromJsonString(jsonString, options);
  }

  static equals(a: MetaUser | PlainMessage<MetaUser> | undefined, b: MetaUser | PlainMessage<MetaUser> | undefined): boolean {
    return proto3.util.equals(MetaUser, a, b);
  }
}

/**
 * Reviewrs/ Approviers
 *
 * @generated from message policy.v1.types.LastEvaluated
 */
export class LastEvaluated extends Message<LastEvaluated> {
  /**
   * @generated from field: string Pk = 1;
   */
  Pk = "";

  /**
   * @generated from field: string Sk = 2;
   */
  Sk = "";

  /**
   * @generated from field: string Gsi1Pk = 3;
   */
  Gsi1Pk = "";

  /**
   * @generated from field: string Gsi1Sk = 4;
   */
  Gsi1Sk = "";

  constructor(data?: PartialMessage<LastEvaluated>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.LastEvaluated";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "Pk", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Sk", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Gsi1Pk", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "Gsi1Sk", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LastEvaluated {
    return new LastEvaluated().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LastEvaluated {
    return new LastEvaluated().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LastEvaluated {
    return new LastEvaluated().fromJsonString(jsonString, options);
  }

  static equals(a: LastEvaluated | PlainMessage<LastEvaluated> | undefined, b: LastEvaluated | PlainMessage<LastEvaluated> | undefined): boolean {
    return proto3.util.equals(LastEvaluated, a, b);
  }
}

/**
 * @generated from message policy.v1.types.Supervisor
 */
export class Supervisor extends Message<Supervisor> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: policy.v1.types.SupervisorType type = 2;
   */
  type = SupervisorType.EDITOR_UNSPECIFIED;

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * @generated from field: string created_at = 5;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 6;
   */
  updatedAt = "";

  /**
   * @generated from field: string notified_at = 7;
   */
  notifiedAt = "";

  /**
   * @generated from field: optional bool action_completed = 8;
   */
  actionCompleted?: boolean;

  constructor(data?: PartialMessage<Supervisor>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.Supervisor";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(SupervisorType) },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "notified_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "action_completed", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Supervisor {
    return new Supervisor().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Supervisor {
    return new Supervisor().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Supervisor {
    return new Supervisor().fromJsonString(jsonString, options);
  }

  static equals(a: Supervisor | PlainMessage<Supervisor> | undefined, b: Supervisor | PlainMessage<Supervisor> | undefined): boolean {
    return proto3.util.equals(Supervisor, a, b);
  }
}

/**
 * @generated from message policy.v1.types.MetadataValue
 */
export class MetadataValue extends Message<MetadataValue> {
  /**
   * @generated from oneof policy.v1.types.MetadataValue.value
   */
  value: {
    /**
     * @generated from field: string str_value = 1;
     */
    value: string;
    case: "strValue";
  } | {
    /**
     * @generated from field: int32 int_value = 2;
     */
    value: number;
    case: "intValue";
  } | {
    /**
     * @generated from field: bool bool_value = 3;
     */
    value: boolean;
    case: "boolValue";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<MetadataValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.MetadataValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "str_value", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
    { no: 2, name: "int_value", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "value" },
    { no: 3, name: "bool_value", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MetadataValue {
    return new MetadataValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MetadataValue {
    return new MetadataValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MetadataValue {
    return new MetadataValue().fromJsonString(jsonString, options);
  }

  static equals(a: MetadataValue | PlainMessage<MetadataValue> | undefined, b: MetadataValue | PlainMessage<MetadataValue> | undefined): boolean {
    return proto3.util.equals(MetadataValue, a, b);
  }
}

/**
 * @generated from message policy.v1.types.FolderObejct
 */
export class FolderObejct extends Message<FolderObejct> {
  /**
   * @generated from oneof policy.v1.types.FolderObejct.object
   */
  object: {
    /**
     * @generated from field: policy.v1.types.Folder folder = 1;
     */
    value: Folder;
    case: "folder";
  } | {
    /**
     * @generated from field: policy.v1.types.Document document = 2;
     */
    value: Document;
    case: "document";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<FolderObejct>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.FolderObejct";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "folder", kind: "message", T: Folder, oneof: "object" },
    { no: 2, name: "document", kind: "message", T: Document, oneof: "object" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FolderObejct {
    return new FolderObejct().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FolderObejct {
    return new FolderObejct().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FolderObejct {
    return new FolderObejct().fromJsonString(jsonString, options);
  }

  static equals(a: FolderObejct | PlainMessage<FolderObejct> | undefined, b: FolderObejct | PlainMessage<FolderObejct> | undefined): boolean {
    return proto3.util.equals(FolderObejct, a, b);
  }
}

/**
 * Hierarchical information
 *
 * @generated from message policy.v1.types.FolderHierarchy
 */
export class FolderHierarchy extends Message<FolderHierarchy> {
  /**
   * DRI of the parent folder
   *
   * @generated from field: string parent_dri = 1;
   */
  parentDri = "";

  /**
   * Full path of the folder
   *
   * @generated from field: string folder_path = 2;
   */
  folderPath = "";

  constructor(data?: PartialMessage<FolderHierarchy>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.FolderHierarchy";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "folder_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FolderHierarchy {
    return new FolderHierarchy().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FolderHierarchy {
    return new FolderHierarchy().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FolderHierarchy {
    return new FolderHierarchy().fromJsonString(jsonString, options);
  }

  static equals(a: FolderHierarchy | PlainMessage<FolderHierarchy> | undefined, b: FolderHierarchy | PlainMessage<FolderHierarchy> | undefined): boolean {
    return proto3.util.equals(FolderHierarchy, a, b);
  }
}

/**
 * @generated from message policy.v1.types.FolderTypeInfo
 */
export class FolderTypeInfo extends Message<FolderTypeInfo> {
  /**
   * Type of the folder
   *
   * @generated from field: policy.v1.types.FolderType type = 1;
   */
  type = FolderType.UNSPECIFIED;

  /**
   * Icon representing the folder
   *
   * @generated from field: policy.v1.types.FolderIcon icon = 2;
   */
  icon = FolderIcon.OTHER_UNSPECIFIED;

  constructor(data?: PartialMessage<FolderTypeInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.FolderTypeInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(FolderType) },
    { no: 2, name: "icon", kind: "enum", T: proto3.getEnumType(FolderIcon) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FolderTypeInfo {
    return new FolderTypeInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FolderTypeInfo {
    return new FolderTypeInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FolderTypeInfo {
    return new FolderTypeInfo().fromJsonString(jsonString, options);
  }

  static equals(a: FolderTypeInfo | PlainMessage<FolderTypeInfo> | undefined, b: FolderTypeInfo | PlainMessage<FolderTypeInfo> | undefined): boolean {
    return proto3.util.equals(FolderTypeInfo, a, b);
  }
}

/**
 * @generated from message policy.v1.types.Folder
 */
export class Folder extends Message<Folder> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: policy.v1.types.FolderHierarchy hierarchy = 4;
   */
  hierarchy?: FolderHierarchy;

  /**
   * @generated from field: policy.v1.types.FolderTypeInfo type_info = 5;
   */
  typeInfo?: FolderTypeInfo;

  /**
   * @generated from field: int32 object_count = 6;
   */
  objectCount = 0;

  /**
   * @generated from field: string created_at = 7;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 8;
   */
  updatedAt = "";

  /**
   * @generated from field: map<string, policy.v1.types.MetadataValue> metadata = 9;
   */
  metadata: { [key: string]: MetadataValue } = {};

  constructor(data?: PartialMessage<Folder>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.Folder";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "hierarchy", kind: "message", T: FolderHierarchy },
    { no: 5, name: "type_info", kind: "message", T: FolderTypeInfo },
    { no: 6, name: "object_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: MetadataValue} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Folder {
    return new Folder().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Folder {
    return new Folder().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Folder {
    return new Folder().fromJsonString(jsonString, options);
  }

  static equals(a: Folder | PlainMessage<Folder> | undefined, b: Folder | PlainMessage<Folder> | undefined): boolean {
    return proto3.util.equals(Folder, a, b);
  }
}

/**
 * @generated from message policy.v1.types.PublishedVersion
 */
export class PublishedVersion extends Message<PublishedVersion> {
  /**
   * @generated from field: int32 version_number = 1;
   */
  versionNumber = 0;

  /**
   * @generated from field: string s3_version = 2;
   */
  s3Version = "";

  /**
   * @generated from field: string enforced_from = 3;
   */
  enforcedFrom = "";

  /**
   * @generated from field: policy.v1.types.DocumentSupervisors supervisors = 4;
   */
  supervisors?: DocumentSupervisors;

  constructor(data?: PartialMessage<PublishedVersion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.PublishedVersion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "version_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "s3_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "enforced_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "supervisors", kind: "message", T: DocumentSupervisors },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PublishedVersion {
    return new PublishedVersion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PublishedVersion {
    return new PublishedVersion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PublishedVersion {
    return new PublishedVersion().fromJsonString(jsonString, options);
  }

  static equals(a: PublishedVersion | PlainMessage<PublishedVersion> | undefined, b: PublishedVersion | PlainMessage<PublishedVersion> | undefined): boolean {
    return proto3.util.equals(PublishedVersion, a, b);
  }
}

/**
 * @generated from message policy.v1.types.DocumentSettings
 */
export class DocumentSettings extends Message<DocumentSettings> {
  /**
   * @generated from field: optional bool skip_review = 1;
   */
  skipReview?: boolean;

  /**
   * @generated from field: optional bool skip_approval = 2;
   */
  skipApproval?: boolean;

  /**
   * @generated from field: optional bool auto_approval = 3;
   */
  autoApproval?: boolean;

  /**
   * @generated from field: optional bool auto_publish = 4;
   */
  autoPublish?: boolean;

  /**
   * @generated from field: optional bool ai_enabled = 5;
   */
  aiEnabled?: boolean;

  constructor(data?: PartialMessage<DocumentSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.DocumentSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "skip_review", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 2, name: "skip_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 3, name: "auto_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 4, name: "auto_publish", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 5, name: "ai_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DocumentSettings {
    return new DocumentSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DocumentSettings {
    return new DocumentSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DocumentSettings {
    return new DocumentSettings().fromJsonString(jsonString, options);
  }

  static equals(a: DocumentSettings | PlainMessage<DocumentSettings> | undefined, b: DocumentSettings | PlainMessage<DocumentSettings> | undefined): boolean {
    return proto3.util.equals(DocumentSettings, a, b);
  }
}

/**
 * @generated from message policy.v1.types.DocumentSupervisors
 */
export class DocumentSupervisors extends Message<DocumentSupervisors> {
  /**
   * @generated from field: repeated policy.v1.types.Supervisor owners = 1;
   */
  owners: Supervisor[] = [];

  /**
   * @generated from field: repeated policy.v1.types.Supervisor editors = 2;
   */
  editors: Supervisor[] = [];

  /**
   * @generated from field: repeated policy.v1.types.Supervisor reviewers = 3;
   */
  reviewers: Supervisor[] = [];

  /**
   * @generated from field: repeated policy.v1.types.Supervisor approvers = 4;
   */
  approvers: Supervisor[] = [];

  constructor(data?: PartialMessage<DocumentSupervisors>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.DocumentSupervisors";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "owners", kind: "message", T: Supervisor, repeated: true },
    { no: 2, name: "editors", kind: "message", T: Supervisor, repeated: true },
    { no: 3, name: "reviewers", kind: "message", T: Supervisor, repeated: true },
    { no: 4, name: "approvers", kind: "message", T: Supervisor, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DocumentSupervisors {
    return new DocumentSupervisors().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DocumentSupervisors {
    return new DocumentSupervisors().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DocumentSupervisors {
    return new DocumentSupervisors().fromJsonString(jsonString, options);
  }

  static equals(a: DocumentSupervisors | PlainMessage<DocumentSupervisors> | undefined, b: DocumentSupervisors | PlainMessage<DocumentSupervisors> | undefined): boolean {
    return proto3.util.equals(DocumentSupervisors, a, b);
  }
}

/**
 * @generated from message policy.v1.types.DocumentVersioning
 */
export class DocumentVersioning extends Message<DocumentVersioning> {
  /**
   * Latest version number of the document
   *
   * @generated from field: int32 latest_version = 1;
   */
  latestVersion = 0;

  /**
   * Versions of the document mapped by version number
   *
   * @generated from field: map<int32, policy.v1.types.PublishedVersion> published_versions = 2;
   */
  publishedVersions: { [key: number]: PublishedVersion } = {};

  /**
   * S3 version key of the document
   *
   * @generated from field: string s3_version_key = 3;
   */
  s3VersionKey = "";

  constructor(data?: PartialMessage<DocumentVersioning>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.DocumentVersioning";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "latest_version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "published_versions", kind: "map", K: 5 /* ScalarType.INT32 */, V: {kind: "message", T: PublishedVersion} },
    { no: 3, name: "s3_version_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DocumentVersioning {
    return new DocumentVersioning().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DocumentVersioning {
    return new DocumentVersioning().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DocumentVersioning {
    return new DocumentVersioning().fromJsonString(jsonString, options);
  }

  static equals(a: DocumentVersioning | PlainMessage<DocumentVersioning> | undefined, b: DocumentVersioning | PlainMessage<DocumentVersioning> | undefined): boolean {
    return proto3.util.equals(DocumentVersioning, a, b);
  }
}

/**
 * File details
 *
 * @generated from message policy.v1.types.FileDetails
 */
export class FileDetails extends Message<FileDetails> {
  /**
   * File size in bytes
   *
   * @generated from field: int64 file_size = 1;
   */
  fileSize = protoInt64.zero;

  /**
   * @generated from field: string signed_url = 2;
   */
  signedUrl = "";

  constructor(data?: PartialMessage<FileDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.FileDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "file_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "signed_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileDetails {
    return new FileDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileDetails {
    return new FileDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileDetails {
    return new FileDetails().fromJsonString(jsonString, options);
  }

  static equals(a: FileDetails | PlainMessage<FileDetails> | undefined, b: FileDetails | PlainMessage<FileDetails> | undefined): boolean {
    return proto3.util.equals(FileDetails, a, b);
  }
}

/**
 * @generated from message policy.v1.types.Document
 */
export class Document extends Message<Document> {
  /**
   * Unique Document Resource Identifier
   *
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * Name of the document
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * Description of the document
   *
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * DRI of the parent folder
   *
   * @generated from field: string parent_folder_dri = 4;
   */
  parentFolderDri = "";

  /**
   * Type of the document
   *
   * @generated from field: policy.v1.types.DocumentType type = 5;
   */
  type = DocumentType.OTHER_UNSPECIFIED;

  /**
   * @generated from field: policy.v1.types.DocumentStatus status = 6;
   */
  status = DocumentStatus.UNSPECIFIED;

  /**
   * @generated from field: policy.v1.types.SecurityClassification security_classification = 7;
   */
  securityClassification = SecurityClassification.INTERNAL_UNSPECIFIED;

  /**
   * @generated from field: policy.v1.types.DocumentSettings settings = 8;
   */
  settings?: DocumentSettings;

  /**
   * @generated from field: policy.v1.types.DocumentSupervisors supervisors = 9;
   */
  supervisors?: DocumentSupervisors;

  /**
   * @generated from field: policy.v1.types.DocumentVersioning versioning = 10;
   */
  versioning?: DocumentVersioning;

  /**
   * @generated from field: policy.v1.types.FileDetails file_details = 11;
   */
  fileDetails?: FileDetails;

  /**
   * @generated from field: string created_at = 12;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 13;
   */
  updatedAt = "";

  /**
   * @generated from field: map<string, policy.v1.types.MetadataValue> metadata = 14;
   */
  metadata: { [key: string]: MetadataValue } = {};

  constructor(data?: PartialMessage<Document>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.Document";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "parent_folder_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(DocumentType) },
    { no: 6, name: "status", kind: "enum", T: proto3.getEnumType(DocumentStatus) },
    { no: 7, name: "security_classification", kind: "enum", T: proto3.getEnumType(SecurityClassification) },
    { no: 8, name: "settings", kind: "message", T: DocumentSettings },
    { no: 9, name: "supervisors", kind: "message", T: DocumentSupervisors },
    { no: 10, name: "versioning", kind: "message", T: DocumentVersioning },
    { no: 11, name: "file_details", kind: "message", T: FileDetails },
    { no: 12, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: MetadataValue} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Document {
    return new Document().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Document {
    return new Document().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Document {
    return new Document().fromJsonString(jsonString, options);
  }

  static equals(a: Document | PlainMessage<Document> | undefined, b: Document | PlainMessage<Document> | undefined): boolean {
    return proto3.util.equals(Document, a, b);
  }
}

/**
 * @generated from message policy.v1.types.Template
 */
export class Template extends Message<Template> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string template_id = 3;
   */
  templateId = "";

  /**
   * @generated from field: policy.v1.types.FileDetails file_details = 4;
   */
  fileDetails?: FileDetails;

  /**
   * @generated from field: policy.v1.types.DocumentType type = 5;
   */
  type = DocumentType.OTHER_UNSPECIFIED;

  /**
   * @generated from field: policy.v1.types.SecurityClassification security_classification = 6;
   */
  securityClassification = SecurityClassification.INTERNAL_UNSPECIFIED;

  /**
   * @generated from field: string created_at = 7;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 8;
   */
  updatedAt = "";

  /**
   * @generated from field: map<string, policy.v1.types.RoleData> roles_map = 9;
   */
  rolesMap: { [key: string]: RoleData } = {};

  /**
   * @generated from field: map<string, policy.v1.types.ControlData> controls_map = 10;
   */
  controlsMap: { [key: string]: ControlData } = {};

  constructor(data?: PartialMessage<Template>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.Template";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "file_details", kind: "message", T: FileDetails },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(DocumentType) },
    { no: 6, name: "security_classification", kind: "enum", T: proto3.getEnumType(SecurityClassification) },
    { no: 7, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "roles_map", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: RoleData} },
    { no: 10, name: "controls_map", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: ControlData} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Template {
    return new Template().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Template {
    return new Template().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Template {
    return new Template().fromJsonString(jsonString, options);
  }

  static equals(a: Template | PlainMessage<Template> | undefined, b: Template | PlainMessage<Template> | undefined): boolean {
    return proto3.util.equals(Template, a, b);
  }
}

/**
 * @generated from message policy.v1.types.RoleData
 */
export class RoleData extends Message<RoleData> {
  /**
   * @generated from field: string document_location = 1;
   */
  documentLocation = "";

  /**
   * will add more data
   *
   * @generated from field: string created_at = 2;
   */
  createdAt = "";

  constructor(data?: PartialMessage<RoleData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.RoleData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document_location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RoleData {
    return new RoleData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RoleData {
    return new RoleData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RoleData {
    return new RoleData().fromJsonString(jsonString, options);
  }

  static equals(a: RoleData | PlainMessage<RoleData> | undefined, b: RoleData | PlainMessage<RoleData> | undefined): boolean {
    return proto3.util.equals(RoleData, a, b);
  }
}

/**
 * @generated from message policy.v1.types.ControlData
 */
export class ControlData extends Message<ControlData> {
  /**
   * @generated from field: string document_location = 1;
   */
  documentLocation = "";

  /**
   * @generated from field: string created_at = 2;
   */
  createdAt = "";

  constructor(data?: PartialMessage<ControlData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.ControlData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document_location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ControlData {
    return new ControlData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ControlData {
    return new ControlData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ControlData {
    return new ControlData().fromJsonString(jsonString, options);
  }

  static equals(a: ControlData | PlainMessage<ControlData> | undefined, b: ControlData | PlainMessage<ControlData> | undefined): boolean {
    return proto3.util.equals(ControlData, a, b);
  }
}

/**
 * @generated from message policy.v1.types.Role
 */
export class Role extends Message<Role> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string role_id = 2;
   */
  roleId = "";

  /**
   * @generated from field: string parent_role_dri = 3;
   */
  parentRoleDri = "";

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * @generated from field: string responsibilities = 5;
   */
  responsibilities = "";

  /**
   * @generated from field: map<string, policy.v1.types.RoleResponsibility> document_responsibility_map = 6;
   */
  documentResponsibilityMap: { [key: string]: RoleResponsibility } = {};

  /**
   * @generated from field: map<string, policy.v1.types.Role> collapsed_roles = 7;
   */
  collapsedRoles: { [key: string]: Role } = {};

  /**
   * @generated from field: string created_at = 8;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 9;
   */
  updatedAt = "";

  /**
   * @generated from field: map<string, policy.v1.types.MetadataValue> metadata = 10;
   */
  metadata: { [key: string]: MetadataValue } = {};

  constructor(data?: PartialMessage<Role>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.Role";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "parent_role_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "responsibilities", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "document_responsibility_map", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: RoleResponsibility} },
    { no: 7, name: "collapsed_roles", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Role} },
    { no: 8, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: MetadataValue} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Role {
    return new Role().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Role {
    return new Role().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Role {
    return new Role().fromJsonString(jsonString, options);
  }

  static equals(a: Role | PlainMessage<Role> | undefined, b: Role | PlainMessage<Role> | undefined): boolean {
    return proto3.util.equals(Role, a, b);
  }
}

/**
 * @generated from message policy.v1.types.RoleResponsibility
 */
export class RoleResponsibility extends Message<RoleResponsibility> {
  /**
   * @generated from field: string document_dri = 1;
   */
  documentDri = "";

  /**
   * @generated from field: string responsibilities = 2;
   */
  responsibilities = "";

  constructor(data?: PartialMessage<RoleResponsibility>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.RoleResponsibility";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "responsibilities", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RoleResponsibility {
    return new RoleResponsibility().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RoleResponsibility {
    return new RoleResponsibility().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RoleResponsibility {
    return new RoleResponsibility().fromJsonString(jsonString, options);
  }

  static equals(a: RoleResponsibility | PlainMessage<RoleResponsibility> | undefined, b: RoleResponsibility | PlainMessage<RoleResponsibility> | undefined): boolean {
    return proto3.util.equals(RoleResponsibility, a, b);
  }
}

/**
 * message Control {
 *   string dri = 1;
 *   map<string, string> document_statement_map = 2; // map of related docsdris: statements
 *   string owner = 3;
 *   repeated string mapped_grc_control_dri = 4;
 *   bool evaluated = 5;
 *   string created_at = 6;
 *   string updated_at = 7;
 *   map<string,MetadataValue> metadata = 8;
 * }
 *
 * @generated from message policy.v1.types.OrganizationProfile
 */
export class OrganizationProfile extends Message<OrganizationProfile> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string org_name = 2;
   */
  orgName = "";

  /**
   * @generated from field: repeated string locations = 3;
   */
  locations: string[] = [];

  /**
   * @generated from field: repeated string security_emails = 4;
   */
  securityEmails: string[] = [];

  /**
   * @generated from field: repeated string industries = 5;
   */
  industries: string[] = [];

  /**
   * @generated from field: string org_size = 6;
   */
  orgSize = "";

  /**
   * @generated from field: map<string, policy.v1.types.MetadataValue> metadata = 7;
   */
  metadata: { [key: string]: MetadataValue } = {};

  constructor(data?: PartialMessage<OrganizationProfile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.OrganizationProfile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "org_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "locations", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "security_emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "industries", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "org_size", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: MetadataValue} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrganizationProfile {
    return new OrganizationProfile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrganizationProfile {
    return new OrganizationProfile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrganizationProfile {
    return new OrganizationProfile().fromJsonString(jsonString, options);
  }

  static equals(a: OrganizationProfile | PlainMessage<OrganizationProfile> | undefined, b: OrganizationProfile | PlainMessage<OrganizationProfile> | undefined): boolean {
    return proto3.util.equals(OrganizationProfile, a, b);
  }
}

/**
 * @generated from message policy.v1.types.DefaultSettings
 */
export class DefaultSettings extends Message<DefaultSettings> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * DRI of the parent folder
   *
   * @generated from field: string parent_folder_dri = 2;
   */
  parentFolderDri = "";

  /**
   * Type of the document
   *
   * @generated from field: policy.v1.types.DocumentType type = 3;
   */
  type = DocumentType.OTHER_UNSPECIFIED;

  /**
   * @generated from field: policy.v1.types.SecurityClassification security_classification = 4;
   */
  securityClassification = SecurityClassification.INTERNAL_UNSPECIFIED;

  /**
   * @generated from field: policy.v1.types.DocumentSettings settings = 5;
   */
  settings?: DocumentSettings;

  /**
   * @generated from field: policy.v1.types.DocumentSupervisors supervisors = 6;
   */
  supervisors?: DocumentSupervisors;

  constructor(data?: PartialMessage<DefaultSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.DefaultSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "parent_folder_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(DocumentType) },
    { no: 4, name: "security_classification", kind: "enum", T: proto3.getEnumType(SecurityClassification) },
    { no: 5, name: "settings", kind: "message", T: DocumentSettings },
    { no: 6, name: "supervisors", kind: "message", T: DocumentSupervisors },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DefaultSettings {
    return new DefaultSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DefaultSettings {
    return new DefaultSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DefaultSettings {
    return new DefaultSettings().fromJsonString(jsonString, options);
  }

  static equals(a: DefaultSettings | PlainMessage<DefaultSettings> | undefined, b: DefaultSettings | PlainMessage<DefaultSettings> | undefined): boolean {
    return proto3.util.equals(DefaultSettings, a, b);
  }
}

/**
 * @generated from message policy.v1.types.OnboardingQuestionTree
 */
export class OnboardingQuestionTree extends Message<OnboardingQuestionTree> {
  /**
   * @generated from field: repeated policy.v1.types.QuestionNode child_nodes = 1;
   */
  childNodes: QuestionNode[] = [];

  constructor(data?: PartialMessage<OnboardingQuestionTree>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.OnboardingQuestionTree";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "child_nodes", kind: "message", T: QuestionNode, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OnboardingQuestionTree {
    return new OnboardingQuestionTree().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OnboardingQuestionTree {
    return new OnboardingQuestionTree().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OnboardingQuestionTree {
    return new OnboardingQuestionTree().fromJsonString(jsonString, options);
  }

  static equals(a: OnboardingQuestionTree | PlainMessage<OnboardingQuestionTree> | undefined, b: OnboardingQuestionTree | PlainMessage<OnboardingQuestionTree> | undefined): boolean {
    return proto3.util.equals(OnboardingQuestionTree, a, b);
  }
}

/**
 * @generated from message policy.v1.types.ChildQuestionMapping
 */
export class ChildQuestionMapping extends Message<ChildQuestionMapping> {
  /**
   * @generated from field: repeated string child_question_ids = 1;
   */
  childQuestionIds: string[] = [];

  /**
   * @generated from oneof policy.v1.types.ChildQuestionMapping.answer
   */
  answer: {
    /**
     * @generated from field: string string_answer = 2;
     */
    value: string;
    case: "stringAnswer";
  } | {
    /**
     * @generated from field: int32 int_answer = 3;
     */
    value: number;
    case: "intAnswer";
  } | {
    /**
     * @generated from field: bool bool_answer = 4;
     */
    value: boolean;
    case: "boolAnswer";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ChildQuestionMapping>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.ChildQuestionMapping";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "child_question_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "string_answer", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "answer" },
    { no: 3, name: "int_answer", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "answer" },
    { no: 4, name: "bool_answer", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "answer" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChildQuestionMapping {
    return new ChildQuestionMapping().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChildQuestionMapping {
    return new ChildQuestionMapping().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChildQuestionMapping {
    return new ChildQuestionMapping().fromJsonString(jsonString, options);
  }

  static equals(a: ChildQuestionMapping | PlainMessage<ChildQuestionMapping> | undefined, b: ChildQuestionMapping | PlainMessage<ChildQuestionMapping> | undefined): boolean {
    return proto3.util.equals(ChildQuestionMapping, a, b);
  }
}

/**
 * @generated from message policy.v1.types.QuestionDocumentMapping
 */
export class QuestionDocumentMapping extends Message<QuestionDocumentMapping> {
  /**
   * documetns will be mapped to templatedri and default parent folder dris
   *
   * @generated from field: repeated string documents = 1;
   */
  documents: string[] = [];

  /**
   * @generated from oneof policy.v1.types.QuestionDocumentMapping.answer
   */
  answer: {
    /**
     * @generated from field: string string_answer = 2;
     */
    value: string;
    case: "stringAnswer";
  } | {
    /**
     * @generated from field: int32 int_answer = 3;
     */
    value: number;
    case: "intAnswer";
  } | {
    /**
     * @generated from field: bool bool_answer = 4;
     */
    value: boolean;
    case: "boolAnswer";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<QuestionDocumentMapping>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.QuestionDocumentMapping";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "documents", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "string_answer", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "answer" },
    { no: 3, name: "int_answer", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "answer" },
    { no: 4, name: "bool_answer", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "answer" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionDocumentMapping {
    return new QuestionDocumentMapping().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionDocumentMapping {
    return new QuestionDocumentMapping().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionDocumentMapping {
    return new QuestionDocumentMapping().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionDocumentMapping | PlainMessage<QuestionDocumentMapping> | undefined, b: QuestionDocumentMapping | PlainMessage<QuestionDocumentMapping> | undefined): boolean {
    return proto3.util.equals(QuestionDocumentMapping, a, b);
  }
}

/**
 * @generated from message policy.v1.types.QuestionNode
 */
export class QuestionNode extends Message<QuestionNode> {
  /**
   * @generated from field: string question_id = 1;
   */
  questionId = "";

  /**
   * @generated from field: string question = 2;
   */
  question = "";

  /**
   * @generated from field: int32 priority = 3;
   */
  priority = 0;

  /**
   * for mapping with org profile
   *
   * @generated from field: string org_profile_field = 4;
   */
  orgProfileField = "";

  /**
   * @generated from field: policy.v1.types.AnswerType answer_type = 5;
   */
  answerType = AnswerType.STRING;

  /**
   * @generated from field: repeated string option_answers = 6;
   */
  optionAnswers: string[] = [];

  /**
   * @generated from field: repeated policy.v1.types.QuestionNode child_nodes = 7;
   */
  childNodes: QuestionNode[] = [];

  /**
   * @generated from field: repeated policy.v1.types.ChildQuestionMapping child_question_mapping = 8;
   */
  childQuestionMapping: ChildQuestionMapping[] = [];

  /**
   * @generated from field: repeated policy.v1.types.QuestionDocumentMapping document_mappings = 9;
   */
  documentMappings: QuestionDocumentMapping[] = [];

  constructor(data?: PartialMessage<QuestionNode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.QuestionNode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "priority", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "org_profile_field", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "answer_type", kind: "enum", T: proto3.getEnumType(AnswerType) },
    { no: 6, name: "option_answers", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "child_nodes", kind: "message", T: QuestionNode, repeated: true },
    { no: 8, name: "child_question_mapping", kind: "message", T: ChildQuestionMapping, repeated: true },
    { no: 9, name: "document_mappings", kind: "message", T: QuestionDocumentMapping, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionNode {
    return new QuestionNode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionNode {
    return new QuestionNode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionNode {
    return new QuestionNode().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionNode | PlainMessage<QuestionNode> | undefined, b: QuestionNode | PlainMessage<QuestionNode> | undefined): boolean {
    return proto3.util.equals(QuestionNode, a, b);
  }
}

/**
 * @generated from message policy.v1.types.OnboardingAnswerTree
 */
export class OnboardingAnswerTree extends Message<OnboardingAnswerTree> {
  /**
   * @generated from field: repeated policy.v1.types.AnswerNode child_nodes = 1;
   */
  childNodes: AnswerNode[] = [];

  constructor(data?: PartialMessage<OnboardingAnswerTree>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.OnboardingAnswerTree";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "child_nodes", kind: "message", T: AnswerNode, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OnboardingAnswerTree {
    return new OnboardingAnswerTree().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OnboardingAnswerTree {
    return new OnboardingAnswerTree().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OnboardingAnswerTree {
    return new OnboardingAnswerTree().fromJsonString(jsonString, options);
  }

  static equals(a: OnboardingAnswerTree | PlainMessage<OnboardingAnswerTree> | undefined, b: OnboardingAnswerTree | PlainMessage<OnboardingAnswerTree> | undefined): boolean {
    return proto3.util.equals(OnboardingAnswerTree, a, b);
  }
}

/**
 * @generated from message policy.v1.types.AnswerNode
 */
export class AnswerNode extends Message<AnswerNode> {
  /**
   * @generated from field: string question_id = 1;
   */
  questionId = "";

  /**
   * @generated from oneof policy.v1.types.AnswerNode.answer
   */
  answer: {
    /**
     * @generated from field: string string_answer = 2;
     */
    value: string;
    case: "stringAnswer";
  } | {
    /**
     * @generated from field: int32 int_answer = 3;
     */
    value: number;
    case: "intAnswer";
  } | {
    /**
     * @generated from field: bool bool_answer = 4;
     */
    value: boolean;
    case: "boolAnswer";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: repeated string string_arr_answer = 5;
   */
  stringArrAnswer: string[] = [];

  /**
   * @generated from field: repeated string int_arr_answer = 6;
   */
  intArrAnswer: string[] = [];

  /**
   * @generated from field: repeated policy.v1.types.AnswerNode child_nodes = 7;
   */
  childNodes: AnswerNode[] = [];

  constructor(data?: PartialMessage<AnswerNode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.types.AnswerNode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "string_answer", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "answer" },
    { no: 3, name: "int_answer", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "answer" },
    { no: 4, name: "bool_answer", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "answer" },
    { no: 5, name: "string_arr_answer", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "int_arr_answer", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "child_nodes", kind: "message", T: AnswerNode, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AnswerNode {
    return new AnswerNode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AnswerNode {
    return new AnswerNode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AnswerNode {
    return new AnswerNode().fromJsonString(jsonString, options);
  }

  static equals(a: AnswerNode | PlainMessage<AnswerNode> | undefined, b: AnswerNode | PlainMessage<AnswerNode> | undefined): boolean {
    return proto3.util.equals(AnswerNode, a, b);
  }
}

