import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Input,
  InputGroup,
  Stack,
  StackItem,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import Card from "../secure-view/Card";
import * as yup from "yup";
import { useAuth } from "../../../context/auth";
import { createRootKey, readRootKey } from "@defense-station/api-service";
import { SymDecryptMessage } from "../../../utils/encrypt";
import Centered from "../../../layouts/Centered";
import { Dropzone } from "../../Dropzone";
import {
  RootKeyProvider,
  useRootKey,
  withRootKeyProvider,
} from "../../../context/request/root-key";
import toast from "../../toast";
import CustomHeading from "../../custom-styled-components/CustomHeading";
import CustomDescription from "../../custom-styled-components/CustomDescription";

const validation = yup.object().shape({
  password: yup.string().required("Password can't be blank."),
});

const forgotValidation = yup.object().shape({
  file: yup.string().required("Phrase can't be blank."),
});

function EnterPassword({ onSubmit, isLoading }) {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const size = useBreakpointValue({
    base: "md",
    xl: "sm",
    "2xl": "md",
  });
  return (
    <Centered
      image={"linear-gradient(135deg, #9AE6B4 0%, #48BB78 100%)"}
      cardTop={{ base: "140px", md: "24vh" }}
      cardBottom={{ base: "50px", lg: "auto" }}
    >
      <Flex
        w="100%"
        maxW="max-content"
        me="auto"
        h="100%"
        alignItems="start"
        position={"relative"}
        justifyContent="center"
        px={{ base: "25px", md: "0px" }}
        flexDirection="column"
      >
        <>
          <Box me="auto" mb="34px">
            <CustomHeading color={textColor} mb="16px">
              Enter Password
            </CustomHeading>
            <CustomDescription
              color={textColorSecondary}
              w={{ base: "100%", lg: "456px" }}
              maxW="100%"
            >
              Enter the password set by the requester.
            </CustomDescription>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", lg: "456px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
            align="start"
          >
            <Formik
              enableReinitialize
              validateOnChange={false}
              validationSchema={validation}
              initialValues={{
                password: "",
              }}
              onSubmit={async (values, { resetForm }) => {
                try {
                  onSubmit(values?.password);
                } catch (e) {
                  if (e.name == "OperationError") {
                    toast({
                      title: "Invalid Password!",
                      description:
                        "The password you have entered, is not correct.",
                      status: "error",
                      duration: 4000,
                      isClosable: true,
                      position: "top",
                    });
                  } else {
                    toast({
                      title: "Error!",
                      description: e.message,
                      status: "error",
                      duration: 4000,
                      isClosable: true,
                      position: "top",
                    });
                  }
                }
              }}
            >
              {({
                values,
                errors,
                isSubmitting,
                setFieldValue,
                handleChange,
              }) => (
                <Form style={{ width: "100%" }}>
                  <Stack spacing={"2"} pb={"4"}>
                    <StackItem>
                      <FormControl isInvalid={errors.label}>
                        <InputGroup size={size}>
                          <Input
                            autoFocus
                            pr="4.5rem"
                            max={5}
                            type="password"
                            placeholder="Enter A Password"
                            required
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                          />
                        </InputGroup>
                        {errors.password && (
                          <FormErrorMessage>{errors.password}</FormErrorMessage>
                        )}
                      </FormControl>
                    </StackItem>
                    <StackItem></StackItem>
                    <Box mt={4}>
                      <Flex justifyContent="center">
                        <Button
                          size="md"
                          width={"full"}
                          colorScheme="brand"
                          mr={3}
                          type="submit"
                          isLoading={isLoading}
                        >
                          Submit
                        </Button>
                      </Flex>
                    </Box>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Flex>
        </>
      </Flex>
    </Centered>
  );
}

export default EnterPassword;
