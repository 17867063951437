import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormControl,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { addDays, endOfDay, startOfDay } from "date-fns";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { FREE_TIER, SOMETHING_WENT_WRONG, SUPPORTED_MIMETYPE } from "../../constants/constants";
import { useAuth } from "../../context/auth";
import { showErrorToast, showWarningToast } from "../../services/toast-service";
import CardHeaderText from "../custom-styled-components/CardHeaderText";
import CustomFormLabel from "../custom-styled-components/CustomFormLabel";
import NumberField from "../fields/NumberField";
import CustomSwitch from "../custom-styled-components/CustomSwitch";
import DatePicker from "../DatePicker";
import CustomButton from "../custom-styled-components/CustomButton";
import { toast } from "react-toastify";

export default function SendSettings({ settings }) {
  const { user } = useAuth();
  const baseDate = new Date();
  const [range, setRange] = useState([
    {
      start_date: startOfDay(baseDate),
      end_date: endOfDay(addDays(baseDate, 3)),
      startDate: startOfDay(baseDate),
      endDate: endOfDay(addDays(baseDate, 3)),
      key: "selection",
    },
  ]);
  const onSubmit = async (values) => {
    try {
      const variables = {
        access_limit: values?.click_allowed,
        access_device: values?.allowed_device,
        days_after_create: values?.days_after_create,
        days_after_start: values?.days_after_start,
        download_allowed: values?.download_allowed,
        enable_notifications: values?.enable_notifications,
        hide_identity: values?.hide_identity,
        hide_stats: values?.hide_stats,
        login_required: values?.login_required,
        password_set: values?.is_password,
        account_id: user?.account_id,
        username: user?.username,
      };
      if (settings) {
        const { updateSendDefaultSettings } = await import("@defense-station/api-service");
        await updateSendDefaultSettings(variables)
      }
      else {
        const { createSendDefaultSettings } = await import("@defense-station/api-service");
        await createSendDefaultSettings(variables)
      }
      toast({
        description: `Setttings has been ${settings?"updated":"created"}.`,
        status: "success"
      })
    } catch (e) {
      showErrorToast("Error", SOMETHING_WENT_WRONG);
    }
  };
  return (
    <Stack spacing={"2"}>
      <Formik
        initialValues={{
          click_allowed: 14,
          hide_stats: true,
          days_after_create: 0,
          days_after_start: 0,
          login_required: false,
          download_allowed: true,
          hide_identity: false,
          is_device: false,
          is_password: false,
          allowed_device: "SEND_DEVICE_TYPE_ALL_UNSPECIFIED",
        }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, errors, isSubmitting, setFieldValue, handleChange }) => (
          <Form style={{ width: "100%" }}>
            <Stack>
              <Stack direction={{ base: "column", md: "row" }}>
              <NumberField
                  mb="0px"
                  pr="4.5rem"
                  max={365}
                  label="Days From Create"
                  name="days_after_create"
                  min={0}
                  onChange={(n) => {
                    setFieldValue("days_after_create", n);
                  }}
                  value={values.days_after_create}
                  p="3"
                  width="80px"
                />
                <NumberField
                  mb="0px"
                  pr="4.5rem"
                  max={365}
                  label="Day From Start"
                  name="days_after_start"
                  min={0}
                  onChange={(n) => {
                    setFieldValue("days_after_start", n);
                  }}
                  value={values.days_after_start}
                  p="3"
                  width="80px"
                />
              </Stack>
              <NumberField
                  mb="0px"
                  pr="4.5rem"
                  max={user?.billing_status == FREE_TIER ? 14 : 100}
                  label="Allowed Opens"
                  name="click_allowed"
                  min={1}
                  onChange={(n) => {
                    setFieldValue("click_allowed", n);
                  }}
                  value={values.click_allowed}
                  p="3"
                  width="80px"
                />
                <Accordion
              defaultIndex={[0]}
              allowToggle
              w={"100%"}
              borderColor={"brand.500"}
              borderWidth={"1px"}
              borderRadius={"15px"}
              mb='16px'>
                <AccordionItem border='none'>
                <AccordionButton
                  _focus='none'
                  _hover='none'
                  p='5px 20px 5px 0px'>
                  <Box flex='1' textAlign='left'>
                    <CustomFormLabel htmlFor="login" mb="0" fontWeight='700'
                      fontSize={{ sm: "md", lg: "md" }}>
                        Advance Settings
                    </CustomFormLabel>
                  </Box>
                  <AccordionIcon color='gray.500' />
                </AccordionButton>
                <AccordionPanel p='0px 0px 10px 0px'>
                <SimpleGrid columns={{base: 1, md: 2, "2xl": 3}}>
                <FormControl display="flex" alignItems="center" p="8px">
                  <CustomSwitch
                    id="download"
                    mr={4}
                    colorScheme="brand"
                    isChecked={values?.download_allowed}
                    onChange={(v) => {
                      setFieldValue(
                        "download_allowed",
                        v?.currentTarget.checked
                      );
                    }}
                  />
                  <CustomFormLabel htmlFor="download" mb="0">
                    Download Allowed
                  </CustomFormLabel>
                  <Tooltip label="Allow the user to download shared files.">
                    <InfoOutlineIcon color={"brand.600"} />
                  </Tooltip>
                </FormControl>
                <FormControl display="flex" alignItems="center" p="8px">
                  <CustomSwitch
                    id="stats"
                    colorScheme="brand"
                    mr={4}
                    isChecked={values?.hide_stats}
                    onChange={(v) => {
                      setFieldValue("hide_stats", v?.currentTarget.checked);
                    }}
                  />
                  <CustomFormLabel htmlFor="login" mb="0">
                    Hide Stats
                  </CustomFormLabel>
                  <Tooltip label="Hide stats of shared content.">
                    <InfoOutlineIcon color={"brand.600"} />
                  </Tooltip>
                </FormControl>
                <FormControl display="flex" alignItems="center" p="8px">
                  <CustomSwitch
                    colorScheme="brand"
                    id="hide_identity"
                    mr={4}
                    isChecked={values?.hide_identity}
                    onChange={(v) => {
                      setFieldValue("hide_identity", v?.currentTarget.checked);
                    }}
                  />
                  <CustomFormLabel htmlFor="login" mb="0">
                    Hide Identity
                  </CustomFormLabel>
                  <Tooltip label="User won't be able to see your identity.">
                    <InfoOutlineIcon color={"brand.600"} />
                  </Tooltip>
                </FormControl>
                <FormControl display="flex" alignItems="center" p="8px">
                  <CustomSwitch
                    id="login"
                    colorScheme="brand"
                    mr={4}
                    isChecked={values?.login_required}
                    onChange={(v) => {
                      setFieldValue("login_required", v?.currentTarget.checked);
                    }}
                  />
                  <CustomFormLabel htmlFor="login" mb="0">
                    Login Required
                  </CustomFormLabel>
                  <Tooltip label="User will need to login to view the shared content.">
                    <InfoOutlineIcon color={"brand.600"} />
                  </Tooltip>
                </FormControl>
                <FormControl display="flex" alignItems="center" p="8px">
                  <CustomSwitch
                    id="password"
                    colorScheme="brand"
                    mr={4}
                    isChecked={values?.is_password}
                    onChange={(v) => {
                      setFieldValue("is_password", v?.currentTarget.checked);
                    }}
                  />
                  <CustomFormLabel htmlFor="password" mb="0">
                    Set Password
                  </CustomFormLabel>
                  <Tooltip label="User will need to enter password to view the shared content.">
                    <InfoOutlineIcon color={"brand.600"} />
                  </Tooltip>
                </FormControl>
                <FormControl display="flex" alignItems="center" p="8px">
                  <CustomSwitch
                    colorScheme="brand"
                    id="device"
                    mr={4}
                    isChecked={values?.is_device}
                    onChange={(v) => {
                      setFieldValue("is_device", v?.currentTarget.checked);
                    }}
                  />
                  <CustomFormLabel htmlFor="device" mb="0" width={120}>
                    Allowed Device
                  </CustomFormLabel>
                  <Tooltip label="Devices on which user can view the shared content.">
                    <InfoOutlineIcon color={"brand.600"} />
                  </Tooltip>
                </FormControl>
              </SimpleGrid>
              {values.is_device && (
                <FormControl>
                  <Select
                    placeholder="Select Device"
                    name="allowed_device"
                    size={"sm"}
                    variant="main"
                    fontWeight="400"
                    me="20px"
                    value={values?.allowed_device}
                    onChange={handleChange}
                  >
                    <option value="SEND_DEVICE_TYPE_ALL_UNSPECIFIED">
                      All
                    </option>
                    <option value="SEND_DEVICE_TYPE_LAPTOP">Desktop</option>
                    <option value="SEND_DEVICE_TYPE_MOBILE">Mobile</option>
                  </Select>
                </FormControl>
              )}
                </AccordionPanel>
              </AccordionItem>
              </Accordion>
              <CustomButton isLoading={isSubmitting} type="submit" colorScheme="brand" w="full">
                {settings?"Update":"Create"}
              </CustomButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
}
