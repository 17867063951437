// Chakra imports
import {
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Text,
    useBreakpointValue,
    useColorModeValue,
  } from "@chakra-ui/react";
  // Custom components
  import React from "react";
  import CustomFormLabel from "../custom-styled-components/CustomFormLabel";
  import CustomText from "../custom-styled-components/CustomText";
import CustomSelect from "../custom-styled-components/CustomSelect";
  
  export default function SelectField(props) {
    const {
      id,
      label,
      extra,
      placeholder,
      type,
      mb,
      width,
      error,
      required,
      fontSize,
      children,
      ...rest
    } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textSize = useBreakpointValue({
      base: "sm",
      xl: "xs",
      "2xl": "sm",
    });
    const marginBottom = useBreakpointValue({
      base: "30px",
      xl: "15px",
      "2xl": "30px",
    });
    const fieldHeight = useBreakpointValue({
      base: "44px",
      xl: "25px",
      "2xl": "44px",
    });
    const fieldPadding = useBreakpointValue({
      base: "20px",
      xl: "15px",
      "2xl": "20px",
    });
    return (
      <FormControl
        isRequired={required}
        width={width ? width : "100%"}
        direction="column"
        mb={mb ? mb : marginBottom}
      >
        {label ? (
          <CustomFormLabel htmlFor={id}>
            {label}
            <Text fontSize="sm" fontWeight="400" ms="2px">
              {extra}
            </Text>
          </CustomFormLabel>
        ) : null}
        <CustomSelect
        id={id}
        fontWeight="500"
        fontSize={textSize}
        variant="main"
        placeholder={placeholder}
        _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
        h={fieldHeight}
        maxh={fieldHeight}
        {...rest}>
            {children}
        </CustomSelect>
        <CustomText mr="4" color={"red.400"}>
          {error}
        </CustomText>
      </FormControl>
    );
  }
  