// Chakra imports
import { Flex, Icon, Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
// Custom components
import React, { useState } from "react";
import IconBox from "./icons/IconBox";

export default function Controller(props) {
  const {
    icon,
    iconColor,
    text,
    onValue,
    offValue,
    initial,
    activated,
    onChange,
  } = props;
  // Chakra Color Mode
  const completeBg = useColorModeValue(
    "white",
    "linear-gradient(180deg, #1F2A4F 0%, #18224D 50.63%, #111C44 100%)"
  );
  const completeIcon = useColorModeValue("brand.500", "white");
  const completeShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "inset 0px 4px 4px rgba(255, 255, 255, 0.2)"
  );
  const completeColor = useColorModeValue("brand.500", "white");
  const incompleteBg = useColorModeValue(
    "white",
    "linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.03) 50.63%, rgba(255, 255, 255, 0) 100%)"
  );
  const incompleteIcon = useColorModeValue(
    "secondaryGray.600",
    "whiteAlpha.200"
  );
  const incompleteColor = useColorModeValue("secondaryGray.700", "white");
  const incompleteShadow = useColorModeValue(
    "inset 0px 18px 22px rgba(112, 144, 176, 0.1)",
    "inset 0px 4px 4px #0B1437"
  );
  const iconBoxSize = useBreakpointValue({
    base: "66px",
    xl: "40px",
    "2xl": "66px",
  });
  const iconSize = useBreakpointValue({
    base: "32px",
    xl: "25px",
    "2xl": "32px",
  });
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      cursor="pointer"
      onClick={function () {
        if (onChange) onChange(!activated);
      }}
    >
      <IconBox
        transition="0.2s linear"
        mb="10px"
        h={iconBoxSize}
        w={iconBoxSize}
        bg={activated ? completeBg : incompleteBg}
        boxShadow={activated ? completeShadow : incompleteShadow}
        icon={
          <Icon
            transition="0.2s linear"
            as={icon}
            color={
              activated
                ? iconColor
                  ? iconColor
                  : completeIcon
                : incompleteIcon
            }
            h={iconSize}
            w={iconSize}
          />
        }
      />
    </Flex>
  );
}
