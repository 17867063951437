import React,{ createContext, useContext, useState } from "react";


const RootKeyContext = createContext();


export const RootKeyProvider = ({children}) => {
    const [root_key, setRootKey] = useState(null);
    const [restoreRootKey, setRestoreRootKey] = useState(false);

// Any user details we want can go here
const value = {
    root_key,
    setRootKey,
    restoreRootKey,
    setRestoreRootKey
  };

  return <RootKeyContext.Provider value={value}>{children}</RootKeyContext.Provider>;
}

export const useRootKey = () => useContext(RootKeyContext)

export const withRootKeyProvider = ({children}) => {
    return (<RootKeyProvider>
      {children}
    </RootKeyProvider>)
  }