import React, { useEffect, useState } from "react";
import InputField from "../fields/InputField";
import { Dropzone } from "../Dropzone";
import { useSubmitData } from "../../context/request/submit-data";
import ViewFile from "./ViewFile";
import { FaFileInvoice } from "react-icons/fa";
import { readAndDecryptFileBlobFromURL } from "../../utils/custom-function";
import FileView from "../fileview";
import {
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { showErrorToast } from "../../services/toast-service";
import { useReadResponse } from "../../context/dropzone/read-dropzone-context";
import CustomFormLabel from "../custom-styled-components/CustomFormLabel";
import CustomText from "../custom-styled-components/CustomText";

const FileViewer = ({
  onDownload,
  onView,
  name,
  file,
  downloadingProgress,
  viewProgress,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const download = async () => {
    setIsDownloading(true);
    await onDownload(file, name);
    setIsDownloading(false);
  };
  const view = async () => {
    setIsLoading(true);
    await onView(file, name);
    setIsLoading(false);
  };
  return (
    <ViewFile
      name={name}
      url="https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI"
      onView={view}
      onDownload={download}
      isDownloading={isDownloading}
      isLoading={isLoading}
      downloadAllowed
      downloadingProgress={downloadingProgress}
      viewProgress={viewProgress}
      icon={<FaFileInvoice />}
    />
  );
};

export default function CustomStringField(props) {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [fileName, setFileName] = useState(null);
  const MAX_FILE_SIZE = props?.schema?.maxSize;
  const MAX_FILE_HEADING = props?.schema?.maxSize == 1073741824 ? "1GB" : "5GB";
  const { addFile, removeFile, files, addFilesWithKeys, setFiles } =
    useSubmitData();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [viewFileName, setViewFileName] = useState(false);
  const [viewFileURL, setViewFileURL] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const isFile = props.schema.format == "file";
  const isMultifile = props.schema.format == "multifile";
  const readRequest = useReadResponse();
  const decKey = readRequest.decKey;
  const readFiles = readRequest.files;
  const [fileUrl, setFileUrl] = useState("");
  const [controller, setController] = useState(new AbortController());
  const [downloadingProgress, setDownloadingProgress] = useState(0);
  const [viewProgress, setViewProgress] = useState(0);
  const onDownloadProgress = (value) => setDownloadingProgress(value);
  const onViewProgress = (value) => setViewProgress(value);
  const onDownload = async (name = null, fileName = null) => {
    try {
      setIsDownloading(true);
      const decryptBlob = await readAndDecryptFileBlobFromURL(
        readFiles[name ? name : props.name]?.value,
        decKey,
        onDownloadProgress,
        fileName ? fileName : props.formData,
        controller.signal
      );
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(decryptBlob);

      a.download = fileName ? fileName : props.formData;
      a.click();
    } catch (e) {
      throw e;
    }
    setIsDownloading(false);
    setDownloadingProgress(0);
  };

  const onView = async (name = null, fileName = null) => {
    try {
      setIsLoading(true);
      const decryptBlob = await readAndDecryptFileBlobFromURL(
        readFiles[name ? name : props.name]?.value,
        decKey,
        onViewProgress,
        fileName ? fileName : props.formData,
        controller.signal
      );
      const url = window.URL.createObjectURL(decryptBlob);
      setViewFileName(fileName);
      setFileUrl(url);
      onOpen();
    } catch (e) {
      throw e;
    }
    setIsLoading(false);
    setViewProgress(0);
  };
  const onChange = (event) => {
    if (isFile) {
      const file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        props.onChange(reader.result);
      };
    } else {
      props.onChange(event.target.value);
    }
  };
  const onFileChange = (file) => {
    if (file) {
      if (MAX_FILE_SIZE) {
        if (file?.size >= MAX_FILE_SIZE) {
          showErrorToast(
            "Error!",
            `File size cannot be larger than ${MAX_FILE_HEADING}.`
          );
          return;
        }
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFileName(`${file.path} (${file.size} bytes)`);
        props.onChange(file?.name);
      };
    } else {
      props.onChange(file);
      setFileName(null);
    }
    addFile(file, props.name);
  };
  const onMultiFileChange = (selectedFiles) => {
    if (selectedFiles?.length) {
      let newFilesObject = {};
      Object.keys(files)?.map((key) => {
        if (!key.includes(props.name)) {
          newFilesObject[key] = files[key];
        }
      });
      setFiles(newFilesObject);
      let newFiles = {};
      let fileNames = [];
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setFileName(`${file.path} (${file.size} bytes)`);
        };
        fileNames.push(file?.name);
        newFiles[props.name + [i]] = file;
        if (i + 1 > props.schema?.maxLength) {
          break;
        }
      }
      props.onChange(fileNames.join(","));
      addFilesWithKeys(newFiles);
    } else if (selectedFiles == undefined) {
      let newFilesObject = {};
      Object.keys(files)?.map((key) => {
        if (!key.includes(props.name)) {
          newFilesObject[key] = files[key];
        }
      });
      setFiles(newFilesObject);
      props.onChange(undefined);
      setFileName(null);
    } else {
    }
  };
  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  if (isMultifile) {
    let filesToBeShown = [];
    let fileNames = props.formData?.split(",");
    if (props.readonly) {
      const fileKeys = Object.keys(readFiles);
      for (let index = 0; index < props.schema.maxLength; index++) {
        const name = props.name + index;
        if (fileKeys.includes(name)) {
          filesToBeShown.push(readFiles[name]);
        }
      }
    }
    return !props.readonly ? (
      <>
        <FormControl isRequired={props.required} width={"100%"}>
          <CustomFormLabel
            display="flex"
            ms="10px"
            color={textColorPrimary}
            fontWeight="bold"
            _hover={{ cursor: "pointer" }}
          >
            {props.schema.title}
          </CustomFormLabel>
        </FormControl>
        <Dropzone
          multifiles={true}
          isRequired={props.required}
          maxFiles={props.schema?.maxLength}
          onChange={onMultiFileChange}
          name={props.name}
          values={fileName ? { [props.name]: { name: fileName } } : null}
          width="full"
        />
      </>
    ) : (
      props.formData && (
        <>
          {isOpen && (
            <FileView
              isOpen={isOpen}
              onClose={onClose}
              file_name={viewFileName}
              fileLocalUrl={fileUrl}
            />
          )}
          <FormControl isRequired={props.required} width={"100%"}>
            <CustomFormLabel
              display="flex"
              ms="10px"
              color={textColorPrimary}
              fontWeight="bold"
              _hover={{ cursor: "pointer" }}
            >
              {props.schema.title}
            </CustomFormLabel>
            <Stack>
              {filesToBeShown.map((file, index) => {
                return (
                  <FileViewer
                    key={index}
                    file={file?.key}
                    name={fileNames[index]}
                    url="https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI"
                    onView={onView}
                    onDownload={onDownload}
                    downloadingProgress={downloadingProgress}
                    viewProgress={viewProgress}
                  />
                );
              })}
            </Stack>
          </FormControl>
        </>
      )
    );
  }

  return isFile ? (
    !props.readonly ? (
      <>
        <FormControl isRequired={props.required} width={"100%"}>
          <CustomFormLabel
            display="flex"
            ms="10px"
            color={textColorPrimary}
            fontWeight="bold"
            _hover={{ cursor: "pointer" }}
          >
            {props.schema.title}
          </CustomFormLabel>
        </FormControl>
        <Dropzone
          onChange={onFileChange}
          isRequired={props.required}
          name={props.name}
          values={fileName ? { [props.name]: { name: fileName } } : null}
          width="full"
        />
      </>
    ) : (
      props.formData && (
        <>
          {isOpen && (
            <FileView
              isOpen={isOpen}
              onClose={onClose}
              file_name={props.formData}
              fileLocalUrl={fileUrl}
            />
          )}
          <FormControl isRequired={props.required} width={"100%"}>
            <CustomFormLabel
              display="flex"
              ms="10px"
              color={textColorPrimary}
              fontSize={"md"}
              fontWeight="bold"
              _hover={{ cursor: "pointer" }}
            >
              {props.schema.title}
            </CustomFormLabel>
          </FormControl>
          <ViewFile
            name={props.formData}
            url="https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI"
            onView={onView}
            onDownload={onDownload}
            isDownloading={isDownloading}
            isLoading={isLoading}
            viewProgress={viewProgress}
            downloadingProgress={downloadingProgress}
            downloadAllowed
            icon={<FaFileInvoice />}
          />
        </>
      )
    )
  ) : !props?.readonly ? (
    <InputField
      pr="4.5rem"
      mb="0px"
      max={5}
      disabled={props.readonly}
      type={isFile ? "file" : "text"}
      label={props.schema.title}
      value={props.formData}
      required={props.required}
      placeholder={props.readonly ? "" : `Enter ${props.schema.title}`}
      name={props.name}
      onChange={onChange}
      data-gramm="false"
    />
  ) : (
    <>
      <FormControl isRequired={props.required} width={"100%"}>
        <CustomFormLabel
          display="flex"
          ms="10px"
          color={textColorPrimary}
          fontWeight="bold"
          _hover={{ cursor: "pointer" }}
        >
          {props.schema.title}
        </CustomFormLabel>
      </FormControl>
      <Flex
        borderWidth={1}
        p={2}
        borderRadius="lg"
        borderColor="secondaryGray.400"
      >
        <CustomText color={textColorPrimary}>{props.formData}</CustomText>
      </Flex>
    </>
  );
}
