// At the top of the file
import { createPromiseClient, Interceptor } from "@connectrpc/connect";
import { ClientConfig } from "../../../../../src/config/ClientConfig";
import { ICredentialProvider } from "../../../../../src/credentials";
import { createAuthorizedTransport } from "../../../../../src/transport";
import { UserService } from "./user_connect";

/**
 * Factory function to create a 'user' client.
 *
 * @param {string} baseUrl - The base URL for 'user'.
 * @param {Array<Function>} additionalInterceptors - Optional additional interceptors.
 * @returns {PromiseClient} The configured 'user' client.
 */
export const createUserServiceClient = (config: ClientConfig,
  credentialProvider: ICredentialProvider, additionalInterceptors: Interceptor[] = []) => {
  const transport = createAuthorizedTransport(config, credentialProvider, additionalInterceptors);
  return createPromiseClient(UserService, transport);
};