export const SUPPORTED_FILE_FOMATS = ["pdf", "jpeg", "jpg", "png"];
export const SERVICE_BASE_PATH = "/sypher/";
export const SERVICE_BASE_PATH_FOR_SEND = "/sypher/send/";
export const SERVICE_BASE_PATH_FOR_REQUEST = "/sypher/request/";
export const SERVICE_BASE_PATH_FOR_DROPZONE = "/sypher/dropzones/";
export const SERVICE_BASE_PATH_FOR_TEMPLATE = "/sypher/template/";
export const FREE_TIER = "billing_free_tier_active";
export const SOMETHING_WENT_WRONG =
  "Something went wrong. Please try again later.";
export const SUPPORTED_MIMETYPE = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
];

export const DOC_TYPES = {
  "policies": "policy",
  "standards": "standard",
  "procedures": "procedure",
  "guidelines": "guideline",
  "others": "others",
}
