// Chakra imports
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  SimpleGrid,
  Button,
  VStack,
  Heading,
  Badge,
} from "@chakra-ui/react";
// Assets
import React, { useRef, useState } from "react";
// Custom components
import { tableColumnsReceipt } from "./variables/tableColumnsReceipt";
import tableDataReceipt from "./variables/tableDataReceipt.json";
import ReceiptTable from "./ReceiptTable";
import { HSeparator } from "../../../separator/Separator";
import * as timeago from "timeago.js";
import InformationRow from "../../../InformationRow";
import Message from "./Message";
import { format, isAfter, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import UpdateForm from "./UpdateForm";
import validator from "@rjsf/validator-ajv8";
import CustomStringField from "../../../rjsf/CustomStringField";
import CustomCheckboxField from "../../../rjsf/CustomCheckboxField";
import { Form } from "@rjsf/chakra-ui";
import { Player } from "@lottiefiles/react-lottie-player";
import jsonPlayerData from "../../../../assets/response-not-found.json";
import Information from "./Information";
import { GoPrimitiveDot } from "react-icons/go";
import { BiCircle } from "react-icons/bi";
import CheckboxRead from "../../../rjsf/CheckboxRead";
import CardHeaderText from "../../../custom-styled-components/CardHeaderText";
import CardDescriptionText from "../../../custom-styled-components/CardDescriptionText";
import JSONForm from "../../../JSONForm";
import { showServerError } from "../../../toast";
import { generateCSV, readAndDecryptFileBlobFromURL } from "../../../../utils/custom-function";
import JSZip from "jszip";
import { useReadResponse } from "../../../../context/dropzone/read-dropzone-context";
import { toast } from "react-toastify";

export default function ResponseContent(props) {
  const { response, onUpdateRequest, schema, formData, ...rest } = props;
  const [isExporting, setExporting] = useState(false);
  const [controller, setController] = useState(new AbortController());
  const toastRef = useRef();
  const readRequest = useReadResponse();
  const decKey = readRequest.decKey;
  const readFiles = readRequest.files;
  // Chakra Color Mode
  let total = 0;
  const navigate = useNavigate();
  for (let i = 0; i < tableDataReceipt.length; i++) {
    total = total + tableDataReceipt[i].amount;
  }
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorLink = useColorModeValue("blue.500", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  const onDownloadProgress = (data) => {
    toast.update(toastRef.current, {
      progress: data,
    })
  }

  const onExport = async () => {
    try {
      toastRef.current = toast("Exporting Data", {
        autoClose: false,
        closeButton: false,
        closeOnClick: false,
        type: toast.TYPE.INFO,
        isLoading: true,
      })
      setExporting(true)
      const data = [
        ['Field Name', 'Value'],
      ]
      toast.update(toastRef.current, {
        render: "Downloading form data.",
        isLoading: true,
      })
      const downloadableFiles = Object.keys(schema?.schema?.properties)?.map(key => ({ ...schema.schema.properties[key], key })).filter(field => field?.type == "string" && field?.format == "data-url");
      toast.update(toastRef.current, {
        render: `Downloading files(${0}/${downloadableFiles?.length}).`,
        isLoading: true,
      })
      Object.keys(formData).map(key => {
        data.push([key, formData[key]])
      })


      const zip = new JSZip();
      const csvData = await generateCSV(data, "export")
      zip.file("Export.csv", csvData);
      const imgFolder = zip.folder("files");
      for (let index = 0; index < downloadableFiles.length; index++) {
        toast.update(toastRef.current, {
          render: `Downloading files(${index}/${downloadableFiles?.length}).`,
          isLoading: true,
          progress: 0,
        })
        const element = downloadableFiles[index];
        const decryptBlob = await readAndDecryptFileBlobFromURL(
          readFiles[element?.key]?.value,
          decKey,
          onDownloadProgress,
          element?.key,
          controller.signal
        );
        imgFolder.file(formData[element?.key], decryptBlob);
      }
      toast.update(toastRef.current, {
        render: `Creating zip file.`,
        isLoading: true,
      })
      const blob = await zip.generateAsync({ type: "blob" })
      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);
      toast.update(toastRef.current, {
        render: `Data Exported`,
        isLoading: false,
        autoClose: 1000,
        type: toast.TYPE.SUCCESS
      })
      toastRef.current = null;
      // Create a temporary <a> element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = "example.zip";

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up by revoking the URL
      window.URL.revokeObjectURL(url);
    }
    catch (e) {
      // showServerError(e)
      console.log(e)
      toast.update(toastRef.current, {
        render: e?.message ? e?.message : "Something went wrong. Please try after sometime.",
        isLoading: false,
        autoClose: 1000,
        type: toast.TYPE.ERROR
      })
    }
    finally {
      setExporting(false)
    }
  }


  return response ? (
    <Flex direction="column" {...rest}>
      {response?.message ? <Message message={response?.message} /> : null}
      <SimpleGrid
        width={"100%"}
        columns={{ sm: 1, md: 1, xl: 1 }}
        spacing={"20px"}
      >
        <Flex justify={"space-between"} align={"center"} ms="10px">
          <Flex flexDir={"column"}>
            <CardHeaderText
              color={textColorPrimary}
              fontWeight="bold"
              mt="10px"
              mb="4px"
            >
              Response
            </CardHeaderText>
            <CardDescriptionText color={textColorSecondary} me="26px">
              Response submitted by respondent.
            </CardDescriptionText>
          </Flex>
          <Button isLoading={isExporting} colorScheme="brand" onClick={onExport}>Export</Button>
        </Flex>
        <JSONForm
          schema={schema.schema}
          uischema={schema.uischema}
          data={formData}
          isReadOnly={true}
        />
      </SimpleGrid>
    </Flex>
  ) : (
    <Flex justifyContent={"center"} alignItems="center">
      <VStack>
        <Player
          autoplay
          keepLastFrame
          src={jsonPlayerData}
          style={{ height: "300px", width: "300px" }}
        ></Player>
        <Text fontSize={"2xl"} fontWeight="500">
          Response not recieved yet.
        </Text>
      </VStack>
    </Flex>
  );
}
