import { Badge, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

export default function CustomBadge({ children, ...rest }) {
  const size = useBreakpointValue({
    base: "ms",
    xl: "xs",
    "2xl": "md",
  });
  return (
    <Badge fontSize={size} {...rest}>
      {children}
    </Badge>
  );
}
