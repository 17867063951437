// Chakra imports
// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
  Badge,
  Tooltip,
  Progress,
} from "@chakra-ui/react";
// Custom components
import Card from "../horizon/Card";
// Custom icons
import React from "react";

export default function Default(props) {
  const {
    startContent,
    endContent,
    name,
    growth,
    value,
    tagEnable,
    tagTooltipLabel,
    focused,
    ...rest
  } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  return (
    <Card
      py="15px"
      bg={focused ? "linear-gradient(135deg, #FC8181 0%, #E53E3E 100%)" : ""}
      {...rest}
    >
      <Flex
        my="auto"
        h="100%"
        align={{ base: "center", xl: "start" }}
        justify={{ base: "center", xl: "center" }}
      >
        {startContent}

        <Stat my="auto" ms={startContent ? "18px" : "0px"}>
          <StatLabel
            lineHeight="100%"
            color={focused ? "white" : textColorSecondary}
            fontSize={{
              base: "sm",
              xl: "xs",
              "2xl": "sm",
            }}
          >
            {name}
          </StatLabel>
          {tagEnable ? (
            <Tooltip label={tagTooltipLabel}>
              <Badge
                minW={"70px"}
                colorScheme={"brand"}
                variant="outline"
                p={"1px"}
                fontSize="12px"
              >
                {value}
              </Badge>
            </Tooltip>
          ) : (
            <StatNumber
              color={focused ? "white" : textColor}
              fontSize={{
                base: "2xl",
                xl: "xs",
                "2xl": "2xl",
              }}
            >
              {value}
            </StatNumber>
          )}
          {growth ? (
            <Flex align="center">
              <Text color="green.500" fontSize="xs" fontWeight="700" me="5px">
                {growth}
              </Text>
              <Text color="secondaryGray.600" fontSize="xs" fontWeight="400">
                since last month
              </Text>
            </Flex>
          ) : null}
        </Stat>
        <Flex ms="auto" w="max-content">
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}
