import toast from "../components/toast";

export const showErrorToast = (title, description) => {
  toast({
    title: title,
    description: description,
    status: "error",
    duration: 4000,
    isClosable: true,
    position: "top",
  });
};

export const showWarningToast = (title, description) => {
  toast({
    title: title,
    description: description,
    status: "warning",
    duration: 4000,
    isClosable: true,
    position: "top",
  });
};
