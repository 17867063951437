import React from "react";
import { DroppableLayout } from "../json-form-field-components/Layouts/DroppableLayout";
import { LayoutType } from "../../types/dnd-types";
import { Text } from "@chakra-ui/react";
import { useJSONBuilderContext } from "../../context/dnd-context";

export default function FieldEditor(): React.ReactElement {
  const { items } = useJSONBuilderContext();
  return (
    <>{!items.length && <Text>Drag & Drop here to start building.</Text>}
    <DroppableLayout
      direction={LayoutType.VERTICAL}
      id="mainContainer"
    /></>
  );
}
