import {
    SortableContext,
    horizontalListSortingStrategy,
    verticalListSortingStrategy,
  } from "@dnd-kit/sortable";
  import React from "react";
  import {
    Box,
    ChakraProps,
    HStack,
    Icon,
    Text,
  } from "@chakra-ui/react";
  import { MdDelete } from "react-icons/md";
import { BuilderFieldArguments, DroppableItemProps, LayoutType } from "../../../../types/dnd-types";
import DroppableItem from "../../../dnd-components/DroppableItem";
import { useJSONBuilderContext } from "../../../../context/dnd-context";
import SortableItem from "../../../dnd-components/SortableItem";
import { renderBuilderField } from "../../Fields/utils";
import { renderLayoutElement } from "../utils";
  
  interface LayoutProps extends Omit<DroppableItemProps, "children" | "items"> {
    direction: LayoutType;
  }
  
  export const DroppableLayout = ({
    id,
    direction = LayoutType.VERTICAL,
  }: LayoutProps): React.ReactElement => {
    const { removeItem, setSelectedItem, selectedItem, getItems } =
      useJSONBuilderContext();
    const items = getItems(id);
    return (
      <DroppableItem
        id={id}
        display="flex"
        gap="10"
        flexDir={direction === LayoutType.VERTICAL ? "column" : "row"}
        minW="full"
        p="8"
      >
        <SortableContext
          items={items}
          strategy={
            direction === LayoutType.VERTICAL
              ? verticalListSortingStrategy
              : horizontalListSortingStrategy
          }
        >
          {items.map((item) => {
            const onClickItem = (e: React.MouseEvent) => {
              e.stopPropagation();
              setSelectedItem(item);
            };
            const onDeleteClick = (
              e: React.MouseEvent<SVGElement, MouseEvent>
            ) => {
              e.stopPropagation();
              removeItem(item.id);
            };
            const args: BuilderFieldArguments = {
              onDelete: onDeleteClick,
              ...item,
            };
            const newStyles: ChakraProps =
              selectedItem?.id === item.id
                ? {
                    borderWidth: "1px",
                    borderColor: "green.500",
                  }
                : {
                  borderWidth:"1px",
                  borderColor: "gray.400"
                };
            return (
              <SortableItem
                w="full"
                textAlign="center"
                flex={1}
                data={item}
                key={item.id}
                id={item.id}
                onClick={onClickItem}
              >
                <Box role="group" {...newStyles} w={"full"} padding={4}>
                  {item.isLayoutElement && (
                    <Box display={"flex"} flexDir={"row"} justifyContent={"space-between"}>
                      <HStack>
                        <Icon as={item.icon} color={"gray.500"} boxSize={5} />
                        <Text fontWeight={"500"} color={"gray.500"}>
                          {item.title}
                        </Text>
                      </HStack>
                      <Icon
                        as={MdDelete}
                        boxSize={6}
                        display={"none"}
                        _groupHover={{ display: "block" }}
                        onClick={args.onDelete}
                      />
                    </Box>
                  )}
                  <Box w="full">
                    {item.isLayoutElement
                      ? renderLayoutElement(item)
                      : renderBuilderField(args)}
                  </Box>
                </Box>
              </SortableItem>
            );
          })}
        </SortableContext>
      </DroppableItem>
    );
  };