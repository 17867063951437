import { PropsWithChildren, SetStateAction, createContext, useContext, useState } from "react";

type FilesTypeEntityType = {
  key: string;
  value: string;
} 

type FilesType = {
  [key: string]: FilesTypeEntityType,
}

type ReadResponseContextType = {
  files: FilesType;
  setFiles: React.Dispatch<React.SetStateAction<FilesType>>;
  addFile: (file: FilesTypeEntityType, key: string) => void;
  removeFile: (key: string) => void;
  decKey: string | undefined;
  setDecKey: React.Dispatch<React.SetStateAction<string | undefined>>
}
const ReadResponseContext = createContext<ReadResponseContextType>({
  files: {},
  setFiles: function (value: SetStateAction<FilesType>): void {
    throw new Error("Function not implemented.");
  },
  addFile: function (file: FilesTypeEntityType, key: string): void {
    throw new Error("Function not implemented.");
  },
  removeFile: function (key: string): void {
    throw new Error("Function not implemented.");
  },
  decKey: "",
  setDecKey: function (value: SetStateAction<string | undefined>): void {
    throw new Error("Function not implemented.");
  }
});

const ReadResponseProvider = ({ children }: PropsWithChildren) => {
  const [files, setFiles] = useState({});
  const [decKey, setDecKey] = useState<string | undefined>();
  const addFile = (file: FilesTypeEntityType, key: string) => {
    setFiles({ ...files, [key]: file });
  };
  const removeFile = (key: string) => {
    let newFiles: FilesType = { ...files };
    delete newFiles[key];
    setFiles(newFiles);
  };

  const context = {
    files,
    setFiles,
    addFile,
    removeFile,
    decKey,
    setDecKey,
  };
  return (
    <ReadResponseContext.Provider value={context}>
      {children}
    </ReadResponseContext.Provider>
  );
};

export const useReadResponse = () => useContext(ReadResponseContext);

export default ReadResponseProvider;
