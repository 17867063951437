// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/sypher/v1/service/dropzone/dropzone.proto (package sypher.v1.dropzone, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AccessDropzoneRequest, AccessDropzoneResponse, CreateDropzoneRequest, CreateDropzoneResponse, GetDropzoneRequest, GetDropzoneResponse, ListDropzonesRequest, ListDropzonesResponse, UpdateDropzoneRequest, UpdateDropzoneResponse } from "./dropzone_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service sypher.v1.dropzone.DropzoneService
 */
export const DropzoneService = {
  typeName: "sypher.v1.dropzone.DropzoneService",
  methods: {
    /**
     * @generated from rpc sypher.v1.dropzone.DropzoneService.CreateDropzone
     */
    createDropzone: {
      name: "CreateDropzone",
      I: CreateDropzoneRequest,
      O: CreateDropzoneResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.dropzone.DropzoneService.GetDropzone
     */
    getDropzone: {
      name: "GetDropzone",
      I: GetDropzoneRequest,
      O: GetDropzoneResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.dropzone.DropzoneService.ListDropzones
     */
    listDropzones: {
      name: "ListDropzones",
      I: ListDropzonesRequest,
      O: ListDropzonesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.dropzone.DropzoneService.UpdateDropzone
     */
    updateDropzone: {
      name: "UpdateDropzone",
      I: UpdateDropzoneRequest,
      O: UpdateDropzoneResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc sypher.v1.dropzone.DropzoneService.AccessDropzone
     */
    accessDropzone: {
      name: "AccessDropzone",
      I: AccessDropzoneRequest,
      O: AccessDropzoneResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

