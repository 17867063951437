import {
  Center,
  Flex,
  Icon,
  List,
  ListItem,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
  Spinner,
  IconButton,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "../card/Card";
import { FaShareAltSquare, FaUserPlus } from "react-icons/fa";
import OrderStep from "../dataDisplay/OrderStep";
import TimelineItem from "../dataDisplay/TimelineItem";
import IconBox from "../icons/IconBox";
import TimelineRow from "../TimelineRow";
import { RiShareLine } from "react-icons/ri";
import { isAfter, parseISO } from "date-fns";
import { BiGitPullRequest, BiUserPlus } from "react-icons/bi";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  MdAdd,
  MdAttachMoney,
  MdCached,
  MdCallReceived,
  MdMoreHoriz,
} from "react-icons/md";
import { AiOutlineDropbox } from "react-icons/ai";
import {
  DROPZONE_COLOR,
  RESPONSE_COLOR,
  SEND_COLOR,
} from "../../constants/sypher";
import CardHeaderText from "../custom-styled-components/CardHeaderText";
import CardDescriptionText from "../custom-styled-components/CardDescriptionText";

export default function NotificationBox(props) {
  const { data, fetchNext, hasNext, loading, ...rest } = props;
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const completeBg = useColorModeValue(
    "white",
    "linear-gradient(180deg, #1F2A4F 0%, #18224D 50.63%, #111C44 100%)"
  );
  const completeShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "inset 0px 4px 4px rgba(255, 255, 255, 0.2)"
  );
  const incompleteColor = useColorModeValue(
    "secondaryGray.600",
    "whiteAlpha.200"
  );
  const incompleteShadow = useColorModeValue(
    "inset 0px 18px 22px rgba(112, 144, 176, 0.1)",
    "inset 0px 4px 4px #0B1437"
  );
  const lineColor = useColorModeValue("%234318FFFF", "%23FFFFFF1A");
  const totalPosts = data?.length ? data?.length : 0;
  const textColorSecondary = "gray.400";
  const iconColor = useColorModeValue("brand.500", "white");
  const greenIcon = useColorModeValue("green.500", "white");
  const redIcon = useColorModeValue("red.500", "white");
  const blueIcon = useColorModeValue("blue.500", "white");
  const yellowIcon = useColorModeValue("yellow.500", "white");
  const bgIconButton = useColorModeValue("white", "whiteAlpha.100");
  const bgIconHover = useColorModeValue(
    { bg: "gray.100" },
    { bg: "whiteAlpha.50" }
  );
  const bgIconFocus = useColorModeValue(
    { bg: "grey.100" },
    { bg: "whiteAlpha.100" }
  );
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.100" }
  );
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const shadow = useColorModeValue(
    "18px 17px 40px 4px rgba(112, 144, 176, 0.1)",
    "unset"
  );
  // Chakra Color Mode
  return (
    <Card direction="column" w="100%" pt="30px" pb="20px" {...rest}>
      {/* <Flex
        position='absolute'
        borderLeft='2px dashed'
        h='100%'
        borderColor='brand.500'></Flex> */}
      <Flex direction="column">
        <CardHeaderText
          color={textColor}
          fontWeight="700"
          lineHeight="10%"
          mb="10px"
        >
          Notifications
        </CardHeaderText>
        <CardDescriptionText color={textColorSecondary}>
          Last month notifications.
        </CardDescriptionText>
      </Flex>
      {props.header ? props.header() : null}
      <Flex position="relative" direction="column" overflow={"auto"}>
        {data?.length ? (
          <InfiniteScroll
            dataLength={data?.length ? data?.length : 0}
            next={fetchNext}
            hasMore={hasNext}
            loading={loading}
            loader={
              <Flex w="100%" justifyContent={"center"} alignItems="center">
                <Spinner colorScheme={"brand"} />
              </Flex>
            }
            scrollableTarget="table-div"
          >
            {data?.map((post, index) => {
              const isPost = post?.requester_type == "read_post";
              const isDropzone = post?.requester_type == "dropzone";
              let link = "";
              let text = "";
              let iconColor = null;
              if (isDropzone) {
                iconColor = DROPZONE_COLOR;
                link = `/sypher/dropzones/responses/${post?.requester_id}/${post?.respondent_id}`;
                text = "Response received for " + post?.requester_id;
              } else {
                link = isPost
                  ? "/sypher/stats/" + post?.uuid
                  : "/sypher/request/responses/" +
                    post?.requester_id +
                    "?email=" +
                    post?.respondent_id;
                text = isPost
                  ? "Data accessed for " + post?.label
                  : "Response received from " + post?.respondent_id;
                iconColor = isPost ? SEND_COLOR : RESPONSE_COLOR;
              }
              let date = isPost ? post?.created_at : post?.created_at;
              if (date == "unknown") date = null;
              const icon = isPost
                ? RiShareLine
                : isDropzone
                ? AiOutlineDropbox
                : MdCallReceived;
              let expired = null;
              let live = null;
              let idle = null;
              let clickLeft = 0;
              if (post?.responder_controls) {
                const parsedEndDate = parseISO(post?.end_date);
                const parsedStartDate = parseISO(post?.created_at);
                expired = isAfter(new Date(), parsedEndDate);
                live =
                  isAfter(new Date(), parsedStartDate) &&
                  isAfter(parsedEndDate, new Date());
                idle =
                  isAfter(parsedStartDate, new Date()) &&
                  isAfter(parsedEndDate, new Date());
                clickLeft = post?.access_limit;
              } else {
                expired = false;
                live = true;
                idle = false;
                clickLeft = 1;
              }
              // if (expired || !clickLeft) {
              //   iconColor = "red.400";
              // } else if (live) {
              //   iconColor = "brand.600";
              // } else if (idle) {
              //   iconColor = "gray.200";
              // }
              return (
                <TimelineRow
                  lineColor={"gray.200"}
                  iconColor={iconColor}
                  key={index}
                  last={totalPosts - 1 == index}
                  text={text}
                  link={link}
                  date={date}
                  icon={icon}
                />
              );
            })}
          </InfiniteScroll>
        ) : (
          <Flex justifyContent={"center"} alignItems="center">
            <Text
              fontSize={{ base: "lg", xl: "xs", "2xl": "lg" }}
              color={textColor}
            >
              No Notification found.
            </Text>
          </Flex>
        )}
      </Flex>
    </Card>
  );
}
