import { Flex, Box } from "@chakra-ui/react";
import * as React from "react";
import { Sidebar } from "../components/sidebar/Sidebar";
import Footer from "../components/footer/FooterAdmin";

const NormalLayout = ({ children }) => {
  return (
    <Box
        display={"flex"}
        flexDir={"column"}
      float="right"
      minHeight="100vh"
      height="100vh"
      overflow="auto"
      position="relative"
      maxHeight="100%"
      w={"full"}
      maxWidth={"full"}
      transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
      transitionDuration=".2s, .2s, .35s"
      transitionProperty="top, bottom, width"
      transitionTimingFunction="linear, linear, ease"
    >
      <Box h={"full"} overflow="auto">
        {children}
      </Box>
      <Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default NormalLayout;
