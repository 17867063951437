import {
  Flex,
  Link,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import jsonPlayerData from "../assets/not-found.json";

export default function NotFound() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "brand.400");
  const playerSize = useBreakpointValue({
    base: "100%",
    md: "700px",
    lg: "700px",
    xl: "700px",
    "2xl": "800px",
  });
  return (
    <Flex
      height={"90vh"}
      justifyContent={"center"}
      direction="column"
      alignItems="center"
    >
      <Player
        autoplay
        loop
        keepLastFrame
        src={jsonPlayerData}
        style={{ height: playerSize, width: playerSize }}
      ></Player>
      <Flex align="center" direction={{ base: "column", md: "row" }} mt="20px">
        <Text
          color={textColor}
          fontWeight="500"
          fontSize={{ base: "md", md: "lg" }}
          me="4px"
        >
          Maybe it’s best you start back at our home page...
        </Text>
        <Link
          color={brandColor}
          fontSize={{ base: "md", md: "lg" }}
          fontWeight="500"
          href="/"
        >
          Return at home here.
        </Link>
      </Flex>
    </Flex>
  );
}
