import React from "react";

// Chakra imports
import { Flex, useColorModeValue, Text } from "@chakra-ui/react";
import { LogoIcon } from "../../icons/icons";

export function SidebarBrand(props) {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");
  let mainText = useColorModeValue("navy.700", "white");
  return (
    <Flex align="center" direction="column" {...props}>
      <Flex flexDirection={"row"}>
        <Text
          fontWeight={"bold"}
          fontSize={{ base: "3xl", xl: "2xl", "2xl": "3xl" }}
          color={mainText}
        >
          Sypher
        </Text>
        {/* <Text fontSize="3xl" color={mainText}>Share</Text> */}
      </Flex>
      <Flex
        h="1px"
        w="100%"
        bg="rgba(135, 140, 189, 0.3)"
        mb="20px"
        mt="20px"
      ></Flex>
    </Flex>
  );
}

export default SidebarBrand;
