// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/asset/v1/service/category/category.proto (package asset.v1.category, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateCategoryRequest, CreateCategoryResponse, DeleteCategoryRequest, GetCategoryRequest, GetCategoryResponse, ListCategoriesRequest, ListCategoriesResponse, UpdateCategoryRequest, UpdateCategoryResponse } from "./category_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service asset.v1.category.CategoryService
 */
export const CategoryService = {
  typeName: "asset.v1.category.CategoryService",
  methods: {
    /**
     * @generated from rpc asset.v1.category.CategoryService.CreateCategory
     */
    createCategory: {
      name: "CreateCategory",
      I: CreateCategoryRequest,
      O: CreateCategoryResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.CategoryService.GetCategory
     */
    getCategory: {
      name: "GetCategory",
      I: GetCategoryRequest,
      O: GetCategoryResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.CategoryService.ListCategories
     */
    listCategories: {
      name: "ListCategories",
      I: ListCategoriesRequest,
      O: ListCategoriesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.CategoryService.UpdateCategory
     */
    updateCategory: {
      name: "UpdateCategory",
      I: UpdateCategoryRequest,
      O: UpdateCategoryResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc asset.v1.category.CategoryService.DeleteCategory
     */
    deleteCategory: {
      name: "DeleteCategory",
      I: DeleteCategoryRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

