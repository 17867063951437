import { Tag, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

export default function CustomTag({ children, ...rest }) {
  const tagSize = useBreakpointValue({
    base: "lg",
    xl: "sm",
    "2xl": "lg",
  });
  return (
    <Tag size={tagSize} {...rest}>
      {children}
    </Tag>
  );
}
