const pattern =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
const otherOrigin = /(\*|null)/;
function isURL(str) {
  if (pattern.test(str)) {
    return true;
  }
  if (otherOrigin.test(str)) {
    return true;
  }
  return false;
}
export default isURL;
