// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/policy/v1/service/workflow/workflow.proto (package policy.v1.workflow, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddApproversRequest, AddApproversResponse, AddReviewersRequest, AddReviewersResponse, ApproveDocumentRequest, ApproveDocumentResponse, ListApproversRequest, ListApproversResponse, ListReviewersRequest, ListReviewersResponse, NotifySupervisorsRequest, PublishDocumentRequest, PublishDocumentResponse, RemoveApproversRequest, RemoveApproversResponse, RemoveReviewersRequest, RemoveReviewersResponse, ReviewDocumentRequest, ReviewDocumentResponse, SendForApprovalRequest, SendForReviewRequest } from "./workflow_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service policy.v1.workflow.WorkflowService
 */
export const WorkflowService = {
  typeName: "policy.v1.workflow.WorkflowService",
  methods: {
    /**
     * @generated from rpc policy.v1.workflow.WorkflowService.AddReviewers
     */
    addReviewers: {
      name: "AddReviewers",
      I: AddReviewersRequest,
      O: AddReviewersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.workflow.WorkflowService.RemoveReviewers
     */
    removeReviewers: {
      name: "RemoveReviewers",
      I: RemoveReviewersRequest,
      O: RemoveReviewersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.workflow.WorkflowService.ListReviewers
     */
    listReviewers: {
      name: "ListReviewers",
      I: ListReviewersRequest,
      O: ListReviewersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.workflow.WorkflowService.SendForReview
     */
    sendForReview: {
      name: "SendForReview",
      I: SendForReviewRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.workflow.WorkflowService.AddApprovers
     */
    addApprovers: {
      name: "AddApprovers",
      I: AddApproversRequest,
      O: AddApproversResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.workflow.WorkflowService.RemoveApprovers
     */
    removeApprovers: {
      name: "RemoveApprovers",
      I: RemoveApproversRequest,
      O: RemoveApproversResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.workflow.WorkflowService.ListApprovers
     */
    listApprovers: {
      name: "ListApprovers",
      I: ListApproversRequest,
      O: ListApproversResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.workflow.WorkflowService.SendForApproval
     */
    sendForApproval: {
      name: "SendForApproval",
      I: SendForApprovalRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.workflow.WorkflowService.NotifySupervisors
     */
    notifySupervisors: {
      name: "NotifySupervisors",
      I: NotifySupervisorsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.workflow.WorkflowService.ReviewDocument
     */
    reviewDocument: {
      name: "ReviewDocument",
      I: ReviewDocumentRequest,
      O: ReviewDocumentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.workflow.WorkflowService.ApproveDocument
     */
    approveDocument: {
      name: "ApproveDocument",
      I: ApproveDocumentRequest,
      O: ApproveDocumentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc policy.v1.workflow.WorkflowService.PublishDocument
     */
    publishDocument: {
      name: "PublishDocument",
      I: PublishDocumentRequest,
      O: PublishDocumentResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

