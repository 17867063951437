import {
    Flex,
    Text,
    Icon,
    HStack,
  } from "@chakra-ui/react";
  import React from "react";
  import { MdDelete, MdLabel } from "react-icons/md";
import { FieldProps } from "../../../../types/dnd-types";
  
  export default function BaseComponent(props: FieldProps & React.PropsWithChildren) {
    return (
      <Flex role="group" w="full" flexDir={"column"} gap={"4"}>
        <Flex flexDir={"row"} justifyContent={"space-between"}>
          <HStack>
            <Icon as={MdLabel} color={"gray.500"} boxSize={6} />
            <Text fontWeight={"500"} color={"gray.500"}>
              {`#properties/${props.keyName}`}
            </Text>
          </HStack>
          <Icon
            as={MdDelete}
            boxSize={6}
            display={"none"}
            _groupHover={{ display: "block" }}
            onClick={props.onDelete}
          />
        </Flex>
        {props.children}
      </Flex>
    );
  }