import { createContext, useContext, useEffect, useState } from "react";

const ErrorContext = createContext({});

export const ErrorsProvider = ({ children }) => {
  // We can have our existing Auth logic here, just making sure that we have a single QueryClient.
  const [isServerError, setIsServerError] = useState(false);

  const [error, setError] = useState();

  const emitError = (error) => {
    setError(error);
    setIsServerError(true);
  };

  const clearServerError = () => {
    setError();
    setIsServerError(false);
  }

  // Any user details we want can go here
  const value = {
    isServerError,
    setIsServerError,
    emitError,
    clearServerError,
    error,
  };

  return (
    <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
  );
};
export const useErrors = () => useContext(ErrorContext);
