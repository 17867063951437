// Chakra imports
import {
  Flex,
  FormLabel,
  Textarea,
  Text,
  useColorModeValue,
  HStack,
  IconButton,
  Icon,
  NumberInput,
  NumberInputField,
  FormControl,
  useBreakpointValue,
} from "@chakra-ui/react";
// Custom components
import React from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import CustomFormLabel from "../custom-styled-components/CustomFormLabel";

export default function NumberField(props) {
  const {
    mb,
    id,
    label,
    extra,
    placeholder,
    min,
    max,
    value,
    onChange,
    direction,
    disabled,
    width,
    justifyContent,
    required,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const bgPrimary = useColorModeValue("transparent", "navy.800");
  const borderPrimary = useColorModeValue(
    "secondaryGray.100",
    "whiteAlpha.100"
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const increment = () => (value >= max ? onChange(max) : onChange(value + 1));
  const decrement = () => (value <= min ? onChange(min) : onChange(value - 1));
  const textSize = useBreakpointValue({
    base: "sm",
    xl: "xs",
    "2xl": "sm",
  });
  const fieldHeight = useBreakpointValue({
    base: "44px",
    xl: "25px",
    "2xl": "44px",
  });
  const fieldPadding = useBreakpointValue({
    base: "20px",
    xl: "15px",
    "2xl": "20px",
  });
  return (
    <FormControl
      isRequired={required}
      direction={direction ? direction : "column"}
      mb={mb ? mb : "30px"}
    >
      {label || extra ? (
        <CustomFormLabel htmlFor={id}>
          {label}
          <Text fontSize="sm" fontWeight="normal" ms="2px">
            {extra}
          </Text>
        </CustomFormLabel>
      ) : null}
      <HStack
        ml={"4"}
        spacing={"4"}
        alignItems={"center"}
        justifyContent={justifyContent}
      >
        <Flex>
          <IconButton
            onClick={decrement}
            variant="brandOutline"
            size={textSize}
            disabled={disabled}
            borderRadius="50%"
          >
            <Icon w="14px" h="14px" as={FiMinus} color="brand.600" />
          </IconButton>
        </Flex>
        <Flex>
          <NumberInput
            min={min}
            max={max}
            disabled={disabled}
            required
            fontSize={textSize}
            onChange={(s, n) => {
              if (n > max) {
                onChange(max);
              } else if (n < min) {
                onChange(min);
              } else {
                onChange(n);
              }
            }}
            value={value}
          >
            <NumberInputField
              h={fieldHeight}
              maxH={fieldHeight}
              fontSize={textSize}
              variant={"main"}
              p={fieldPadding}
              {...rest}
            />
          </NumberInput>
        </Flex>
        <Flex>
          <IconButton
            onClick={increment}
            variant="brandOutline"
            borderRadius="50%"
            disabled={disabled}
            size={textSize}
          >
            <Icon w="14px" h="14px" as={FiPlus} color="brand.600" />
          </IconButton>
        </Flex>
      </HStack>
    </FormControl>
  );
}
