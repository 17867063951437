// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/sypher/v1/service/template/template.proto (package sypher.v1.template, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { LastEvaluated, Template, TemplateData } from "../../types/types_pb";

/**
 * @generated from message sypher.v1.template.CreateTemplateRequest
 */
export class CreateTemplateRequest extends Message<CreateTemplateRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: sypher.v1.types.TemplateData template = 3;
   */
  template?: TemplateData;

  constructor(data?: PartialMessage<CreateTemplateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.template.CreateTemplateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "template", kind: "message", T: TemplateData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTemplateRequest {
    return new CreateTemplateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTemplateRequest {
    return new CreateTemplateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTemplateRequest {
    return new CreateTemplateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTemplateRequest | PlainMessage<CreateTemplateRequest> | undefined, b: CreateTemplateRequest | PlainMessage<CreateTemplateRequest> | undefined): boolean {
    return proto3.util.equals(CreateTemplateRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.template.CreateTemplateResponse
 */
export class CreateTemplateResponse extends Message<CreateTemplateResponse> {
  /**
   * @generated from field: sypher.v1.types.Template template = 1;
   */
  template?: Template;

  constructor(data?: PartialMessage<CreateTemplateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.template.CreateTemplateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template", kind: "message", T: Template },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTemplateResponse {
    return new CreateTemplateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTemplateResponse {
    return new CreateTemplateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTemplateResponse {
    return new CreateTemplateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTemplateResponse | PlainMessage<CreateTemplateResponse> | undefined, b: CreateTemplateResponse | PlainMessage<CreateTemplateResponse> | undefined): boolean {
    return proto3.util.equals(CreateTemplateResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.template.GetTemplateRequest
 */
export class GetTemplateRequest extends Message<GetTemplateRequest> {
  /**
   * @generated from field: string template_dri = 1;
   */
  templateDri = "";

  constructor(data?: PartialMessage<GetTemplateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.template.GetTemplateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTemplateRequest {
    return new GetTemplateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTemplateRequest {
    return new GetTemplateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTemplateRequest {
    return new GetTemplateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTemplateRequest | PlainMessage<GetTemplateRequest> | undefined, b: GetTemplateRequest | PlainMessage<GetTemplateRequest> | undefined): boolean {
    return proto3.util.equals(GetTemplateRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.template.GetTemplateResponse
 */
export class GetTemplateResponse extends Message<GetTemplateResponse> {
  /**
   * @generated from field: sypher.v1.types.Template template = 1;
   */
  template?: Template;

  constructor(data?: PartialMessage<GetTemplateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.template.GetTemplateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template", kind: "message", T: Template },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTemplateResponse {
    return new GetTemplateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTemplateResponse {
    return new GetTemplateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTemplateResponse {
    return new GetTemplateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTemplateResponse | PlainMessage<GetTemplateResponse> | undefined, b: GetTemplateResponse | PlainMessage<GetTemplateResponse> | undefined): boolean {
    return proto3.util.equals(GetTemplateResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.template.ListTemplatesRequest
 */
export class ListTemplatesRequest extends Message<ListTemplatesRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_template = 2;
   */
  lastEvaluatedTemplate?: LastEvaluated;

  constructor(data?: PartialMessage<ListTemplatesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.template.ListTemplatesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_evaluated_template", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTemplatesRequest {
    return new ListTemplatesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTemplatesRequest {
    return new ListTemplatesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTemplatesRequest {
    return new ListTemplatesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListTemplatesRequest | PlainMessage<ListTemplatesRequest> | undefined, b: ListTemplatesRequest | PlainMessage<ListTemplatesRequest> | undefined): boolean {
    return proto3.util.equals(ListTemplatesRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.template.ListTemplatesResponse
 */
export class ListTemplatesResponse extends Message<ListTemplatesResponse> {
  /**
   * @generated from field: repeated sypher.v1.types.Template templates = 1;
   */
  templates: Template[] = [];

  /**
   * @generated from field: sypher.v1.types.LastEvaluated last_evaluated_template = 2;
   */
  lastEvaluatedTemplate?: LastEvaluated;

  constructor(data?: PartialMessage<ListTemplatesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.template.ListTemplatesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "templates", kind: "message", T: Template, repeated: true },
    { no: 2, name: "last_evaluated_template", kind: "message", T: LastEvaluated },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTemplatesResponse {
    return new ListTemplatesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTemplatesResponse {
    return new ListTemplatesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListTemplatesResponse {
    return new ListTemplatesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListTemplatesResponse | PlainMessage<ListTemplatesResponse> | undefined, b: ListTemplatesResponse | PlainMessage<ListTemplatesResponse> | undefined): boolean {
    return proto3.util.equals(ListTemplatesResponse, a, b);
  }
}

/**
 * @generated from message sypher.v1.template.UpdateTemplateRequest
 */
export class UpdateTemplateRequest extends Message<UpdateTemplateRequest> {
  /**
   * @generated from field: string template_dri = 1;
   */
  templateDri = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: sypher.v1.types.TemplateData template = 3;
   */
  template?: TemplateData;

  constructor(data?: PartialMessage<UpdateTemplateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.template.UpdateTemplateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "template", kind: "message", T: TemplateData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTemplateRequest {
    return new UpdateTemplateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTemplateRequest {
    return new UpdateTemplateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTemplateRequest {
    return new UpdateTemplateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTemplateRequest | PlainMessage<UpdateTemplateRequest> | undefined, b: UpdateTemplateRequest | PlainMessage<UpdateTemplateRequest> | undefined): boolean {
    return proto3.util.equals(UpdateTemplateRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.template.DeleteTemplateRequest
 */
export class DeleteTemplateRequest extends Message<DeleteTemplateRequest> {
  /**
   * @generated from field: string template_dri = 1;
   */
  templateDri = "";

  constructor(data?: PartialMessage<DeleteTemplateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.template.DeleteTemplateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteTemplateRequest {
    return new DeleteTemplateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteTemplateRequest {
    return new DeleteTemplateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteTemplateRequest {
    return new DeleteTemplateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteTemplateRequest | PlainMessage<DeleteTemplateRequest> | undefined, b: DeleteTemplateRequest | PlainMessage<DeleteTemplateRequest> | undefined): boolean {
    return proto3.util.equals(DeleteTemplateRequest, a, b);
  }
}

