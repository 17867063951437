/* eslint-disable prettier/prettier */
import {
  Box,
  Link,
  Flex,
  Grid,
  useColorModeValue,
  SimpleGrid,
  Spinner,
  Stack,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  IconButton,
  Text,
  useBreakpoint,
  useBreakpointValue,
  HStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAuth } from "../context/auth";
import { formatBytes } from "../utils/format-bytes";
import DocSideBar from "../components/dashboard/DocSideBar";
import NotificationBox from "../components/dashboard/NotificationBox";
import { BiData, BiUserPlus } from "react-icons/bi";
import { AiOutlineDropbox } from "react-icons/ai";
import IconBox from "../components/icons/IconBox";
import MiniStatistics from "../components/dashboard/MiniStatistics";
import Statistics from "../components/dashboard/MiniStatisticsWide";
import {
  RiShareLine,
  RiFileShield2Line,
  RiArrowUpSFill,
  RiArrowDownSFill,
} from "react-icons/ri";
import PieCard from "../components/dashboard/PieCard";
import ProfitEstimation from "../components/dashboard/ProfitEstimation";
import MapBox from "../components/maps/Map";
import { isPermissionError } from "../utils/custom-function";
import LoadingScreen from "./LoadingScreen";
import { addDays, endOfDay, startOfDay } from "date-fns";
import { FREE_TIER } from "../constants/constants";
import "../assets/css/charts.css";
import { VSeparator } from "../components/separator/Separator";
import {
  MdCallReceived,
  MdFileCopy,
  MdOutlineBarChart,
  MdPerson,
  MdShield,
} from "react-icons/md";
import Nft1 from "../assets/img/nfts/Nft1.png";
import Nft2 from "../assets/img/nfts/Nft2.png";
import Nft3 from "../assets/img/nfts/Nft3.png";
import Nft4 from "../assets/img/features/location.png";
import Nft5 from "../assets/img/nfts/Nft5.png";
import Nft6 from "../assets/img/nfts/Nft6.png";
import Avatar1 from "../assets/img/avatars/avatar1.png";
import Avatar2 from "../assets/img/avatars/avatar2.png";
import Avatar3 from "../assets/img/avatars/avatar3.png";
import Avatar4 from "../assets/img/avatars/avatar4.png";
import NFT from "../components/card/NFT";
import { not } from "@jsonforms/core";
import Balance from "../components/Balance";
import Storage from "../components/dashboard/Storage";
import Controller from "../components/Controller";
import {
  DROPZONE_COLOR,
  REQUEST_COLOR,
  RESPONSE_COLOR,
} from "../constants/sypher";
import toast, { showServerError } from "../components/toast";
import ManagementTable from "../components/landing/ManagementTable";
import { tableColumnsManagement } from "../components/landing/variables/tableColumnsManagement";
import { useNavigate } from "react-router-dom";
import Conversion from "../components/landing/PieCard";
import { navigateToUrl } from "single-spa";
import Card from "../components/card/Card";
import serviceCardBG from "../assets/img/dashboards/service-card.jpg";
import ServiceCard from "../components/landing/ServiceCard";
import { TbFileBarcode } from "react-icons/tb";
import { IoDocuments } from "react-icons/io5";

const staticMapData = [
  [76.78890228271484, 30.73390007019043],
  [-79.51280212402344, 43.85029983520508],
];

const HISTORY_TYPE = {
  SEND: "SEND",
  REQUEST: "REQUEST",
  DROPZONE: "DROPZONE",
};

export default function Landing() {
  const [data, setData] = useState([]);
  const [isNonCompliantUsersLoading, setNonCompliantUsersLoading] =
    useState(true);
  const [nonCompliantUsers, setNonCompliantUsers] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [historyType, setHistoryType] = useState(HISTORY_TYPE.SEND);
  const [accessHistory, setAccessHistory] = useState(null);
  const [date, setDate] = useState(new Date());
  const [responseHistory, setResponseHistory] = useState(null);
  const [dropzoneResponseHistory, setDropzoneResponseHistory] = useState(null);
  const [responseHistoryEnabled, setResponseHistoryEnabled] = useState(true);
  const [dropzoneResponseHistoryEnabled, setDropzoneResponseHistoryEnabled] =
    useState(true);
  const navigate = useNavigate();
  const [mapData, setMapData] = useState([]);
  const { user, setHasPermission } = useAuth();
  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const greenIcon = useColorModeValue("green.500", "white");
  const redIcon = useColorModeValue("red.500", "white");
  const blueIcon = useColorModeValue("blue.500", "white");
  const yellowIcon = useColorModeValue("yellow.500", "white");
  const bgIconButton = useColorModeValue("white", "whiteAlpha.100");
  const bgIconHover = useColorModeValue(
    { bg: "gray.100" },
    { bg: "whiteAlpha.50" }
  );
  const bgIconFocus = useColorModeValue(
    { bg: "grey.100" },
    { bg: "whiteAlpha.100" }
  );
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.100" }
  );
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const shadow = useColorModeValue(
    "18px 17px 40px 4px rgba(112, 144, 176, 0.1)",
    "unset"
  );
  const iconBg = useColorModeValue("secondaryGray.300", "navy.700");
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const [next, setNext] = useState(null);
  const [hasMoreDropzoneResponses, setHasMoreDropzoneResponses] =
    useState(null);
  const baseDate = new Date();
  const [range, setRange] = useState([
    {
      start_date: startOfDay(addDays(baseDate, -30)),
      end_date: endOfDay(baseDate),
      startDate: startOfDay(addDays(baseDate, -30)),
      endDate: endOfDay(baseDate),
      key: "selection",
    },
  ]);
  const filterAndSetNotifications = (notifications) => {
    let modifiedNotificaions = [];
    notifications?.forEach((notification) => {
      let newNotification = {};
      notification?.notification?.forEach((pair) => {
        newNotification[pair?.key] = pair?.value;
      });
      modifiedNotificaions?.push(newNotification);
    });
    setNotificationData([...notificationData, ...modifiedNotificaions]);
    return [...notificationData, ...modifiedNotificaions];
  };

  const modifyAndSetNextNotification = (lastEvalutedPost) => {
    if (!lastEvalutedPost?.sk) {
      setNext(null);
      return;
    }
    let nextData = { ...lastEvalutedPost };
    delete nextData.__typename;
    setNext(nextData);
  };

  const modifyAndSetNextForDropzoneNotification = (lastEvalutedPost) => {
    if (!lastEvalutedPost?.sk) {
      setHasMoreDropzoneResponses(null);
      return;
    }
    let nextData = { ...lastEvalutedPost };
    delete nextData.__typename;
    setHasMoreDropzoneResponses(nextData);
  };

  const scanAndMakeMapData = (data) => {
    let newMapData = [];
    data?.forEach((accessHistory) => {
      newMapData.push([accessHistory?.lon, accessHistory?.lat]);
    });
    let y = Object.values(
      newMapData.reduce((p, c) => ((p[JSON.stringify(c)] = c), p), {})
    );
    setMapData(y);
  };

  const fetchNonCompliantUsers = async () => {
    setNonCompliantUsersLoading(true);
    const { listNonCompliantUsers } = await import(
      "@defense-station/api-service"
    );
    listNonCompliantUsers({
      username: user.username,
      account_id: user?.account_id,
      date: date,
    })
      .then((result) => {
        setNonCompliantUsers(
          result?.data?.policy_manager_v1_ListNonCompliantPersonnels
            ?.non_compliant_personnels
            ? result?.data?.policy_manager_v1_ListNonCompliantPersonnels
                ?.non_compliant_personnels
            : []
        );
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setNonCompliantUsersLoading(false);
      });
  };

  const fetchData = async () => {
    const { getLandingPageData } = await import("@defense-station/api-service");
    const searchMonth = baseDate.getMonth() + 1;
    getLandingPageData({
      account_id: user?.account_id,
      username: user?.username,
      start_date: range[0]?.start_date,
      end_date: range[0]?.end_date,
      month_year:
        (searchMonth < 10 ? "0" : "") +
        searchMonth +
        "-" +
        baseDate.getFullYear(),
    })
      .then((response) => {
        setData(response?.data?.sypher_v1_GetUsage);
        let notificationData = [];
        if (response?.data?.sypher_v1_ListResponses?.responses) {
          setResponseHistory(
            response?.data?.sypher_v1_ListResponses?.responses
          );
          notificationData = [
            ...notificationData,
            ...response?.data?.sypher_v1_ListResponses?.responses,
          ];
        }
        if (response?.data?.sypher_v1_ListDropZoneResponses?.responses) {
          setDropzoneResponseHistory(
            response?.data?.sypher_v1_ListDropZoneResponses?.responses
          );
          notificationData = [
            ...notificationData,
            ...response?.data?.sypher_v1_ListDropZoneResponses?.responses,
          ];
        }
        setNotificationData(notificationData);
        scanAndMakeMapData(
          response?.data?.sypher_v1_ListAccessHistoryByUsername
            ?.access_histories
        );
        modifyAndSetNextNotification(
          response?.data?.sypher_v1_ListResponses
            ? response?.data?.sypher_v1_ListResponses?.last_evaluated_response
            : null
        );
        modifyAndSetNextForDropzoneNotification(
          response?.data?.sypher_v1_ListDropZoneResponses
            ? response?.data?.sypher_v1_ListDropZoneResponses
                ?.last_evaluated_response
            : null
        );
        setLoading(false);
      })
      .catch((e) => {
        if (isPermissionError(e?.message)) {
          setHasPermission(false);
        } else {
          showServerError(e);
        }
      });
  };
  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    if (user) fetchNonCompliantUsers();
  }, [date, user]);
  const fetchNext = async () => {
    setIsLazyLoading(true);
    const {
      getResponsesWithLastEvaluatedResponse,
      getDropzoneResponsesByLastEvaluatedResponse,
    } = await import("@defense-station/api-service");
    const notifications = [];
    if (next) {
      const responses = await getResponsesWithLastEvaluatedResponse(
        user.account_id,
        user.username,
        range[0]?.start_date,
        range[0]?.end_date,
        next
      );
      const tempResponseHistory = [];
      if (responseHistory?.length) {
        tempResponseHistory.push(...responseHistory);
      }
      if (responses?.data?.sypher_v1_ListResponses?.responses?.length) {
        tempResponseHistory.push(
          responses?.data?.sypher_v1_ListResponses?.responses
        );
        if (responseHistoryEnabled) {
          notifications.push(
            ...responses?.data?.sypher_v1_ListResponses?.responses
          );
        }
      }
      setResponseHistory(tempResponseHistory);
      modifyAndSetNextNotification(
        responses?.data?.sypher_v1_ListResponses
          ? responses?.data?.sypher_v1_ListResponses?.last_evaluated_response
          : null
      );
    }
    if (hasMoreDropzoneResponses) {
      const dropzoneResponse =
        await getDropzoneResponsesByLastEvaluatedResponse(
          user.account_id,
          user.username,
          range[0]?.start_date,
          range[0]?.end_date,
          hasMoreDropzoneResponses
        );
      const tempDropzoneResponseHistory = [];
      if (dropzoneResponseHistory?.length) {
        tempDropzoneResponseHistory.push(...dropzoneResponseHistory);
      }
      if (
        dropzoneResponse?.data?.sypher_v1_ListDropZoneResponses?.responses
          ?.length
      ) {
        tempDropzoneResponseHistory.push(
          dropzoneResponse?.data?.sypher_v1_ListDropZoneResponses?.responses
        );
        if (dropzoneResponseHistoryEnabled) {
          notifications.push(...dropzoneResponse);
        }
      }
      setDropzoneResponseHistory(tempDropzoneResponseHistory);
      modifyAndSetNextForDropzoneNotification(
        dropzoneResponse?.data?.sypher_v1_ListDropZoneResponses
          ? dropzoneResponse?.data?.sypher_v1_ListDropZoneResponses
              ?.last_evaluated_response
          : null
      );
    }
    setNotificationData(notifications);
    setIsLazyLoading(false);
  };

  const totalData = formatBytes(data?.total_data ? data?.total_data : 0);
  const sendCount = data?.send_count ? data?.send_count : 0;
  const totalDropzones = data?.dropzone_count ? data?.dropzone_count : 0;
  const totalResponses = data?.response_count ? data?.response_count : 0;
  const requestCount = data?.request_count ? data?.request_count : 0;
  const responseCount = data?.response_count ? data?.response_count : 0;
  const totalPosts = sendCount + requestCount;
  const sendCountPecentage = totalPosts
    ? ((sendCount / totalPosts) * 100).toFixed(2)
    : 0;
  const requestCountPecentage = totalPosts
    ? ((requestCount / totalPosts) * 100).toFixed(2)
    : 0;
  const totalFileCount = data?.file_count ? data?.file_count : 0;
  const totalCost = (totalPosts * 0.35).toFixed(2);
  const brandColor = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const dash = useColorModeValue("234318FFFF", "237551FFFF");
  const inputBg = "#ffffff78";
  const dataSharedInMBs = (data?.total_data ? data?.total_data : 0) / 1000000;
  const dataSharedInGBs =
    (data?.total_data ? data?.total_data : 0) / 1000000000;
  const estimatedCostPerPost = totalPosts
    ? (
        (totalFileCount / totalPosts) *
        (dataSharedInMBs / totalPosts) *
        5
      ).toFixed(2)
    : 0;
  const estimatedCost = totalPosts
    ? (
        dataSharedInGBs * 0.03 +
        (sendCount + requestCount + responseCount) * 0.25
      ).toFixed(2)
    : 0;
  const totalDataBytes = data?.total_data ? data?.total_data : 0;
  const usedData = totalDataBytes - 1073741824;
  const usedSends = sendCount - 20;
  const usedDropzones = totalDropzones - 1;
  const usedResponses = totalResponses - 50;
  const usedRequests = requestCount - 20;
  const queryCost =
    ((usedSends > 0 ? usedSends : 0) +
      (usedDropzones > 0 ? usedDropzones : 0) +
      (usedResponses > 0 ? usedResponses : 0) +
      (usedRequests > 0 ? usedRequests : 0)) *
    0.25;
  const dataCost = usedData > 0 ? usedData * 0.00000000004 : 0;
  const sendUsedPecentage = (sendCount / 20) * 100;
  const requestUsedPecentage = (requestCount / 20) * 100;
  const repsonsesUsedPecentage = (responseCount / 20) * 100;
  const dropzonesUsedPecentage = (totalDropzones / 20) * 100;
  const dataUsedPecentage =
    ((data?.total_data ? data?.total_data : 0) / 1073741824) * 100;
  const sendUsed = sendUsedPecentage > 100 ? 100 : sendUsedPecentage;
  const requestUsed = requestUsedPecentage > 100 ? 100 : requestUsedPecentage;
  const repsonsesUsed =
    repsonsesUsedPecentage > 100 ? 100 : repsonsesUsedPecentage;
  const dropzoneUsed =
    dropzonesUsedPecentage > 100 ? 100 : dropzonesUsedPecentage;
  const dataUsed = dataUsedPecentage > 100 ? 100 : dataUsedPecentage;
  const totalConsumption = (
    (sendUsed + requestUsed + repsonsesUsed + dataUsed) /
    4
  ).toFixed(2);
  const barChartData = [
    sendUsed.toFixed(2),
    requestUsed.toFixed(2),
    repsonsesUsed.toFixed(2),
    dropzoneUsed.toFixed(2),
    dataUsed.toFixed(2),
  ];
  const mapHeight = useBreakpointValue({ base: "50vh", md: "100%" });
  console.log(mapHeight);
  const size = useBreakpoint();
  console.log(size);
  const paleGray = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  const showLargeScreenStatistics = useBreakpointValue({
    base: false,
    xl: false,
    "2xl": true,
  });
  const showSmallScreenStatistics = useBreakpointValue({
    base: true,
    xl: true,
    "2xl": false,
  });
  const onNotificationFilterChange = (type) => {
    let tempNotificationData = [];
    switch (type) {
      case HISTORY_TYPE.REQUEST:
        const newResponseHistoryEnabled = !responseHistoryEnabled;
        setResponseHistoryEnabled(newResponseHistoryEnabled);
        if (responseHistory?.length && newResponseHistoryEnabled) {
          tempNotificationData.push(...responseHistory);
        }
        if (dropzoneResponseHistory?.length && dropzoneResponseHistoryEnabled) {
          tempNotificationData.push(...dropzoneResponseHistory);
        }
        setNotificationData(tempNotificationData);
        break;
      case HISTORY_TYPE.DROPZONE:
        const newDropzoneResponseHistoryEnabled =
          !dropzoneResponseHistoryEnabled;
        setDropzoneResponseHistoryEnabled(newDropzoneResponseHistoryEnabled);
        if (responseHistory?.length && responseHistoryEnabled) {
          tempNotificationData.push(...responseHistory);
        }
        if (
          dropzoneResponseHistory?.length &&
          newDropzoneResponseHistoryEnabled
        ) {
          tempNotificationData.push(...dropzoneResponseHistory);
        }
        setNotificationData(tempNotificationData);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (barChartData) {
      setTimeout(() => {
        let target = document.querySelectorAll(
          ".apexcharts-series:nth-of-type(1) > path"
        );
        if (target?.length) {
          [
            100 - barChartData[0],
            100 - barChartData[1],
            100 - barChartData[2],
            100 - barChartData[3],
            100 - barChartData[1],
          ].forEach((d, i) => {
            if (d == 0) target[i].classList.add("apex-bar");
          });
        }
      }, 1000);
    }
  }, [barChartData]);

  const onUpgrade = async () => {
    setIsUpgrading(true);
    try {
      const { getStripeSession } = await import("@defense-station/api-service");
      const data = await getStripeSession();
      window.location.replace(
        data?.data?.billing_v1_GetUpgradeStripeSession?.redirect_url
      );
    } catch (e) {
      if (e.response) {
        toast({
          title: "Response Error",
          description: e?.response?.errors[0]?.message,
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        return;
      }

      toast({
        title: "Application Error",
        description: e.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    }
    setIsUpgrading(false);
  };

  const renderNotificationBox = (display) => {
    let comp = (
      <Box
        mb={{ base: "20px", lg: "0px" }}
        maxH={{ base: "100%", xl: "70vh", "2xl": "100%" }}
        height={{ base: "max-content", md: "100%" }}
        display={display}
      >
        <NotificationBox
          header={() => (
            <Flex mx="auto" mb="20px" mt="10px">
              <Flex
                direction="column"
                align="center"
                me={{ base: "22px", md: "40px", "2xl": "36px" }}
              >
                <Controller
                  activated={responseHistoryEnabled}
                  text="Request"
                  iconColor={REQUEST_COLOR}
                  onChange={() =>
                    onNotificationFilterChange(HISTORY_TYPE.REQUEST)
                  }
                  icon={BiUserPlus}
                />
                <Text
                  fontSize={{ base: "sm", xl: "xs", "2xl": "sm" }}
                  fontWeight="500"
                  color={textColor}
                >
                  Request
                </Text>
              </Flex>
              <Flex
                direction="column"
                align="center"
                me={{ base: "22px", md: "40px", "2xl": "36px" }}
              >
                <Controller
                  activated={dropzoneResponseHistoryEnabled}
                  text="Dropzone"
                  iconColor={DROPZONE_COLOR}
                  onChange={() =>
                    onNotificationFilterChange(HISTORY_TYPE.DROPZONE)
                  }
                  icon={AiOutlineDropbox}
                />
                <Text
                  fontSize={{ base: "sm", xl: "xs", "2xl": "sm" }}
                  fontWeight="500"
                  color={textColor}
                >
                  Dropzone
                </Text>
              </Flex>
            </Flex>
          )}
          hasNext={next || hasMoreDropzoneResponses}
          fetchNext={fetchNext}
          loading={isLazyLoading}
          data={notificationData}
          maxH={{ base: "auto", lg: "100%" }}
          minH={{ base: "auto", lg: "100%" }}
          overflow="hidden"
          _hover={{
            overflow: "auto",
          }}
        />
        {/* <DocSideBar pr={{ base: "auto", lg: "31px" }} maxH={{ base: "auto", lg: "834px" }} minH={{ base: "auto", lg: "834px" }} overflow="hidden" _hover={{
        overflow: "auto",
      }} /> */}
      </Box>
    );
    return comp;
  };
  const statisticsIconBoxSize = useBreakpointValue({
    base: "56px",
    xl: "40px",
    "2xl": "59px",
  });
  const statisticsIconSize = useBreakpointValue({
    base: "32px",
    xl: "25px",
    "2xl": "32px",
  });
  const statisticsCardPadding = useBreakpointValue({
    base: "20px",
    xl: "10px",
    "2xl": "20px",
  });
  const renderStatistics = () => (
    <>
      <MiniStatistics
        focused={
          usedSends >= 0 && user?.billing_status == FREE_TIER ? true : false
        }
        startContent={
          <IconBox
            w={statisticsIconBoxSize}
            h={statisticsIconBoxSize}
            bg={boxBg}
            icon={
              <Icon
                w={statisticsIconSize}
                h={statisticsIconSize}
                as={RiShareLine}
                color={brandColor}
              />
            }
          />
        }
        name="Total Sends"
        value={sendCount}
        p={statisticsCardPadding}
      />
      <MiniStatistics
        focused={
          usedRequests >= 0 && user?.billing_status == FREE_TIER ? true : false
        }
        startContent={
          <IconBox
            w={statisticsIconBoxSize}
            h={statisticsIconBoxSize}
            bg={boxBg}
            icon={
              <Icon
                w={statisticsIconSize}
                h={statisticsIconSize}
                as={BiUserPlus}
                color={REQUEST_COLOR}
              />
            }
          />
        }
        name="Total Requests"
        value={requestCount}
        p={statisticsCardPadding}
      />
      <MiniStatistics
        focused={
          usedDropzones >= 0 && user?.billing_status == FREE_TIER ? true : false
        }
        startContent={
          <IconBox
            w={statisticsIconBoxSize}
            h={statisticsIconBoxSize}
            bg={boxBg}
            icon={
              <Icon
                w={statisticsIconSize}
                h={statisticsIconSize}
                as={AiOutlineDropbox}
                color={DROPZONE_COLOR}
              />
            }
          />
        }
        name="Total Dropzones"
        value={totalDropzones}
        p={statisticsCardPadding}
      />
      <MiniStatistics
        focused={
          usedResponses >= 0 && user?.billing_status == FREE_TIER ? true : false
        }
        startContent={
          <IconBox
            w={statisticsIconBoxSize}
            h={statisticsIconBoxSize}
            bg={boxBg}
            icon={
              <Icon
                w={statisticsIconSize}
                h={statisticsIconSize}
                as={MdCallReceived}
                color={RESPONSE_COLOR}
              />
            }
          />
        }
        name="Total Responses"
        value={totalResponses}
        p={statisticsCardPadding}
      />
    </>
  );

  const onRowClick = (row) =>
    navigateToUrl(`/policy/manage/personnels/${row?.email}`);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Box
          p={{ base: "20px", md: "30px", xl: "30px" }}
          display={"flex"}
          flexDir="column"
          flex={"1"}
          maxW="100%"
          h="full"
        >
          <Box mb="20px">
            <SimpleGrid
              columns={3}
              gap={{ base: "20px", xl: "10px", "2xl": "20px" }}
            >
              <ServiceCard 
              onClick={() => navigateToUrl("/sypher/dashboard")}
              bgBox={serviceCardBG}
              bgSize="cover"
              icon={<Icon as={MdShield} color='white' w='100px' h='100px' />}
              title='Sypher'
              desc='Share Informatiion on the Go'
              topics={["Post Quantum Encryption", "Secure Data Sharing"]}
              />
              <ServiceCard 
              onClick={() => navigateToUrl("/asset-intelligence/dashboard")}
              bgBox={serviceCardBG}
              bgSize="cover"
              icon={<Icon as={TbFileBarcode} color='white' w='100px' h='100px' />}
              title='Asset Intelligence'
              desc='Asset Intelligence: Get the unknown'
              topics={["Next Generation Of Inventory Mangement", "AI Security Assistant"]}
              />
              <ServiceCard 
              onClick={() => navigateToUrl("/policy/dashboard")}
              bgBox={serviceCardBG}
              bgSize="cover"
              icon={<Icon as={IoDocuments} color='white' w='100px' h='100px' />}
              title='Policy Manager'
              desc='Manage your security policies'
              topics={["Automated management", "Policies in few minutes"]}
              />
            </SimpleGrid>
          </Box>
          <Grid
            gridTemplateColumns={{
              base: "repeat(2, 1fr)",
              "2xl": "1fr 1.13fr 1.45fr",
            }}
            gridTemplateRows={{
              base: "repeat(2, 1fr)",
              "2xl": "1fr",
            }}
            gap={{ base: "20px", xl: "20px" }}
            display={{ base: "block", lg: "grid" }}
          >
            <Box h="full" gridArea="1 / 1 / 2 / 2">
              {renderNotificationBox({ base: "block", xl: "block" })}
            </Box>
            <Stack
              gridArea="1 / 2 / 2 / 3"
              spacing={{ base: "20px", xl: "20px" }}
            >
              <Conversion flex="1" data={[10, 20]} />
              <ManagementTable
                isLoading={isNonCompliantUsersLoading}
                tableData={nonCompliantUsers}
                columnsData={tableColumnsManagement}
                onDateChange={(v) => setDate(v)}
                onEdit={onRowClick}
              />
            </Stack>
            <Box gridArea={{ base: "2 / 1 / 3 / 3", "2xl": "1 / 3 / 2 / 4" }}>
              <MapBox
                disableMarkers={!mapData?.length}
                style={{ height: mapHeight, borderRadius: "15px" }}
                bounds={mapData?.length ? mapData : staticMapData}
              />
            </Box>
          </Grid>
        </Box>
      )}
    </>
  );
}
