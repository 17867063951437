import {
  and,
  CellProps,
  formatIs,
  isStringControl,
  RankedTester,
  rankWith,
  schemaMatches,
} from '@reactjsonforms/core';
import { Input } from '@chakra-ui/react';
import { withJsonFormsCellProps } from '@reactjsonforms/react';
import merge from 'lodash/merge';
import { VanillaRendererProps, withVanillaCellProps } from '@reactjsonforms/chakra-renderers';
import { ChangeEvent } from 'react';

export const FileCell = (props: CellProps & VanillaRendererProps) => {
  const {
    config,
    data,
    className,
    id,
    enabled,
    uischema,
    schema,
    path,
    handleChange,
  } = props;
  const maxLength = schema.maxLength;
  const appliedUiSchemaOptions = merge({}, config, uischema.options);

  const size = appliedUiSchemaOptions.trim ? maxLength : undefined;
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const file = event.target.files?.[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          handleChange(path, e.target?.result as string);
        };
        reader.readAsDataURL(file);
      }
    }
  }
  console.log("From renderer", schema, data)
  return (
    <Input
      type={"file"}
      // value={data || ''}
      onChange={handleFileChange}
      multiple={uischema.options?.multiple}
      className={className}
      id={id}
      isDisabled={!enabled}
      autoFocus={appliedUiSchemaOptions.focus}
      placeholder={appliedUiSchemaOptions.placeholder}
      maxLength={appliedUiSchemaOptions.restrict ? maxLength : undefined}
      htmlSize={size}
    />
  );
};

/**
 * Default tester for text-based/string controls.
 * @type {RankedTester}
 */
export const fileCellTester: RankedTester = rankWith(4, and(
  isStringControl,
  formatIs("data-url")
));

export default withJsonFormsCellProps(withVanillaCellProps(FileCell));
