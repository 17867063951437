import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import { FieldProps } from "../../../../types/dnd-types";
import BaseComponent from "../BaseComponent";

export default function SelectField(props: FieldProps) {
  return (
    <BaseComponent  keyName={props.keyName} onDelete={props.onDelete}>
      <FormControl isRequired={props.required}>
        <FormLabel>{props.title}</FormLabel>
        <Select isDisabled placeholder={props.placeholder}></Select>
      </FormControl>
    </BaseComponent>
  );
}
