import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  FormControl,
  InputGroup,
  SimpleGrid,
  Stack,
  Switch,
  TagCloseButton,
  TagLabel,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { addDays, endOfDay, startOfDay } from "date-fns";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { FREE_TIER, SOMETHING_WENT_WRONG } from "../../constants/constants";
import { useAuth } from "../../context/auth";
import { showErrorToast } from "../../services/toast-service";
import CardHeaderText from "../custom-styled-components/CardHeaderText";
import CustomFormLabel from "../custom-styled-components/CustomFormLabel";
import NumberField from "../fields/NumberField";
import TextField from "../fields/TextField";
import CustomButton from "../custom-styled-components/CustomButton";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import CustomTag from "../custom-styled-components/CustomTag";
import toast from "../toast";
import InputRequestedFields from "../secure-share/request/InputRequestedFields";
import { buildJSONSchema } from "../../utils/custom-function";

const MAX_EMAIL_LENGTH = 20;

export default function RequestSettings({ settings }) {
  const MAX_FILE_SIZE =
    user?.billing_status == FREE_TIER ? 1073741824 : 5368709120;
  const { user } = useAuth();
  const baseDate = new Date();
  const [range, setRange] = useState([
    {
      start_date: startOfDay(baseDate),
      end_date: endOfDay(addDays(baseDate, 3)),
      startDate: startOfDay(baseDate),
      endDate: endOfDay(addDays(baseDate, 3)),
      key: "selection",
    },
  ]);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  let bg = useColorModeValue("brand.500", "brand.400");
  const multiEmailFieldHeight = useBreakpointValue({
    base: "100px",
    xl: "70px",
    "2xl": "100px",
  });
  const onSubmit = async (values) => {
    try {
      let schema = null;
      let data = undefined;
      if(values?.requested_fields?.length) {
        schema = buildJSONSchema(values?.requested_fields, MAX_FILE_SIZE)
        data = btoa(JSON.stringify(schema));
      }
      const variables = {
        access_limit: values?.click_allowed,
        days_after_create: values?.days_after_create,
        days_after_start: values?.days_after_start,
        enable_notifications: values?.enable_notifications,
        hide_identity: values?.hide_identity,
        hide_stats: values?.hide_stats,
        login_required: values?.login_required,
        message: values?.message,
        responder_controls: values?.responder_controls,
        data: data,
        signer_files: schema?.files,
        respondent_ids: values?.req_emails,
        account_id: user?.account_id,
        username: user?.username,
      }
      if (settings) {
        const { updateRequestDefaultSettings } = await import("@defense-station/api-service");
        await updateRequestDefaultSettings(variables)
      }
      else {
        const { createRequestDefaultSettings } = await import("@defense-station/api-service");
        await createRequestDefaultSettings(variables)
      }
      toast({
        description: `Setttings has been ${settings?"updated":"created"}.`,
        status: "success"
      })
    } catch (e) {
      console.log(e)
      showErrorToast("Error", SOMETHING_WENT_WRONG);
    }
  };
  return (
    <Stack spacing={"2"}>
      <Formik
        initialValues={{
          click_allowed: 14,
          days_after_create: 0,
          days_after_start: 0,
          hide_stats: true,
          login_required: false,
          download_allowed: true,
          hide_identity: false,
          requested_fields: [],
          is_device: false,
          req_emails: [],
        }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, errors, isSubmitting, setFieldValue, handleChange }) => (
          <Form style={{ width: "100%" }}>
            <Stack>
              <TextField
                h="85px"
                name="message"
                label="Message"
                value={values.message}
                placeholder="Enter a message(Do not add any sensitive information.)"
                onChange={handleChange}
                error={errors?.message}
                data-gramm="false"
              />
              <FormControl>
                <CustomFormLabel
                  display="flex"
                  ms="10px"
                  color={textColorPrimary}
                  fontWeight="bold"
                  _hover={{ cursor: "pointer" }}
                >
                  Recipient Emails
                </CustomFormLabel>
                <InputGroup size="md">
                  <ReactMultiEmail
                    style={{
                      height: multiEmailFieldHeight,
                      borderColor: "#E2E8F0",
                      borderRadius: "16px",
                    }}
                    emails={values?.req_emails}
                    variant="main"
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <CustomTag
                          fontSize="xs"
                          h="25px"
                          mb="6px"
                          me="6px"
                          borderRadius="12px"
                          variant="solid"
                          bg={bg}
                          key={index}
                        >
                          <TagLabel w="100%">{email}</TagLabel>
                          <TagCloseButton
                            justifySelf="flex-end"
                            color="white"
                            onClick={() => removeEmail(index)}
                          />
                        </CustomTag>
                      );
                    }}
                    onChange={(_emails) => {
                      setFieldValue("req_emails", _emails);
                    }}
                    validateEmail={(email) => {
                      if (isEmail(email)) {
                        if (values.req_emails.length == MAX_EMAIL_LENGTH) {
                          toast({
                            title: "Error",
                            description: "Maximum 20 emails are allowed.",
                            status: "error",
                            duration: 4000,
                            isClosable: true,
                            position: "top",
                          });
                          return false;
                        }
                        return true;
                      }
                      return false;
                    }}
                  />
                </InputGroup>
                {errors.req_emails && (
                  <CustomText color={"red.400"}>{errors.req_emails}</CustomText>
                )}
              </FormControl>
              <Stack direction={{ base: "column", md: "row" }}>
              <NumberField
                  mb="0px"
                  pr="4.5rem"
                  max={365}
                  label="Days From Create"
                  name="days_after_create"
                  min={0}
                  onChange={(n) => {
                    setFieldValue("days_after_create", n);
                  }}
                  value={values.days_after_create}
                  p="3"
                  width="80px"
                />
                <NumberField
                  mb="0px"
                  pr="4.5rem"
                  max={365}
                  label="Day From Start"
                  name="days_after_start"
                  min={0}
                  onChange={(n) => {
                    setFieldValue("days_after_start", n);
                  }}
                  value={values.days_after_start}
                  p="3"
                  width="80px"
                />
              </Stack>
              <NumberField
                mb="0px"
                pr="4.5rem"
                max={user?.billing_status == FREE_TIER ? 14 : 100}
                label="Allowed Opens"
                name="click_allowed"
                min={1}
                onChange={(n) => {
                  setFieldValue("click_allowed", n);
                }}
                value={values.click_allowed}
                p="3"
                width="80px"
              />
              <InputRequestedFields
                          maxFieldCount={10}
                          values={values}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          errors={errors}
                        />
              <Accordion
              defaultIndex={[0]}
              allowToggle
              w={"100%"}
              borderColor={"brand.500"}
              borderWidth={"1px"}
              borderRadius={"15px"}
              mb='16px'>
                <AccordionItem border='none'>
                <AccordionButton
                  _focus='none'
                  _hover='none'
                  p='5px 20px 5px 0px'>
                  <Box flex='1' textAlign='left'>
                    <CustomFormLabel htmlFor="login" mb="0" fontWeight='700'
                      fontSize={{ sm: "md", lg: "md" }}>
                        Advance Settings
                    </CustomFormLabel>
                  </Box>
                  <AccordionIcon color='gray.500' />
                </AccordionButton>
                <AccordionPanel p='0px 0px 10px 0px'>
                <SimpleGrid columns={{base: 1, md: 2, "2xl": 3}}>
                <FormControl display="flex" alignItems="center" p="8px">
                  <Switch
                    name="responder_controls"
                    colorScheme="brand"
                    mr={4}
                    isChecked={values?.responder_controls}
                    onChange={(v) => {
                      setFieldValue(
                        "responder_controls",
                        v?.currentTarget.checked
                      );
                    }}
                  />
                  <CustomFormLabel htmlFor="responder_controls" mb="0">
                    Responder Controls
                  </CustomFormLabel>
                  <Tooltip label="Enable responder controls to allow user to set access limits.">
                    <InfoOutlineIcon color={"brand.600"} />
                  </Tooltip>
                </FormControl>
                <FormControl display="flex" alignItems="center" p="8px">
                  <Switch
                    colorScheme="brand"
                    id="stats"
                    name="hide_stats"
                    mr={4}
                    isChecked={values?.hide_stats}
                    onChange={(v) => {
                      setFieldValue("hide_stats", v?.currentTarget.checked);
                    }}
                  />
                  <CustomFormLabel htmlFor="hide_stats" mb="0">
                    Hide Stats
                  </CustomFormLabel>
                  <Tooltip label="Hide stats of shared content.">
                    <InfoOutlineIcon color={"brand.600"} />
                  </Tooltip>
                </FormControl>
                <FormControl display="flex" alignItems="center" p="8px">
                  <Switch
                    colorScheme="brand"
                    id="login"
                    mr={4}
                    name="login_required"
                    isChecked={values?.login_required}
                    onChange={(v) => {
                      setFieldValue("login_required", v?.currentTarget.checked);
                    }}
                  />
                  <CustomFormLabel htmlFor="login_required" mb="0">
                    Login Required
                  </CustomFormLabel>
                  <Tooltip label="User will need to login to view the shared content.">
                    <InfoOutlineIcon color={"brand.600"} />
                  </Tooltip>
                </FormControl>
                <FormControl display="flex" alignItems="center" p="8px">
                  <Switch
                    colorScheme="brand"
                    id="hide_identity"
                    name="hide_identity"
                    mr={4}
                    isChecked={values?.hide_identity}
                    onChange={(v) => {
                      setFieldValue("hide_identity", v?.currentTarget.checked);
                    }}
                  />
                  <CustomFormLabel htmlFor="hide_identity" mb="0">
                    Hide Identity
                  </CustomFormLabel>
                  <Tooltip label="User won't be able to see your identity.">
                    <InfoOutlineIcon color={"brand.600"} />
                  </Tooltip>
                </FormControl>
              </SimpleGrid>
              </AccordionPanel>
              </AccordionItem>
              </Accordion>
              <CustomButton type="submit" isLoading={isSubmitting} colorScheme="brand" w="full">
                Create
              </CustomButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
}
