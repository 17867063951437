import { Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import React from "react";

export default function CustomDescription({ children, ...rest }) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textSize = useBreakpointValue({
    base: "md",
    xl: "sm",
    "2xl": "md",
  });
  return (
    <Text fontSize={textSize} color={textColor} fontWeight="400" {...rest}>
      {children}
    </Text>
  );
}
