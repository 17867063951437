// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/policy/v1/service/document/document.proto (package policy.v1.document, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Document, DocumentSettings, DocumentType, SecurityClassification } from "../../types/types_pb";

/**
 * @generated from message policy.v1.document.CreateDocumentRequest
 */
export class CreateDocumentRequest extends Message<CreateDocumentRequest> {
  /**
   * @generated from field: string parent_folder_dri = 1;
   */
  parentFolderDri = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: policy.v1.types.DocumentType type = 4;
   */
  type = DocumentType.OTHER_UNSPECIFIED;

  /**
   * @generated from field: policy.v1.types.SecurityClassification security_classification = 5;
   */
  securityClassification = SecurityClassification.INTERNAL_UNSPECIFIED;

  /**
   * @generated from field: int64 file_size = 6;
   */
  fileSize = protoInt64.zero;

  constructor(data?: PartialMessage<CreateDocumentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.document.CreateDocumentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent_folder_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "type", kind: "enum", T: proto3.getEnumType(DocumentType) },
    { no: 5, name: "security_classification", kind: "enum", T: proto3.getEnumType(SecurityClassification) },
    { no: 6, name: "file_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateDocumentRequest {
    return new CreateDocumentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateDocumentRequest {
    return new CreateDocumentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateDocumentRequest {
    return new CreateDocumentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateDocumentRequest | PlainMessage<CreateDocumentRequest> | undefined, b: CreateDocumentRequest | PlainMessage<CreateDocumentRequest> | undefined): boolean {
    return proto3.util.equals(CreateDocumentRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.document.CreateDocumentResponse
 */
export class CreateDocumentResponse extends Message<CreateDocumentResponse> {
  /**
   * @generated from field: policy.v1.types.Document document = 1;
   */
  document?: Document;

  constructor(data?: PartialMessage<CreateDocumentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.document.CreateDocumentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document", kind: "message", T: Document },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateDocumentResponse {
    return new CreateDocumentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateDocumentResponse {
    return new CreateDocumentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateDocumentResponse {
    return new CreateDocumentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateDocumentResponse | PlainMessage<CreateDocumentResponse> | undefined, b: CreateDocumentResponse | PlainMessage<CreateDocumentResponse> | undefined): boolean {
    return proto3.util.equals(CreateDocumentResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.document.GetDocumentRequest
 */
export class GetDocumentRequest extends Message<GetDocumentRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * version 0 means draft, if version not given: means latest version
   *
   * @generated from field: optional int32 version = 2;
   */
  version?: number;

  constructor(data?: PartialMessage<GetDocumentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.document.GetDocumentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDocumentRequest {
    return new GetDocumentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDocumentRequest {
    return new GetDocumentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDocumentRequest {
    return new GetDocumentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetDocumentRequest | PlainMessage<GetDocumentRequest> | undefined, b: GetDocumentRequest | PlainMessage<GetDocumentRequest> | undefined): boolean {
    return proto3.util.equals(GetDocumentRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.document.GetDocumentResponse
 */
export class GetDocumentResponse extends Message<GetDocumentResponse> {
  /**
   * @generated from field: policy.v1.types.Document document = 1;
   */
  document?: Document;

  constructor(data?: PartialMessage<GetDocumentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.document.GetDocumentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document", kind: "message", T: Document },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDocumentResponse {
    return new GetDocumentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDocumentResponse {
    return new GetDocumentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDocumentResponse {
    return new GetDocumentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetDocumentResponse | PlainMessage<GetDocumentResponse> | undefined, b: GetDocumentResponse | PlainMessage<GetDocumentResponse> | undefined): boolean {
    return proto3.util.equals(GetDocumentResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.document.UpdateDocumentRequest
 */
export class UpdateDocumentRequest extends Message<UpdateDocumentRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string parent_folder_dri = 2;
   */
  parentFolderDri = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: policy.v1.types.DocumentType type = 5;
   */
  type = DocumentType.OTHER_UNSPECIFIED;

  /**
   * @generated from field: policy.v1.types.DocumentSettings settings = 6;
   */
  settings?: DocumentSettings;

  /**
   * @generated from field: int64 file_size = 7;
   */
  fileSize = protoInt64.zero;

  constructor(data?: PartialMessage<UpdateDocumentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.document.UpdateDocumentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "parent_folder_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(DocumentType) },
    { no: 6, name: "settings", kind: "message", T: DocumentSettings },
    { no: 7, name: "file_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateDocumentRequest {
    return new UpdateDocumentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateDocumentRequest {
    return new UpdateDocumentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateDocumentRequest {
    return new UpdateDocumentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateDocumentRequest | PlainMessage<UpdateDocumentRequest> | undefined, b: UpdateDocumentRequest | PlainMessage<UpdateDocumentRequest> | undefined): boolean {
    return proto3.util.equals(UpdateDocumentRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.document.UpdateDocumentResponse
 */
export class UpdateDocumentResponse extends Message<UpdateDocumentResponse> {
  /**
   * @generated from field: policy.v1.types.Document document = 1;
   */
  document?: Document;

  constructor(data?: PartialMessage<UpdateDocumentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.document.UpdateDocumentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document", kind: "message", T: Document },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateDocumentResponse {
    return new UpdateDocumentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateDocumentResponse {
    return new UpdateDocumentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateDocumentResponse {
    return new UpdateDocumentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateDocumentResponse | PlainMessage<UpdateDocumentResponse> | undefined, b: UpdateDocumentResponse | PlainMessage<UpdateDocumentResponse> | undefined): boolean {
    return proto3.util.equals(UpdateDocumentResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.document.SearchDocumentsRequest
 */
export class SearchDocumentsRequest extends Message<SearchDocumentsRequest> {
  /**
   * @generated from field: string search = 1;
   */
  search = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 3;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<SearchDocumentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.document.SearchDocumentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchDocumentsRequest {
    return new SearchDocumentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchDocumentsRequest {
    return new SearchDocumentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchDocumentsRequest {
    return new SearchDocumentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SearchDocumentsRequest | PlainMessage<SearchDocumentsRequest> | undefined, b: SearchDocumentsRequest | PlainMessage<SearchDocumentsRequest> | undefined): boolean {
    return proto3.util.equals(SearchDocumentsRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.document.SearchDocumentsResponse
 */
export class SearchDocumentsResponse extends Message<SearchDocumentsResponse> {
  /**
   * @generated from field: repeated policy.v1.types.Document documents = 1;
   */
  documents: Document[] = [];

  constructor(data?: PartialMessage<SearchDocumentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.document.SearchDocumentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "documents", kind: "message", T: Document, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchDocumentsResponse {
    return new SearchDocumentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchDocumentsResponse {
    return new SearchDocumentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchDocumentsResponse {
    return new SearchDocumentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SearchDocumentsResponse | PlainMessage<SearchDocumentsResponse> | undefined, b: SearchDocumentsResponse | PlainMessage<SearchDocumentsResponse> | undefined): boolean {
    return proto3.util.equals(SearchDocumentsResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.document.MoveDocumentRequest
 */
export class MoveDocumentRequest extends Message<MoveDocumentRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string destination_folder_dri = 2;
   */
  destinationFolderDri = "";

  constructor(data?: PartialMessage<MoveDocumentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.document.MoveDocumentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "destination_folder_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MoveDocumentRequest {
    return new MoveDocumentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MoveDocumentRequest {
    return new MoveDocumentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MoveDocumentRequest {
    return new MoveDocumentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MoveDocumentRequest | PlainMessage<MoveDocumentRequest> | undefined, b: MoveDocumentRequest | PlainMessage<MoveDocumentRequest> | undefined): boolean {
    return proto3.util.equals(MoveDocumentRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.document.MoveDocumentResponse
 */
export class MoveDocumentResponse extends Message<MoveDocumentResponse> {
  /**
   * @generated from field: string destination_folder_dri = 1;
   */
  destinationFolderDri = "";

  constructor(data?: PartialMessage<MoveDocumentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.document.MoveDocumentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "destination_folder_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MoveDocumentResponse {
    return new MoveDocumentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MoveDocumentResponse {
    return new MoveDocumentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MoveDocumentResponse {
    return new MoveDocumentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: MoveDocumentResponse | PlainMessage<MoveDocumentResponse> | undefined, b: MoveDocumentResponse | PlainMessage<MoveDocumentResponse> | undefined): boolean {
    return proto3.util.equals(MoveDocumentResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.document.AddRolesToDocumentRequest
 */
export class AddRolesToDocumentRequest extends Message<AddRolesToDocumentRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: repeated string role_dris = 2;
   */
  roleDris: string[] = [];

  constructor(data?: PartialMessage<AddRolesToDocumentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.document.AddRolesToDocumentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddRolesToDocumentRequest {
    return new AddRolesToDocumentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddRolesToDocumentRequest {
    return new AddRolesToDocumentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddRolesToDocumentRequest {
    return new AddRolesToDocumentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddRolesToDocumentRequest | PlainMessage<AddRolesToDocumentRequest> | undefined, b: AddRolesToDocumentRequest | PlainMessage<AddRolesToDocumentRequest> | undefined): boolean {
    return proto3.util.equals(AddRolesToDocumentRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.document.RemoveRolesFromDocumentRequest
 */
export class RemoveRolesFromDocumentRequest extends Message<RemoveRolesFromDocumentRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: repeated string role_dris = 2;
   */
  roleDris: string[] = [];

  constructor(data?: PartialMessage<RemoveRolesFromDocumentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.document.RemoveRolesFromDocumentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role_dris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveRolesFromDocumentRequest {
    return new RemoveRolesFromDocumentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveRolesFromDocumentRequest {
    return new RemoveRolesFromDocumentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveRolesFromDocumentRequest {
    return new RemoveRolesFromDocumentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveRolesFromDocumentRequest | PlainMessage<RemoveRolesFromDocumentRequest> | undefined, b: RemoveRolesFromDocumentRequest | PlainMessage<RemoveRolesFromDocumentRequest> | undefined): boolean {
    return proto3.util.equals(RemoveRolesFromDocumentRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.document.ListDocumentRolesRequest
 */
export class ListDocumentRolesRequest extends Message<ListDocumentRolesRequest> {
  /**
   * @generated from field: string document_dri = 1;
   */
  documentDri = "";

  /**
   * @generated from field: int32 page_size = 2;
   */
  pageSize = 0;

  /**
   * @generated from field: int32 page_number = 3;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ListDocumentRolesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.document.ListDocumentRolesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "document_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDocumentRolesRequest {
    return new ListDocumentRolesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDocumentRolesRequest {
    return new ListDocumentRolesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDocumentRolesRequest {
    return new ListDocumentRolesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListDocumentRolesRequest | PlainMessage<ListDocumentRolesRequest> | undefined, b: ListDocumentRolesRequest | PlainMessage<ListDocumentRolesRequest> | undefined): boolean {
    return proto3.util.equals(ListDocumentRolesRequest, a, b);
  }
}

/**
 * repeated policy.v1.types.Role roles = 1;
 *
 * @generated from message policy.v1.document.ListDocumentRolesResponse
 */
export class ListDocumentRolesResponse extends Message<ListDocumentRolesResponse> {
  constructor(data?: PartialMessage<ListDocumentRolesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.document.ListDocumentRolesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListDocumentRolesResponse {
    return new ListDocumentRolesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDocumentRolesResponse {
    return new ListDocumentRolesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListDocumentRolesResponse {
    return new ListDocumentRolesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListDocumentRolesResponse | PlainMessage<ListDocumentRolesResponse> | undefined, b: ListDocumentRolesResponse | PlainMessage<ListDocumentRolesResponse> | undefined): boolean {
    return proto3.util.equals(ListDocumentRolesResponse, a, b);
  }
}

/**
 * @generated from message policy.v1.document.UpdateDocumentClassificationRequest
 */
export class UpdateDocumentClassificationRequest extends Message<UpdateDocumentClassificationRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: policy.v1.types.SecurityClassification old_security_classification = 2;
   */
  oldSecurityClassification = SecurityClassification.INTERNAL_UNSPECIFIED;

  /**
   * @generated from field: policy.v1.types.SecurityClassification security_classification = 3;
   */
  securityClassification = SecurityClassification.INTERNAL_UNSPECIFIED;

  constructor(data?: PartialMessage<UpdateDocumentClassificationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.document.UpdateDocumentClassificationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "old_security_classification", kind: "enum", T: proto3.getEnumType(SecurityClassification) },
    { no: 3, name: "security_classification", kind: "enum", T: proto3.getEnumType(SecurityClassification) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateDocumentClassificationRequest {
    return new UpdateDocumentClassificationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateDocumentClassificationRequest {
    return new UpdateDocumentClassificationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateDocumentClassificationRequest {
    return new UpdateDocumentClassificationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateDocumentClassificationRequest | PlainMessage<UpdateDocumentClassificationRequest> | undefined, b: UpdateDocumentClassificationRequest | PlainMessage<UpdateDocumentClassificationRequest> | undefined): boolean {
    return proto3.util.equals(UpdateDocumentClassificationRequest, a, b);
  }
}

/**
 * @generated from message policy.v1.document.DeleteDocumentRequest
 */
export class DeleteDocumentRequest extends Message<DeleteDocumentRequest> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string parent_folder_dri = 2;
   */
  parentFolderDri = "";

  constructor(data?: PartialMessage<DeleteDocumentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "policy.v1.document.DeleteDocumentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "parent_folder_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteDocumentRequest {
    return new DeleteDocumentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteDocumentRequest {
    return new DeleteDocumentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteDocumentRequest {
    return new DeleteDocumentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteDocumentRequest | PlainMessage<DeleteDocumentRequest> | undefined, b: DeleteDocumentRequest | PlainMessage<DeleteDocumentRequest> | undefined): boolean {
    return proto3.util.equals(DeleteDocumentRequest, a, b);
  }
}

