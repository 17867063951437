import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spinner,
  Stack,
  Switch,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getPostByStats } from "@defense-station/api-service";
import { useAuth } from "../../../context/auth";
import Card from "../../card/Card";
import { Form, Formik } from "formik";
import { InfoIcon } from "@chakra-ui/icons";

const Information = (props) => {
  const { post, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={{
        retry_limit: post?.retry_limit,
        hide_stats: post?.hide_stats,
        login_required: post?.login_required,
        enableNotification: post?.enableNotification,
        hide_identity: post?.hide_identity,
        label: post?.label,
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, handleChange }) => (
        <Form style={{ width: "100%" }}>
          <Flex direction="column" mb="30px" ms="10px">
            <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
              Advance Settings
            </Text>
            <Text fontSize="md" color={textColorSecondary}>
              Here you can set product social profiles
            </Text>
          </Flex>
          <SimpleGrid
            mb="30px"
            ms="10px"
            columns={{ sm: 1, md: 3, xl: 3 }}
            spacing={{ base: "20px", xl: "0px" }}
          >
            <FormControl display="flex" alignItems="center" p="8px">
              <Switch
                name="enableNotification"
                colorScheme="brand"
                mr={4}
                isChecked={values?.enableNotification}
                onChange={(v) => {
                  setFieldValue("enableNotification", v?.currentTarget.checked);
                }}
              />
              <FormLabel htmlFor="enableNotification" mb="0">
                Enable Notification
              </FormLabel>
              <Tooltip label="Enable notification to know when the link is opened.">
                <InfoIcon />
              </Tooltip>
            </FormControl>
            <FormControl display="flex" alignItems="center" p="8px">
              <Switch
                colorScheme="brand"
                id="stats"
                name="hide_stats"
                mr={4}
                isChecked={values?.hide_stats}
                onChange={(v) => {
                  setFieldValue("hide_stats", v?.currentTarget.checked);
                }}
              />
              <FormLabel htmlFor="hide_stats" mb="0">
                Hide Stats
              </FormLabel>
              <Tooltip label="Hide stats of shared content.">
                <InfoIcon />
              </Tooltip>
            </FormControl>
            <FormControl display="flex" alignItems="center" p="8px">
              <Switch
                colorScheme="brand"
                id="login"
                mr={4}
                name="login_required"
                isChecked={values?.login_required}
                onChange={(v) => {
                  setFieldValue("login_required", v?.currentTarget.checked);
                }}
              />
              <FormLabel htmlFor="login_required" mb="0">
                Login Required
              </FormLabel>
              <Tooltip label="User will need to login to view the shared content.">
                <InfoIcon />
              </Tooltip>
            </FormControl>
            <FormControl display="flex" alignItems="center" p="8px">
              <Switch
                colorScheme="brand"
                id="hide_identity"
                name="hide_identity"
                mr={4}
                isChecked={values?.hide_identity}
                onChange={(v) => {
                  setFieldValue("hide_identity", v?.currentTarget.checked);
                }}
              />
              <FormLabel htmlFor="hide_identity" mb="0">
                Hide Identity
              </FormLabel>
              <Tooltip label="User won't be able to see your identity.">
                <InfoIcon />
              </Tooltip>
            </FormControl>
          </SimpleGrid>
        </Form>
      )}
    </Formik>
  );
};
export default function ViewDetailsModal({ isOpen, onOpen, onClose, post }) {
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );

  const [overlay, setOverlay] = useState(<OverlayOne />);
  const { user } = useAuth();
  const [map, setMapData] = useState([]);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  return (
    <>
      <Modal
        scrollBehavior="outside"
        size={"6xl"}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        {overlay}
        <ModalContent>
          <ModalHeader color={textColorPrimary} fontWeight="bold">
            Request Details
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Information post={post} />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="brand"
              variant={"outline"}
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
