import { Spinner } from "@/components/ui/spinner";
import React from "react";

export default function LoadingScreen() {
  return (
    <div className="h-screen flex justify-center items-center">
      <Spinner />
    </div>
  );
}
