import {
  CellProps,
  isStringControl,
  RankedTester,
  rankWith,
} from '@reactjsonforms/core';
import { Input } from '@chakra-ui/react';
import { withJsonFormsCellProps } from '@reactjsonforms/react';
import merge from 'lodash/merge';
import { VanillaRendererProps, withVanillaCellProps } from '@reactjsonforms/chakra-renderers';

export const TextCell = (props: CellProps & VanillaRendererProps) => {
  const {
    config,
    data,
    className,
    id,
    enabled,
    uischema,
    schema,
    path,
    handleChange,
  } = props;
  const maxLength = schema.maxLength;
  const appliedUiSchemaOptions = merge({}, config, uischema.options);

  const size = appliedUiSchemaOptions.trim ? maxLength : undefined;

  return (
    <Input
      type={schema.format?schema.format:"text"}
      value={data || ''}
      onChange={(ev) =>
        handleChange(path, ev.target.value === '' ? undefined : ev.target.value)
      }
      className={className}
      id={id}
      isDisabled={!enabled}
      autoFocus={appliedUiSchemaOptions.focus}
      placeholder={appliedUiSchemaOptions.placeholder}
      maxLength={appliedUiSchemaOptions.restrict ? maxLength : undefined}
      htmlSize={size}
    />
  );
};

/**
 * Default tester for text-based/string controls.
 * @type {RankedTester}
 */
export const textCellTester: RankedTester = rankWith(2, isStringControl);

export default withJsonFormsCellProps(withVanillaCellProps(TextCell));
