import * as yup from "yup";

export const createDropzoneSchema = yup.object().shape({
  dropzone_name: yup.string().required().max(20),
  description: yup.string().required().max(250),
  message: yup.string().max(250),
  allowed_origins: yup
    .array()
    .required()
    .min(1, "Atleast 1 origin is required."),
  requested_files: yup.array().of(
    yup.object().shape({
      file_name: yup
        .string()
        .min(3, "Name is too short")
        .required("Name is Required"),
      file_required: yup.boolean().required(),
    })
  ),
  requested_fields: yup.array().of(
    yup.object().shape({
      field_name: yup
        .string()
        .min(3, "Name is too short")
        .required("Name is Required"),
      field_required: yup.boolean().required(),
    })
  ),
  // .min(1, "Template should have atleast one field."),
  is_password: yup.boolean(),
  password: yup.string().when("is_password", {
    is: true,
    then: yup.string().required(),
  }),
  confirm_password: yup.string().when("is_password", {
    is: true,
    then: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  }),
});

export const updateDropzoneSchema = yup.object().shape({
  allowed_origins: yup
    .array()
    .required()
    .min(1, "Atleast 1 origin is required."),
});
