import {
  FormLabel,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

export default function CustomFormLabel({ children, ...rest }) {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textSize = useBreakpointValue({
    base: "sm",
    xl: "xs",
    "2xl": "sm",
  });
  return (
    <FormLabel
      display="flex"
      ms="10px"
      fontSize={textSize}
      color={textColorPrimary}
      fontWeight="bold"
      _hover={{ cursor: "pointer" }}
      {...rest}
    >
      {children}
    </FormLabel>
  );
}
