import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  List,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";

export default function CollapsableLink(props) {
  useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  const textSize = useBreakpointValue({
    base: "md",
    xl: "xs",
    "2xl": "md",
  });
  let activeIcon = useColorModeValue("brand.500", "white");
  return (
    <Accordion defaultIndex={[0]} allowToggle>
      <AccordionItem border="none">
        <AccordionButton
          display="flex"
          align="center"
          justify="center"
          _hover={{
            bg: "unset",
          }}
          _focus={{
            boxShadow: "none",
          }}
          borderRadius="8px"
          w={{
            sm: "100%",
            xl: "100%",
            "2xl": "95%",
          }}
          px={props.icon ? null : "0px"}
          py="0px"
          bg={"transparent"}
          ms={0}
        >
          <Flex align="center" justifyContent="space-between" w="100%">
            <HStack
              mb={{ base: "6px", xl: "3px", "2xl": "6px" }}
              spacing={activeRoute(props.to) ? "22px" : "26px"}
            >
              <Flex w="100%" alignItems="center" justifyContent="center">
                <Box
                  color={activeRoute(props.to) ? activeIcon : inactiveColor}
                  me="12px"
                  mt={{ base: "6px", xl: "3px", "2xl": "6px" }}
                >
                  {props.icon}
                </Box>
                <Text
                  me="auto"
                  color={
                    activeRoute(props.to) ? activeColor : "secondaryGray.600"
                  }
                  fontWeight="500"
                  fontSize={textSize}
                >
                  {props.name}
                </Text>
              </Flex>
            </HStack>
            <AccordionIcon
              ms="auto"
              color={"secondaryGray.600"}
              transform={props.icon ? null : "translateX(-70%)"}
            />
          </Flex>
        </AccordionButton>
        <AccordionPanel
          pe={props.icon ? null : "0px"}
          py="0px"
          ps={props.icon ? null : "8px"}
        >
          <List>{props.children}</List>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
