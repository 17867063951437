// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/alias/alias.proto (package iam.v1.alias, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Alias } from "../../types/types_pb";

/**
 * @generated from message iam.v1.alias.CreateAliasRequest
 */
export class CreateAliasRequest extends Message<CreateAliasRequest> {
  /**
   * @generated from field: string alias = 1;
   */
  alias = "";

  constructor(data?: PartialMessage<CreateAliasRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.alias.CreateAliasRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "alias", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAliasRequest {
    return new CreateAliasRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAliasRequest {
    return new CreateAliasRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAliasRequest {
    return new CreateAliasRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAliasRequest | PlainMessage<CreateAliasRequest> | undefined, b: CreateAliasRequest | PlainMessage<CreateAliasRequest> | undefined): boolean {
    return proto3.util.equals(CreateAliasRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.alias.CreateAliasResponse
 */
export class CreateAliasResponse extends Message<CreateAliasResponse> {
  /**
   * @generated from field: iam.v1.types.Alias alias = 1;
   */
  alias?: Alias;

  constructor(data?: PartialMessage<CreateAliasResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.alias.CreateAliasResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "alias", kind: "message", T: Alias },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAliasResponse {
    return new CreateAliasResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAliasResponse {
    return new CreateAliasResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAliasResponse {
    return new CreateAliasResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAliasResponse | PlainMessage<CreateAliasResponse> | undefined, b: CreateAliasResponse | PlainMessage<CreateAliasResponse> | undefined): boolean {
    return proto3.util.equals(CreateAliasResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.alias.GetAliasRequest
 */
export class GetAliasRequest extends Message<GetAliasRequest> {
  constructor(data?: PartialMessage<GetAliasRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.alias.GetAliasRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAliasRequest {
    return new GetAliasRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAliasRequest {
    return new GetAliasRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAliasRequest {
    return new GetAliasRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAliasRequest | PlainMessage<GetAliasRequest> | undefined, b: GetAliasRequest | PlainMessage<GetAliasRequest> | undefined): boolean {
    return proto3.util.equals(GetAliasRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.alias.GetAliasResponse
 */
export class GetAliasResponse extends Message<GetAliasResponse> {
  /**
   * @generated from field: iam.v1.types.Alias alias = 1;
   */
  alias?: Alias;

  constructor(data?: PartialMessage<GetAliasResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.alias.GetAliasResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "alias", kind: "message", T: Alias },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAliasResponse {
    return new GetAliasResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAliasResponse {
    return new GetAliasResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAliasResponse {
    return new GetAliasResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAliasResponse | PlainMessage<GetAliasResponse> | undefined, b: GetAliasResponse | PlainMessage<GetAliasResponse> | undefined): boolean {
    return proto3.util.equals(GetAliasResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.alias.UpdateAliasRequest
 */
export class UpdateAliasRequest extends Message<UpdateAliasRequest> {
  /**
   * @generated from field: string alias = 1;
   */
  alias = "";

  constructor(data?: PartialMessage<UpdateAliasRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.alias.UpdateAliasRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "alias", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAliasRequest {
    return new UpdateAliasRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAliasRequest {
    return new UpdateAliasRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAliasRequest {
    return new UpdateAliasRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAliasRequest | PlainMessage<UpdateAliasRequest> | undefined, b: UpdateAliasRequest | PlainMessage<UpdateAliasRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAliasRequest, a, b);
  }
}

/**
 * @generated from message iam.v1.alias.UpdateAliasResponse
 */
export class UpdateAliasResponse extends Message<UpdateAliasResponse> {
  /**
   * @generated from field: iam.v1.types.Alias alias = 1;
   */
  alias?: Alias;

  constructor(data?: PartialMessage<UpdateAliasResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.alias.UpdateAliasResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "alias", kind: "message", T: Alias },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAliasResponse {
    return new UpdateAliasResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAliasResponse {
    return new UpdateAliasResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAliasResponse {
    return new UpdateAliasResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAliasResponse | PlainMessage<UpdateAliasResponse> | undefined, b: UpdateAliasResponse | PlainMessage<UpdateAliasResponse> | undefined): boolean {
    return proto3.util.equals(UpdateAliasResponse, a, b);
  }
}

/**
 * @generated from message iam.v1.alias.DeleteAliasRequest
 */
export class DeleteAliasRequest extends Message<DeleteAliasRequest> {
  constructor(data?: PartialMessage<DeleteAliasRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.alias.DeleteAliasRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAliasRequest {
    return new DeleteAliasRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAliasRequest {
    return new DeleteAliasRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAliasRequest {
    return new DeleteAliasRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAliasRequest | PlainMessage<DeleteAliasRequest> | undefined, b: DeleteAliasRequest | PlainMessage<DeleteAliasRequest> | undefined): boolean {
    return proto3.util.equals(DeleteAliasRequest, a, b);
  }
}

