import { Active, Over, UniqueIdentifier } from "@dnd-kit/core";
import { createContext, useContext, useEffect, useState } from "react";
import React from "react";
import { Item } from "../types/dnd-types";
import { JsonSchema, Layout, UISchemaElement } from "@reactjsonforms/core";

type JSONBuilderContextType = {
  jsonFormData: unknown;
  setJsonFormData: React.Dispatch<React.SetStateAction<unknown>>;
  jsonSchema: CustomJsonSchema | object;
  uiSchema: UISchemaElement;
  active: Active | undefined;
  over: Over | undefined;
  setActive: React.Dispatch<React.SetStateAction<Active | undefined>>;
  selectedItem: Item | undefined;
  setSelectedItem: React.Dispatch<React.SetStateAction<Item | undefined>>;
  setOver: React.Dispatch<React.SetStateAction<Over | undefined>>;
  items: Item[];
  setItems: React.Dispatch<React.SetStateAction<Item[]>>;
  updateItems: (items: Item[]) => void;
  updateItem: (item: Item) => void;
  removeItem: (id: UniqueIdentifier) => void;
  getItems: (parentId?: UniqueIdentifier | undefined) => Item[];
  clonedItems: Item[] | undefined;
  setClonedItems: React.Dispatch<React.SetStateAction<Item[] | undefined>>;
  sidebarKey: number;
  setSidebardKey: React.Dispatch<React.SetStateAction<number>>;
  isPreviewMode: boolean;
  setPreviewMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const JSONBuilderContext = createContext<JSONBuilderContextType | undefined>(
  undefined
);

interface CustomJsonSchema extends Omit<JsonSchema, "properties"> {
  properties: {
    [key: string]: JsonSchema & {
      formatMinimum?: string;
      formatMaximum?: string;
    };
  };
}

type CustomLayoutType =
  | Layout
  | { label: string; scope?: string; elements?: UISchemaElement[] };

type CustomUiSchemaMapObject = { [key: string]: CustomLayoutType };

export default function JSONBuilderProvider({
  children,
}: React.PropsWithChildren): React.ReactElement {
  const [uiSchema, setUISchema] = useState<UISchemaElement>({
    type: "VerticalLayout",
  });
  const [jsonSchema, setJsonSchema] = useState<CustomJsonSchema | object>({});
  const [jsonFormData, setJsonFormData] = useState<unknown>({});
  const [active, setActive] = useState<Active | undefined>();
  const [over, setOver] = useState<Over | undefined>();
  const [items, setItems] = useState<Item[]>([]);
  const [selectedItem, setSelectedItem] = useState<Item>();
  const [clonedItems, setClonedItems] = useState<Item[]>();
  const [sidebarKey, setSidebardKey] = useState<number>(Date.now());
  const [isPreviewMode, setPreviewMode] = useState<boolean>(false);

  const updateUISchema = (items: Item[]) => {
    const uiSchema: Layout = { type: "VerticalLayout", elements: [] };
    const map: CustomUiSchemaMapObject = {};

    // Create a map of nodes using their ids
    items.forEach((item) => {
      if (item.isLayoutElement) {
        map[item.id] = {
          type: item.type,
          label: item.title,
          elements: [],
        };
      } else {
        map[item.id] = {
          type: "Control",
          label: item.title,
          options: {
            placeholder: item.placeholder ? item.placeholder : "",
            multi: item.multiline ? true : false,
            multiple: item.multiple ? true : false,
            toggle: true,
            detail: "DEFAULT",
            readonly: item.readonly ? true : false,
            format: item.format,
          },
          scope: `#/properties/${item.keyName}`,
        };
      }
    });

    // Traverse the items and build the tree
    items.forEach((item) => {
      if (!item.parentId) return item;
      const parent: CustomLayoutType = map[item.parentId];
      if (parent) {
        parent?.elements?.push(map[item.id] as unknown as UISchemaElement);
      } else {
        uiSchema.elements.push(map[item.id] as unknown as UISchemaElement);
      }
    });
    setUISchema(uiSchema);
  };

  const updateJsonSchema = (items: Item[]) => {
    const schema: CustomJsonSchema = {
      type: "object",
      properties: {},
      required: [] as string[],
    };
    const required: string[] = [];
    const properties: {
      [key: string]: JsonSchema & {
        formatMinimum?: string;
        formatMaximum?: string;
      };
    } = {};
    items.map((item) => {
      if (item.isLayoutElement) return item;
      properties[item.keyName] = {
        type: item.type,
        title: item.title,
      };
      if (item.format) properties[item.keyName]["format"] = item.format;
      if (item.format === "date") {
        if (item.minDate)
          properties[item.keyName]["formatMinimum"] = item.minDate;
        if (item.maxDate)
          properties[item.keyName]["formatMaximum"] = item.maxDate;
      }
      if (item.minLength !== undefined)
        properties[item.keyName]["minLength"] = item.minLength;
      if (item.maxLength !== undefined)
        properties[item.keyName]["maxLength"] = item.maxLength;
      if (item.minimum !== undefined)
        properties[item.keyName]["minimum"] = item.minimum;
      if (item.maximum !== undefined)
        properties[item.keyName]["maximum"] = item.maximum;
      if (item.description !== undefined)
        properties[item.keyName]["description"] = item.description;
      if (item.required) required.push(item.keyName);
      if (item.format === "enum") {
        properties[item.keyName]["oneOf"] = item.options?.length
          ? item.options
          : [
              {
                title: "",
                const: "",
              },
            ];
      } else if (item.format === "radio") {
        properties[item.keyName]["enum"] = item.options?.length
          ? item.options?.map((option) => option.const)
          : [""];
      }
      return item;
    });
    schema.properties = properties;
    schema.required = required;
    setJsonSchema(schema);
  };

  useEffect(() => {
    updateJsonSchema(items);
    updateUISchema(items);
    setJsonFormData(undefined);
  }, [items]);

  const removeItem = (id: UniqueIdentifier) => {
    setItems((items) =>
      items?.filter((item) => item.id !== id && item.parentId !== id)
    );
    setSelectedItem(undefined);
  };

  const updateItems = (items: Item[]) => {
    setItems(items);
  };

  const updateItem = (item: Item) => {
    const newItems = items.map((oldItem) => {
      if (oldItem.id === item.id) {
        return item;
      }
      return oldItem;
    });
    setItems(newItems);
  };

  const getItems = (parentId: UniqueIdentifier | undefined = undefined) => {
    return items.filter((item) =>
      parentId ? item.parentId === parentId : !item.parentId
    );
  };

  const value = {
    active,
    setActive,
    over,
    setOver,
    items,
    setItems,
    removeItem,
    selectedItem,
    setSelectedItem,
    updateItem,
    updateItems,
    jsonSchema,
    uiSchema,
    jsonFormData,
    setJsonFormData,
    getItems,
    clonedItems,
    setClonedItems,
    sidebarKey,
    setSidebardKey,
    isPreviewMode,
    setPreviewMode,
  };
  return (
    <JSONBuilderContext.Provider value={value}>
      {children}
    </JSONBuilderContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export const useJSONBuilderContext = () =>
  useContext(JSONBuilderContext) as JSONBuilderContextType;

