import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import React from "react";
import "./assets/css/App.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-toastify/dist/ReactToastify.css";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { LoggedInRoute } from "./context/auth";
import theme from "./theme/theme";
import { RootKeyProvider } from "./context/request/root-key";
import LoadingScreen from "./pages/LoadingScreen";
import FixedPlugin from "./components/fixed-plugin/FixedPlugin";
import SubmitDataProvider from "./context/request/submit-data";
import ReadRequestProvider from "./context/request/read-request-context";
import Dropzones from "./pages/Dropzones";
import DropzoneSubmit from "./pages/DropzoneSubmit";
import DropzoneResponses from "./pages/DropzoneResponses";
import DropzoneReadResponse from "./pages/DropzoneReadResponse";
import ReadResponseProvider from "./context/dropzone/read-dropzone-context";
import DropzoneAllResponses from "./pages/DropzoneAllResponses";
import DropzoneDetails from "./pages/DropzoneDetails";
import RootKey from "./pages/RootKey";
import PassPhrase from "./pages/PassPhrase";
import { ToastContainer } from "react-toastify";
import { ErrorsProvider } from "./context/errors";
import NotFound from "./pages/NotFound";
import Settings from "./pages/Settings";
import Storage from "./pages/Storage";
import NormalLayout from "./layouts/NormalLayout";
import Landing from "./pages/Landing";
import { ErrorBoundary } from "react-error-boundary";
import ServerError from "./pages/ServerError";
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const HelpCenter = React.lazy(() => import("./pages/HelpCenter"));
const SecureShareRequest = React.lazy(() =>
  import("./pages/SecureShareRequest")
);
const SecureShareSubmitData = React.lazy(() =>
  import("./pages/SecureShareSubmitData")
);
const SecureShareReadRequest = React.lazy(() =>
  import("./pages/SecureShareReadRequest")
);
const RequestHistory = React.lazy(() => import("./pages/RequestHistory"));
const SecureShareLayout = React.lazy(() =>
  import("./layouts/SecureShareLayout")
);
const SecureSharePage = React.lazy(() => import("./pages/SecureSharePage"));
const ShareHistoryPage = React.lazy(() => import("./pages/ShareHistoryPage"));
const SecureStatsPage = React.lazy(() => import("./pages//SecureStatsPage"));
const SecureViewPage = React.lazy(() => import("./pages/SecureViewPage"));
const Templates = React.lazy(() => import("./pages/Templates"));
const Template = React.lazy(() => import("./pages/Template"));
const RequestDetails = React.lazy(() => import("./pages/RequestDetails"));
const RequestResponses = React.lazy(() => import("./pages/RequestResponses"));
const Dropzone = React.lazy(() => import("./pages/Dropzone"));

function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return <ServerError error={error} reset={resetErrorBoundary} />;
}

export default function Root(props) {
  return (
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme}>
        <ErrorBoundary
          fallbackRender={fallbackRender}
          onReset={(details) => {
            // Reset the state of your app so the error doesn't happen again
          }}
        >
          <ErrorsProvider>
            <Router basename="/sypher">
              <React.Suspense fallback={<LoadingScreen />}>
                <Routes>
                  <Route path="secure/:uuid" element={<SecureViewPage />} />
                  <Route
                    path="request/:uuid"
                    element={
                      <SubmitDataProvider>
                        <SecureShareSubmitData />
                      </SubmitDataProvider>
                    }
                  />
                  <Route
                    path="dropzones/submit/:uuid/:is_password"
                    element={
                      <SubmitDataProvider>
                        <DropzoneSubmit />
                      </SubmitDataProvider>
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <LoggedInRoute>
                        <RootKeyProvider>
                          <SecureShareLayout>
                            <Outlet />
                          </SecureShareLayout>
                        </RootKeyProvider>
                      </LoggedInRoute>
                    }
                  >
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="send/new" element={<SecureSharePage />} />

                    <Route path="send/history">
                      <Route index element={<ShareHistoryPage />} />
                    </Route>
                    <Route path="help">
                      <Route index element={<HelpCenter />} />
                    </Route>
                    <Route path="send/history/:uuid">
                      <Route index element={<SecureStatsPage />} />
                    </Route>
                    <Route path="request/new">
                      <Route index element={<SecureShareRequest />} />
                    </Route>
                    <Route path="request/responses/:uuid">
                      <Route
                        index
                        element={
                          <ReadResponseProvider>
                            <SecureShareReadRequest />
                          </ReadResponseProvider>
                        }
                      />
                    </Route>
                    <Route path="request/history/:uuid">
                      <Route
                        index
                        element={
                          <ReadResponseProvider>
                            <RequestDetails />
                          </ReadResponseProvider>
                        }
                      />
                    </Route>
                    <Route path="request/history">
                      <Route index element={<RequestHistory />} />
                    </Route>
                    <Route path="request/responses">
                      <Route index element={<RequestResponses />} />
                    </Route>
                    <Route path="dropzones/new">
                      <Route index element={<Dropzone />} />
                    </Route>
                    <Route path="dropzones/history">
                      <Route index element={<Dropzones />} />
                    </Route>
                    <Route path="dropzones/history/:dropzone_name">
                      <Route index element={<DropzoneDetails />} />
                    </Route>
                    <Route path="dropzones/history/:dropzone_name/responses">
                      <Route index element={<DropzoneResponses />} />
                    </Route>
                    <Route path="dropzones/responses">
                      <Route index element={<DropzoneAllResponses />} />
                    </Route>
                    <Route path="dropzones/responses/:requester_id/:respondent_id">
                      <Route
                        index
                        element={
                          <ReadResponseProvider>
                            <DropzoneReadResponse />
                          </ReadResponseProvider>
                        }
                      />
                    </Route>
                    <Route path="templates/list">
                      <Route index element={<Templates />} />
                    </Route>
                    <Route path="templates/new">
                      <Route index element={<Template />} />
                    </Route>
                    <Route path="templates/update/:name">
                      <Route index element={<Template />} />
                    </Route>
                    <Route path="storage">
                      <Route index element={<Storage />} />
                    </Route>
                    <Route path="settings">
                      <Route index element={<Settings />} />
                    </Route>
                    <Route
                      path="/"
                      element={<Navigate to="/dashboard" replace />}
                    />
                    <Route path="root-key">
                      <Route
                        index
                        element={
                          <ReadResponseProvider>
                            <RootKey />
                          </ReadResponseProvider>
                        }
                      />
                    </Route>
                    <Route path="pass-phrase">
                      <Route
                        index
                        element={
                          <ReadResponseProvider>
                            <PassPhrase />
                          </ReadResponseProvider>
                        }
                      />
                    </Route>
                  </Route>
                  <Route
                    path="/"
                    element={
                      <LoggedInRoute>
                        <NormalLayout>
                          <Outlet />
                        </NormalLayout>
                      </LoggedInRoute>
                    }
                  >
                    <Route path="landing" element={<Landing />} />
                  </Route>

                  <Route element={<SecureSharePage />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </React.Suspense>
            </Router>
            <FixedPlugin />
            <ToastContainer />
          </ErrorsProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </>
  );
}
