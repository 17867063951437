import { useDroppable } from "@dnd-kit/core";
import React from "react";
import { DroppableItemProps } from "../../../types/dnd-types";
import { Box, ChakraProps } from "@chakra-ui/react";

interface Props extends DroppableItemProps {}

export default function DroppableItem({
  children,
  id,
  disabled,
  ...rest
}: Props & ChakraProps): React.ReactElement {
  const { setNodeRef, isOver, active } = useDroppable({
    id: id,
    data: {
      parent: null,
      isContainer: true,
    },
    disabled: !!disabled,
  });

  const style: ChakraProps = {
    background: active?.id !== id && isOver ? "gray.100" : "#fff",
    borderRadius: "16px",
    padding: "10px",
    color: active?.id !== id && isOver ? "white" : "black",
  };
  return (
    <Box ref={setNodeRef} {...style} {...rest}>
      {children}
    </Box>
  );
}
