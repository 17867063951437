import { PropsWithChildren, SetStateAction, createContext, useContext, useState } from "react";

type SubmitDataContextType = {
  files: {
    [key:string]: File,
  };
  setFiles: React.Dispatch<React.SetStateAction<{
    [key:string]: File,
  }>>;
  addFile: (file: File, key: string) => void;
  removeFile: (key: string) => void;
  addFilesWithKeys: (files: {
    [key:string]: File,
  }) => void;
};

const SubmitDataContext = createContext<SubmitDataContextType>({
  files: {},
  setFiles: function (value: SetStateAction<{
    [key:string]: File,
  }>): void {
    throw new Error("Function not implemented.");
  },
  addFile: function (file: File, key: string): void {
    throw new Error("Function not implemented.");
  },
  removeFile: function (key: string): void {
    throw new Error("Function not implemented.");
  },
  addFilesWithKeys: function (files: Object): void {
    throw new Error("Function not implemented.");
  }
});

const SubmitDataProvider = ({ children }: PropsWithChildren) => {
  const [files, setFiles] = useState({});
  const addFile = (file: File, key: string) => {
    setFiles({ ...files, [key]: file });
  };

  const addFilesWithKeys = (newFiles: Object) => {
    setFiles({ ...files, ...newFiles });
  };
  const removeFile = (key: string) => {
    let newFiles: {
      [key:string]: File,
    } = { ...files };
    delete newFiles[key];
    setFiles(newFiles);
  };

  const context = {
    files,
    setFiles,
    addFile,
    removeFile,
    addFilesWithKeys,
  };
  return (
    <SubmitDataContext.Provider value={context}>
      {children}
    </SubmitDataContext.Provider>
  );
};

export const useSubmitData = () => useContext(SubmitDataContext);

export default SubmitDataProvider;
