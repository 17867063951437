// @generated by protoc-gen-connect-es v0.13.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/service/role/role.proto (package iam.v1.role, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddPoliciesToRolesRequest, AssumeRoleRequest, AssumeRoleResponse, CreateRoleRequest, CreateRoleResponse, DeleteRolesRequest, GetRoleRequest, GetRoleResponse, ListRolePoliciesRequest, ListRolePoliciesResponse, ListRolesRequest, ListRolesResponse, RemovePoliciesFromRolesRequest, UpdateRoleRequest, UpdateRoleResponse } from "./role_pb";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service iam.v1.role.RoleService
 */
export const RoleService = {
  typeName: "iam.v1.role.RoleService",
  methods: {
    /**
     * @generated from rpc iam.v1.role.RoleService.CreateRole
     */
    createRole: {
      name: "CreateRole",
      I: CreateRoleRequest,
      O: CreateRoleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.role.RoleService.GetRole
     */
    getRole: {
      name: "GetRole",
      I: GetRoleRequest,
      O: GetRoleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.role.RoleService.ListRoles
     */
    listRoles: {
      name: "ListRoles",
      I: ListRolesRequest,
      O: ListRolesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.role.RoleService.UpdateRole
     */
    updateRole: {
      name: "UpdateRole",
      I: UpdateRoleRequest,
      O: UpdateRoleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.role.RoleService.DeleteRoles
     */
    deleteRoles: {
      name: "DeleteRoles",
      I: DeleteRolesRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.role.RoleService.AddPoliciesToRoles
     */
    addPoliciesToRoles: {
      name: "AddPoliciesToRoles",
      I: AddPoliciesToRolesRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.role.RoleService.RemovePoliciesFromRoles
     */
    removePoliciesFromRoles: {
      name: "RemovePoliciesFromRoles",
      I: RemovePoliciesFromRolesRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.role.RoleService.ListRolePolicies
     */
    listRolePolicies: {
      name: "ListRolePolicies",
      I: ListRolePoliciesRequest,
      O: ListRolePoliciesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.v1.role.RoleService.AssumeRole
     */
    assumeRole: {
      name: "AssumeRole",
      I: AssumeRoleRequest,
      O: AssumeRoleResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

