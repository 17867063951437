// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/sypher/v1/service/dashboard/dashboard.proto (package sypher.v1.dashboard, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from message sypher.v1.dashboard.GetUsageRequest
 */
export class GetUsageRequest extends Message<GetUsageRequest> {
  /**
   * @generated from field: string month_year = 1;
   */
  monthYear = "";

  constructor(data?: PartialMessage<GetUsageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.dashboard.GetUsageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "month_year", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUsageRequest {
    return new GetUsageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUsageRequest {
    return new GetUsageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUsageRequest {
    return new GetUsageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUsageRequest | PlainMessage<GetUsageRequest> | undefined, b: GetUsageRequest | PlainMessage<GetUsageRequest> | undefined): boolean {
    return proto3.util.equals(GetUsageRequest, a, b);
  }
}

/**
 * @generated from message sypher.v1.dashboard.GetUsageResponse
 */
export class GetUsageResponse extends Message<GetUsageResponse> {
  /**
   * @generated from field: int64 send_count = 1;
   */
  sendCount = protoInt64.zero;

  /**
   * @generated from field: int64 file_count = 2;
   */
  fileCount = protoInt64.zero;

  /**
   * @generated from field: int64 request_count = 3;
   */
  requestCount = protoInt64.zero;

  /**
   * @generated from field: int64 response_count = 4;
   */
  responseCount = protoInt64.zero;

  /**
   * @generated from field: int64 dropzone_count = 5;
   */
  dropzoneCount = protoInt64.zero;

  /**
   * @generated from field: int64 total_data = 6;
   */
  totalData = protoInt64.zero;

  constructor(data?: PartialMessage<GetUsageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "sypher.v1.dashboard.GetUsageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "send_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "file_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "request_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "response_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "dropzone_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "total_data", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUsageResponse {
    return new GetUsageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUsageResponse {
    return new GetUsageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUsageResponse {
    return new GetUsageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetUsageResponse | PlainMessage<GetUsageResponse> | undefined, b: GetUsageResponse | PlainMessage<GetUsageResponse> | undefined): boolean {
    return proto3.util.equals(GetUsageResponse, a, b);
  }
}

