// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file station/iam/v1/types/types.proto (package iam.v1.types, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum iam.v1.types.NotificationSeverity
 */
export enum NotificationSeverity {
  /**
   * @generated from enum value: NOTIFICATION_SEVERITY_INFO = 0;
   */
  INFO = 0,

  /**
   * @generated from enum value: NOTIFICATION_SEVERITY_LOW = 1;
   */
  LOW = 1,

  /**
   * @generated from enum value: NOTIFICATION_SEVERITY_MEDIUM = 2;
   */
  MEDIUM = 2,

  /**
   * @generated from enum value: NOTIFICATION_SEVERITY_HIGH = 3;
   */
  HIGH = 3,

  /**
   * @generated from enum value: NOTIFICATION_SEVERITY_CRITICAL = 4;
   */
  CRITICAL = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(NotificationSeverity)
proto3.util.setEnumType(NotificationSeverity, "iam.v1.types.NotificationSeverity", [
  { no: 0, name: "NOTIFICATION_SEVERITY_INFO" },
  { no: 1, name: "NOTIFICATION_SEVERITY_LOW" },
  { no: 2, name: "NOTIFICATION_SEVERITY_MEDIUM" },
  { no: 3, name: "NOTIFICATION_SEVERITY_HIGH" },
  { no: 4, name: "NOTIFICATION_SEVERITY_CRITICAL" },
]);

/**
 * @generated from enum iam.v1.types.AccountType
 */
export enum AccountType {
  /**
   * @generated from enum value: ACCOUNT_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ACCOUNT_TYPE_PERSONAL = 1;
   */
  PERSONAL = 1,

  /**
   * @generated from enum value: ACCOUNT_TYPE_BUSINESS = 2;
   */
  BUSINESS = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(AccountType)
proto3.util.setEnumType(AccountType, "iam.v1.types.AccountType", [
  { no: 0, name: "ACCOUNT_TYPE_UNSPECIFIED" },
  { no: 1, name: "ACCOUNT_TYPE_PERSONAL" },
  { no: 2, name: "ACCOUNT_TYPE_BUSINESS" },
]);

/**
 * @generated from enum iam.v1.types.GroupType
 */
export enum GroupType {
  /**
   * @generated from enum value: GROUP_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
}
// Retrieve enum metadata with: proto3.getEnumType(GroupType)
proto3.util.setEnumType(GroupType, "iam.v1.types.GroupType", [
  { no: 0, name: "GROUP_TYPE_UNSPECIFIED" },
]);

/**
 * @generated from enum iam.v1.types.UserAccessType
 */
export enum UserAccessType {
  /**
   * @generated from enum value: USER_ACCESS_TYPE_MANAGEMENT_UNSPECIFIED = 0;
   */
  MANAGEMENT_UNSPECIFIED = 0,

  /**
   * @generated from enum value: USER_ACCESS_TYPE_PROGRAMATIC = 1;
   */
  PROGRAMATIC = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(UserAccessType)
proto3.util.setEnumType(UserAccessType, "iam.v1.types.UserAccessType", [
  { no: 0, name: "USER_ACCESS_TYPE_MANAGEMENT_UNSPECIFIED" },
  { no: 1, name: "USER_ACCESS_TYPE_PROGRAMATIC" },
]);

/**
 * @generated from message iam.v1.types.Notification
 */
export class Notification extends Message<Notification> {
  /**
   * @generated from field: string service = 1;
   */
  service = "";

  /**
   * @generated from field: string message = 2;
   */
  message = "";

  /**
   * @generated from field: iam.v1.types.NotificationSeverity severity = 3;
   */
  severity = NotificationSeverity.INFO;

  constructor(data?: PartialMessage<Notification>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Notification";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "service", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "severity", kind: "enum", T: proto3.getEnumType(NotificationSeverity) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Notification {
    return new Notification().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Notification {
    return new Notification().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Notification {
    return new Notification().fromJsonString(jsonString, options);
  }

  static equals(a: Notification | PlainMessage<Notification> | undefined, b: Notification | PlainMessage<Notification> | undefined): boolean {
    return proto3.util.equals(Notification, a, b);
  }
}

/**
 * @generated from message iam.v1.types.Role
 */
export class Role extends Message<Role> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string account_id = 2;
   */
  accountId = "";

  /**
   * @generated from field: string role_name = 3;
   */
  roleName = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: repeated iam.v1.types.RoleTrustStatement trust_statements = 5;
   */
  trustStatements: RoleTrustStatement[] = [];

  /**
   * @generated from field: string created_at = 6;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 7;
   */
  updatedAt = "";

  /**
   * @generated from field: map<string, string> metadata = 8;
   */
  metadata: { [key: string]: string } = {};

  /**
   * @generated from field: string last_accessed = 9;
   */
  lastAccessed = "";

  constructor(data?: PartialMessage<Role>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Role";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "role_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "trust_statements", kind: "message", T: RoleTrustStatement, repeated: true },
    { no: 6, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 9, name: "last_accessed", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Role {
    return new Role().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Role {
    return new Role().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Role {
    return new Role().fromJsonString(jsonString, options);
  }

  static equals(a: Role | PlainMessage<Role> | undefined, b: Role | PlainMessage<Role> | undefined): boolean {
    return proto3.util.equals(Role, a, b);
  }
}

/**
 * Defines a policy statement.
 *
 * @generated from message iam.v1.types.RoleTrustStatement
 */
export class RoleTrustStatement extends Message<RoleTrustStatement> {
  /**
   * Statement ID
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string effect = 2;
   */
  effect = "";

  /**
   * @generated from field: iam.v1.types.Principal principal = 3;
   */
  principal?: Principal;

  /**
   * @generated from field: repeated string actions = 4;
   */
  actions: string[] = [];

  /**
   * @generated from field: iam.v1.types.Conditions conditions = 5;
   */
  conditions?: Conditions;

  constructor(data?: PartialMessage<RoleTrustStatement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.RoleTrustStatement";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "effect", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "principal", kind: "message", T: Principal },
    { no: 4, name: "actions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "conditions", kind: "message", T: Conditions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RoleTrustStatement {
    return new RoleTrustStatement().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RoleTrustStatement {
    return new RoleTrustStatement().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RoleTrustStatement {
    return new RoleTrustStatement().fromJsonString(jsonString, options);
  }

  static equals(a: RoleTrustStatement | PlainMessage<RoleTrustStatement> | undefined, b: RoleTrustStatement | PlainMessage<RoleTrustStatement> | undefined): boolean {
    return proto3.util.equals(RoleTrustStatement, a, b);
  }
}

/**
 * @generated from message iam.v1.types.SSOProvier
 */
export class SSOProvier extends Message<SSOProvier> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string account_id = 2;
   */
  accountId = "";

  /**
   * @generated from field: iam.v1.types.SSOConfig sso_config = 3;
   */
  ssoConfig?: SSOConfig;

  /**
   * @generated from field: map<string, iam.v1.types.SSOClaimRoleMapping> claim_mappings = 4;
   */
  claimMappings: { [key: string]: SSOClaimRoleMapping } = {};

  /**
   * @generated from field: string created_at = 5;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 6;
   */
  updatedAt = "";

  /**
   * @generated from field: map<string, string> metadata = 7;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<SSOProvier>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.SSOProvier";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sso_config", kind: "message", T: SSOConfig },
    { no: 4, name: "claim_mappings", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: SSOClaimRoleMapping} },
    { no: 5, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SSOProvier {
    return new SSOProvier().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SSOProvier {
    return new SSOProvier().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SSOProvier {
    return new SSOProvier().fromJsonString(jsonString, options);
  }

  static equals(a: SSOProvier | PlainMessage<SSOProvier> | undefined, b: SSOProvier | PlainMessage<SSOProvier> | undefined): boolean {
    return proto3.util.equals(SSOProvier, a, b);
  }
}

/**
 * @generated from message iam.v1.types.SSOConfig
 */
export class SSOConfig extends Message<SSOConfig> {
  /**
   * @generated from oneof iam.v1.types.SSOConfig.config
   */
  config: {
    /**
     * @generated from field: iam.v1.types.GoogleSSO google_sso = 1;
     */
    value: GoogleSSO;
    case: "googleSso";
  } | {
    /**
     * @generated from field: iam.v1.types.OktaSSO okta_sso = 2;
     */
    value: OktaSSO;
    case: "oktaSso";
  } | {
    /**
     * @generated from field: iam.v1.types.AzureADSSO azuread_sso = 3;
     */
    value: AzureADSSO;
    case: "azureadSso";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SSOConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.SSOConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "google_sso", kind: "message", T: GoogleSSO, oneof: "config" },
    { no: 2, name: "okta_sso", kind: "message", T: OktaSSO, oneof: "config" },
    { no: 3, name: "azuread_sso", kind: "message", T: AzureADSSO, oneof: "config" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SSOConfig {
    return new SSOConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SSOConfig {
    return new SSOConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SSOConfig {
    return new SSOConfig().fromJsonString(jsonString, options);
  }

  static equals(a: SSOConfig | PlainMessage<SSOConfig> | undefined, b: SSOConfig | PlainMessage<SSOConfig> | undefined): boolean {
    return proto3.util.equals(SSOConfig, a, b);
  }
}

/**
 * @generated from message iam.v1.types.SSOClaimRoleMapping
 */
export class SSOClaimRoleMapping extends Message<SSOClaimRoleMapping> {
  /**
   * @generated from field: string claim_name = 1;
   */
  claimName = "";

  /**
   * @generated from field: repeated string values = 2;
   */
  values: string[] = [];

  /**
   * @generated from field: string role_dri = 3;
   */
  roleDri = "";

  constructor(data?: PartialMessage<SSOClaimRoleMapping>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.SSOClaimRoleMapping";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "claim_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "role_dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SSOClaimRoleMapping {
    return new SSOClaimRoleMapping().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SSOClaimRoleMapping {
    return new SSOClaimRoleMapping().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SSOClaimRoleMapping {
    return new SSOClaimRoleMapping().fromJsonString(jsonString, options);
  }

  static equals(a: SSOClaimRoleMapping | PlainMessage<SSOClaimRoleMapping> | undefined, b: SSOClaimRoleMapping | PlainMessage<SSOClaimRoleMapping> | undefined): boolean {
    return proto3.util.equals(SSOClaimRoleMapping, a, b);
  }
}

/**
 * @generated from message iam.v1.types.GoogleSSO
 */
export class GoogleSSO extends Message<GoogleSSO> {
  /**
   * @generated from field: string client_id = 1;
   */
  clientId = "";

  /**
   * @generated from field: string client_secret = 2;
   */
  clientSecret = "";

  constructor(data?: PartialMessage<GoogleSSO>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.GoogleSSO";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GoogleSSO {
    return new GoogleSSO().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GoogleSSO {
    return new GoogleSSO().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GoogleSSO {
    return new GoogleSSO().fromJsonString(jsonString, options);
  }

  static equals(a: GoogleSSO | PlainMessage<GoogleSSO> | undefined, b: GoogleSSO | PlainMessage<GoogleSSO> | undefined): boolean {
    return proto3.util.equals(GoogleSSO, a, b);
  }
}

/**
 * @generated from message iam.v1.types.OktaSSO
 */
export class OktaSSO extends Message<OktaSSO> {
  /**
   * @generated from field: string client_id = 1;
   */
  clientId = "";

  /**
   * @generated from field: string client_secret = 2;
   */
  clientSecret = "";

  /**
   * @generated from field: string okta_domain = 3;
   */
  oktaDomain = "";

  constructor(data?: PartialMessage<OktaSSO>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.OktaSSO";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "okta_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OktaSSO {
    return new OktaSSO().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OktaSSO {
    return new OktaSSO().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OktaSSO {
    return new OktaSSO().fromJsonString(jsonString, options);
  }

  static equals(a: OktaSSO | PlainMessage<OktaSSO> | undefined, b: OktaSSO | PlainMessage<OktaSSO> | undefined): boolean {
    return proto3.util.equals(OktaSSO, a, b);
  }
}

/**
 * @generated from message iam.v1.types.AzureADSSO
 */
export class AzureADSSO extends Message<AzureADSSO> {
  /**
   * @generated from field: string client_id = 1;
   */
  clientId = "";

  /**
   * @generated from field: string client_secret = 2;
   */
  clientSecret = "";

  /**
   * @generated from field: string tenant_id = 3;
   */
  tenantId = "";

  constructor(data?: PartialMessage<AzureADSSO>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.AzureADSSO";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "tenant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AzureADSSO {
    return new AzureADSSO().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AzureADSSO {
    return new AzureADSSO().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AzureADSSO {
    return new AzureADSSO().fromJsonString(jsonString, options);
  }

  static equals(a: AzureADSSO | PlainMessage<AzureADSSO> | undefined, b: AzureADSSO | PlainMessage<AzureADSSO> | undefined): boolean {
    return proto3.util.equals(AzureADSSO, a, b);
  }
}

/**
 * @generated from message iam.v1.types.Account
 */
export class Account extends Message<Account> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string alias = 2;
   */
  alias = "";

  /**
   * @generated from field: string account_id = 3;
   */
  accountId = "";

  /**
   * @generated from field: string root_user = 4;
   */
  rootUser = "";

  /**
   * @generated from field: map<string, string> metadata = 5;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<Account>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Account";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "alias", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "root_user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Account {
    return new Account().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJsonString(jsonString, options);
  }

  static equals(a: Account | PlainMessage<Account> | undefined, b: Account | PlainMessage<Account> | undefined): boolean {
    return proto3.util.equals(Account, a, b);
  }
}

/**
 * @generated from message iam.v1.types.Group
 */
export class Group extends Message<Group> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string group_name = 2;
   */
  groupName = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: iam.v1.types.GroupType group_type = 4;
   */
  groupType = GroupType.UNSPECIFIED;

  /**
   * @generated from field: string created_at = 5;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 6;
   */
  updatedAt = "";

  /**
   * @generated from field: map<string, string> metadata = 7;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<Group>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Group";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "group_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "group_type", kind: "enum", T: proto3.getEnumType(GroupType) },
    { no: 5, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Group {
    return new Group().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Group {
    return new Group().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Group {
    return new Group().fromJsonString(jsonString, options);
  }

  static equals(a: Group | PlainMessage<Group> | undefined, b: Group | PlainMessage<Group> | undefined): boolean {
    return proto3.util.equals(Group, a, b);
  }
}

/**
 * @generated from message iam.v1.types.User
 */
export class User extends Message<User> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string account_id = 2;
   */
  accountId = "";

  /**
   * @generated from field: string username = 3;
   */
  username = "";

  /**
   * @generated from field: string email = 4;
   */
  email = "";

  /**
   * @generated from field: string first_name = 5;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 6;
   */
  lastName = "";

  /**
   * @generated from field: iam.v1.types.AccountType account_type = 7;
   */
  accountType = AccountType.UNSPECIFIED;

  /**
   * @generated from field: string user_type = 8;
   */
  userType = "";

  /**
   * @generated from field: iam.v1.types.UserAccessType access_type = 9;
   */
  accessType = UserAccessType.MANAGEMENT_UNSPECIFIED;

  /**
   * @generated from field: string created_at = 10;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 11;
   */
  updatedAt = "";

  /**
   * @generated from field: map<string, string> metadata = 12;
   */
  metadata: { [key: string]: string } = {};

  /**
   * @generated from field: bool email_verified = 13;
   */
  emailVerified = false;

  /**
   * @generated from field: string last_accessed = 14;
   */
  lastAccessed = "";

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "account_type", kind: "enum", T: proto3.getEnumType(AccountType) },
    { no: 8, name: "user_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "access_type", kind: "enum", T: proto3.getEnumType(UserAccessType) },
    { no: 10, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 13, name: "email_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "last_accessed", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

/**
 * @generated from message iam.v1.types.AccessKey
 */
export class AccessKey extends Message<AccessKey> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string account_id = 2;
   */
  accountId = "";

  /**
   * @generated from field: string access_key = 3;
   */
  accessKey = "";

  /**
   * @generated from field: string secret_key = 4;
   */
  secretKey = "";

  /**
   * @generated from field: bool active = 5;
   */
  active = false;

  /**
   * @generated from field: string created_at = 6;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 7;
   */
  updatedAt = "";

  /**
   * @generated from field: map<string, string> metadata = 8;
   */
  metadata: { [key: string]: string } = {};

  /**
   * @generated from field: string last_accessed = 9;
   */
  lastAccessed = "";

  constructor(data?: PartialMessage<AccessKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.AccessKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "access_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "secret_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 9, name: "last_accessed", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccessKey {
    return new AccessKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccessKey {
    return new AccessKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccessKey {
    return new AccessKey().fromJsonString(jsonString, options);
  }

  static equals(a: AccessKey | PlainMessage<AccessKey> | undefined, b: AccessKey | PlainMessage<AccessKey> | undefined): boolean {
    return proto3.util.equals(AccessKey, a, b);
  }
}

/**
 * @generated from message iam.v1.types.Alias
 */
export class Alias extends Message<Alias> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string account_id = 2;
   */
  accountId = "";

  /**
   * @generated from field: string alias = 3;
   */
  alias = "";

  /**
   * @generated from field: string created_at = 4;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 5;
   */
  updatedAt = "";

  /**
   * @generated from field: map<string, string> metadata = 6;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<Alias>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Alias";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "alias", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Alias {
    return new Alias().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Alias {
    return new Alias().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Alias {
    return new Alias().fromJsonString(jsonString, options);
  }

  static equals(a: Alias | PlainMessage<Alias> | undefined, b: Alias | PlainMessage<Alias> | undefined): boolean {
    return proto3.util.equals(Alias, a, b);
  }
}

/**
 * policy messages
 *
 * @generated from message iam.v1.types.Principal
 */
export class Principal extends Message<Principal> {
  /**
   * @generated from field: string kind = 1;
   */
  kind = "";

  /**
   * @generated from field: repeated string values = 2;
   */
  values: string[] = [];

  constructor(data?: PartialMessage<Principal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Principal";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "kind", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Principal {
    return new Principal().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Principal {
    return new Principal().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Principal {
    return new Principal().fromJsonString(jsonString, options);
  }

  static equals(a: Principal | PlainMessage<Principal> | undefined, b: Principal | PlainMessage<Principal> | undefined): boolean {
    return proto3.util.equals(Principal, a, b);
  }
}

/**
 * @generated from message iam.v1.types.StringCondition
 */
export class StringCondition extends Message<StringCondition> {
  /**
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * @generated from field: repeated string values = 2;
   */
  values: string[] = [];

  constructor(data?: PartialMessage<StringCondition>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.StringCondition";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StringCondition {
    return new StringCondition().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StringCondition {
    return new StringCondition().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StringCondition {
    return new StringCondition().fromJsonString(jsonString, options);
  }

  static equals(a: StringCondition | PlainMessage<StringCondition> | undefined, b: StringCondition | PlainMessage<StringCondition> | undefined): boolean {
    return proto3.util.equals(StringCondition, a, b);
  }
}

/**
 * @generated from message iam.v1.types.Int32Condition
 */
export class Int32Condition extends Message<Int32Condition> {
  /**
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * @generated from field: repeated int32 values = 2;
   */
  values: number[] = [];

  constructor(data?: PartialMessage<Int32Condition>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Int32Condition";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "values", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Int32Condition {
    return new Int32Condition().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Int32Condition {
    return new Int32Condition().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Int32Condition {
    return new Int32Condition().fromJsonString(jsonString, options);
  }

  static equals(a: Int32Condition | PlainMessage<Int32Condition> | undefined, b: Int32Condition | PlainMessage<Int32Condition> | undefined): boolean {
    return proto3.util.equals(Int32Condition, a, b);
  }
}

/**
 * @generated from message iam.v1.types.BoolCondition
 */
export class BoolCondition extends Message<BoolCondition> {
  /**
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * @generated from field: bool value = 2;
   */
  value = false;

  constructor(data?: PartialMessage<BoolCondition>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.BoolCondition";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BoolCondition {
    return new BoolCondition().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BoolCondition {
    return new BoolCondition().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BoolCondition {
    return new BoolCondition().fromJsonString(jsonString, options);
  }

  static equals(a: BoolCondition | PlainMessage<BoolCondition> | undefined, b: BoolCondition | PlainMessage<BoolCondition> | undefined): boolean {
    return proto3.util.equals(BoolCondition, a, b);
  }
}

/**
 * @generated from message iam.v1.types.Conditions
 */
export class Conditions extends Message<Conditions> {
  /**
   * @generated from field: repeated iam.v1.types.StringCondition string_equals = 1;
   */
  stringEquals: StringCondition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.StringCondition string_not_equals = 2;
   */
  stringNotEquals: StringCondition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.StringCondition string_like = 3;
   */
  stringLike: StringCondition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.Int32Condition numeric_equals = 4;
   */
  numericEquals: Int32Condition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.Int32Condition numeric_not_equals = 5;
   */
  numericNotEquals: Int32Condition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.Int32Condition numeric_less_than = 6;
   */
  numericLessThan: Int32Condition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.Int32Condition numeric_greater_than = 7;
   */
  numericGreaterThan: Int32Condition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.Int32Condition numeric_less_than_equals = 8;
   */
  numericLessThanEquals: Int32Condition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.Int32Condition numeric_greater_than_equals = 9;
   */
  numericGreaterThanEquals: Int32Condition[] = [];

  /**
   * @generated from field: repeated iam.v1.types.BoolCondition bool = 10;
   */
  bool: BoolCondition[] = [];

  constructor(data?: PartialMessage<Conditions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Conditions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "string_equals", kind: "message", T: StringCondition, repeated: true },
    { no: 2, name: "string_not_equals", kind: "message", T: StringCondition, repeated: true },
    { no: 3, name: "string_like", kind: "message", T: StringCondition, repeated: true },
    { no: 4, name: "numeric_equals", kind: "message", T: Int32Condition, repeated: true },
    { no: 5, name: "numeric_not_equals", kind: "message", T: Int32Condition, repeated: true },
    { no: 6, name: "numeric_less_than", kind: "message", T: Int32Condition, repeated: true },
    { no: 7, name: "numeric_greater_than", kind: "message", T: Int32Condition, repeated: true },
    { no: 8, name: "numeric_less_than_equals", kind: "message", T: Int32Condition, repeated: true },
    { no: 9, name: "numeric_greater_than_equals", kind: "message", T: Int32Condition, repeated: true },
    { no: 10, name: "bool", kind: "message", T: BoolCondition, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Conditions {
    return new Conditions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Conditions {
    return new Conditions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Conditions {
    return new Conditions().fromJsonString(jsonString, options);
  }

  static equals(a: Conditions | PlainMessage<Conditions> | undefined, b: Conditions | PlainMessage<Conditions> | undefined): boolean {
    return proto3.util.equals(Conditions, a, b);
  }
}

/**
 * Defines a policy statement.
 *
 * @generated from message iam.v1.types.Statement
 */
export class Statement extends Message<Statement> {
  /**
   * Statement ID
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string effect = 2;
   */
  effect = "";

  /**
   * @generated from field: repeated string actions = 3;
   */
  actions: string[] = [];

  /**
   * @generated from field: repeated string not_actions = 4;
   */
  notActions: string[] = [];

  /**
   * @generated from field: repeated string resources = 5;
   */
  resources: string[] = [];

  /**
   * @generated from field: repeated string not_resources = 6;
   */
  notResources: string[] = [];

  /**
   * @generated from field: iam.v1.types.Conditions conditions = 7;
   */
  conditions?: Conditions;

  constructor(data?: PartialMessage<Statement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Statement";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "effect", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "actions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "not_actions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "resources", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "not_resources", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "conditions", kind: "message", T: Conditions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Statement {
    return new Statement().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Statement {
    return new Statement().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Statement {
    return new Statement().fromJsonString(jsonString, options);
  }

  static equals(a: Statement | PlainMessage<Statement> | undefined, b: Statement | PlainMessage<Statement> | undefined): boolean {
    return proto3.util.equals(Statement, a, b);
  }
}

/**
 * Represents the overall policy.
 *
 * @generated from message iam.v1.types.Policy
 */
export class Policy extends Message<Policy> {
  /**
   * @generated from field: string dri = 1;
   */
  dri = "";

  /**
   * @generated from field: string policy_version = 2;
   */
  policyVersion = "";

  /**
   * @generated from field: string policy_name = 3;
   */
  policyName = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: repeated string scopes = 5;
   */
  scopes: string[] = [];

  /**
   * @generated from field: repeated iam.v1.types.Statement statements = 6;
   */
  statements: Statement[] = [];

  /**
   * @generated from field: string created_at = 7;
   */
  createdAt = "";

  /**
   * @generated from field: string updated_at = 8;
   */
  updatedAt = "";

  /**
   * @generated from field: map<string, string> metadata = 9;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<Policy>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "iam.v1.types.Policy";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "policy_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "policy_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "scopes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "statements", kind: "message", T: Statement, repeated: true },
    { no: 7, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "updated_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Policy {
    return new Policy().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Policy {
    return new Policy().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Policy {
    return new Policy().fromJsonString(jsonString, options);
  }

  static equals(a: Policy | PlainMessage<Policy> | undefined, b: Policy | PlainMessage<Policy> | undefined): boolean {
    return proto3.util.equals(Policy, a, b);
  }
}

