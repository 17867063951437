// Chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import Project1 from "../../assets/Project1.png";
import Project2 from "../../assets/Project2.png";
import Project3 from "../../assets/location.png";
// Custom components
import Card from "../card/Card";
import React from "react";
import Feature from "./Feature";

export default function Features(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  return (
    <Flex flexDir={"column"} mb={{ base: "0px", "2xl": "20px" }}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        New Releases
      </Text>
      <Feature
        mb='20px'
        image={Project1}
        subtitle='In the case something went wrong you can always figure out what?'
        link='/history'
        linkText="Check now"
        title='Track who has access your data'
      />
      <Feature
        mb='20px'
        image={Project2}
        subtitle='Never miss any important events with email notifications.'
        link='/share'
        linkText="Try today"
        title='Get up to dated with notification feature'
      />
      <Feature
        mb='20px'
        image={Project2}
        subtitle='Never miss any important events with email notifications.'
        link='/share'
        linkText="Try today"
        title='Get up to dated with notification feature'
      />
    </Flex>
  );
}
