// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue, Divider, useBreakpointValue } from "@chakra-ui/react";
// Custom components
import Card from "../card/Card";
import PieChart from "../charts/PieChart";
import { pieChartData, pieChartOptions } from "./variables/charts";
import { VSeparator } from "../separator/Separator";
import React from "react";

export default function Conversion(props) {
  console.log({props})
  const { leftData, rightData, data, ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const textSize = useBreakpointValue({base:"lg", xl: "xs", "2xl": "lg"})
  return (
    <Card p='20px' align='center' justifyContent="center" direction='column' w='100%' {...rest}>
      <Text
        color={textColor}
        fontSize={textSize}
        fontWeight='700'
        mx='auto'>
        Roles
      </Text>

      <PieChart
        h='100%'
        w='100%'
        chartData={data}
        chartOptions={pieChartOptions}
      />
      <Card
        bg={cardColor}
        flexDirection='row'
        boxShadow={cardShadow}
        w='100%'
        p='15px'
        px='20px'
        justifyContent="space-evenly"
        mx='0'>
        <Flex direction='column' py='5px'>
          <Flex align='baseline' justifyContent={"center"}>
            <Box h='8px' w='8px' bg='brand.400' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.600'
              fontWeight='700'
              mb='5px'>
              Filled
            </Text>
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
            {leftData}
          </Text>
        </Flex>
        <Divider orientation="vertical" />
        <Flex direction='column' py='5px' me='10px'>
          <Flex align='baseline'>
            <Box h='8px' w='8px' bg='brand.100' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.600'
              fontWeight='700'
              mb='5px'>
              Unfilled
            </Text>
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
            {rightData}
          </Text>
        </Flex>
      </Card>
    </Card>
  );
}
