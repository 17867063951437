import React from "react";
import {
  Card,
  CardBody,
  ChakraProps,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { UniqueIdentifier } from "@dnd-kit/core";
import { FieldItemType } from "../../types/common-types";

type BoxProps = {
  onDelete?: (id: UniqueIdentifier) => void;
};

export default function PalleteElement({
  title,
  type,
  minLength,
  multiline,
  icon,
  onDelete,
  ...rest
}: BoxProps & ChakraProps & FieldItemType): React.ReactElement {
  return (
    <Card cursor={"pointer"} {...rest}>
      <CardBody>
        <VStack spacing={2} w="full" justifyContent={"center"} align={"center"}>
          {icon && <Icon as={icon} boxSize={6} color={"green.500"} />}
          <Text userSelect={"none"}>{title}</Text>
        </VStack>
      </CardBody>
    </Card>
  );
}
