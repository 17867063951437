// Chakra imports
import {
  Flex,
  FormLabel,
  Textarea,
  Text,
  useColorModeValue,
  FormErrorMessage,
  FormControl,
  useBreakpointValue,
} from "@chakra-ui/react";
// Custom components
import React from "react";

export default function TextField(props) {
  const { mb, id, label, extra, placeholder, error, required, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const bgPrimary = useColorModeValue("transparent", "navy.800");
  const borderPrimary = useColorModeValue(
    "secondaryGray.100",
    "whiteAlpha.100"
  );
  const labelSize = useBreakpointValue({
    base: "sm",
    xl: "xs",
    "2xl": "sm",
  });
  const marginBottom = useBreakpointValue({
    base: "30px",
    xl: "15px",
    "2xl": "30px",
  });
  return (
    <FormControl
      isRequired={required}
      width={"100%"}
      direction="column"
      mb={mb ? mb : marginBottom}
    >
      <FormLabel
        display="flex"
        ms="10px"
        htmlFor={id}
        fontSize={labelSize}
        color={textColorPrimary}
        fontWeight="bold"
        _hover={{ cursor: "pointer" }}
      >
        {label}
        <Text fontSize="sm" fontWeight="normal" ms="2px">
          {extra}
        </Text>
      </FormLabel>
      <Textarea
        id={id}
        placeholder={placeholder}
        h="30px"
        maxh="30px"
        color={textColorPrimary}
        fontSize={labelSize}
        bg={bgPrimary}
        border="1px solid "
        borderColor={borderPrimary}
        borderRadius="16px"
        {...rest}
        _placeholder={{ color: "secondaryGray.500" }}
      />
      <Text mr="4" color={"red.400"}>
        {error}
      </Text>
    </FormControl>
  );
}
